import React, { useContext } from "react";
import {
  CubeTransparentIcon,
  EllipsisVerticalIcon,
  InversionProgressIcon,
  LayoutIcon,
  MapPinIcon,
} from "../../assets/icons";
import { NodeDataContext } from "../../contexts";
import ToolbarTooltipPanel from "../workspace/modules/Plotting/Toolbar/ToolbarTooltipPanel";
import { NodeInfo } from "../workspace/modules/Plotting/Toolbar/NodeInfo";
import LineSelect from "../workspace/modules/Plotting/Toolbar/LineSelect";
import TooltipButton from "../common/TooltipButton";
import { LayoutEditor } from "../workspace/modules/Plotting/Toolbar/LayoutEditor";
import CustomizedModebar from "../workspace/modules/Plotting/Toolbar/CustomizedModebar";
import { UpdateInversionVersion } from "../workspace/modules/Plotting/Toolbar/UpdateVersion";
import { useNavigate } from "react-router-dom";
import VersionSelect from "./VersionSelect";
import { useActiveProject } from "../../hooks/useActiveProject";
import { usePlotTools } from "../../contexts/PlotToolsContext";

const PlotToolbar = () => {
  const { nodeData, activeNode, workspaceLayout, nodeType } = useContext(NodeDataContext);
  const { setCurrentDragMode, subplotZooms, setSubplotZooms, isManualEditSelect, setIsManualEditSelect } =
    usePlotTools();
  const { projectTitle } = useActiveProject();
  const navigate = useNavigate();

  const openMap = () => window.open("/views/components/workspace/modules/Map/Map");
  const open3d = () => window.open("/views/components/workspace/modules/View3d/View3d");
  const navigateToInversionProgress = () => {
    navigate(`/project/${projectTitle}/studio/processing-inversion/inversion-progress`);
  };

  return (
    <div className="beryl_plot-toolbar">
      <div title={nodeData?.title} className="px-1 overflow-hidden text-ellipsis whitespace-nowrap text-sm">
        {nodeData?.title}
      </div>

      <VersionSelect />

      <ToolbarTooltipPanel
        content={<NodeInfo />}
        icon={<EllipsisVerticalIcon className="h-5 w-5" />}
        tooltipText={"More info"}
        width={"700px"}
        height={"600px"}
      />
      <LineSelect />

      <div className="beryl_plot-toolbar--group">
        {activeNode?.length === 5 && <UpdateInversionVersion />}

        {activeNode?.length === 5 && (
          <TooltipButton
            onClick={navigateToInversionProgress}
            tooltipText="Inversion progress"
            tooltipSpacing={"3"}
            icon={<InversionProgressIcon />}
          />
        )}

        <ToolbarTooltipPanel
          content={<LayoutEditor />}
          icon={<LayoutIcon />}
          tooltipText={
            workspaceLayout && workspaceLayout[nodeType] && workspaceLayout[nodeType].title
              ? workspaceLayout[nodeType].title
              : "Workspace"
          }
          width={"auto"}
        />
      </div>

      <div className="beryl_plot-toolbar--right">
        {activeNode?.length === 5 && (
          <TooltipButton
            onClick={open3d}
            tooltipText="Open 3d view"
            tooltipSpacing={"3"}
            icon={<CubeTransparentIcon className="h-5 w-5 text-zinc-700" />}
          />
        )}
        <TooltipButton
          onClick={openMap}
          tooltipText="Open map view"
          tooltipSpacing={"3"}
          icon={<MapPinIcon className="h-5 w-5 text-zinc-700" />}
        />
        <CustomizedModebar
          subplotZooms={subplotZooms}
          setSubplotZooms={setSubplotZooms}
          setCurrentDragMode={setCurrentDragMode}
          setIsManualEditSelect={setIsManualEditSelect}
          isManualEditSelect={isManualEditSelect}
        />
      </div>
    </div>
  );
};

export default PlotToolbar;
