import { colorNotInUse, colorNotInUseSecondary, colorSignActive, colorSignActiveSecondary, colorPalette, colorPaletteSecondary } from "./colors";
import {
  getGateTimes,
  markerSize,
  titleDatasetChannel,
  schemaDatasetChannelStyle,
  channel2col,
  channel2inusecol,
  channel2stdcol,
  channel2system,
} from "./utils";

const generic_sounding_gates = (unitDipole) => {
  return {
    title: titleDatasetChannel,
    xaxis: "gatetime",
    yaxis: unitDipole ? "dbdt" : "dbdtmoment",
    fn: function (context, args, elements) {
      const { nodeBinaryByLine, manualEdits, selectedLineId, selectedSoundingId } = context;
      const binary = nodeBinaryByLine?.[args.dataset || "measured"]?.[selectedLineId];
      if (!binary || !binary.flightlines) return [];
      const channel = channel2col(args.channel);
      const sounding = selectedSoundingId;
      const channelnr = parseInt(channel.replace(/[^0-9]*([0-9]*)[^0-9]*/, "$1"));
      const gatetimes = getGateTimes(binary, "Channel" + channelnr.toString());
      const inUseChannel = channel2inusecol(args.channel);
      const stdChannel = channel2stdcol(args.channel);
      const systemChannel = channel2system(args.channel);
      const moment = unitDipole ? 1 : binary.system[systemChannel].ApproxDipoleMoment;
      
      const editBinary = manualEdits?.[selectedLineId];
      const applyIdx = editBinary?.flightlines?.apply_idx;
      const editIdx = applyIdx ? applyIdx.indexOf(sounding) : -1;

      const soundingsAll = context.selectedSoundingIds;
      const soundings = [...new Set(
        soundingsAll.filter((idx) => idx !== selectedSoundingId)
      )].sort().concat([selectedSoundingId]);
      
      let res = [];
      soundings.forEach((sounding) => {
        const traces = [];
        const dbdts = [];
        const stds = [];
        const inUse = [];

        for (var col in binary.layer_data[channel]) {
          dbdts.push(binary.layer_data[channel][col][sounding]);

          if (binary.layer_data[stdChannel] !== undefined) {
            stds.push(Math.abs(binary.layer_data[stdChannel][col][sounding]));
          } else {
            stds.push(NaN);
          }

          let inUseValue = 1;
          if (binary.layer_data[inUseChannel] !== undefined) {
            inUseValue = Number(binary.layer_data[inUseChannel][col][sounding]);
          }

          if (editIdx !== -1) {
            const editInUseValue = editBinary?.layer_data?.[inUseChannel]?.[col][editIdx];
            if (!isNaN(editInUseValue)) {
              inUseValue = editInUseValue;
            }
          }

          inUse.push(inUseValue);
        }

        var xdist = binary.flightlines.xdist[sounding];

        const name = args.channel + " @ " + xdist?.toFixed(0) + "m";

        const ys = dbdts.map((y) => Math.abs(y) * moment * binary.model_info.scalefactor);
        const ysOriginal = dbdts.map((y) => y * moment * binary.model_info.scalefactor);

        const color_idxs = args.showNegative
          ? ysOriginal.map((p, idx) => (p >= 0 ? 1 : 0) + 2 * inUse[idx])
          : ysOriginal.map((p, idx) => 2 * inUse[idx]);

        const lineWidth = 1;
        const style = args.style === "lines" ? "lines+markers" : args.style || "lines+markers";

        elements.yaxis[this.yaxis].exponentformat = "power";
        elements.xaxis[this.xaxis].exponentformat = "power";
        elements.yaxis[this.yaxis].tickfont = {
          size: 10,
        };
        elements.xaxis[this.xaxis].tickfont = {
          size: 10,
        };

        traces.push.apply(
          traces,
          [0, 1, 2, 3].map((color_idx) => {
            const yValues = ys.map((d, idx) =>
              (color_idxs[idx] === color_idx && (color_idxs[idx - 1] >= color_idx || color_idxs[idx + 1] >= color_idx)) ||
              (color_idxs[idx] > color_idx && (color_idxs[idx - 1] === color_idx || color_idxs[idx + 1] === color_idx))
                ? d
                : NaN
            );

            const errorArray = args.showError
              ? ys.map((d, idx) =>
                  inUse[idx] === 1 && (inUse[idx - 1] === 1 || inUse[idx + 1] === 1) ? d * (1 + stds[idx]) - d : NaN
                )
              : null;

            const errorArrayMinus = args.showError
              ? ys.map((d, idx) =>
                  inUse[idx] === 1 && (inUse[idx - 1] === 1 || inUse[idx + 1] === 1) ? d - d / (1 + stds[idx]) : NaN
                )
              : null;

            const errorAmplitudes = stds.map((error) => (error * 100).toFixed(2));

            const customData = errorAmplitudes.map((amplitude, idx) => {
              return {
                error_amplitude: amplitude,
                gate: String(idx).padStart(2, "0"),
                yValue: ysOriginal[idx],
              };
            });

            const xUnit = elements.xaxis[this.xaxis].shortTitle;
            const yUnit = elements.yaxis[this.yaxis].shortTitle;

            const hoverTemplate = `${xdist?.toFixed(
              1
            )} m, %{customdata.yValue:.5e} ± %{customdata.error_amplitude}% ${yUnit} @ %{x} ${xUnit} [Gate%{customdata.gate}]<extra>Ch${String(
              channelnr
            ).padStart(2, "0")} ${args.dataset}</extra>`;

            let trace = {
              type: "scattergl",
              mode: style,
              marker: {
                size: args.style === "lines" ? 0.000001 : markerSize,
              },
              legendgroup: args.channel,
              name: name,
              showlegend: false,
              x: gatetimes,
              y: yValues,
              gate: col,
              error_y:
                args.showError && stds !== null
                  ? {
                      type: "data",
                      symmetric: false,
                      array: errorArray,
                      arrayminus: errorArrayMinus,
                      width: 1,
                      thickness: 1,
                    }
                  : null,
              hoverinfo: context.showHover ? "all" : "none",
              hovertemplate: context.showHover ? hoverTemplate : "",
              customdata: customData,
            };

            return trace;
          })
        );

        const applyColors = (traces) => {
          const primary = soundings.length === 1 || sounding === selectedSoundingId;
          const palette = primary ? colorPalette : colorPaletteSecondary;
          const notInUse = primary ? colorNotInUse : colorNotInUseSecondary;
          const conditionColor = primary ? colorSignActive : colorSignActiveSecondary;
          
          return traces.map((trace, index) => {
            let selectedColor; // Color to be determined based on conditions

            if (args.showNegative) {
              selectedColor = conditionColor[index % conditionColor.length];
            } else {
              // For color_idx 2 when args.showNegative is false, assign dynamically
              if (index % 4 === 2) {
                // Use the numeric part of the legendgroup to calculate the color index
                const legendGroupNumber = parseInt(trace.legendgroup.replace(/\D/g, ""), 10);
                selectedColor = palette[(legendGroupNumber - 1) % colorPalette.length];
              } else {
                selectedColor = notInUse;
              }
            }
            // Apply the selected color with a consistent lineWidth for all traces
            trace.line = {
              color: selectedColor,
              width: lineWidth,
            };

            return trace;
          });
        };

        applyColors(traces);

        res = res.concat(traces);
      });

      return res;
    },

    schema: (context) => {
      const res = schemaDatasetChannelStyle(context);
      res.required.push("showError");
      res.properties.showError = {
        type: "boolean",
        default: false,
      };
      res.properties.showNegative = {
        type: "boolean",
        default: false,
      };
      return res;
    },
  };
};

export const sounding_gates = generic_sounding_gates(true);
export const sounding_gates_dipole_moment = generic_sounding_gates(false);
