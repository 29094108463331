import React from "react";

export const FormLabel = ({
  label,
  htmlFor,
  size = "sm",
  fontWeight = "font-medium",
}) => {
  const sizeClass = {
    xs: "text-xs",
    sm: "text-sm",
    base: "text-base",
  };

  return (
    <label
      htmlFor={htmlFor}
      className={`${sizeClass[size]} mb-1 text-zinc-800 ${fontWeight} normal-case`}
    >
      {label}
    </label>
  );
};
