import React from "react";
import { iconColor } from "./color";

const BoxSelectionIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13V16C0 18.2091 1.79086 20 4 20H7V18.4H4C2.67452 18.4 1.6 17.3255 1.6 16V13H0ZM0 7H1.6V4C1.6 2.67452 2.67452 1.6 4 1.6H7V0H4C1.79086 0 0 1.79086 0 4V7ZM13 0V1.6H16C17.3255 1.6 18.4 2.67452 18.4 4V7H20V4C20 1.79086 18.2091 0 16 0H13ZM20 13H18.4V16C18.4 17.3255 17.3255 18.4 16 18.4H13V20H16C18.2091 20 20 18.2091 20 16V13Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default BoxSelectionIcon;
