import React from "react";
import { iconColor } from "./color";

const LayoutIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.85"
        y="0.85"
        width="18.3"
        height="18.3"
        rx="3"
        stroke={iconColor}
        strokeWidth="1.6"
      />
      <path
        d="M10 1V19"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M10 10L19 10"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LayoutIcon;
