import { useContext, useState } from "react";
import { NodeDataContext } from "../contexts";
import useCreateProcessing from "./useCreateProcessing";
import { createManualEdit } from "../api/createManualEdit";
import { mergeManualEdits } from "../api/binaryUtils";

const useUpdateProcessing = ({ onProcessingSuccess }) => {
  const { activeNode, setActiveNode, nodeData, nodeBinaryByLine, manualEdits } = useContext(NodeDataContext);
  const [isLoading, setIsLoading] = useState(false);
  const isTemplate = false;

  const spec = {};
  spec[nodeData?.class_name] = nodeData?.arguments;

  const parentId = nodeData?.parent?.id;

  const { createProcessingMutation } = useCreateProcessing(
    activeNode[0],
    activeNode[1],
    activeNode[2],
    { id: nodeData?.environment }, // WTF??!?
    { name: nodeData?.title }, // WTF?!
    isTemplate,
    parentId,
    setActiveNode,
    onProcessingSuccess,
    isLoading,
    setIsLoading,
    nodeData?.processing.id
  );

  const update = async (title) => {
    try {
      const manualEdit = await createManualEdit({
        project: activeNode[0],
        title: title,
        differences: mergeManualEdits(nodeBinaryByLine?.processed, manualEdits),
      });

      createProcessingMutation.mutate(
        nodeData.steps.concat([
          {
            "Use manual edits": {
              diff: {
                url: manualEdit?.data.src,
                title: manualEdit?.data.title,
                id: manualEdit?.data.id,
              },
            },
          },
        ])
      );
    } catch (error) {
      console.error("Error in update:", error?.response?.statusText || error);
      throw error; // Rethrow the error to be caught by the caller
    }
  };

  return update;
};

export default useUpdateProcessing;
