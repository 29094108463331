import React from "react";
import { registerSchemaComponent } from "../fieldRegistration";
import { useProjections } from "../../../../hooks/api/useProjections";
import FieldDescription from "../FieldDescription";
import SimpleSearchSelect from "../../../common/SimpleSearchSelect";
import { FormLabel } from "../../../common/forms/Form";

export const ProjectionFormStep = ({ value, schemaFragment, setValue }) => {
  const projectionsQuery = useProjections();

  if (projectionsQuery.data) {
    const projections = Object.entries(projectionsQuery.data).map(([id, title]) => {
      return { id: id, title: title };
    });
    const selected = projections.filter((item) => item.id === parseInt(value, 10))[0];

    return (
      <div className="block grid grid-cols-6 gap-2 items-center mt-1">
        <div className="col-span-2 flex items-center">
          <FormLabel label={"projection"} htmlFor={"projection"} fontWeight="font-normal" />
        </div>
        <div className="col-span-4 flex items-center">
          <SimpleSearchSelect
            items={projections}
            selected={selected}
            setSelected={(proj) => setValue(parseInt(proj.id, 10))}
            inputStyles="projection-select relative text-sm w-96 bg-transparent py-2 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
            inputWidth="w-full"
          />
          <FieldDescription schemaFragment={schemaFragment} />
        </div>

        <div className="col-span-6 flex text-xs text-zinc-600">
          WARNING: If you input the wrong projection your data will not work in the map.
        </div>
      </div>
    );
  } else {
    return <div className="text-zinc-600">No projection data available</div>;
  }
};

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment && schemaFragment.format === "x-epsg";
}, ProjectionFormStep);
