const MapMenuButton = ({ isActive, onClick, icon, label }) => {
  return (
    <button onClick={onClick} className="flex items-center gap-1.5 p-1 h-8 w-8">
      <div className="p-0.5 hover:scale-110 transition ease-in-out duration-200">{icon}</div>
      {isActive && <div className="font-medium">{label}</div>}
    </button>
  );
};

export default MapMenuButton;
