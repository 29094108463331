import client from "../../api/client";

// Fetch and cache the groups data

export const fetchGroups = async (projectId) => {
  const { data } = await client.get(`/api/projects/${projectId}/groups`);
  return data;
};

export const fetchGroupDetails = async (projectId, groupId) => {
  const { data } = await client.get(`/api/projects/${projectId}/groups/${groupId}`);
  return data;
};
