import React from "react";
import Heading from "../../../../../../common/ui/Heading";
import { Description } from "./Description";

export const NodeInfoHeader = ({ nodeData, flightlines, tag }) => {
  return (
    <div className="px-5 py-4 flex flex-col gap-2 overflow-scroll">
      <Heading size="h3">{nodeData?.title}</Heading>
      <Description flightlines={flightlines} tag={tag} />
    </div>
  );
};
