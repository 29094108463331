import React, { useEffect, useState } from "react";
import { ArrowUpTrayIcon, PlusIcon } from "../assets/icons";
import EditDialog from "../components/Sidebar/EditDialog";
import { useEditDialog } from "../hooks/useEditDialog";
import { useNavigate } from "react-router-dom";
import { useActiveProject } from "../hooks/useActiveProject";

const EmptyProjectPage = ({ isEmptyProject, setIsEmptyProject, isEmptyGroups, groupId }) => {
  const { isEditMode, isOpen, handleClosePopup, groupCreateClick } = useEditDialog();
  const { projectTitle } = useActiveProject();
  const navigate = useNavigate();

  const [nodeType, setNodeType] = useState("project");

  console.log("nodeType", nodeType, "isEmptyProject", isEmptyProject);

  useEffect(() => {
    if (isEmptyProject && isEmptyGroups) {
      setNodeType("project");
    } else if (isEmptyProject === false) {
      setNodeType("group");
    }
  }, [isEmptyProject, isEmptyGroups]);

  const nodeContent = {
    group: {
      text: "Import data to your group.",
      buttonText: "Import data",
      icon: <ArrowUpTrayIcon className="w-5 h-5" />,
      popupComponent: null,
    },
    project: {
      text: "Start your project by creating a group for your data.",
      buttonText: "Create group",
      icon: <PlusIcon className="w-5 h-5" />,
      popupComponent: (
        <EditDialog
          isOpen={isOpen}
          isEditMode={isEditMode}
          closePopup={handleClosePopup}
          setIsEmptyProject={setIsEmptyProject}
        />
      ),
    },
  };

  const { text, buttonText, popupComponent, icon } = nodeContent[nodeType] || {
    text: "No content available.",
    buttonText: "",
    icon: null,
    popupComponent: null,
  };

  const handleButtonClick = () => {
    if (nodeType === "group") {
      navigate(`/${projectTitle}/workspace/import`, {
        state: {
          groupId, // send in groupId so the ImportForm gets it
        },
      });
    } else if (nodeType === "project") {
      groupCreateClick();
    }
  };

  return (
    <div className="empty-project-page">
      <div className="empty-project-content">
        <h2>{text}</h2>
        {buttonText && (
          <button className="create-group-btn" onClick={handleButtonClick}>
            {icon} {buttonText}
          </button>
        )}
      </div>
      {popupComponent}
    </div>
  );
};

export default EmptyProjectPage;
