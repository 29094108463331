const InversionIcon = ({ color }) => {
  let inv3 = true;
  if (inv3) {
    return (
      <svg
        width="15.5"
        height="15.5"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="18"
          height="7"
          fill={color}
          fillOpacity="0.15"
        />
        <rect
          x="1"
          y="8"
          width="18"
          height="10"
          fill={color}
          fillOpacity="0.35"
        />
        <path
          d="M3 11V15"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
        <path
          d="M3 4V5"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
        <path
          d="M1 7.61108L19 7.61108"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
        <path
          d="M1 1L19 1"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
        <path
          d="M1 18L19 18"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.8"
          y="0.8"
          width="18.4"
          height="18.4"
          rx="3.2"
          stroke={color}
          strokeWidth="1.6"
        />
        <path
          d="M1 8L19 8"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
        <path
          d="M4 11V16"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
        <path
          d="M4 4V5"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
        />
        <rect
          x="1"
          y="1"
          width="18"
          height="7"
          rx="2"
          fill={color}
          fillOpacity="0.1"
        />
        <rect
          x="1"
          y="8"
          width="18"
          height="11"
          rx="2"
          fill={color}
          fillOpacity="0.3"
        />
      </svg>
    );
  }
};

export default InversionIcon;
