import { useSafeQuery } from "./useSafeQuery";
import client from "../../api/client";

export const fetchAllAuxiliaryData = async ({ projectId }) => {
  if (projectId === undefined) return undefined;
  const { data } = await client.get(`/api/projects/${projectId}/data`);
  return data;
};

export const fetchAuxiliaryData = async ({ projectId, type }) => {
  if (projectId === undefined) return undefined;
  const typeUrl = type.replace("/", "__");
  const { data } = await client.get(
    `/api/projects/${projectId}/datatypes/${typeUrl}/data`
  );
  return data;
};

export const useAuxiliaryData = (dataType, projectId, type) => {
  return useSafeQuery([dataType, projectId, type], () =>
    fetchAuxiliaryData({ projectId, type })
  );
};

export const useAllAuxiliaryData = (projectId) => {
  return useSafeQuery(["allAuxiliaryData", projectId], () =>
    fetchAllAuxiliaryData({ projectId })
  );
};
