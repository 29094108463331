import React from "react";
import { ArrowUpTrayIcon, MapPinIcon } from "../../../assets/icons";
import TooltipButton from "../../common/TooltipButton";

const MiscDataHeader = ({ miscDataCreateClick }) => {
  const openMap = () => window.open("/views/components/workspace/modules/Map/Map");
  return (
    <div className="beryl_misc-data-header">
      <input type="text" placeholder="Search" className="misc-data-search" disabled={true} />
      <TooltipButton
        tooltipSpacing={"2"}
        tooltipText={"Open map"}
        tooltipPlacement="left"
        icon={<MapPinIcon className="w-5 h-5" />}
        onClick={() => openMap()}
      />
      <TooltipButton
        tooltipSpacing={"2"}
        tooltipText={"Upload data"}
        tooltipPlacement="left"
        icon={<ArrowUpTrayIcon className="w-5 h-5" />}
        onClick={() => miscDataCreateClick(true)}
      />
    </div>
  );
};

export default MiscDataHeader;
