import React, { useContext } from "react";
import { registerSchemaComponent } from "../fieldRegistration";
import { ChannelInput } from "./ChannelInput";
import { GateInput } from "./GateInput";

import { NodeDataContext } from "../../../../contexts";

export const ChannelGateField = ({
  value,
  propKey,
  schemaFragment,
  setValue,
}) => {
  const { nodeBinaryByLine } = useContext(NodeDataContext);

  const onSelectChannel = (channel) => {
    const newInternalValue = { ...value };
    newInternalValue.channel = channel.id;
    setValue(newInternalValue);
  };

  const onStartGateClick = (gate) => {
    const newInternalValue = { ...value };
    newInternalValue.gate = parseInt(gate.title);
    setValue(newInternalValue);
  };

  let selectedChannel = value && value.channel ? value.channel : null;

  const revertChannel = (channel) => {
    return `Gate_Ch${channel?.toString().padStart(2, "0")}`;
  };
  const fullChannel = revertChannel(selectedChannel);

  const getChannelList = (nodeBinaryByLine, channel) => {
    if (!nodeBinaryByLine) return null;

    const data = Object.values(nodeBinaryByLine.measured)?.[0].layer_data[
      channel
    ];

    if (!data) return null;

    let length;
    if (data instanceof Float64Array) {
      length = data.length;
    } else if (Array.isArray(data)) {
      length = data.length;
    } else if (typeof data === "object") {
      length = Object.keys(data).length;
    } else {
      length = 0;
    }

    return Array.from({ length }, (_, i) => ({ title: i, id: i }));
  };

  const gates = getChannelList(nodeBinaryByLine, fullChannel);

  const selectedGate = gates?.find((item) => item.title === value?.gate);

  return (
    <div>
      <div className=" mt-1 mb-1">
        <ChannelInput
          value={value}
          onSelectChannel={onSelectChannel}
          propKey={propKey}
          schemaFragment={schemaFragment.properties.channel}
          label={"Channel"}
        />
        <GateInput
          gates={gates}
          selectedGate={selectedGate}
          onSelectGate={onStartGateClick}
          schemaFragment={schemaFragment.properties.gate}
          label={"Gate"}
        />
      </div>
    </div>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment && schemaFragment["x-reference"] === "channel-gate";
}, ChannelGateField);
