import React, { createContext, useContext, useState, useEffect } from "react";

const PlotToolsContext = createContext();

export const PlotToolsProvider = ({ children }) => {
  const [subplotZooms, setSubplotZooms] = useState(() => JSON.parse(localStorage.getItem("subplotZooms")) || {});
  const [currentDragMode, setCurrentDragMode] = useState("zoom");
  const [isManualEditSelect, setIsManualEditSelect] = useState(false);

  useEffect(() => {
    localStorage.setItem("subplotZooms", JSON.stringify(subplotZooms));
  }, [subplotZooms]);

  return (
    <PlotToolsContext.Provider
      value={{
        subplotZooms,
        setSubplotZooms,
        currentDragMode,
        setCurrentDragMode,
        isManualEditSelect,
        setIsManualEditSelect,
      }}
    >
      {children}
    </PlotToolsContext.Provider>
  );
};

export const usePlotTools = () => {
  return useContext(PlotToolsContext);
};
