import { useState, useEffect } from "react";
import client from "../api/client";

export const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!url) {
          setIsLoading(false);
          return;
        }

        const response = await client.get(url);
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
        console.error(error);
      }
    };

    fetchData();
  }, [url]);

  return { data, isLoading, error };
};
