import { resistivity } from "./Resistivity";
import { gates, gatesDipoleMoment } from "./Gates";
import { gates_error_bars, gates_error_bars_dipole_moment } from "./GatesErrorbars";
import { sounding_gates, sounding_gates_dipole_moment } from "./SoundingGates";
import { sounding_gates_error_bars, sounding_gates_error_bars_moment } from "./SoundingGatesErrorbars";
import { sounding_resistivity, sounding_resistivity_logspaced } from "./SoundingResistivity";
import { Elevation } from "./Elevation";
import {
  Pitch,
  Roll,
  GroundSpeed,
  RMF,
  TMI,
  PowerLineMonitor,
  Misfit,
  Topo,
  Current,
  DipoleMoment,
  Altitude,
} from "./AuxData";
import { fake1, fake2, fake3, fake4 } from "./Fake";
import VerticalLine from "./VerticalLine";

const includeFakeElements = false;
const axisTitleColor = "rgb(107 114 128)";
const axisTitleSize = 12;

export const elements = {
  traces: {
    Topography: Topo,
    "Tx elevation": Elevation,
    "Tx altitude": Altitude,
    Misfit: Misfit,
    "Model profile": resistivity,
    "Model sounding plot": sounding_resistivity_logspaced,
    "Model sounding plot (linear)": sounding_resistivity,
    "Data profile (TX-RX norm)": gates,
    "Data profile (RX norm)": gatesDipoleMoment,
    "Data profile errors (TX-RX norm)": gates_error_bars,
    "Data profile errors (RX norm)": gates_error_bars_dipole_moment,
    "Sounding plot (TX-RX norm)": sounding_gates,
    "Sounding plot (RX norm)": sounding_gates_dipole_moment,
    "Sounding plot errors (TX-RX norm)": sounding_gates_error_bars,
    "Sounding plot errors (RX norm)": sounding_gates_error_bars_moment,
    Pitch: Pitch,
    Roll: Roll,
    "Ground speed": GroundSpeed,
    "Residual magnetic field": RMF,
    "Total magnetic intensity": TMI,
    "Power Line Noise Intensity": PowerLineMonitor,
    "Tx Current": Current,
    "Dipole moment": DipoleMoment,
    "Current sounding": VerticalLine,
  },
  xaxis: {
    xdist: {
      title: {
        text: "xdist (m)",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "m",
      onClick: function (ev, context) {
        if (ev.event.button !== 0) return;
        if (ev.event.altKey || ev.event.ctrlKey || ev.event.shiftKey) return;
        const { setSelectedSoundingId } = context;
        // We used to do ev.points[0].pointIndex but that doesn't work for models, only for data...
        setSelectedSoundingId(
          Object.values(context.nodeBinaryByLine)[0][context.selectedLineId].flightlines.xdist.findIndex((a) => a >= ev.points[0].x));
      },
    },
    gatetime: {
      title: {
        text: "time (s)",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "s",
      type: "log",
    },
    log10_ohmm: {
      title: {
        text: "Res (Ωm)",
        longtext: "Resistivity (Ωm)",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "Ωm",
      type: "log",
    },
    ohmm: {
      title: {
        text: "Res (Ωm)",
        longtext: "Linear resistivity (Ωm)",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "Ωm",
    },
    fake: {
      title: {
        text: "fake",
      },
    },
  },
  yaxis: {
    altitude: {
      title: {
        text: "Altitude (m)",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "m",
    },
    elevation: {
      title: {
        text: "Elevation (m AMSL)",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "m AMSL",
    },
    dbdt: {
      title: {
        text: "V/Am^4",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "dBdt",
      type: "log",
    },
    dbdtmoment: {
      title: {
        text: "V/m^2",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "dBdt",
      type: "log",
    },
    degrees: {
      title: {
        text: "Angle (deg)",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "°",
    },
    kmh: {
      title: {
        text: "km/h",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "km/h",
    },
    nT: {
      title: {
        text: "nT",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "nT",
    },
    powerline: {
      title: {
        text: "",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
    },
    misfit: {
      title: {
        text: "RMSE / uncert",
        font: {
          size: axisTitleSize,
          color: "rgb(255 0 0)", // Note: Intentionally not the same as all other axis :)
        },
      },
      shortTitle: "dBdt",
    },
    ampere: {
      title: {
        text: "Amp",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "A",
    },
    moment: {
      title: {
        text: "Moment",
        font: {
          size: axisTitleSize,
          color: axisTitleColor,
        },
      },
      shortTitle: "mom",
    },
    fake: {
      title: {
        text: "fake",
      },
    },
  },
  coloraxis: {
    log10_ohmm: {
      colorscale: "Portland",
      cmin: 0,
      cmax: 4,
      colorbar: {
        title: {
          text: "Res (Ωm)",
          longtext: "Resistivity (Ωm)",
          font: {
            size: axisTitleSize,
            color: axisTitleColor,
          },
        },
        thickness: 30,
        len: 1,
        x: 1.05,
        outlinewidth: 0,
        tickmode: "array",
        tickvals: [0, 1, 2, 3, 4],
        ticktext: ["1", "10", "100", "1000", "10000"],
        logspace: true,
      },
    },
    ohmm: {
      colorscale: "Portland",
      cmin: 1,
      cmax: 10000,
      colorbar: {
        title: {
          text: "Res (Ωm)",
          longtext: "Linear resistivity (Ωm)",
          font: {
            size: axisTitleSize,
            color: axisTitleColor,
          },
        },
        thickness: 30,
        len: 1,
        x: 1.15,
        outlinewidth: 0,
        tickmode: "auto",
        nticks: 10,
        logspace: false,
      },
    },
    test: {
      colorbar: {
        x: 1.1,
        title: {
          text: "test",
        },
        thickness: 30,
        len: 0.4,
        outlinewidth: 0,
        tickmode: "array",
        tickvals: [1, 2, 3, 4],
        ticktext: ["1", "2", "3", "4"],
      },
    },
  },
};

if (includeFakeElements) {
  elements.traces["Fake 1"] = fake1;
  elements.traces["Fake 2"] = fake2;
  elements.traces["Fake 3"] = fake3;
  elements.traces["Fake 4"] = fake4;
}
