export const TableRow = ({ children }) => (
  <tr className="items-center bg-white text-sm border-b border-zinc-200 hover:bg-zinc-50">
    {children}
  </tr>
);

export const TableCell = ({ children }) => (
  <td className="px-6 py-2">{children}</td>
);

export const TableHeaderCell = ({ children }) => (
  <th
    scope="row"
    className="px-6 py-2 font-medium text-zinc-800 whitespace-nowrap flex gap-3 items-center"
  >
    {children}
  </th>
);
