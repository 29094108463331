import { fetchDatasetProgress } from "./useDatasets";
import { fetchProcessingProgress } from "./useProcessings";
import { fetchInversionProgress } from "./useInversions";
import jsyaml from "js-yaml";
import { useEffect, useState } from "react";
import { useSafeQuery } from "./useSafeQuery";

// Fetch and cache the node progress data based on activeNode length

const useFetchProgress = (activeNode) => {
  return useSafeQuery(["nodeLogs", activeNode], () => fetchProgressData(activeNode));
};

export const useNodeProgress = (activeNode) => {
  const [plots, setPlots] = useState([]);
  const { data: nodeLogsQueryData, refetch } = useFetchProgress(activeNode);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 2000); // Refetch every 2 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [refetch]);

  // Process nodeLogsQueryData to prepare for plotting
  useEffect(() => {
    const progressPlotData = nodeLogsQueryData?.logs?.Task;

    if (progressPlotData) {
      const filteredData = progressPlotData.filter((item) => item.iter !== undefined);
      const xValues = filteredData.map((item) => item.iter);

      let plotsData = [];

      if (filteredData && filteredData.length > 0 && filteredData[0]) {
        plotsData = Object.keys(filteredData[0])
          .filter((key) => key !== "iter" && key !== "status" && key !== "step")
          .map((key) => ({
            x: xValues,
            y: filteredData.map((item) => item[key]),
            type: "scatter",
            mode: "lines",
            name: key,
          }));
      }

      setPlots(plotsData);
    }
  }, [nodeLogsQueryData]);

  const yamlifyLogs = jsyaml.dump(nodeLogsQueryData?.logs || null);

  return { plots, yamlifyLogs };
};

const fetchProgressData = (activeNode) => {
  if (activeNode && activeNode.length === 3) {
    return fetchDatasetProgress(activeNode[0], activeNode[1], activeNode[2]);
  } else if (activeNode && activeNode.length === 4) {
    //console.log("Refetch progress processing");
    return fetchProcessingProgress(activeNode[0], activeNode[1], activeNode[2], activeNode[3]);
  } else if (activeNode && activeNode.length === 5) {
    //console.log("Refetch progress inversion");
    return fetchInversionProgress(activeNode[0], activeNode[1], activeNode[2], activeNode[3], activeNode[4]);
  } else {
    return null;
  }
};
