import { useState, useEffect } from "react";
import client from "../api/client";
import GeoJSON from "ol/format/GeoJSON.js";
import { DEFAULT_STYLE } from "../utils/flightlineStyles";

const extractGeoJSONFeatures = (data) => {
  if (!data) return [];
  return new GeoJSON().readFeatures(data).map((feature) => {
    feature.set("style", DEFAULT_STYLE);
    return feature;
  });
};

const getDataUrl = (nodeType, projectId, groupId, datasetId, processingId, inversionId, component) => {
  const urls = {
    dataset: `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/${component}/geojson`,
    processing: `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${processingId}/${component}/geojson`,
    inversion: `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${processingId}/inversions/${inversionId}/${component}/geojson`,
  };
  return urls[nodeType];
};

export const useFlightlineGeojson = (params) => {
  const { nodeType, projectId, groupId, datasetId, processingId, inversionId, component } = params;
  const [flightlineFeatures, setFlightlineFeatures] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = getDataUrl(nodeType, projectId, groupId, datasetId, processingId, inversionId, component);
      if (url) {
        try {
          const { data } = await client.get(url);
          setFlightlineFeatures(extractGeoJSONFeatures(data));
        } catch (error) {
          console.error(`Error fetching geojson for ${params.nodeType}:`, error);
        }
      }
    };

    fetchData();
  }, [nodeType, projectId, groupId, datasetId, processingId, inversionId, component, params.nodeType]);

  return flightlineFeatures;
};
