import { useSafeQuery } from "./useSafeQuery";
import client from "../../api/client";
import useSharedState from "../useSharedState";

export const fetchTransactions = async ({ projectId }) => {
  if (projectId === undefined) {
    return undefined;
  }
  const { data } = await client.get(`/api/projects/${projectId}/transactions`);
  return data;
};

export const useTransactions = (projectId) => {
  const [lastTransaction, setLastTransaction] = useSharedState("lastTransaction", null); // eslint-disable-line
  
  return useSafeQuery(["transactions", projectId, lastTransaction], () =>
    fetchTransactions({ projectId })
  );
};
