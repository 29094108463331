import Uuid from "./utils/uuid"; // eslint-disable-line
import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import ProfilePage from "./pages/ProfilePage";
import PaymentPage from "./pages/PaymentPage";
import PlotPage from "./pages/PlotPage";
import ErrorPage from "./components/common/ErrorPage";
import BillingPage from "./pages/BillingPage";

import { Messages } from "./components/common/Messages";
import { MessagesProvider } from "./contexts/MessagesContext";
import { WorkspaceProvider } from "./contexts/WorkspaceContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { NodeDataProvider, NodeDataContext } from "./contexts/NodeDataContext";
import { NodeInfoProvider } from "./contexts/NodeInfoContext";

import { useQuery } from "@tanstack/react-query";
import { InteractiveStateProvider, useInteractiveStates } from "./contexts/InteractiveStateContext";
import { NodeStatusProvider } from "./contexts/NodeStatusContext";
import { DialogProvider } from "./contexts/DialogContext";
import EnvironmentPage from "./pages/EnvironmentPage";
import NiceJsonEditorPage from "./pages/NiceJsonEditorPage";
import WorkspaceLayout from "./components/Layouts/WorkspaceLayout";
import { PlotToolsProvider } from "./contexts/PlotToolsContext";
import { PlotContextProvider } from "./components/workspace/modules/Plotting/PlotContext";
import { ImportForm } from "./components/Forms/ImportForm";
import ProcessingForm from "./components/Forms/ProcessingForm";
import InversionForm from "./components/Forms/InversionForm";
import Home from "./pages/Home";

const ComponentPage = () => {
  const fullPath = window.location.pathname.slice("/views/".length);
  const path = fullPath.slice(0, fullPath.lastIndexOf("/"));
  const componentName = fullPath.slice(fullPath.lastIndexOf("/") + 1);

  const componentQuery = useQuery({
    queryKey: [path],
    queryFn: () => {
      return import(`./${path}`);
    },
    persister: null,
  });

  if (componentQuery.data) {
    const Component = componentQuery.data[componentName];
    return <Component />;
  } else if (componentQuery.isError) {
    return <div>Error loading component: {JSON.stringify(componentQuery.error)}</div>;
  } else {
    return <div>Loading...</div>;
  }
};

const PageTitle = () => {
  const { nodeData, workspaceLayout, nodeType } = useContext(NodeDataContext);
  const { selectedLineId } = useInteractiveStates();

  //const title = "Beryl | " + nodeData.title + " | " + selectedLineId + " | " + workspaceLayout[nodeType].title;
  const title =
    workspaceLayout && workspaceLayout[nodeType] && workspaceLayout[nodeType].title
      ? "Beryl | Workspace | " + workspaceLayout[nodeType].title
      : "Beryl";
  useEffect(() => {
    if (nodeData !== undefined) {
      document.title = title;
    } else {
      document.title = "Beryl";
    }
  }, [nodeData, selectedLineId, workspaceLayout, nodeType, title]);
};

const App = () => {
  if (window.location.pathname.startsWith("/accounts")) return null;

  return (
    <MessagesProvider>
      <NodeStatusProvider>
        <DialogProvider>
          <SidebarProvider>
            <InteractiveStateProvider>
              <NodeDataProvider currentPath={window.location.pathname}>
                <PlotContextProvider>
                  <PlotToolsProvider>
                    <WorkspaceProvider>
                      <NodeInfoProvider>
                        <PageTitle />
                        <div className="app">
                          <Messages />
                          <Router>
                            <Routes>
                              <Route path="/environments" element={<EnvironmentPage />} />

                              <Route path="/nicejsoneditor" element={<NiceJsonEditorPage />} />

                              <Route path="/payment/return" element={<PaymentPage />} />
                              <Route path="/project/billing" element={<BillingPage />} />

                              <Route exact path="/" element={<Home />} />

                              <Route path="/:projectSlug/*" element={<WorkspaceLayout />}>
                                <Route path="workspace" element={<PlotPage />} />
                                <Route path="workspace/import" element={<ImportForm />} />
                                <Route path="workspace/process" element={<ProcessingForm />} />
                                <Route path="workspace/invert" element={<InversionForm />} />
                              </Route>

                              <Route path="/profile" element={<ProfilePage />} />
                              <Route path="/views/*" element={<ComponentPage />} />
                              <Route path="*" element={<ErrorPage message={"404 Page not found"} />} />
                              <Route path="*" element={<Navigate to="/" replace />} />
                            </Routes>
                          </Router>
                        </div>
                      </NodeInfoProvider>
                    </WorkspaceProvider>
                  </PlotToolsProvider>
                </PlotContextProvider>
              </NodeDataProvider>
            </InteractiveStateProvider>
          </SidebarProvider>
        </DialogProvider>
      </NodeStatusProvider>
    </MessagesProvider>
  );
};

export default App;
