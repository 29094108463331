import React from "react";
import SidebarHeader from "./SidebarHeader";
import SidebarTabs from "./SidebarTabs";
import { useSidebar } from "../../contexts/SidebarContext";
import SidebarFooter from "./SidebarFooter";

const Sidebar = () => {
  const { isCollapsed, sidebarWidth, handleSidebarResize } = useSidebar();

  const onDragStart = (e) => {
    e.preventDefault();

    const onDrag = (e) => {
      const newWidth = Math.max(300, Math.min(e.clientX, 600)); // Constrain between 300px and 600px
      handleSidebarResize(newWidth); // Update the sidebar width in context
    };

    const onDragEnd = () => {
      document.removeEventListener("mousemove", onDrag);
      document.removeEventListener("mouseup", onDragEnd);

      setTimeout(() => {
        window.dispatchEvent(new Event("resize")); // Resize event to make sure plot updates size
      }, 5); // Delay
    };

    document.addEventListener("mousemove", onDrag);
    document.addEventListener("mouseup", onDragEnd);
  };

  return (
    <div
      className={`beryl_sidebar ${isCollapsed ? "collapsed" : "expanded"}`}
      style={{ width: isCollapsed ? "300px" : `${sidebarWidth}px` }}
    >
      <SidebarHeader />

      {!isCollapsed && (
        <div className="beryl_sidebar-content">
          <SidebarTabs />
        </div>
      )}

      {!isCollapsed && <SidebarFooter />}

      {!isCollapsed && <div className="resize-handle" onMouseDown={onDragStart} />}
    </div>
  );
};

export default Sidebar;
