import React from "react";
import { ChannelInput } from "./ChannelInput";
import { registerSchemaComponent } from "../fieldRegistration";

export const ChannelFormStep = ({
  value,
  propKey,
  schemaFragment,
  setValue,
}) => {
  const onSelectChannel = (item) => {
    const channel = item.id;
    setValue(channel);
  };
  return (
    <ChannelInput
      value={value}
      onSelectChannel={onSelectChannel} // Pass the channel number to ChannelInput
      propKey={propKey}
      schemaFragment={schemaFragment}
      label={"Channel"}
    />
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment && schemaFragment["x-reference"] === "channel";
}, ChannelFormStep);
