import React from "react";
import { useSidebar } from "../../contexts/SidebarContext";
import { ShrinkSidebarIcon } from "../../assets/icons";

const SidebarToggleButton = () => {
  const { toggleSidebar } = useSidebar();

  return (
    <button onClick={toggleSidebar} className="sidebar-toggle-button">
      <ShrinkSidebarIcon />
    </button>
  );
};

export default SidebarToggleButton;
