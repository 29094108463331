import { useQueryClient, useMutation } from "@tanstack/react-query";
import client from "../api/client";

const useCreateAuxData = (projectId, setShowForm, setIsLoading, setErrorMsg, setProjection) => {
  const queryClient = useQueryClient();

  const createAuxDataMutation = useMutation({
    mutationFn: (data) =>
      client.post(`/api/projects/${projectId}/data/`, {
        src: data.src,
        datatype_id: data.datatype_id,
        tags_ids: data.tags_ids,
        title: data.title,
        project: data.project,
        projection: data.projection,
      }),
    onSuccess: (response) => {
      if (response.status === 200 && response.data === "Error: Could not find EPSG code!") {
        alert("Error: Could not find EPSG code! Please upload and specify EPSG code.");
      }

      queryClient.invalidateQueries("allAuxiliaryData");

      setIsLoading(false);
      setShowForm(false); // Close the form
      setProjection(null); // Null out projection
    },
    onError: (error) => {
      console.log("RESPONSE", error.response);
      setProjection(null); // Null out projection
      if (error.response.status === 401) {
        setErrorMsg(`${error.response.data.message}`);
      } else {
        console.error("Error creating aux data.", error.message);
        setErrorMsg(`Error saving upload, ${error.message}`);
      }
    },
  });

  const submitAuxiliaryData = (data) => {
    createAuxDataMutation.mutate(data);
  };

  return {
    createAuxDataMutation,
    submitAuxiliaryData,
  };
};

export default useCreateAuxData;
