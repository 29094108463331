import React from "react";
export * from "./TableComponents";

const Table = ({ columns = [], data = [], renderRow = () => {}, emptyMessage = "No data available" }) => {
  return (
    <div className="relative rounded-lg">
      <table className="w-full text-left text-zinc-800 bg-white rounded-md table-auto rounded-lg">
        <thead className="text-sm text-zinc-700 bg-transparent bg-zinc-100 border-b border-t border-zinc-200 rounded-lg">
          <tr>
            {columns.map((col, index) => (
              <th key={index} scope="col" className="px-6 py-3 font-medium">
                {col.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, rowIndex) => renderRow(item, rowIndex))
          ) : (
            <tr>
              <td colSpan={columns.length} className="px-6 py-4 text-zinc-600">
                {emptyMessage}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
