import client from "./client";
import { diff, packBinary } from "./fetchBinary";

export const createManualEditFromDiff = async ({ project, title, orig, modified }) => {
  return await createManualEdit({
    project,
    title,
    differences: diff(orig, modified)});
};

export const createManualEdit = async ({ project, title, differences }) => {
  try {
    const msgpackdata = packBinary(differences);

    const blob = new Blob([msgpackdata], {
      type: "application/octet-stream",
    });

    const formData = new FormData();
    formData.append("file", blob, "diff.msgpack");

    const fileUpload = await client.post(`/api/file/new`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("File Upload Response:", fileUpload);

    const manualEditResponse = await client.post(
      `/api/projects/${project}/manual-edit/`,
      {
        title,
        description: "",
        project: project,
        survey: null,
        src: fileUpload.data.file,
      }
    );

    console.log("Manual Edit Response:", manualEditResponse);

    return manualEditResponse;
  } catch (error) {
    console.error("Error in createManualEdit:", error.response.statusText);
    throw error;
  }
};
