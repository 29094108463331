import React, { useContext } from "react";
import { SchemaComponent, registerSchemaComponent } from "../fieldRegistration";
import { NodeDataContext } from "../../../../contexts";

export const FlightlineColumnsList = ({
  schemaFragment,
  ...props
}) => {
  const { nodeBinaryByLine } = useContext(NodeDataContext);

  let columns = Object.keys(Object.values(nodeBinaryByLine.measured)[0].flightlines);
  
  return (<SchemaComponent
            {...props}
            schemaFragment={{
              ...schemaFragment,
              items: {
                ...schemaFragment.items,
                "x-reference": undefined,
                enum: columns}}}
          />);
};

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment && schemaFragment.items?.["x-reference"] === "flightline-column-name";
}, FlightlineColumnsList);
