import { useContext } from "react";
import { NodeDataContext } from "../../contexts";
import SimpleSelect from "../common/SimpleSelect";
import { ChevronUpDownIcon } from "../../assets/icons";
import { formatVersionTimestamp } from "../../utils";

const VersionSelect = () => {
  const { nodeData, activeNode, setActiveNode } = useContext(NodeDataContext);

  if (!nodeData || nodeData.versions === undefined) return null;

  const items = nodeData.versions
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map((item) => {
      const version = formatVersionTimestamp(item.created_at);
      return { ...item, title: version };
    });

  const nodeId = activeNode.slice(-1)[0];
  const versionId = nodeId.length ? nodeId[1] : undefined;
  const selected = versionId !== undefined ? items.filter((item) => item.id === versionId)[0] : undefined;

  return (
    <SimpleSelect
      items={items}
      selected={selected}
      setSelected={(item) => {
        console.log("Set version", item.id);
        let nodeId = activeNode.slice(-1)[0];
        if (nodeId.length) nodeId = nodeId[0];
        setActiveNode(activeNode.slice(0, -1).concat([[nodeId, item.id]]));
      }}
      width="w-fit"
      nothingTitle="Latest"
      buttonStyles={`relative flex items-center gap-2 justify-between w-fit rounded-md p-0.5 px-1.5
       border border-zinc-200 bg-zinc-50 text-zinc-800 text-sm hover:border-zinc-500`}
      IconComponent={ChevronUpDownIcon}
      iconPosition="after"
      dropdownWidth="w-48"
    />
  );
};

export default VersionSelect;
