const Point = () => {
  return <div className="beryl_color-swatch--pointer"></div>;
};

const ColorSwatch = ({ colors, selectedColor, setSelectedColor }) => {
  return (
    <div className="beryl_color-swatch">
      {colors.map((color) => (
        <button
          key={color}
          onClick={() => setSelectedColor(color)}
          className="beryl_color-swatch--item"
          style={{ backgroundColor: color }}
        >
          {selectedColor === color && <Point />}
        </button>
      ))}
    </div>
  );
};

export default ColorSwatch;
