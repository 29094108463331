import React from "react";

const Layout1x1 = ({ strokeColor }) => {
  return (
    <svg
      width="48"
      height="34"
      viewBox="0 0 40 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="29" rx="1" fill="black" fillOpacity="0.3" />
    </svg>
  );
};

export default Layout1x1;
