import React from "react";

const DatasetIcon = ({ color }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2 4.5C19.2 5.23175 18.5023 6.16064 16.7428 6.95244C15.0583 7.71046 12.6751 8.2 10 8.2C7.32491 8.2 4.9417 7.71046 3.25722 6.95244C1.49766 6.16064 0.8 5.23175 0.8 4.5C0.8 3.76825 1.49766 2.83936 3.25722 2.04756C4.9417 1.28954 7.32491 0.8 10 0.8C12.6751 0.8 15.0583 1.28954 16.7428 2.04756C18.5023 2.83936 19.2 3.76825 19.2 4.5Z"
        stroke={color}
        strokeWidth="1.6"
      />
      <path
        d="M19 5.5V15"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M1 5.5V15.5"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M19 10C19 12.2091 14.9706 14 10 14C5.02944 14 1 12.2091 1 10"
        stroke={color}
        strokeWidth="1.6"
      />
      <path
        d="M19 15C19 17.2091 14.9706 19 10 19C5.02944 19 1 17.2091 1 15"
        stroke={color}
        strokeWidth="1.6"
      />
    </svg>
  );
};

export default DatasetIcon;
