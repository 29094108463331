import { useEffect, useState } from "react";

const Slider = ({ itemId, setMapLayout, mapLayout, map }) => {
  const [value, setValue] = useState(
    () => mapLayout.rasters_configs[itemId]?.opacity || 1
  );

  // Effect to initialize the slider value from the global state when component mounts
  useEffect(() => {
    const initialOpacity = mapLayout.rasters_configs[itemId]?.opacity || 1;
    setValue(initialOpacity);

    const allLayers = map.current?.overlayLayers?.getLayers();

    if (allLayers) {
      allLayers.forEach((layer) => {
        if (layer.get("layerId") === itemId) {
          layer.setOpacity(parseFloat(initialOpacity));
        }
      });
    }
  }, [itemId, mapLayout, map]);

  const handleImmediateChange = (event) => {
    const newOpacity = parseFloat(event.target.value);
    setValue(newOpacity); // Update local state for the slider

    const allLayers = map.current?.overlayLayers?.getLayers();
    if (allLayers) {
      allLayers.forEach((layer) => {
        if (layer.get("layerId") === itemId) {
          layer.setOpacity(newOpacity);
        }
      });
    }
  };

  // Update global state when user finishes dragging
  const handleDragEnd = () => {
    let newLayout = { ...mapLayout };
    newLayout.rasters_configs = {
      ...newLayout.rasters_configs,
      [itemId]: { ...newLayout.rasters_configs[itemId], opacity: value },
    };
    setMapLayout(newLayout);
  };

  return (
    <div className="flex items-center gap-2">
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={value}
        onChange={handleImmediateChange}
        onMouseUp={handleDragEnd}
        onTouchEnd={handleDragEnd}
        className="slider w-full h-1.5 bg-zinc-200 rounded-full cursor-pointer appearance-none cursor-pointer dark:bg-zinc-400"
      />
      <div className="text-zinc-300">{value.toFixed(2)}</div>
    </div>
  );
};

export default Slider;
