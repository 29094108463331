import React, { useCallback, useContext, useEffect, useState } from "react";
import CreateProjectForm from "./CreateProjectForm";
import { useProjects } from "../../hooks/api/useProjects";
import ErrorPage from "../common/ErrorPage";
import { NodeDataContext } from "../../contexts";
import ProjectCard from "./ProjectCard";
import { PopupDialog } from "../common/PopupDialog";
import { LoadingPlaceholder } from "../common/LoadingPlaceholder";
import { useNavigate } from "react-router-dom";
import { slugify } from "../../utils";
import { PlusIcon } from "../../assets/icons";

const Projects = () => {
  const { setActiveNode } = useContext(NodeDataContext);
  const { data: projects, isLoading, error } = useProjects();

  const [filteredProjects, setFilteredProjects] = useState([]);

  const saveProjectDetails = (title, id) => {
    const projectInfo = { title: title, projectId: id };
    localStorage.setItem("activeProject", JSON.stringify(projectInfo));
  };

  const saveRecentlyViewed = (projectId) => {
    let recentlyViewed = JSON.parse(localStorage.getItem("recentlyViewedProjectIds")) || [];
    recentlyViewed = recentlyViewed.filter((id) => id !== projectId); // Remove the project ID if it already exists
    recentlyViewed.unshift(projectId); // Add the project ID to the start
    recentlyViewed = recentlyViewed.slice(0, 4); // Limit number of entries
    localStorage.setItem("recentlyViewedProjectIds", JSON.stringify(recentlyViewed));
  };

  const updateFilteredProjects = useCallback(() => {
    const storedProjectIds = JSON.parse(localStorage.getItem("recentlyViewedProjectIds")) || [];

    const updatedFilteredProjects = storedProjectIds
      .map((id) => projects?.find((project) => project.id === id))
      .filter((project) => project !== undefined); // Filter out any undefined entries in case a project ID wasn't found

    setFilteredProjects(updatedFilteredProjects);
  }, [projects]);

  useEffect(() => {
    updateFilteredProjects();
  }, [updateFilteredProjects]);

  const [isOpen, setIsOpen] = useState(false);
  const handleOpenPopup = () => setIsOpen(true);
  const handleClosePopup = () => setIsOpen(false);

  let navigate = useNavigate();

  const handleProjectClick = async (project) => {
    saveRecentlyViewed(project.id);
    setActiveNode([project.id]);
    saveProjectDetails(project.title, project.id);
    updateFilteredProjects();

    const title = slugify(project.title);
    // Navigate after all actions are complete
    navigate(`/${title}/workspace`);
  };

  if (isLoading) return <LoadingPlaceholder />;
  if (error) return <ErrorPage message={`${"Ooops 😣 An error occured: "} ${error.message}`} />;

  return (
    <div className="beryl_projects">
      <div className="text-zinc-800  text-xl font-medium">Recents</div>
      <div className="beryl_projects--section">
        <ul>
          {filteredProjects.map((project) => (
            <li key={project.id}>
              <ProjectCard title={project.title} projectId={project.id} onClick={() => handleProjectClick(project)} />
            </li>
          ))}
          <li key={"new-project-button"}>
            <button onClick={handleOpenPopup} className="beryl_create-project-btn">
              <div className="square">
                <PlusIcon className="w-8 h-8" />
              </div>
              New project
            </button>
          </li>
        </ul>
      </div>
      <div className="text-zinc-800 text-xl font-medium">All projects</div>
      <div className="beryl_projects--section">
        <ul>
          {projects
            ?.slice()
            .reverse()
            .map((project) => (
              <li key={project.id}>
                <ProjectCard title={project.title} projectId={project.id} onClick={() => handleProjectClick(project)} />
              </li>
            ))}
        </ul>
      </div>
      <PopupDialog isOpen={isOpen} closeModal={handleClosePopup} title="Create new project" width="md">
        <CreateProjectForm
          closePopup={handleClosePopup}
          handleProjectClick={handleProjectClick}
          existingProjects={projects}
        />
      </PopupDialog>
    </div>
  );
};

export default Projects;
