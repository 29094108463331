import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../api/client";
import handleError from "../api/handleError";

const useDeleteGroup = (groupId) => {
  const queryClient = useQueryClient();

  const deleteGroupMutation = useMutation({
    mutationFn: (groupId) => client.delete(`/api/groups/${groupId}/`),
    onSuccess: () => {
      queryClient.invalidateQueries("groups");
    },
    onError: (error) => {
      handleError(error, "An error occured while deleting a group.");
    },
  });

  const handleDeleteGroup = (groupId) => {
    if (window.confirm("Are you sure you want to delete this group?")) {
      deleteGroupMutation.mutate(groupId);
    }
  };

  return {
    handleDeleteGroup,
    isError: deleteGroupMutation.isError,
    error: deleteGroupMutation.error,
  };
};

export default useDeleteGroup;
