import React from "react";
import { FormLabel } from "../../../common/forms/Form";
import SimpleSelect from "../../../common/SimpleSelect";
import FieldDescription from "../FieldDescription";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";

export const GateInput = ({
  gates,
  selectedGate,
  onSelectGate,
  schemaFragment,
  label,
}) => {
  return (
    <div className="block grid grid-cols-6 gap-2 items-center mt-1">
      <div className="col-span-2 flex items-center">
        <FormLabel label={label} htmlFor={"gate"} fontWeight="font-normal" />
      </div>
      <div className="col-span-4 flex items-center">
        <SimpleSelect
          items={gates}
          width="w-20"
          dropdownWidth="w-full"
          selected={selectedGate}
          setSelected={onSelectGate}
          nothingTitle="-"
          buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
          IconComponent={ChevronUpDownIcon}
          iconPosition="after"
        />
        <FieldDescription schemaFragment={schemaFragment} />
      </div>
    </div>
  );
};
