import React from "react";
import { strokeColor } from "./layout/variables";

const MagicWandIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.36073 8.36072C8.10641 8.61505 8.10633 9.02758 8.36088 9.28213L17.7177 18.639C17.9723 18.8935 18.3848 18.8934 18.6391 18.6391C18.8935 18.3848 18.8935 17.9723 18.639 17.7177L9.28214 8.36087C9.02759 8.10632 8.61506 8.1064 8.36073 8.36072ZM7.41083 10.2322C6.63172 9.45307 6.63151 8.18984 7.41068 7.41067C8.18985 6.6315 9.45308 6.63171 10.2322 7.41082L19.589 16.7677C20.3681 17.5468 20.3684 18.81 19.5892 19.5892C18.81 20.3683 17.5468 20.3681 16.7677 19.589L7.41083 10.2322Z"
        fill={strokeColor}
      />
      <path
        d="M7.37494 7.40234C7.25607 7.52119 7.16178 7.66228 7.09744 7.81757C7.03311 7.97286 7 8.1393 7 8.30739C7 8.47548 7.03311 8.64192 7.09744 8.79721C7.16178 8.9525 7.25607 9.0936 7.37494 9.21245L9.18989 11.0274L11 9.2173L9.18504 7.40234C9.0662 7.28347 8.9251 7.18918 8.76981 7.12485C8.61452 7.06052 8.44808 7.0274 8.27999 7.0274C8.1119 7.0274 7.94546 7.06052 7.79017 7.12485C7.63488 7.18918 7.49378 7.28347 7.37494 7.40234Z"
        fill={strokeColor}
      />
      <path
        d="M12.51 2.48543C12.5699 2.30117 12.8305 2.30117 12.8904 2.48543L13.1604 3.31654C13.1872 3.39894 13.264 3.45474 13.3507 3.45474H14.2245C14.4183 3.45474 14.4988 3.70266 14.3421 3.81654L13.6351 4.33019C13.565 4.38112 13.5357 4.47139 13.5625 4.5538L13.8325 5.3849C13.8924 5.56916 13.6815 5.72239 13.5247 5.60851L12.8178 5.09486C12.7477 5.04393 12.6527 5.04393 12.5826 5.09486L11.8757 5.60851C11.7189 5.72239 11.508 5.56916 11.5679 5.3849L11.8379 4.5538C11.8647 4.47139 11.8354 4.38112 11.7653 4.33019L11.0583 3.81654C10.9016 3.70266 10.9821 3.45474 11.1759 3.45474H12.0497C12.1364 3.45474 12.2132 3.39894 12.2399 3.31654L12.51 2.48543Z"
        fill={strokeColor}
      />
      <path
        d="M12.51 2.48543C12.5699 2.30117 12.8305 2.30117 12.8904 2.48543L13.1604 3.31654C13.1872 3.39894 13.264 3.45474 13.3507 3.45474H14.2245C14.4183 3.45474 14.4988 3.70266 14.3421 3.81654L13.6351 4.33019C13.565 4.38112 13.5357 4.47139 13.5625 4.5538L13.8325 5.3849C13.8924 5.56916 13.6815 5.72239 13.5247 5.60851L12.8178 5.09486C12.7477 5.04393 12.6527 5.04393 12.5826 5.09486L11.8757 5.60851C11.7189 5.72239 11.508 5.56916 11.5679 5.3849L11.8379 4.5538C11.8647 4.47139 11.8354 4.38112 11.7653 4.33019L11.0583 3.81654C10.9016 3.70266 10.9821 3.45474 11.1759 3.45474H12.0497C12.1364 3.45474 12.2132 3.39894 12.2399 3.31654L12.51 2.48543Z"
        fill={strokeColor}
      />
      <path
        d="M6.80979 0.58541C6.86966 0.401148 7.13034 0.401148 7.19021 0.58541L7.46025 1.41652C7.48703 1.49892 7.56382 1.55471 7.65047 1.55471H8.52434C8.71808 1.55471 8.79864 1.80264 8.6419 1.91652L7.93492 2.43017C7.86482 2.48109 7.83549 2.57137 7.86226 2.65377L8.13231 3.48488C8.19218 3.66914 7.98128 3.82237 7.82454 3.70848L7.11756 3.19483C7.04746 3.14391 6.95254 3.14391 6.88244 3.19483L6.17546 3.70848C6.01872 3.82237 5.80782 3.66914 5.86769 3.48488L6.13774 2.65377C6.16451 2.57137 6.13518 2.48109 6.06508 2.43017L5.3581 1.91652C5.20136 1.80264 5.28192 1.55471 5.47566 1.55471H6.34953C6.43618 1.55471 6.51297 1.49892 6.53975 1.41652L6.80979 0.58541Z"
        fill={strokeColor}
      />
      <path
        d="M6.80979 0.58541C6.86966 0.401148 7.13034 0.401148 7.19021 0.58541L7.46025 1.41652C7.48703 1.49892 7.56382 1.55471 7.65047 1.55471H8.52434C8.71808 1.55471 8.79864 1.80264 8.6419 1.91652L7.93492 2.43017C7.86482 2.48109 7.83549 2.57137 7.86226 2.65377L8.13231 3.48488C8.19218 3.66914 7.98128 3.82237 7.82454 3.70848L7.11756 3.19483C7.04746 3.14391 6.95254 3.14391 6.88244 3.19483L6.17546 3.70848C6.01872 3.82237 5.80782 3.66914 5.86769 3.48488L6.13774 2.65377C6.16451 2.57137 6.13518 2.48109 6.06508 2.43017L5.3581 1.91652C5.20136 1.80264 5.28192 1.55471 5.47566 1.55471H6.34953C6.43618 1.55471 6.51297 1.49892 6.53975 1.41652L6.80979 0.58541Z"
        fill={strokeColor}
      />
      <path
        d="M2.05979 5.33541C2.11966 5.15115 2.38034 5.15115 2.44021 5.33541L2.71025 6.16652C2.73703 6.24892 2.81382 6.30471 2.90047 6.30471H3.77434C3.96808 6.30471 4.04864 6.55264 3.8919 6.66652L3.18492 7.18017C3.11482 7.23109 3.08549 7.32137 3.11226 7.40377L3.38231 8.23488C3.44218 8.41914 3.23128 8.57237 3.07454 8.45848L2.36756 7.94483C2.29746 7.89391 2.20254 7.89391 2.13244 7.94483L1.42546 8.45848C1.26872 8.57237 1.05782 8.41914 1.11769 8.23488L1.38774 7.40377C1.41451 7.32137 1.38518 7.23109 1.31508 7.18017L0.608103 6.66652C0.45136 6.55264 0.531915 6.30471 0.72566 6.30471H1.59953C1.68618 6.30471 1.76297 6.24892 1.78975 6.16652L2.05979 5.33541Z"
        fill={strokeColor}
      />
      <path
        d="M2.05979 5.33541C2.11966 5.15115 2.38034 5.15115 2.44021 5.33541L2.71025 6.16652C2.73703 6.24892 2.81382 6.30471 2.90047 6.30471H3.77434C3.96808 6.30471 4.04864 6.55264 3.8919 6.66652L3.18492 7.18017C3.11482 7.23109 3.08549 7.32137 3.11226 7.40377L3.38231 8.23488C3.44218 8.41914 3.23128 8.57237 3.07454 8.45848L2.36756 7.94483C2.29746 7.89391 2.20254 7.89391 2.13244 7.94483L1.42546 8.45848C1.26872 8.57237 1.05782 8.41914 1.11769 8.23488L1.38774 7.40377C1.41451 7.32137 1.38518 7.23109 1.31508 7.18017L0.608103 6.66652C0.45136 6.55264 0.531915 6.30471 0.72566 6.30471H1.59953C1.68618 6.30471 1.76297 6.24892 1.78975 6.16652L2.05979 5.33541Z"
        fill={strokeColor}
      />
      <path
        d="M3.95969 11.0354C4.01956 10.8512 4.28024 10.8512 4.34011 11.0354L4.61016 11.8665C4.63693 11.9489 4.71372 12.0047 4.80037 12.0047H5.67424C5.86799 12.0047 5.94854 12.2526 5.7918 12.3665L5.08482 12.8802C5.01472 12.9311 4.98539 13.0214 5.01217 13.1038L5.28221 13.9349C5.34208 14.1192 5.13118 14.2724 4.97444 14.1585L4.26746 13.6448C4.19736 13.5939 4.10244 13.5939 4.03235 13.6448L3.32537 14.1585C3.16862 14.2724 2.95773 14.1192 3.0176 13.9349L3.28764 13.1038C3.31441 13.0214 3.28508 12.9311 3.21498 12.8802L2.50801 12.3665C2.35126 12.2526 2.43182 12.0047 2.62556 12.0047H3.49944C3.58608 12.0047 3.66287 11.9489 3.68965 11.8665L3.95969 11.0354Z"
        fill={strokeColor}
      />
      <path
        d="M3.95969 11.0354C4.01956 10.8512 4.28024 10.8512 4.34011 11.0354L4.61016 11.8665C4.63693 11.9489 4.71372 12.0047 4.80037 12.0047H5.67424C5.86799 12.0047 5.94854 12.2526 5.7918 12.3665L5.08482 12.8802C5.01472 12.9311 4.98539 13.0214 5.01217 13.1038L5.28221 13.9349C5.34208 14.1192 5.13118 14.2724 4.97444 14.1585L4.26746 13.6448C4.19736 13.5939 4.10244 13.5939 4.03235 13.6448L3.32537 14.1585C3.16862 14.2724 2.95773 14.1192 3.0176 13.9349L3.28764 13.1038C3.31441 13.0214 3.28508 12.9311 3.21498 12.8802L2.50801 12.3665C2.35126 12.2526 2.43182 12.0047 2.62556 12.0047H3.49944C3.58608 12.0047 3.66287 11.9489 3.68965 11.8665L3.95969 11.0354Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default MagicWandIcon;
