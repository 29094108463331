import { fetchBinary, apply_diff } from "../../api/fetchBinary";
import client from "../../api/client";
import { fetchDatasetBinary } from "./useDatasets";

// Fetch and cache the processings data for a specific dataset

export const fetchProcessings = async (projectId, groupId, datasetId) => {
  const { data } = await client.get(`/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings`);
  return data;
};

export const fetchProcessingDetails = async (projectId, groupId, datasetId, processingId) => {
  const procId = processingId.length ? `${processingId[0]}/${processingId[1]}` : processingId;
  const { data } = await client.get(
    `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${procId}`
  );
  return data;
};

export const fetchProcessingProgress = async (projectId, groupId, datasetId, processingId) => {
  const procId = processingId.length ? `${processingId[0]}/${processingId[1]}` : processingId;
  const { data } = await client.get(
    `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${procId}/progress`
  );
  return data;
};

export const fetchProcessingBinary = async (
  queryClient,
  projectId,
  groupId,
  datasetId,
  processingId,
  nodeData,
  selectedLineId,
  onProgress
) => {
  if (!nodeData) return null;

  const procId = processingId.length ? `${processingId[0]}/${processingId[1]}` : processingId;

  const base_dataset = await queryClient.fetchQuery({
    queryKey: ["nodeBinary", [projectId, groupId, datasetId, datasetId], selectedLineId],
    queryFn: async () => await fetchDatasetBinary(projectId, groupId, datasetId, selectedLineId, onProgress),
    structuralSharing: false,
  });

  console.log("nodedata outputs", nodeData.outputs);

  return Object.fromEntries(
    await Promise.all(
      nodeData.outputs.map(async (output) => {
        const diff = await fetchBinary(
          `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${procId}/${output.name}/${selectedLineId}/diff`
        );
        const binary = base_dataset?.measured && diff && apply_diff(base_dataset.measured, diff);
        return [output.name, binary];
      })
    )
  );

  /*
  let outputs = await Promise.all(
    nodeData.outputs.map(async (output) => {
      return [
        output.name,
        await fetchBinary(
          `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${procId}/${output.name}/binary`
        ),
      ];
    })
    );
  */
};
