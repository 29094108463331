import { createDefaultStyle } from "ol/style/Style";
import { Circle, Fill, Stroke, Style } from "ol/style.js";
import {
  DEFAULT_STYLE,
  SELECTED_STYLE,
} from "../../../../utils/flightlineStyles";

export const getFlightlineStyle = (map, hiddenFlightlines) => {
  return (feature, resolution) => {
    if (isLineString(feature)) {
      let { color, width } = feature.get("style") || DEFAULT_STYLE;

      const isSelected =
        map.current.selectedLineId === feature.getProperties().Line;
      const isHidden = hiddenFlightlines.includes(feature.getProperties().Line);

      if (isSelected) {
        color = SELECTED_STYLE.color;
        width = SELECTED_STYLE.width;
      }

      if (isHidden) {
        color = "rgba(0, 0, 0, 0)"; // Making the color transparent
      }

      return getLineFeatureStyle(color, width);
    }
    return createDefaultStyle(feature, resolution)[0];
  };
};

const isLineString = (feature) =>
  feature.getGeometry().getType() === "LineString";

const getLineFeatureStyle = (color, width) =>
  new Style({
    stroke: new Stroke({
      color,
      width,
    }),
  });

export const getSelectedSoundingStyle = () => {
  return new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({ color: DEFAULT_STYLE.color }),
      stroke: new Stroke({
        color: SELECTED_STYLE.color,
        width: SELECTED_STYLE.width,
      }),
    }),
  });
};
