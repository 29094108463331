import React from "react";

const Layout3x2 = ({ strokeColor }) => {
  return (
    <svg
      width="48"
      height="34"
      viewBox="0 0 42 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="31" height="8" rx="1" fill="black" fillOpacity="0.3" />
      <rect
        y="11"
        width="31"
        height="7"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
      <rect x="34" width="8" height="8" rx="1" fill="black" fillOpacity="0.3" />
      <rect
        x="34"
        y="11"
        width="8"
        height="7"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
      <rect
        y="21"
        width="31"
        height="8"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
      <rect
        x="34"
        y="21"
        width="8"
        height="8"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default Layout3x2;
