import msgpack from "msgpack-lite";
import client from "./client";
import {
  unpackNumpy,
  packNumpy,
  packBinary,
  unpackBinary,
} from "msgpack-numpy-js";
import { diff_df, extract_df, diff, apply_diff } from "libaarhusxyz-js";

export { diff_df, extract_df, diff, apply_diff };
export { unpackNumpy, packNumpy, packBinary, unpackBinary };

export const fetchBinary = async (url, onProgress) => {
  try {
    const { data, status } = await client.get(url, {
      responseType: "arraybuffer",
      onDownloadProgress: (progressEvent) => {
        if (onProgress && progressEvent.total) {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          onProgress(progress);
        }
      },
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
    // Ensure the request was successful
    if (status !== 200) {
      console.error(`Request failed with status code: ${status}`);
      return null; // or you could throw an error or return a default value here
    }

    // Ensure the data is not empty
    if (data.byteLength === 0) {
      console.error(
        "Seems like there is no data to fetch :( data.byteLength = 0"
      );
      return null; // or you could throw an error or return a default value here
    }

    const res = unpackBinary(new Uint8Array(data));
    res.url = url;
    return res;
  } catch (error) {
    console.error(
      "An error occurred while fetching and decoding the data:",
      error
    );
    return null; // or you could throw the error again or return a default value
  }
};

export const diffSummary = (orig, diff) => {
  let current = null;
  const sections = [];
  for (var i = 0; i < diff.flightlines.apply_idx.length; i++) {
    const idx = diff.flightlines.apply_idx[i];
    const line = orig.flightlines.Line[idx];
    if (!current || current.line !== line) {
      current = {
        line: line,
        start: idx,
        startdist: orig.flightlines.xdist[idx],
      };
      sections.push(current);
    }
    current.end = idx;
    current.enddist = orig.flightlines.xdist[idx];
  }
  return sections;
};

export const diffSummaryText = (orig, diff) => {
  return diffSummary(orig, diff)
    .map(
      (entry) =>
        `${entry.line} @ ${entry.startdist.toFixed(
          0
        )}m - ${entry.enddist.toFixed(0)}m`
    )
    .join(", ");
};

window.msgpack = msgpack;
window.packBinary = packBinary;
window.unpackBinary = unpackBinary;
window.unpackNumpy = unpackNumpy;
window.packNumpy = packNumpy;
window.diff_df = diff_df;
window.extract_df = extract_df;
window.diff = diff;
window.apply_diff = apply_diff;
