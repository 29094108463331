import React, { useRef, useContext } from "react";
import { NodeDataContext, MapLayoutContext } from "../../../../contexts";
import BaseMap from "../../../common/BaseMap";
import { MapMenu } from "./MapMenu";
import { useFlightlineLayerEffect } from "../../../../hooks/mapHooks/useFlightlineLayerEffect";
import { useOverlayLayersEffect } from "../../../../hooks/mapHooks/useOverlayLayersEffect";
import { useSelectedSoundingLayerEffect } from "../../../../hooks/mapHooks/useSelectedSoundingLayerEffect";
import { useRerenderOnStylingChangeEffect } from "../../../../hooks/mapHooks/useRerenderOnStylingChangeEffect";
import { useMapLayers } from "../../../../hooks/mapHooks/useMapLayers";
import { useBaseMaps } from "../../../../hooks/mapHooks/useBaseMaps";
import { useBaseMapSwitcher } from "../../../../hooks/mapHooks/useBaseMapSwitcher";
import { useInteractiveStates } from "../../../../contexts/InteractiveStateContext";
import { useFlightlineGeojson } from "../../../../hooks/useFlightlineGeojson";
import { handleHover, handleMapClick, zoomToSoundingExtent } from "./handlers";
import { LocationIcon } from "../../../../assets/icons";

const DatasetMap = ({ showMapMenu }) => {
  const { mapLayout } = useContext(MapLayoutContext);
  const { nodeBinaryByLine, activeNode, flightlines, nodeType, nodeData } = useContext(NodeDataContext);

  const [projectId, groupId, datasetId, processingIds, inversionIds] = activeNode;
  const processingId = processingIds?.length ? `${processingIds[0]}/${processingIds[1]}` : processingIds;
  const inversionId = inversionIds?.length ? `${inversionIds[0]}/${inversionIds[1]}` : inversionIds;
  const component = nodeData && nodeData.outputs ? nodeData?.outputs.map((output) => output.name)[0] : "";

  const params = {
    nodeType,
    projectId,
    groupId,
    datasetId,
    processingId,
    inversionId,
    component,
  };

  const flightlineFeatures = useFlightlineGeojson(params);

  const {
    selectedLineId,
    setSelectedLineId,
    selectedSoundingId,
    setSelectedSoundingId,
    setHoveredLineId,
    setHoveredSoundingId,
    hiddenLineIds,
    setHiddenLineIds,
  } = useInteractiveStates();

  const map = useRef();
  const { defaultBaseMap } = useBaseMaps();
  const { baseMapURL, switchBaseMap } = useBaseMapSwitcher(defaultBaseMap?.src);

  const onMapInit = (initializedMap) => {
    map.current = initializedMap;
  };

  useMapLayers(map);
  useFlightlineLayerEffect(map, flightlineFeatures, hiddenLineIds);
  useSelectedSoundingLayerEffect(map, nodeBinaryByLine, selectedLineId, selectedSoundingId);
  useOverlayLayersEffect(map, mapLayout, projectId);
  useRerenderOnStylingChangeEffect(map, selectedLineId);

  return (
    <div className="map-wrapper-inner">
      {showMapMenu && (
        <MapMenu
          switchBaseMap={switchBaseMap}
          activeNode={activeNode}
          selectedLineId={selectedLineId}
          flightlines={flightlines}
          hiddenLineIds={hiddenLineIds}
          setHiddenLineIds={setHiddenLineIds}
          map={map}
        />
      )}

      {showMapMenu && (
        <button
          onClick={zoomToSoundingExtent(map)}
          className="absolute right-5 bottom-16 bg-zinc-900/70 rounded-md p-2 text-white z-50 h-fit w-fit"
        >
          <LocationIcon color="rgb(250 250 250)" />
        </button>
      )}
      <BaseMap
        onHover={handleHover(map, setHoveredLineId, setHoveredSoundingId)}
        onClick={handleMapClick(map, setSelectedLineId, setSelectedSoundingId)}
        onMapInit={onMapInit}
        baseMapURL={baseMapURL}
      />
    </div>
  );
};

export default DatasetMap;
