import { useEffect } from "react";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { getSelectedSoundingStyle } from "../../components/workspace/modules/Map/mapStyles";

export const useSelectedSoundingLayerEffect = (
  map,
  nodeBinaryByLine,
  selectedLineId,
  selectedSoundingId,
) => {
  useEffect(() => {
    if (!map.current?.flightlineLayer) return;
    if (!nodeBinaryByLine || !Object.values(nodeBinaryByLine).length) return;

    const binary = Object.values(nodeBinaryByLine)?.[0]?.[selectedLineId];
    if (!binary) return;
    const x = binary.flightlines.x_web[selectedSoundingId];
    const y = binary.flightlines.y_web[selectedSoundingId];

    setSelectedSoundingSource(map.current.selectedSoundingLayer, x, y);
    setSelectedSoundingStyle(map.current.selectedSoundingLayer);

    map.current.selectedSoundingId = selectedSoundingId;
    map.current.nodeBinaryByLine = nodeBinaryByLine;
  }, [nodeBinaryByLine, map, selectedLineId, selectedSoundingId]);
};

const setSelectedSoundingSource = (layer, x, y) => {
  const source = new VectorSource({
    features: [new Feature({ geometry: new Point([x, y]) })],
  });
  layer.setSource(source);
};

const setSelectedSoundingStyle = (layer) => {
  layer.setStyle(getSelectedSoundingStyle());
};
