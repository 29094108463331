import React from "react";
import SelectionComponent from "./SelectionComponent";

export const ShapefilesSelection = ({ activeNode }) => (
  <SelectionComponent
    title="Shapefiles"
    dataQuery="application/zipped-shapefile"
    contextHandler={activeNode[0]}
    filterCondition={(tag) => tag.name === "Broken"}
    itemIdentifier="layers"
    itemName="shapefile"
    itemType="shapefile"
  />
);
