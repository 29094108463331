import React from "react";
import { borderRadius, strokeWidth } from "./variables";

const Layout4x2Filled = ({ strokeColor }) => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.65"
        y="0.65"
        width="29.5091"
        height="29.5091"
        rx={borderRadius}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d="M22.0063 1.54041V29.2686"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <rect
        x="22.0063"
        y="0.880249"
        width="7.92233"
        height="29.0485"
        rx="2"
        fill={strokeColor}
        fillOpacity="0.3"
      />
      <rect
        x="0.880371"
        y="7.92236"
        width="21.1262"
        height="7.04207"
        rx="2"
        fill={strokeColor}
        fillOpacity="0.3"
      />
      <rect
        x="0.880371"
        y="22.8867"
        width="21.1262"
        height="7.04207"
        rx="2"
        fill={strokeColor}
        fillOpacity="0.3"
      />
      <path
        d="M1.76074 15.1404L29.4889 15.1404"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M1.76074 7.92236L29.4889 7.92236"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M1.76074 22.3585H29.4889"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M5 19H18.2039"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M5 11H18.2039"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M5 26H18.2039"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M5 4L18.2039 4"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Layout4x2Filled;
