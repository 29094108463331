import React, { Fragment, useState } from "react";
import { registerSchemaComponent } from "../fieldRegistration";
import { FormLabel } from "../../../common/forms/Form";
import { Combobox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon, XMarkIcon } from "@heroicons/react/20/solid";

export const CustomMultiSelectorStep = ({
  schemaFragment,
  value,
  propKey,
  setValue,
}) => {
  const [query, setQuery] = useState("");
  const predefinedItems = schemaFragment?.items?.anyOf?.[0]?.enum;

  return (
    <div className="block grid grid-cols-6 gap-2 items-center mt-1">
      <div className="col-span-2 flex items-center">
        <FormLabel label={propKey} htmlFor={propKey} fontWeight="font-normal" />
      </div>
      <div className="col-span-4 flex items-center">
        <Combobox>
          <div className="relative">
            <div className="flex gap-1.5 gap-y-0.5 flex-wrap items-center">
              {value &&
                value?.map((item) => {
                  return (
                    <div className="inline-flex items-center rounded-full text-sm font-normal text-zinc-800 px-1.5 py-0.5 mt-2 bg-zinc-100 border border-zinc-200">
                      {item}
                      <XMarkIcon
                        className="ml-1.5 h-4 w-4 cursor-pointer"
                        onClick={() =>
                          setValue(value.filter((val) => val !== item))
                        }
                        aria-hidden="true"
                      />
                    </div>
                  );
                })}
            </div>
            <div className="relative w-72 cursor-default overflow-hidden rounded-lg bg-white text-left rounded-md mt-2">
              <Combobox.Input
                placeholder="Add or type custom + Enter"
                onChange={(event) => setQuery(event.target.value)}
                onKeyDown={(event) => {
                  if (
                    event.key === "Enter" &&
                    event.target.value.trim() !== ""
                  ) {
                    event.preventDefault();
                    if (!value.includes(event.target.value.trim())) {
                      setValue([...value, event.target.value.trim()]);
                      setQuery("");
                    }
                  }
                }}
                value={query}
                className="relative w-72 flex items-center gap-2 justify-between text-sm bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
              />

              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-4 w-4 text-zinc-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => {}}
            >
              <Combobox.Options className="absolute mt-1 max-h-44 z-50 w-72 overflow-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {predefinedItems.map((item, index) => (
                  <Combobox.Option
                    key={item.id}
                    value={item}
                    onClick={() => {
                      if (!value.includes(item)) {
                        setValue([...value, item]);
                      }
                    }}
                    className={({ active }) =>
                      `flex items-center gap-2 relative cursor-default select-none py-2 p-4 hover:bg-zinc-100 text-zinc-900`
                    }
                  >
                    {item}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return (
    schemaFragment &&
    schemaFragment?.items?.anyOf &&
    schemaFragment?.items?.anyOf?.length === 2 &&
    schemaFragment?.items?.anyOf?.[0]?.enum &&
    schemaFragment?.items?.anyOf?.[1]?.type === "string"
  );
}, CustomMultiSelectorStep);
