import { createContext, useContext, useState } from "react";
import { useSharedState } from "../hooks";
export const InteractiveStateContext = createContext();

export const InteractiveStateProvider = ({ children }) => {
  const [selectedLineId, setSelectedLineId] = useSharedState(
    "selectedLineId",
    null
  );
  const [hoveredLineId, setHoveredLineId] = useSharedState(
    "hoveredLineId",
    null
  );
  const [selectedSoundingId, setSelectedSoundingId] = useSharedState(
    "selectedSoundingId",
    0
  );
  const [selectedSoundingColor, setSelectedSoundingColor] = useSharedState(
    "selectedSoundingColor",
    0
  );
  const [hoveredSoundingId, setHoveredSoundingId] = useSharedState(
    "hoveredSoundingId",
    null
  );

  const [hiddenLineIds, setHiddenLineIds] = useSharedState("hiddenLineIds", []);

  const [selections, setSelections] = useState(undefined);

  const [showHover, setShowHover] = useState(true);
  
  return (
    <InteractiveStateContext.Provider
      value={{
        selectedLineId,
        setSelectedLineId,
        selectedSoundingId,
        setSelectedSoundingId,
        hoveredLineId,
        setHoveredLineId,
        hoveredSoundingId,
        setHoveredSoundingId,
        selectedSoundingColor,
        setSelectedSoundingColor,
        hiddenLineIds,
        setHiddenLineIds,
        selections,
        setSelections,
        showHover,
        setShowHover
      }}
    >
      {children}
    </InteractiveStateContext.Provider>
  );
};

export const useInteractiveStates = () => {
  return useContext(InteractiveStateContext);
};
