import React from "react";
import { XMarkIcon } from "../../../assets/icons";

const FlyoutContainer = ({
  heading,
  children,
  toggleVisibility,
  position = "right",
}) => {
  const positionClass = `${position}-0`;

  return (
    <div className="absolute bg-zinc-900/30 h-full w-full z-50 right-0">
      <div
        className={`absolute h-full min-h-min drop-shadow-md bg-white w-2/5 border-r border-zinc-100 z-50 ${positionClass}`}
      >
        <div className="flex flex-row justify-between items-center border-b border-zinc-200 p-4 py-3">
          <h4 className="text-zinc-800 font-medium">{heading}</h4>
          <button
            onClick={() => toggleVisibility(false)}
            className="flex items-center text-zinc-500 bg-transparent text-base hover:bg-zinc-100 hover:text-zinc-600 rounded-md p-1"
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default FlyoutContainer;
