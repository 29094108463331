import React from "react";
import { PencilSquareIcon, InformationCircleIcon } from "../../assets/icons";
import { TableRow, TableCell, TableHeaderCell } from "../common/Table";

const EnvironmentListItem = ({ item, showEnvironment, createEnvironment }) => {
  return (
    <TableRow>
      <TableHeaderCell>{item.title}</TableHeaderCell>

      <TableCell>{item.status}</TableCell>

      <TableCell>
        <div className="flex gap-2">
          <button onClick={() => createEnvironment(item.id)}>
            <PencilSquareIcon className="w-4 h-4" />
          </button>
          <button onClick={() => showEnvironment(item.id)}>
            <InformationCircleIcon className="w-4 h-4" />
          </button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default EnvironmentListItem;
