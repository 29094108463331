import React, { useState } from "react";

export const DragArea = ({ children, formSteps, setFormSteps }) => {
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [overIndex, setOverIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (index) => {
    if (index !== overIndex) {
      setOverIndex(index);
      // Perform the reorder preemptively
      const newSteps = reorder(formSteps, draggingIndex, index);
      setFormSteps(newSteps);
      setDraggingIndex(index); // Update dragging index after reorder
    }
  };

  const handleDrop = (index) => {
    setOverIndex(null); // Clear over index
    setDraggingIndex(null); // Clear dragging index
  };

  // Function to reorder an array
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const childrenWithProps = React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      index,
      onDragStart: handleDragStart,
      onDragOver: handleDragOver,
      onDrop: handleDrop,
      isDragging: index === draggingIndex,
      isOver: index === overIndex,
    })
  );

  return <div className="space-y-0">{childrenWithProps}</div>;
};

export const Draggable = ({
  index,
  onDragStart,
  onDragOver,
  onDrop,
  isDragging,
  isOver,
  children,
}) => {
  return (
    <div
      draggable
      onDragStart={() => onDragStart(index)}
      onDragEnter={() => onDragOver(index)}
      onDragOver={(e) => e.preventDefault()} // Necessary to allow drop
      onDrop={() => onDrop(index)}
      className={`cursor-move draggable-item rounded-md ${
        isDragging ? "dragging" : ""
      } ${isOver ? "over" : ""}`}
    >
      {children}
    </div>
  );
};
