import { ShareIcon, ArrowDownTrayIcon } from "../../../../../../assets/icons";
import SimpleSelect from "../../../../../common/SimpleSelect";

export const NodeInfoNav = ({ downloadUrls, isInversion }) => {
  let downloadMenu;
  if (Object.keys(downloadUrls).length === 0) {
    downloadMenu = <span />;
  } else if (Object.keys(downloadUrls).length === 1) {
    downloadMenu = (
      <a
        href={Object.values(downloadUrls)[0]}
        className="tooltip-button--text"
        download
      >
        <ArrowDownTrayIcon className="w-4 h-4" />
        Export
      </a>
    );
  } else {
    const downloadItems = Object.entries(downloadUrls).map(([name, url]) => ({
      id: url,
      title: name,
      url,
    }));

    const DownloadSelect = () => (
      <SimpleSelect
        items={downloadItems}
        nothingTitle="Export"
        buttonStyles="relative h-7 text-sm w-full bg-transparent py-1 px-2 flex items-center gap-2 rounded-md hover:border-zinc-500"
        IconComponent={ArrowDownTrayIcon}
        iconPosition="before"
        renderOption={({ item }) => (
          <a
            href={item.url}
            className="relative flex items-center gap-2 py-2 pl-4 pr-4 text-sm text-zinc-800 hover:text-zinc-600"
          >
            {item.title}
          </a>
        )}
      />
    );

    downloadMenu = <DownloadSelect />;
  }

  return (
    <div className="sidepanel__menu px-2 py-1.5 border-b border-zinc-200 flex gap-4 items-center">
      <button className="flex gap-2 items-center text-sm text-zinc-800 hover:text-zinc-600 hidden">
        <ShareIcon className="w-4 h-4" />
        Share result
      </button>

      {downloadMenu}
    </div>
  );
};
