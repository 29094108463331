// Modern paranoia got us here...

try {
  crypto.randomUUID();
} catch (e) {
  crypto.randomUUID = () => {
    var rnd = crypto.getRandomValues(new Uint8Array(31));
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c, i) =>
      ((c ^ rnd[i]) & (15 >> (c / 4))).toString(16)
    );
  };
}

export default {}; // eslint-disable-line
