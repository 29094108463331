import React, { createContext, useState, useContext, useEffect } from "react";

export const SidebarContext = createContext();

export const useSidebarContext = () => useContext(SidebarContext);

export const useSidebar = () => {
  return useContext(SidebarContext);
};

export const SidebarProvider = ({ children }) => {
  const [sidebarResized, setSidebarResized] = useState(false);
  const [nodeItemClicked, setNodeItemClicked] = useState(null);

  // Function to toggle the resize state
  const toggleSidebarResized = () => {
    setSidebarResized((prev) => !prev);
  };

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(300);

  const toggleSidebar = () => {
    setSidebarWidth(300);
    setIsCollapsed((prevState) => !prevState);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 5); // Delay

    // Cleanup timeout if the component unmounts
    return () => clearTimeout(timeout);
  }, [isCollapsed, sidebarWidth]);

  const handleSidebarResize = (newWidth) => {
    setSidebarWidth(newWidth);
  };

  const value = {
    sidebarResized,
    toggleSidebarResized,
    nodeItemClicked,
    setNodeItemClicked,

    isCollapsed,
    toggleSidebar,
    handleSidebarResize,
    sidebarWidth,
  };

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};
