import React from "react";
import EnvironmentListItem from "./EnvironmentListItem";
import Table from "../common/Table";

const EnvironmentsList = ({ data, showEnvironment, createEnvironment }) => {
  const columns = [{ title: "Name" }, { title: "Status" }, { title: "" }];

  const renderRow = (item, rowIndex) => (
    <EnvironmentListItem
      key={item.id}
      item={item}
      showEnvironment={showEnvironment}
      createEnvironment={createEnvironment}
    />
  );

  return <Table columns={columns} data={data} renderRow={renderRow} emptyMessage="No environments yet" />;
};

export default EnvironmentsList;
