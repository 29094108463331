import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../api/client";
import handleError from "../api/handleError";

const useDeleteLayout = (layoutId) => {
  const queryClient = useQueryClient();
  const deleteLayoutMutation = useMutation({
    mutationFn: (layoutId) => client.delete(`/api/layouts/${layoutId}`),
    onSuccess: () => {
      queryClient.invalidateQueries("layouts");
    },
    onError: (error) => {
      handleError(error, "An error occured while deleting a layout.");
    },
  });

  const handleDeleteLayout = (layoutId, layoutName) => {
    if (window.confirm(`Are you sure you want to delete ${layoutName}?`)) {
      deleteLayoutMutation.mutate(layoutId);
    }
  };

  return {
    handleDeleteLayout,
    isError: deleteLayoutMutation.isError,
    error: deleteLayoutMutation.error,
  };
};

export default useDeleteLayout;
