import { useContext, useState } from "react";
import { useNodeDetailsQuery } from "../../../../hooks/api/useNodeData";
import NodeSelect from "../../../common/NodeSelect";
import SimpleSelect from "../../../common/SimpleSelect";
import { FormLabel } from "../../../common/forms/Form";
import { NodeDataContext } from "../../../../contexts";
import { registerSchemaComponent } from "../fieldRegistration";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import FieldDescription from "../FieldDescription";

export const InversionFormStep = ({ propKey, schemaFragment, setValue }) => {
  const { activeNode } = useContext(NodeDataContext);
  const [activePath, setActivePath] = useState([]);
  const [internalValue, setInternalValue] = useState({});

  const urlForOutput = (path, outputName) => {
    const [projectId, groupId, datasetId, processingId, inversionId] = path;
    return `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${processingId}/inversions/${inversionId}/${outputName}/download`;
  };

  const nodeDataQuery = useNodeDetailsQuery(activePath);
  const outputs =
    nodeDataQuery?.data?.outputs?.map((item) => {
      item.id = item.name;
      item.title = item.name;
      return item;
    }) || [];

  const activeModelOutput = outputs.filter(
    (output) => urlForOutput(activePath, output.name) === internalValue.model
  )[0];
  const activeFwdOutput = outputs.filter(
    (output) => urlForOutput(activePath, output.name) === internalValue.fwd
  )[0];
  const activeMeasuredOutput = outputs.filter(
    (output) => urlForOutput(activePath, output.name) === internalValue.measured
  )[0];

  const onNodeClick = (path, action) => {
    if (path.length === 5) {
      setActivePath(path);
      const newInternalValue = { ...internalValue };
      newInternalValue.model = null;
      newInternalValue.fwd = null;
      newInternalValue.measured = null;
      setInternalValue(newInternalValue);
    }
  };

  const onModelOutputClick = (output) => {
    const newInternalValue = { ...internalValue };
    newInternalValue.model = urlForOutput(activePath, output.name);
    setInternalValue(newInternalValue);
    setValue(newInternalValue);
  };

  const onFwdOutputClick = (output) => {
    const newInternalValue = { ...internalValue };
    newInternalValue.fwd = urlForOutput(activePath, output.name);
    setInternalValue(newInternalValue);
    setValue(newInternalValue);
  };

  const onMeasuredOutputClick = (output) => {
    const newInternalValue = { ...internalValue };
    newInternalValue.measured = urlForOutput(activePath, output.name);
    setInternalValue(newInternalValue);
    setValue(newInternalValue);
  };

  return (
    <div>
      <div className="flex items-center mb-3 mt-2">
        <h4 className="font-medium text-sm capitalize">
          {schemaFragment.title || propKey}
        </h4>
        <FieldDescription schemaFragment={schemaFragment} />
      </div>
      <div className="pl-4 border-l border-zinc-300 mt-1 mb-1">
        <div className="block grid grid-cols-6 gap-2 items-center mt-1">
          <div className="col-span-2 flex items-center">
            <FormLabel
              label={"Inversion"}
              htmlFor={"inversion"}
              fontWeight="font-normal"
            />
          </div>
          <div className="col-span-4 flex items-center">
            <NodeSelect
              projectId={activeNode[0]}
              activePath={activePath}
              setActivePath={onNodeClick}
              width="w-72"
              buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
              IconComponent={ChevronUpDownIcon}
              nothingTitle="Nothing selected"
            />
          </div>
          <div className="col-span-2 flex items-center">
            <FormLabel
              label={"Model"}
              htmlFor={"model"}
              fontWeight="font-normal"
            />
          </div>
          <div className="col-span-4 flex items-center">
            <SimpleSelect
              items={outputs}
              selected={activeModelOutput}
              setSelected={onModelOutputClick}
              width="w-72"
              dropdownWidth="w-full"
              nothingTitle="Nothing selected"
              buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
              IconComponent={ChevronUpDownIcon}
              iconPosition="after"
            />
          </div>
          <div className="col-span-2 flex items-center">
            <FormLabel label={"Fwd"} htmlFor={"fwd"} fontWeight="font-normal" />
          </div>
          <div className="col-span-4 flex items-center">
            <SimpleSelect
              items={outputs}
              selected={activeFwdOutput}
              setSelected={onFwdOutputClick}
              width="w-72"
              dropdownWidth="w-full"
              nothingTitle="Nothing selected"
              buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
              IconComponent={ChevronUpDownIcon}
              iconPosition="after"
            />
          </div>
          <div className="col-span-2 flex items-center">
            <FormLabel
              label={" Measured"}
              htmlFor={"measured"}
              fontWeight="font-normal"
            />
          </div>
          <div className="col-span-4 flex items-center">
            <SimpleSelect
              items={outputs}
              selected={activeMeasuredOutput}
              setSelected={onMeasuredOutputClick}
              width="w-72"
              dropdownWidth="w-full"
              nothingTitle="Nothing selected"
              buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
              IconComponent={ChevronUpDownIcon}
              iconPosition="after"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment && schemaFragment["x-reference"] === "inversion";
}, InversionFormStep);
