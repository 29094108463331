export function getDownloadUrlsForNode(activeNode, nodeData) {
  let baseUrl;
  if (activeNode.length === 3) {
    const [projectId, groupId, datasetId] = activeNode;
    baseUrl = `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}`;
  } else if (activeNode.length === 4) {
    const [projectId, groupId, datasetId, processingId] = activeNode;
    baseUrl = `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${processingId}`;
  } else if (activeNode.length === 5) {
    const [projectId, groupId, datasetId, processingId, inversionId] =
      activeNode;
    baseUrl = `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${processingId}/inversions/${inversionId}`;
  }
  const downloadUrls =
    baseUrl && nodeData?.outputs
      ? Object.fromEntries(
          nodeData.outputs.map((output) => [
            output.name,
            `${baseUrl}/${output.name}/download`,
          ])
        )
      : [];

  return downloadUrls;
}
