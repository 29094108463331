import { useEffect, useMemo, useRef } from "react";
import VectorSource from "ol/source/Vector";
import { getFlightlineStyle } from "../../components/workspace/modules/Map/mapStyles";
// import * as olExtent from "ol/extent";
import LineString from "ol/geom/LineString";
import MultiLineString from "ol/geom/MultiLineString";

const simplifyFeatures = (features, tolerance = 0.02) => {
  return features.map((feature) => {
    const geometry = feature.getGeometry().clone();
    if (geometry instanceof LineString || geometry instanceof MultiLineString) {
      const simplifiedGeometry = geometry.simplify(tolerance);
      feature.setGeometry(simplifiedGeometry);
    }
    return feature;
  });
};

export const useFlightlineLayerEffect = (map, flightlineFeatures, hiddenFlightlines) => {
  const hasInitiallyFitted = useRef(false);

  const simplifiedFeatures = useMemo(() => simplifyFeatures(flightlineFeatures), [flightlineFeatures]);

  useEffect(() => {
    if (!map.current?.flightlineLayer) return;

    setFlightlineSource(map.current.flightlineLayer, simplifiedFeatures, hiddenFlightlines);

    setFlightlineStyle(map.current.flightlineLayer, map, hiddenFlightlines);

    const features = map.current.flightlineLayer.getSource().getFeatures();
    if (!features.length) return;

    const extent = map.current.flightlineLayer.getSource().getExtent();
    if (!isEmptyExtent(extent) && !hasInitiallyFitted.current) {
      map.current.getView().fit(extent, { duration: 500 });
      hasInitiallyFitted.current = true;
    }
  }, [map, simplifiedFeatures, hiddenFlightlines]);
};
// Helper functions
function isEmptyExtent(extent) {
  return !extent || extent[0] >= extent[2] || extent[1] >= extent[3];
}

const setFlightlineSource = (layer, features, hiddenFlightlines) => {
  const uniqueFeatures = features.map((feature, index) => {
    if (!feature.getId() || feature.getId() === "0") {
      feature.setId(`feature_${index}`);
    }
    return feature;
  });
  const src = new VectorSource({ features: uniqueFeatures });
  layer.setSource(src);
};

const setFlightlineStyle = (layer, map, hiddenFlightlines) => {
  layer.setStyle(getFlightlineStyle(map, hiddenFlightlines));
};
