import React, { useState } from "react";
import TreeNavigation from "./TreeNavigation";
import MiscData from "./MiscData/MiscData";

const SidebarTabs = () => {
  const [activeTab, setActiveTab] = useState("em"); // default to show em data

  return (
    <div className="beryl_sidebar-tabs">
      <div className="tabs">
        <button className={`tab-button ${activeTab === "em" ? "active" : ""}`} onClick={() => setActiveTab("em")}>
          EM data
        </button>
        <button className={`tab-button ${activeTab === "misc" ? "active" : ""}`} onClick={() => setActiveTab("misc")}>
          Misc
        </button>
      </div>

      <div className="tab-content">
        {activeTab === "em" && <TreeNavigation />}
        {activeTab === "misc" && <MiscData />}
      </div>
    </div>
  );
};

export default SidebarTabs;
