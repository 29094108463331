import { useMutation } from "@tanstack/react-query";
import client from "../api/client";

const useCreateTag = (existingTags, projectId, setErrorMsg) => {
  const createTagMutation = useMutation({
    mutationFn: (newTag) =>
      client.post(`/api/projects/${projectId}/tags/`, newTag),
    onSuccess: (data) => {},
    onError: (error) => {
      console.error("Error creating tag:", error);
      setErrorMsg("There was an error creating tags");
    },
  });

  const submitNewTags = async (data) => {
    const submittedTags = data.tags;

    // Helper to create a new tag and return a promise.
    const createTag = (tag) => {
      return new Promise((resolve, reject) => {
        createTagMutation.mutate(tag, {
          onSuccess: resolve, // Resolve the promise when mutation is successful.
          onError: reject, // Reject the promise when there's an error.
        });
      });
    };

    for (let submittedTag of submittedTags) {
      if (
        !existingTags.some(
          (existingTag) => existingTag.name === submittedTag.name
        )
      ) {
        try {
          await createTag({
            name: submittedTag.name,
            color: submittedTag.color,
            project: projectId,
          });
        } catch (error) {
          console.error("Error creating tag:", error);
        }
      }
    }
  };

  return {
    createTagMutation,
    submitNewTags,
  };
};

export default useCreateTag;
