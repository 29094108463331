import React, { createContext, useState } from "react";

export const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
  const [activeComponent, setActiveComponent] = useState(null);
  const [isToolbarExpanded, setIsToolbarExpanded] = useState(true);

  return (
    <WorkspaceContext.Provider
      value={{
        activeComponent,
        setActiveComponent,
        isToolbarExpanded,
        setIsToolbarExpanded,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};
