import React, { useContext } from "react";
import { SchemaComponent, registerSchemaComponent } from "../fieldRegistration";
import { NodeDataContext } from "../../../../contexts";

export const LayerDatas = ({
  schemaFragment,
  ...props
}) => {
  const { nodeBinaryByLine } = useContext(NodeDataContext);

  let columns = Object.keys(Object.values(nodeBinaryByLine.measured)[0].layer_data);
  
  return (<SchemaComponent
            {...props}
            schemaFragment={{...schemaFragment, "x-reference": undefined, enum: columns}}
          />);
};

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment && schemaFragment["x-reference"] === "layer-data-name";
}, LayerDatas);
