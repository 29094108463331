import React, { forwardRef } from "react";
import { FormLabel } from "./FormLabel";

export const FormInput = forwardRef(
  (
    {
      label,
      htmlFor,
      size,
      type = "text",
      placeholder,
      error,
      className,
      value,
      onChange,
      ...props
    },
    ref
  ) => {
    const defaultClass = `
      block w-full rounded-md placeholder:text-zinc-400
      focus:ring-0 focus:ring-inset sm:leading-6 focus:outline outline-zinc-200
      focus:border-zinc-500 text-sm bg-transparent py-1.5 px-2
      border border-1 border-zinc-300 rounded-md hover:border-zinc-500
    `;
    const combinedClass = `${defaultClass} ${className || ""} ${
      error ? "border-red-600" : ""
    }`;

    return (
      <div className="flex flex-col">
        {label && <FormLabel htmlFor={htmlFor} label={label} size={size} />}
        <input
          ref={ref}
          type={type}
          placeholder={placeholder}
          className={combinedClass}
          value={value}
          onChange={onChange}
          {...props}
        />
        {error && <p className="text-red-600 text-sm p-0.5">{error.message}</p>}
      </div>
    );
  }
);
