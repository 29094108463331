import * as yup from "yup";

export const schema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters long")
    .max(60, "Title is too long."),
  specification: yup.string().required("Specification is required"),
});
