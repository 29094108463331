import React, { useState, useContext } from "react";
import { EnvironmentSelect } from "../common/EnvironmentSelect";
import Heading from "../common/ui/Heading";
import useEnvironmentsData from "../../hooks/useEnvironmentsData";
import useCreateImport from "../../hooks/useCreateImport";
import { NodeDataContext } from "../../contexts";
import Spinner from "../common/ui/Spinner";
import { NiceJsonForm } from "../workspace/NiceJsonForm";
import { uploadAllFiles } from "../workspace/NiceJsonForm/utils";
import Tooltip from "../common/Tooltip";
import { FormInput } from "../common/forms/Form";

import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";

const formValidationSchema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters long")
    .max(150, "Title can not be more than 150 characters long."),
});

export const ImportForm = () => {
  const [steps, setSteps] = useState(undefined);
  const nodeDataContext = useContext(NodeDataContext);
  const { setActiveNode, activeNode } = nodeDataContext;
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { groupId } = location.state || {};

  const { environments, selectedEnvironment, envError, envLoading, environmentDetails, handleEnvironmentSelect } =
    useEnvironmentsData();

  const { handleFormSubmit } = useCreateImport(
    activeNode,
    activeNode[1] || groupId,
    activeNode[0],
    selectedEnvironment,
    title,
    setActiveNode,
    setIsLoading,
    isLoading
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formValidationSchema),
    defaultValues: {
      title: "",
    },
  });

  const onSubmit = async (e) => {
    setIsLoading(true);
    const newFormSteps = await uploadAllFiles(steps);
    console.log("NEW FORM STEPS", newFormSteps);
    handleFormSubmit(newFormSteps);
  };

  if (envError) return <div>Error loading environment details...</div>;

  let schema = null;
  if (environmentDetails && environmentDetails.introspection && environmentDetails.introspection.Import) {
    schema = environmentDetails.introspection.Import;
  }

  return (
    <div className="bg-zinc-100 py-10 px-16 w-full h-full overflow-scroll">
      <div className="bg-white shadow-lg shadow-zinc-300/40 py-5 px-6 m-4 rounded-lg w-6/6 max-w-8xl border border-zinc-100 flex flex-col gap-5 relative">
        {isLoading && (
          <div
            className="absolute bg-white/80 h-full w-full -mt-5 -ml-6 rounded-lg flex justify-center items-center"
            style={{ zIndex: 2000 }}
          >
            <div className="text-xl flex gap-2 items-center">
              <Spinner borderColor="#222" borderTopColor="white" /> Please wait, your files are being uploaded
            </div>
          </div>
        )}

        <div className="header flex justify-between items-center">
          <Heading size="h2">Import EM data</Heading>
          <div className="flex items-center gap-2 text-sm">
            <Tooltip tooltipText={"Software version"} placement="left">
              {envLoading ? (
                <div>...</div>
              ) : (
                <EnvironmentSelect
                  environments={environments}
                  selectedEnvironment={selectedEnvironment}
                  onEnvironmentSelect={handleEnvironmentSelect}
                />
              )}
            </Tooltip>
          </div>
        </div>

        <div className="space-y-4 w-full">
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <FormInput
                label="Title"
                placeholder="Write a title for your import"
                size="base"
                error={errors.title}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  setTitle(e.target.value);
                }}
              />
            )}
          />
        </div>

        <form className="import-form flex gap-4 flex-col">
          <NiceJsonForm schema={schema} value={steps} setValue={setSteps} />
          <input type="text" name="steps" readOnly value={JSON.stringify(steps)} className="w-full" hidden />
        </form>

        <div className="flex w-full justify-end items-center gap-2">
          <button
            onClick={handleSubmit(onSubmit)}
            className={`bg-zinc-700 hover:bg-zinc-600 text-white py-2 px-10 rounded-md w-full flex items-center justify-center ${
              isLoading ? "opacity-60 hover:bg-zinc-700 pointer-events-none" : ""
            }`}
            disabled={isLoading ? true : false}
          >
            Create import
          </button>
        </div>
      </div>
    </div>
  );
};
