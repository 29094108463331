import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ArrowUturnLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ProcessingAndInverisonIcon,
  UserPlusIcon,
} from "../../assets/icons";
import InviteUserForm from "../Forms/InviteUserForm";

const Divider = () => {
  return <div className="beryl_divider" />;
};

const DropdownLinkButton = ({ onClick, icon, label }) => {
  return (
    <button className="beryl_dropdown-content--link" type="button" onClick={onClick}>
      {icon}
      <div>{label}</div>
    </button>
  );
};

const DropdownMenu = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);

  const handleOpenModal = () => {
    setIsDropdownOpen(false);
    setIsOpen(true);
  };
  const handleCloseModal = () => setIsOpen(false);

  const menuItems = [
    {
      path: "workspace",
      label: "Processing and inversion",
      icon: <ProcessingAndInverisonIcon className={"w-4 h-4"} />,
      disabled: false,
      tag: null,
    },
    /*{
      path: "/",
      label: "Interpretation",
      icon: <InterpretationIcon className={"w-4 h-4"} />,
      disabled: true,
      tag: (
        <div className="bg-orange-200 border border-orange-300 text-orange-800 p-0.5 px-1 rounded-lg text-xs ml-auto">
          Coming 2025
        </div>
      ),
    },*/
  ];

  const currentPath = location.pathname.includes("workspace") ? "workspace" : location.pathname.split("/").pop();

  const handleNavigate = (path) => {
    navigate(`/${currentPath}/${path}`);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="beryl_dropdown-menu" ref={dropdownRef}>
      <button type="button" className="beryl_dropdown-toggle" onClick={toggleDropdown}>
        <span className="beryl_dropdown-toggle--icon">
          {menuItems.find((item) => item.path === currentPath)?.icon
            ? React.cloneElement(menuItems.find((item) => item.path === currentPath).icon, { color: "white" })
            : null}
        </span>
        {isDropdownOpen ? <ChevronUpIcon className="w-4 h-4" /> : <ChevronDownIcon className="w-4 h-4" />}
      </button>
      {isDropdownOpen && (
        <div className="beryl_dropdown-content">
          <h3 className="beryl_support-title">Workspace</h3>
          <ul>
            {menuItems.map((item) => (
              <li
                key={item.path}
                className={`${item.path === currentPath ? "active" : ""}${item.disabled ? "disabled" : ""}`}
                onClick={() => handleNavigate(item.path)}
              >
                <span className="icon">
                  {React.cloneElement(item.icon, { color: item.disabled ? "rgb(157, 157, 157)" : "black" })}
                </span>
                {item.label}
                {item.tag}
              </li>
            ))}
          </ul>
          <Divider />
          <DropdownLinkButton
            onClick={() => handleOpenModal(true)}
            icon={<UserPlusIcon className="w-5 h-5" color="black" />}
            label="Invite team member"
          />
          <Divider />
          <DropdownLinkButton
            onClick={() => navigate(`/`)}
            icon={<ArrowUturnLeftIcon className="w-5 h-5" color="black" />}
            label="All projects"
          />{" "}
        </div>
      )}
      <InviteUserForm isOpen={isOpen} handleCloseModal={handleCloseModal} />
    </div>
  );
};

export default DropdownMenu;
