import { useEffect } from "react";
import VectorLayer from "ol/layer/Vector";
import LayerGroup from "ol/layer/Group";

export function useMapLayers(map) {
  useEffect(() => {
    if (map.current) {
      const flightlineLayer = new VectorLayer({});
      const selectedSoundingLayer = new VectorLayer({});
      const overlayLayers = new LayerGroup({ layers: [] });

      flightlineLayer.martineId = crypto.randomUUID();

      map.current.addLayer(overlayLayers);
      map.current.addLayer(flightlineLayer);
      map.current.addLayer(selectedSoundingLayer);
      map.current.flightlineLayer = flightlineLayer;
      map.current.selectedSoundingLayer = selectedSoundingLayer;
      map.current.overlayLayers = overlayLayers;
    }
  }, [map]);
}
