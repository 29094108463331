import * as yup from "yup";

export const schema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters long")
    .max(60, "Title is too long."),
  file: yup.mixed().required("A file is required"),
  tags: yup
    .array()
    .of(yup.object())
    .min(1, "Please select at least one tag")
    .required("Tags field is required"),
  datatype: yup
    .object()
    .shape({
      id: yup.string().required("ID is required"),
      title: yup.string().required("Title is required"),
    })
    .required("Datatype is required"),
});
