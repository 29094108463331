import MapMenuButton from "./MapMenuButton";

const MapMenuSection = ({
  title,
  isActive,
  toggleFunction,
  children,
  icon,
}) => {
  return (
    <div
      className={`beryl_ui_map_menu__item ${
        isActive
          ? "max-w-72 w-[265px] min-h-[220px]"
          : "max-w-12 w-[41px] h-[41px] min-h-[41px]"
      }`}
    >
      <MapMenuButton
        isActive={isActive}
        onClick={toggleFunction}
        icon={icon}
        label={title}
      />
      <div
        className={`beryl_ui_map_menu__item--layers ${
          isActive ? "block" : "hidden"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default MapMenuSection;
