import React from "react";
import { iconColor } from "./color";

const LassoIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 21L2.65789 18.5L3.37828 17.5558C3.84031 16.9501 4.36578 16.3957 4.9457 15.9018L5.46836 15.4567C5.80391 15.1709 6.17409 14.9285 6.57017 14.7352L6.72463 14.6598C6.96016 14.5448 7.20736 14.4555 7.46198 14.3934L7.67372 14.3417C8.68779 14.0942 9.75896 14.3513 10.5502 15.0321L10.5864 15.0632C11.2776 15.6579 11.6752 16.5245 11.6752 17.4363V17.5012C11.6752 18.1489 11.4613 18.7784 11.0667 19.2921V19.2921C10.4848 20.0495 9.57121 20.4771 8.61683 20.4385L8.49996 20.4338C8.28998 20.4253 8.08137 20.396 7.87721 20.3461L7.59911 20.2783C6.53556 20.0187 5.56269 19.4746 4.78474 18.7044L4.07332 18V18C2.43561 16.4014 1.5122 14.2096 1.5122 11.921V11V11C1.5122 5.47715 5.98935 1 11.5122 1H12C17.5228 1 22 5.47715 22 11V11V11C22 15.4063 18.9974 19.2455 14.721 20.3074L14.2632 20.4211"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LassoIcon;
