export const initializeDefaultValue = (prop) => {
  if (!prop) return null;
  switch (prop.type) {
    case "object":
      return {};
    case "number":
    case "integer":
      return 0;
    case "boolean":
      return false;
    case "array":
      return [];
    default:
      return "";
  }
};

/* For production:
export const downloadAllFiles = async (value) => {
  if (typeof value === "object" && value.length !== undefined) {
    return await Promise.all(value.map(downloadAllFiles));
  } else if (typeof value === "function") {
    return await value();
  } else if (typeof value === "object" && value !== null) {
    return Object.fromEntries(
      await Promise.all(
        Object.entries(value).map(async ([key, subValue]) => {
          const newSubValue = await downloadAllFiles(subValue);
          return [key, newSubValue];
        })
      )
    );
  } else {
    return value;
  }
};
*/

export const uploadAllFiles = async (value) => {
  if (typeof value === "object" && value.length !== undefined) {
    const res = [];
    for (const subValue of value) {
      res.push(await uploadAllFiles(subValue));
    }
    return res;
  } else if (typeof value === "function") {
    return await value();
  } else if (typeof value === "object" && value !== null) {
    const res = {};
    for (const name in value) {
      res[name] = await uploadAllFiles(value[name]);
    }
    return res;
  } else {
    return value;
  }
};
