import React from "react";
import { ChevronUpDownIcon } from "../../assets/icons";
import SimpleSelect from "./SimpleSelect";

export const EnvironmentSelect = ({
  environments,
  selectedEnvironment,
  onEnvironmentSelect,
}) => {
  return (
    <SimpleSelect
      items={environments}
      selected={selectedEnvironment}
      setSelected={onEnvironmentSelect}
      buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-2 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
      IconComponent={ChevronUpDownIcon}
      iconPosition="after"
      width=""
    />
  );
};
