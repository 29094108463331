import SimpleSelect from "../../common/SimpleSelect";
import { FormLabel } from "../../common/forms/Form";
import { registerSchemaComponent } from "./fieldRegistration";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import FieldDescription from "./FieldDescription";
import { useId } from "react";

export const EnumFormStep = ({ value, schemaFragment, setValue, propKey }) => {
  const id = useId();

 const items = schemaFragment.enum.map((value) => {
    return {"id": value.toString(), "title": value};
  });

  const selectedItem = items.filter((item) => item.id === value)[0];

  const setSelectedItem = (item) => setValue(item.id);

  return (
    <div className="block grid grid-cols-6 gap-2 items-center mt-1">
      <div className="col-span-2 flex items-center">
        <FormLabel
          label={schemaFragment?.title || propKey}
          htmlFor={id}
          fontWeight="font-normal"
        />
      </div>
      <div className="col-span-4 flex items-center">
        <SimpleSelect
          name={id}
          items={items}
          width="w-72"
          dropdownWidth="w-full"
          selected={selectedItem}
          setSelected={setSelectedItem}
          buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
          IconComponent={ChevronUpDownIcon}
          iconPosition="after"
        />
        <FieldDescription schemaFragment={schemaFragment} />
      </div>
    </div>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return (
    schemaFragment?.enum !== undefined
  );
}, EnumFormStep);
