export const copyToClipboard = (text) => {
  // Create a <textarea> element to use for copying
  const textarea = document.createElement("textarea");
  textarea.value = text;

  // Append to the document
  document.body.appendChild(textarea);

  // Select the text
  textarea.select();

  // Copy the text
  document.execCommand("copy");

  // Remove the <textarea> from the document
  document.body.removeChild(textarea);
};
