import { useEffect } from "react";
import FieldDescription from "./FieldDescription";
import { SchemaComponent, registerSchemaComponent } from "./fieldRegistration";

import { XMarkIcon, PlusCircleIcon } from "@heroicons/react/20/solid";


export const ArrayFormStep = ({
  value,
  propKey,
  schemaFragment,
  setValue,
  topLevelObject,
  ...props
}) => {
  if (typeof value != "object" || value.length === undefined) value = [];

  useEffect(
    () => {
      if (!schemaFragment) return;
      if (schemaFragment.minItems !== undefined && value.length < schemaFragment.minItems) {
        let newValue = Array.from(value);
        for (var i = newValue.length; i < schemaFragment.minItems; i++) {
          newValue.push(undefined);
        }
        setValue(newValue);
      }
      if (schemaFragment.maxItems !== undefined && value.length > schemaFragment.maxItems) {
        setValue(value.slice(0, schemaFragment.maxItems));
      }
    },
    [schemaFragment, value, setValue]);
            
  const onAdd = () => {
    if (schemaFragment?.maxItems !== undefined && value.length >= schemaFragment?.maxItems) {
      return;
    }
    setValue(value.concat([undefined]));
  }
  return (
    <>
      <div className="flex items-center mb-2 mt-2">
        <h4 className="font-medium text-sm capitalize">{propKey}</h4>
        <FieldDescription schemaFragment={schemaFragment} />
      </div>
      <div className="array_step">
        {value.map((subValue, idx) => {
          const setSubValue = (subValue) => {
            const newValue = [...value];
            newValue[idx] = subValue;
            setValue(newValue);
          };
          const onRemove = () => {
            const newValue = value.slice(0, idx).concat(value.slice(idx+1));
            setValue(newValue);
          };
          return (
            <div key={idx}>
              <SchemaComponent
                {...props}
                schemaFragment={schemaFragment.items}
                value={subValue}
                setValue={setSubValue}
                propKey={idx}
                key={idx}
              />
              {(schemaFragment.minItems === undefined || value.length > schemaFragment.minItems) &&
               <button
                type="button"
                onClick={onRemove}
                className="hover:bg-zinc-100 hover:text-zinc-800 text-zinc-700 font-bold p-1 rounded-md"
                >
                 <XMarkIcon className="h-4 w-4" />
               </button>
              }
            </div>
          );
        })}
        {(schemaFragment.maxItems === undefined || value.length < schemaFragment.maxItems) &&
          <button
            type="button"
            onClick={onAdd}
            className="hover:bg-zinc-100 hover:text-zinc-800 text-zinc-700 font-bold p-1 rounded-md"
          >
            <PlusCircleIcon className="h-4 w-4" />
         </button>
        }
      </div>
    </>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return (
    (schemaFragment?.type === "array") &&
    schemaFragment?.items
  );
}, ArrayFormStep);
