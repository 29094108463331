import React, { createContext, useContext, useMemo } from "react";
import { useInteractiveStates } from "../../../../contexts/InteractiveStateContext";
import { NodeDataContext } from "../../../../contexts";
import { useSelectedSoundings } from "../../../../hooks/useSelectedSoundings";

// Do NOT use this outside of plotting!

export const PlotContext = createContext();

export const PlotContextProvider = ({ children }) => {
  const { nodeData, nodeModel, nodeType, workspaceLayout, nodeBinaryByLine, manualEdits, setManualEdits } =
    useContext(NodeDataContext);

  const { selectedLineId, selectedSoundingId, setSelectedSoundingId, setSelectedSoundingColor, selectedSoundingColor, selections, showHover, setShowHover } =
    useInteractiveStates();

  const selectedSoundingIds = useSelectedSoundings();
  
  const value = useMemo(
    () => ({
      nodeData,
      nodeModel,
      nodeType,
      workspaceLayout,
      selectedLineId,
      selectedSoundingId,
      selectedSoundingIds,
      setSelectedSoundingId,
      setSelectedSoundingColor,
      selectedSoundingColor,
      nodeBinaryByLine,
      manualEdits,
      setManualEdits,
      selections,
      showHover,
      setShowHover
    }),
    [
      nodeData,
      nodeModel,
      nodeType,
      workspaceLayout,
      selectedLineId,
      selectedSoundingId,
      selectedSoundingIds,
      setSelectedSoundingId,
      setSelectedSoundingColor,
      selectedSoundingColor,
      nodeBinaryByLine,
      manualEdits,
      setManualEdits,
      selections,
      showHover,
      setShowHover
    ]
  );

  return <PlotContext.Provider value={value}>{children}</PlotContext.Provider>;
};

export const usePlotContext = () => {
  return useContext(PlotContext);
};
