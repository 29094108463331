import React from "react";

const Banner = () => {
  return (
    <div className="welcome-banner">
      <h2>
        Welcome to Beryl <span>Beta</span>
      </h2>
      <p>
        Thank you for joining the first official beta release of Beryl. As we're still in the production phase, you may
        encounter some errors along the way. Your feedback is invaluable and will help us enhance the upcoming versions.
      </p>
      <p>
        {" "}
        Please note that during this beta, you are limited to create<span className="font-medium"> 3 projects.</span>
      </p>
      <p>We appreciate your time and support as we work to develop Beryl!</p>
    </div>
  );
};

export default Banner;
