const generic_sounding_resistivity = (logspaced) => {
  return {
    title: (context, args) => args.dataset,
    xaxis: logspaced ? "log10_ohmm" : "ohmm",
    yaxis: "elevation",
    fn: function (context, args, elements) {
      const { nodeBinaryByLine, selectedLineId, selectedSoundingId } = context;
      let binaries = null;
      if (nodeBinaryByLine?.[args.dataset] === undefined) {
        binaries = nodeBinaryByLine?.[args.dataset + "_model"];
      } else {
        binaries = nodeBinaryByLine?.[args.dataset];
      }
      let binary = binaries?.[selectedLineId];
      if (!binary || !binary.flightlines) return [];

      const soundingsAll = context.selectedSoundingIds;
      const soundings = [...new Set(
        soundingsAll.filter((idx) => idx !== selectedSoundingId)
      )].sort().concat([selectedSoundingId]);

      let res = [];
      soundings.forEach((sounding) => {
        const resistivities = [];
        const elevations = []

        for (var col in binary.layer_data.resistivity) {
          // Yes, twice, once for top, once for bottom
          let r = binary.layer_data.resistivity[col][sounding];
//          resistivities.push(r);
          resistivities.push(r);
//          elevations.push(-binary.layer_data.dep_top[col][sounding]);
//          elevations.push(-binary.layer_data.dep_bot[col][sounding]);
          elevations.push((-binary.layer_data.dep_top[col][sounding] - binary.layer_data.dep_bot[col][sounding]) / 2);
        }
        elevations[elevations.length - 1] = elevations[elevations.length - 2] - (elevations[elevations.length - 3] - elevations[elevations.length - 2]);

        var xdist = binary.flightlines.xdist[sounding];

        const name = "Resistivity @ " + xdist?.toFixed(0) + "m";

        const xUnit = elements.xaxis.ohmm.shortTitle;
        const yUnit = elements.yaxis[this.yaxis].shortTitle;

        const hoverTemplate = `${xdist?.toFixed(
          1
        )} m, %{x} ${xUnit} @ %{y} ${yUnit} <extra>${args.dataset}</extra>`;

        res.push({
          type: "scattergl",
          mode: "lines",
          marker: { size: 0.000001 },
          legendgroup: "Resistivity",
          name: name,
          showlegend: true,
          x: resistivities,
          y: elevations,
          gate: col,
          hovertemplate: hoverTemplate,
          line: {
            color: "rgb(128, 0, 0)",
            width: 1,
          },
          hoverinfo: context.showHover ? "all" : "none",
        });
      });

      return res;
    },

    schema: (context) => {
      const datasets = Object.keys(context.nodeBinaryByLine)
        .filter((dataset) => !!Object.values(context.nodeBinaryByLine[dataset])?.[0].layer_data.resistivity)
        .map((dataset) => dataset.replace("_model", ""));

      if (!datasets.length) {
        return false;
      }

      return {
        type: "object",
        required: ["dataset"],
        properties: {
          dataset: {
            type: "string",
            enum: datasets,
          }
        },
        additionalProperties: false,
      };
    },
  };
};

export const sounding_resistivity = generic_sounding_resistivity(false);
export const sounding_resistivity_logspaced = generic_sounding_resistivity(true);
