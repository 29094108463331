import React, { useContext } from "react";
import { SchemaComponent, registerSchemaComponent } from "../fieldRegistration";
import { NodeDataContext } from "../../../../contexts";

export const AvailableFlightTypeList = ({ schemaFragment, ...props }) => {
  const { nodeBinaryByLine } = useContext(NodeDataContext);

  let flight_types = Object.values(nodeBinaryByLine.measured)
    .map((line) => line?.flight_types || [])
    .flat();
  if (flight_types.length === 0) flight_types = ["Production"];

  return (
    <SchemaComponent
      {...props}
      schemaFragment={{
        ...schemaFragment,
        items: {
          ...schemaFragment.items,
          "x-reference": undefined,
          enum: flight_types,
        },
      }}
    />
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return (
    schemaFragment && schemaFragment.items?.["x-reference"] === "flight_type"
  );
}, AvailableFlightTypeList);
