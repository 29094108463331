import React from "react";
import { PaneMenu } from "./PaneMenu";
import { PaneContent } from "./PaneContent";

export const DetailsPane = ({
  nodeType,
  setActiveMenuOption,
  activeMenuOption,
  setIsSidePanelOpen,
  nodeData,
  logData,
  isLoading,
  flightlines,
}) => {
  return (
    <div className="h-full overflow-hidden bg-white rounded-bl-lg rounded-br-lg">
      <PaneMenu
        nodeType={nodeType}
        setActiveMenuOption={setActiveMenuOption}
        activeMenuOption={activeMenuOption}
        flightlines={flightlines}
      />
      <PaneContent
        activeMenuOption={activeMenuOption}
        logData={logData}
        isLoading={isLoading}
        nodeData={nodeData}
        setIsSidePanelOpen={setIsSidePanelOpen}
        flightlines={flightlines}
      />
    </div>
  );
};
