import { SchemaComponent } from "./fieldRegistration";

import {
  FileTypeFormStep,
  BooleanTypeFormStep,
  StringTypeFormStep,
  IntegerTypeFormStep,
  FloatTypeFormStep,
} from "./BasicTypeFormStep";
import { EnumFormStep } from "./EnumFormStep";
import { ArrayFormStep } from "./ArrayFormStep";
import { ObjectFormStep } from "./ObjectFormStep";
import { SingleObjectStep } from "./SingleObjectStep";
import { SequenceFormStep } from "./combined/SequenceFormStep";
import { TopLevelObjectStep } from "./combined/TopLevelObjectStep";
import { ArrayOfEnumStep } from "./combined/ArrayOfEnumStep";
import { AnyOfFormStep } from "./AnyOfFormStep";
import { CustomSelectorStep } from "./combined/CustomSelectorStep";
import { CustomMultiSelectorStep } from "./combined/CustomMultiSelectorStep";
import { FileImportField } from "./FileImportField";
import { SingleFileImportField } from "./SingleFileImportField";
import { ProjectionFormStep } from "./custom/ProjectionFormStep";
import { AuxFileFormStep } from "./custom/AuxFileFormStep";
import { InversionFormStep } from "./custom/InversionFormStep";
import { ManualEditsFormStep } from "./custom/ManualEditsFormStep";
import { FlightlinesFormStep } from "./custom/FlightlinesFormStep";
import { ChannelFormStep } from "./custom/ChannelFormStep";
import { ChannelGateField } from "./custom/ChannelGateField";
import { GateRangeFormStep } from "./custom/GateRangeFormStep";
import { ChannelGateRangeField } from "./custom/ChannelGateRangeField";
import { AvailableFlightType } from "./custom/AvailableFlightType";
import { AvailableFlightTypeList } from "./custom/AvailableFlightTypeList";
import { FlightlineColumns } from "./custom/FlightlineColumns";
import { FlightlineColumnsList } from "./custom/FlightlineColumnsList";
import { LayerDatas } from "./custom/LayerDatas";
import { LayerDatasList } from "./custom/LayerDatasList";

FileTypeFormStep.register();
BooleanTypeFormStep.register();
StringTypeFormStep.register();
IntegerTypeFormStep.register();
FloatTypeFormStep.register();
EnumFormStep.register();
ArrayFormStep.register();
ObjectFormStep.register();
SingleObjectStep.register();
SequenceFormStep.register();
TopLevelObjectStep.register();
ArrayOfEnumStep.register();
AnyOfFormStep.register();
CustomSelectorStep.register();
CustomMultiSelectorStep.register();
FileImportField.register();
SingleFileImportField.register();
ProjectionFormStep.register();
AuxFileFormStep.register();
InversionFormStep.register();
ManualEditsFormStep.register();
FlightlinesFormStep.register();
ChannelFormStep.register();
ChannelGateField.register();
GateRangeFormStep.register();
ChannelGateRangeField.register();
AvailableFlightType.register();
AvailableFlightTypeList.register();
FlightlineColumns.register();
FlightlineColumnsList.register();
LayerDatas.register();
LayerDatasList.register();

export const NiceJsonForm = ({ schema, value, setValue }) => {
  //console.log("value", value);
  return <SchemaComponent schemaFragment={schema} value={value} setValue={setValue} />;
};
