import React, { useContext } from "react";
import { FormLabel } from "../../../common/forms/Form";
import SimpleSelect from "../../../common/SimpleSelect";
import { NodeDataContext } from "../../../../contexts";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import FieldDescription from "../FieldDescription";

const extractChannels = (nodeBinaryByLine) => {
  if (!nodeBinaryByLine) return null;

  const channels = {};
  Object.values(nodeBinaryByLine).forEach((dataset) =>
    Object.keys(Object.values(dataset)?.[0].layer_data).forEach((key) => {
      if (/^gate_ch\d{2}$/i.test(key)) {
        channels[key] = true;
      }
    })
  );
  return Object.keys(channels);
};

export const ChannelInput = ({
  value,
  label,
  schemaFragment,
  onSelectChannel,
}) => {
  const { nodeBinaryByLine } = useContext(NodeDataContext);

  const channelKeys = extractChannels(nodeBinaryByLine);
  const channels = channelKeys
    ? channelKeys
        .filter((key) => /^gate_ch\d{2}$/i.test(key))
        .map((key) => {
          return {
            title: parseInt(key.match(/\d{2}/)[0]),
            id: parseInt(key.match(/\d{2}/)[0]),
          };
        })
    : null;

  const selectedChannel = channels?.find(
    (item) => item.id === (value?.channel ? value.channel : value)
  );

  return (
    <div className="block grid grid-cols-6 gap-2 items-center mt-1">
      <div className="col-span-2 flex items-center">
        <FormLabel label={label} htmlFor={"channel"} fontWeight="font-normal" />
      </div>
      <div className="col-span-4 flex items-center">
        <SimpleSelect
          items={channels}
          width="w-20"
          dropdownWidth="w-full"
          selected={selectedChannel}
          setSelected={onSelectChannel}
          nothingTitle="-"
          buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
          IconComponent={ChevronUpDownIcon}
          iconPosition="after"
        />
        <FieldDescription schemaFragment={schemaFragment} />
      </div>
    </div>
  );
};
