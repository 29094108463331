import React from "react";
import Billing from "../components/workspace/modules/Billing";

const BillingPage = () => {
  return (
    <>
      <Billing />
    </>
  );
};

export default BillingPage;
