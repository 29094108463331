import { useQueryClient } from "@tanstack/react-query";
import client from "../../api/client";

const useAddRasterStyle = (invalidateKey = "raster") => {
  const queryClient = useQueryClient();

  const handleAddStyle = async (projectId, rasterId) => {
    try {
      const { data } = await client.get(
        `/rasterserver/process/project/${projectId}/raster/${rasterId}`
      );
      queryClient.invalidateQueries([invalidateKey]);
      return data;
    } catch (error) {
      console.error("Failed to add style:", error);
      throw error; // Optionally handle or re-throw the error
    }
  };

  return handleAddStyle;
};

export const useAddStyleClick = (invalidateKey) => {
  const addStyle = useAddRasterStyle(invalidateKey);

  const onAddStyleClick = async (projectId, rasterId) => {
    try {
      const result = await addStyle(projectId, rasterId);
      console.log("Style added successfully:", result);
      return result;
    } catch (error) {
      console.error("Error adding style:", error);
      throw error;
    }
  };

  return onAddStyleClick;
};
