import React from "react";

const ErrorPage = ({ message }) => {
  return (
    <div className="flex justify-center pt-40 w-full h-screen">
      <h2 className="text-2xl text-zinc-500">{message}</h2>
    </div>
  );
};

export default ErrorPage;
