import React from "react";

const Layout5x2 = ({ strokeColor }) => {
  return (
    <svg
      width="48"
      height="34"
      viewBox="0 0 41 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="4.14286" rx="1" fill="black" fillOpacity="0.3" />
      <rect
        y="6.21429"
        width="30"
        height="4.14286"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
      <rect
        x="32.8"
        width="8.2"
        height="4.14286"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
      <rect
        x="32.8"
        y="6.21429"
        width="8.2"
        height="4.14286"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
      <rect
        y="12.4286"
        width="30"
        height="4.14286"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
      <rect
        x="32.8"
        y="12.4286"
        width="8.2"
        height="4.14286"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
      <rect
        y="18.6429"
        width="30"
        height="4.14286"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
      <rect
        x="32.8"
        y="18.6429"
        width="8.2"
        height="4.14286"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
      <rect
        y="24.8571"
        width="30"
        height="4.14286"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
      <rect
        x="32.8"
        y="24.8571"
        width="8.2"
        height="4.14286"
        rx="1"
        fill="black"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default Layout5x2;
