import {
  titleDatasetChannel,
  schemaDatasetChannel,
  channel2col,
  channel2stdcol,
  channel2system,
} from "./utils";

const genericGatesErrorBars = (unitDipole) => {
  return {
    title: titleDatasetChannel,
    fn: function (context, args, elements) {
      const { nodeBinaryByLine, selectedLineId } = context;
      const binary =
        nodeBinaryByLine?.[args.dataset || "measured"]?.[selectedLineId];
      if (!binary || !binary.flightlines) return [];
      var traces = [];
      const channel = channel2col(args.channel);
      const stdChannel = channel2stdcol(args.channel);
      var xdist = binary.flightlines.xdist;

      const systemChannel = channel2system(args.channel);
      const moment = unitDipole
        ? 1
        : binary.system[systemChannel].ApproxDipoleMoment;

      elements.yaxis[this.yaxis].exponentformat = "power";
      elements.xaxis[this.xaxis].exponentformat = "power";
      elements.yaxis[this.yaxis].tickfont = {
        size: 10,
      };
      elements.xaxis[this.xaxis].tickfont = {
        size: 12,
      };

      for (let col in binary.layer_data[channel]) {
        const name = args.channel + " gate " + col;

        const ys = binary.layer_data[channel][col].map(
          (y) => Math.abs(y) * binary.model_info.scalefactor * moment
        );
        const ystd = binary.layer_data[stdChannel] !== undefined ? binary.layer_data[stdChannel][col] : binary.layer_data[channel][col].map((a) => 0);

        for (let side = -1; side < 2; side += 2) {
          traces.push({
            type: "scatter",
            mode: "lines",
            fill: side > 0 ? "tonexty" : null,
            fillcolor: "#2925241A",
            line: { width: 1, color: "#2925241A" },
            name: name,
            showlegend: false,
            legendgroup: args.channel,
            x: xdist,
            y: ys.map((d, idx) =>
              side < 0 ? d * (1 + ystd[idx]) : d / (1 + ystd[idx])
            ),
            gate: col,
            hoverinfo: context.showHover ? "all" : "none",
          });
        }
      }
      return traces;
    },
    xaxis: "xdist",
    yaxis: unitDipole ? "dbdt" : "dbdtmoment",
    schema: schemaDatasetChannel,
  };
};

export const gates_error_bars = genericGatesErrorBars(true);
export const gates_error_bars_dipole_moment = genericGatesErrorBars(false);
