import React, { createContext, useState, useContext } from "react";

export const MessagesContext = createContext();

export const MessagesProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const addMessage = (msg) => {
    setMessages([msg, ...messages]);
  };

  const removeMessage = (msg) => {
    setMessages(messages.filter((m) => m !== msg));
    if (msg.retry) {
      msg.retry();
    }
  };

  return (
    <MessagesContext.Provider
      value={{ messages, setMessages, addMessage, removeMessage }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessagesContext = () => useContext(MessagesContext);
