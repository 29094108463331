import { useMemo } from "react";
import { slugify } from "../utils";

export function useActiveProject() {
  const projectInfo = useMemo(() => {
    const storedInfo = localStorage.getItem("activeProject");
    if (storedInfo) {
      const { title, projectId } = JSON.parse(storedInfo);
      return {
        projectTitle: slugify(title),
        projectId,
      };
    }
    // Default values or error handling if needed
    return { projectTitle: null, projectId: null };
  }, []);

  return projectInfo;
}
