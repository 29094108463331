import { extend as olExtend, buffer } from "ol/extent";

export const handleHover = (map, setHoveredLineId, setHoveredSoundingId) => (evt) => {
  if (map.current && map.current.nodeBinaryByLine) {
    var feature = map.current.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
      return layer === map.current.flightlineLayer ? feature : null;
    });

    if (feature) {
      const lineId = feature.getProperties().Line;
      const binary = Object.values(map.current.nodeBinaryByLine)?.[0]?.[lineId];
      const point = map.current.getCoordinateFromPixel(evt.pixel);
      const xs = binary.flightlines.x_web;
      const ys = binary.flightlines.y_web;
      const dists = xs.map((x, idx) => (x - point[0]) ** 2 + (ys[idx] - point[1]) ** 2);
      const soundingId = dists.indexOf(Math.min(...dists));
      setHoveredLineId(lineId);
      setHoveredSoundingId(soundingId);
    } else {
      setHoveredLineId(null);
      setHoveredSoundingId(null);
    }
  }
};

export const handleMapClick = (map, setSelectedLineId, setSelectedSoundingId) => (evt) => {
  var feature = map.current.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
    return layer === map.current.flightlineLayer ? feature : null;
  });
  if (feature) {
    const lineId = feature.getProperties().Line;
    setSelectedLineId(lineId);

    if (!map.current.nodeBinaryByLine) return;

    const point = map.current.getCoordinateFromPixel(evt.pixel);
    const binary = Object.values(map.current.nodeBinaryByLine)?.[0]?.[lineId];
    const xs = binary.flightlines.x_web;
    const ys = binary.flightlines.y_web;
    const dists = xs.map((x, idx) => (x - point[0]) ** 2 + (ys[idx] - point[1]) ** 2);
    const soundingId = dists.indexOf(Math.min(...dists));
    setSelectedSoundingId(soundingId);
  }
};

export const zoomToSoundingExtent = (map) => (evt) => {
  const soundingExtent = map.current.selectedSoundingLayer.getSource().getExtent();
  const flightlineExtent = map.current.flightlineLayer.getSource().getExtent();
  const marginFactor = 0.07; // Amount of space around the sounding
  const bufferedExtent = buffer(
    soundingExtent,
    marginFactor *
      Math.max(
        flightlineExtent[2] - flightlineExtent[0], // width of the flightline extent
        flightlineExtent[3] - flightlineExtent[1] // height of the flightline extent
      )
  );
  const finalExtent = olExtend(bufferedExtent, soundingExtent);
  map.current.getView().fit(finalExtent, {
    duration: 200,
  });
};
