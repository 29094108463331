import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../api/client";
import { error2message } from "./api/useSafeQuery";

const useCreateGroup = (onClose, activeNode, addMessage, setIsEmptyProject) => {
  const queryClient = useQueryClient();

  const projectId = activeNode[0];

  const createGroupMutation = useMutation({
    mutationFn: (data) =>
      client.post(`/api/projects/${projectId}/groups/`, {
        title: data.title,
        description: data.description,
        project: projectId,
        color: data.color,
      }),
    onSuccess: (data) => {
      const newData = data.data;
      queryClient.setQueryData(["groups", projectId], (oldData) => {
        return oldData ? [...oldData, newData] : [newData];
      });

      setIsEmptyProject(false);
      if (typeof onClose === "function") {
        onClose();
      }
    },
    onError: (error) => {
      onClose();
      if (error.response.status === 401) {
        const errorMessage = {
          title: error.response.statusText,
          message: error.response.data.message,
        };

        addMessage({
          title: errorMessage.title,
          shortMessage: errorMessage.message,
          type: "notice",
          message: errorMessage.message,
        });
      } else {
        addMessage(error2message(error));
        console.error("error", error);
      }
    },
  });

  const handleFormSubmit = (data) => {
    createGroupMutation.mutate(data);
  };

  return {
    createGroupMutation,
    handleFormSubmit,
  };
};

export default useCreateGroup;
