import React from "react";
import { strokeColor } from "./layout/variables";

const PinIcon = ({ pinned = false }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.2 23C8.2 23.4418 8.55817 23.8 9 23.8C9.44183 23.8 9.8 23.4418 9.8 23H8.2ZM8.2 16V23H9.8V16H8.2Z"
        fill={strokeColor}
      />
      <path
        d="M16.6632 15.5H1.33681C1.15079 15.5 1 15.3492 1 15.1632C1 12.6117 2.44156 10.2792 4.72367 9.13817L4.94875 9.02563C4.97869 9.01065 4.99422 8.97689 4.9861 8.94441L3.31063 2.24254C3.15285 1.61139 3.63021 1 4.28078 1H13.7192C14.3698 1 14.8472 1.61139 14.6894 2.24254L13.0139 8.94441C13.0058 8.97689 13.0213 9.01065 13.0513 9.02563L13.2763 9.13817C15.5584 10.2792 17 12.6117 17 15.1632C17 15.3492 16.8492 15.5 16.6632 15.5Z"
        fill={pinned ? strokeColor : "transparent"}
        stroke={strokeColor}
        strokeWidth="1.6"
      />
    </svg>
  );
};

export default PinIcon;
