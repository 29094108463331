import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NodeDataContext } from "../../contexts";
import { colorOptions } from "../../utils";
import Form, { FormInput, FormLabel } from "../common/forms/Form";
import ColorSwatch from "../common/ColorSwatch";

// validation schema
const schema = yup.object().shape({
  title: yup.string().required("Title is required").min(3, "Title must be at least 3 characters long"),
  description: yup.string().required("Description is required"),
});

const GroupForm = ({
  handleFormSubmit,
  onClose,
  submitButtonText,
  initialTitle,
  initialDescription,
  titleInputLabel,
  descriptionInputLabel,
}) => {
  const { nodeData } = useContext(NodeDataContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: initialTitle,
      description: initialDescription,
    },
  });

  const onSubmit = (data) => {
    data.color = selectedColor;
    handleFormSubmit(data);
  };

  const [selectedColor, setSelectedColor] = useState(nodeData?.color || "#535357");

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="title"
        defaultValue={initialTitle || ""}
        render={({ field }) => (
          <FormInput label={titleInputLabel} placeholder="Title" error={errors.title} {...field} />
        )}
      />

      <div className="flex flex-col">
        <FormLabel label={descriptionInputLabel} htmlFor="description" />
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <textarea
              {...field}
              className={` block w-full rounded-md placeholder:text-zinc-400
      focus:ring-0 focus:ring-inset sm:leading-6 focus:outline outline-zinc-200
      focus:border-zinc-500 text-sm bg-transparent py-1.5 px-2
      border border-1 border-zinc-300 rounded-md hover:border-zinc-500 ${errors.description ? "border-red-600" : ""}`}
              placeholder="Type your description here..."
              rows="3"
            />
          )}
        />
        {errors.description && <p className="text-red-600 text-sm p-0.5">{errors.description.message}</p>}
      </div>

      <ColorPicker setSelectedColor={setSelectedColor} selectedColor={selectedColor} />

      <div className="button-group flex flex-row justify-end gap-2 mt-2">
        <button
          onClick={onClose}
          type="button"
          className="bg-transparent text-zinc-800 text-sm px-3 py-2 rounded-md border border-zinc-200 bg-zinc-50 hover:bg-zinc-100"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-zinc-900 text-white text-sm px-4 py-2 rounded-md hover:bg-zinc-900/90 transition ease-in-out 2s"
        >
          {submitButtonText}
        </button>
      </div>
    </Form>
  );
};

const ColorPicker = ({ setSelectedColor, selectedColor }) => {
  return (
    <div className="flex flex-col gap-2 mb-2">
      <FormLabel label={"Pick a color"} htmlFor={"color"} />
      <input type="hidden" name="color" value={selectedColor} />
      <ColorSwatch colors={colorOptions} setSelectedColor={setSelectedColor} selectedColor={selectedColor} />
    </div>
  );
};

export default GroupForm;
