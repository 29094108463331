import React from "react";
import SimpleSelect from "../../../common/SimpleSelect";
import { ChevronUpDownIcon } from "../../../../assets/icons";
import { FormLabel } from "../../../common/forms/Form/FormLabel";

export const DataTypeSelect = ({ datatypes, selectedDatatype, setSelectedDataype, error, onChange }) => {
  return (
    <div>
      <FormLabel label={"Select datatype"} htmlFor={"datatype"} />
      <SimpleSelect
        items={datatypes}
        selected={selectedDatatype}
        setSelected={(selectedItem) => {
          setSelectedDataype(selectedItem);
          onChange(selectedItem);
        }}
        buttonStyles="relative mt-1.5 flex items-center gap-2 justify-between text-sm w-full bg-transparent py-2 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
        IconComponent={ChevronUpDownIcon}
        iconPosition="after"
        width=""
        showCheckIcon={true}
      />
      <div className="text-sm text-zinc-500 mt-1">{error && <p className="text-red-500">{error.message}</p>}</div>
    </div>
  );
};
