import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useCreateProject from "../../hooks/useCreateProject";
import Form, { FormInput } from "../common/forms/Form";
import { MessagesContext } from "../../contexts";

const createValidationSchema = (existingProjects) => {
  return yup.object().shape({
    title: yup
      .string()
      .required("Title is required")
      .min(3, "Title must be at least 3 characters long")
      .test(
        "is-unique",
        "Title already exists",
        (value) => !existingProjects.map((project) => project.title).includes(value) // Check uniqueness
      ),
  });
};

const CreateProjectForm = ({ closePopup, handleProjectClick, existingProjects }) => {
  const { addMessage } = useContext(MessagesContext);
  const { handleFormSubmit } = useCreateProject(addMessage, handleProjectClick);

  const schema = createValidationSchema(existingProjects);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    handleFormSubmit(data);
    closePopup();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        {...register("title", { required: true, minLength: 3 })}
        placeholder="Title"
        label={"Title"}
        htmlFor={"title"}
        error={errors.title}
      />

      <div className="button-group flex flex-row justify-between mt-1">
        <button
          onClick={closePopup}
          type="button"
          className="bg-transparent text-zinc-600 text-sm px-4 py-2 rounded-md border border-zinc-200 bg-zinc-50 hover:bg-zinc-100"
        >
          Cancel
        </button>
        <button type="submit" className="bg-zinc-600 text-white text-sm px-4 py-2 rounded-md hover:bg-zinc-700">
          Create
        </button>
      </div>
    </Form>
  );
};

export default CreateProjectForm;
