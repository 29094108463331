import React, { useContext } from "react";
import { MessagesContext, NodeDataContext } from "../../contexts";
import { useCreateGroup, useUpdateGroup } from "../../hooks";
import GroupForm from "./GroupForm";

const EditGroupForm = ({ isEditMode, groupData, closePopup, setIsEmptyProject }) => {
  const { activeNode } = useContext(NodeDataContext);
  const { addMessage } = useContext(MessagesContext);
  const createGroupHandler = useCreateGroup(closePopup, activeNode, addMessage, setIsEmptyProject);
  const updateGroupHandler = useUpdateGroup(closePopup, activeNode, addMessage);

  const { handleFormSubmit } = isEditMode ? updateGroupHandler : createGroupHandler;

  const formTitle = isEditMode ? "Edit group" : "Add new group";
  const submitButtonText = isEditMode ? "Save changes" : "Create";
  const titleInputLabel = isEditMode ? "Title" : "Add a title";
  const descriptionInputLabel = isEditMode ? "Description" : "Add a description";
  const formElementClasses = `group-form absolute ${
    isEditMode ? "bottom-1 -left-2" : "top-10 left-2"
  } drop-shadow-xl bg-white rounded-lg w-96 border border-zinc-200 z-50`;

  return (
    <GroupForm
      activeNode={activeNode}
      handleFormSubmit={handleFormSubmit}
      onClose={closePopup}
      formTitle={formTitle}
      submitButtonText={submitButtonText}
      formElementClasses={formElementClasses}
      initialTitle={isEditMode ? groupData?.title : ""}
      initialDescription={isEditMode ? groupData?.description : ""}
      titleInputLabel={titleInputLabel}
      descriptionInputLabel={descriptionInputLabel}
    />
  );
};

export default EditGroupForm;
