import { useEffect } from "react";
import { initializeDefaultValue } from "./utils";

const registery = [];

export const registerSchemaComponent = (schemaFilter, Component) => {
  Component.register = () => {
    registery.push({ filter: schemaFilter, component: Component });
  };
};

export const SchemaComponent = ({
  schemaFragment,
  value,
  setValue,
  readOnly,
  ...props
}) => {
  const oldValue = value;

  readOnly = readOnly || schemaFragment?.readOnly;

  if (value === undefined || readOnly) {
    if (schemaFragment?.default !== undefined) {
      value = schemaFragment.default;
    } else {
      value = initializeDefaultValue(schemaFragment);
    }
  }

  const shortSchemaDebug = schemaFragment
    ? Object.fromEntries(
        Object.entries(schemaFragment).map(([key, subSchema]) => {
          if (typeof subSchema === "object" && subSchema !== null) {
            return [key, { length: Object.keys(subSchema).length }];
          } else {
            return [key, subSchema];
          }
        })
      )
    : schemaFragment;

  const defaultSet = value !== oldValue;
  useEffect(() => {
    if (oldValue === undefined && defaultSet) {
      setValue(value);
    }
  }, [defaultSet, oldValue, value, setValue]);

  for (var i = registery.length - 1; i >= 0; i--) {
    if (registery[i].filter({ schemaFragment, ...props })) {
      const Component = registery[i].component;
      return (
        <div
          className={`nice-json-form-component nice-json-form-component--${Component.name}`}
        >
          <div className="nice-json-form-debug">
            <div className="nice-json-form-debug__component">
              Component: {Component.name}
            </div>
            <div className="nice-json-form-debug__schemaFragment">
              SchemaFragment: {JSON.stringify(shortSchemaDebug)}
            </div>
            <div className="nice-json-form-debug__value">
              Value: {JSON.stringify(value)}
            </div>
          </div>
          <Component
            schemaFragment={schemaFragment}
            value={value}
            setValue={readOnly ? (value) => {} : setValue}
            readOnly={readOnly}
            {...props}
          />
        </div>
      );
    }
  }
  return (
    <div className="text-red-700 p-1">
      No component found for your schema. {JSON.stringify(schemaFragment)}
    </div>
  );
};
