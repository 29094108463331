import React from "react";
import SidebarToggleButton from "./SidebarToggleButton";
import DropdownMenu from "./DropdownMenu";
import SidebarTitle from "./SidebarTitle";

const SidebarHeader = () => {
  return (
    <div className="beryl_sidebar-header">
      <div className="beryl_sidebar-header--controls">
        <div className="beryl_sidebar-header--left">
          <DropdownMenu />
          <SidebarTitle />
        </div>

        <SidebarToggleButton />
      </div>
    </div>
  );
};

export default SidebarHeader;
