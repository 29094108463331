import { useEffect } from "react";

export const useRerenderOnStylingChangeEffect = (map, selectedLineId) => {
  useEffect(() => {
    if (!map.current?.flightlineLayer) return;
    map.current.selectedLineId = selectedLineId;
    const src = map.current.flightlineLayer.getSource();
    if (src) src.changed();
  }, [map, map.current?.flightlineLayer, selectedLineId]);
};
