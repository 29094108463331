import React, { useContext, useEffect, useState } from "react";
import { MapLayoutContext } from "../../../../../contexts";
import { useAuxiliaryData } from "../../../../../hooks/api/useAuxiliaryData";
import { FormCheck } from "../../../../common/forms/Form";
import {
  MapMenuList,
  MapMenuListHeading,
  MapMenuListItem,
} from "./MapMenuListItems";
import TooltipButton from "../../../../common/TooltipButton";
import { PlusIcon, ChevronDownIcon } from "../../../../../assets/icons";
import Slider from "../../../../common/ui/Slider";
import { useAddStyleClick } from "../../../../../hooks/mapHooks/useAddRasterStyle";

const SelectionComponent = ({
  title,
  dataQuery,
  contextHandler,
  filterCondition,
  itemIdentifier,
  itemName,
  itemType,
  selectionMode = "multiple",
  onSelectionChange,
  map,
}) => {
  const { mapLayout, setMapLayout } = useContext(MapLayoutContext);
  const queryResult = useAuxiliaryData(itemType, contextHandler, dataQuery);
  const items = queryResult.data || [];
  const onAddStyleClick = useAddStyleClick();

  const [allSelected, setAllSelected] = useState(
    items.every((item) => mapLayout[itemIdentifier].includes(item.id))
  );

  useEffect(() => {
    setAllSelected(
      items.every((item) => mapLayout[itemIdentifier].includes(item.id))
    );
  }, [items]); // eslint-disable-line

  const filteredItems = items.filter(
    (item) => !item.tags.some((tag) => filterCondition(tag))
  );

  const handleItemChange = (itemId) => {
    if (selectionMode === "single") {
      //onSelectionChange(item); // Use custom logic for single selection
    } else {
      const isChecked = mapLayout[itemIdentifier].indexOf(itemId) >= 0;
      if (!isChecked) {
        mapLayout[itemIdentifier] = [...mapLayout[itemIdentifier], itemId];
      } else {
        mapLayout[itemIdentifier] = mapLayout[itemIdentifier].filter(
          (id) => id !== itemId
        );
      }
      console.log("MAPLAYOUTTTTTTT ", mapLayout);
      setMapLayout({ ...mapLayout });
    }
  };

  const handleDivClick = (itemId) => {
    handleItemChange(itemId);
  };

  const toggleAll = () => {
    if (allSelected) {
      setMapLayout({ ...mapLayout, [itemIdentifier]: [] });
    } else {
      const allItemIds = filteredItems.map((item) => item.id);
      setMapLayout({ ...mapLayout, [itemIdentifier]: allItemIds });
    }
    setAllSelected(!allSelected);
  };

  const [showList, setShowList] = useState({});

  const toggleListVisibility = (itemId) => {
    setShowList((prev) => ({
      ...prev,
      [itemId]: !prev[itemId], // Toggle the specific item's list visibility
    }));
  };

  const [togglePanelVisibility, setTogglePanelVisibility] = useState({});

  // Function to toggle panel visibility
  const handleTogglePanelVisibility = (itemId) => {
    setTogglePanelVisibility((prev) => ({
      ...prev,
      [itemId]: !prev[itemId], // Toggle the specific item's panel visibility
    }));
  };

  const RasterItem = ({
    item,
    itemId = item.id,
    itemTitle = item.title,
    itemType,
    addStyle = true,
  }) => {
    return (
      <>
        <MapMenuListItem key={itemId} onClick={() => handleDivClick(itemId)}>
          <FormCheck
            id={`${itemType}`}
            name={itemType}
            value={itemId}
            checked={mapLayout[itemIdentifier].indexOf(itemId) >= 0}
            onChange={() => handleDivClick(itemId)}
            isCheckBox={false}
          />
          <div
            title={itemTitle}
            className="text-ellipsis truncate clip"
            aria-label={"Title"}
          >
            {itemTitle}
          </div>

          <div className="flex items-center ml-auto">
            {addStyle && (
              <TooltipButton
                tooltipSpacing={"2"}
                tooltipText={"Add style"}
                icon={<PlusIcon className="w-4 h-4" />}
                additionalClasses="text-zinc-400 hover:text-zinc-300 w-6 h-6"
                onClick={(event) => {
                  event.stopPropagation();
                  onAddStyleClick(item.project, item.id);
                }}
              />
            )}
            <button
              onClick={(event) => {
                event.stopPropagation();
                handleTogglePanelVisibility(itemId);
              }}
              className="tooltip-button  w-6 h-6 text-zinc-400 hover:text-zinc-300"
            >
              <ChevronDownIcon
                className={`h-4 w-4 transition-transform duration-300 ${
                  togglePanelVisibility[itemId] ? "rotate-180" : ""
                }`}
              />
            </button>
          </div>
        </MapMenuListItem>
        <div
          className={`layer-edits-panel py-1.5 block w-full ${
            togglePanelVisibility[itemId] ? "block" : "hidden"
          }`}
        >
          <div className="bg-zinc-900/80 text-zinc-300 border border-zinc-700 p-1.5 px-2 rounded-md">
            Opacity
            <Slider
              itemId={itemId}
              setMapLayout={setMapLayout}
              mapLayout={mapLayout}
              map={map}
            />
          </div>
        </div>
      </>
    );
  };

  const renderRasters = (urlObject, itemType, item) => {
    if (urlObject.styles === undefined) {
      return <RasterItem item={item} itemType={itemType} />;
    }
    return (
      <>
        <div
          aria-label="show raster layers"
          className="flex items-center justify-between px-1.5  text-zinc-300"
        >
          <div
            className="text-zinc-300 text-ellipsis truncate clip"
            title={item.title}
            aria-label={"Title"}
          >
            {item.title}
          </div>

          <div className="flex items-center">
            <TooltipButton
              tooltipSpacing={"2"}
              tooltipText={"Add style"}
              icon={<PlusIcon className="w-4 h-4" />}
              additionalClasses="text-zinc-400 hover:text-zinc-300 w-6 h-6"
              onClick={() => onAddStyleClick(item.project, item.id)}
            />

            <button
              onClick={(event) => {
                event.stopPropagation();
                toggleListVisibility(item.id);
              }}
              className="tooltip-button  w-6 h-6 text-zinc-400 hover:text-zinc-300"
            >
              <ChevronDownIcon
                className={`h-4 w-4 transition-transform duration-300 ${
                  showList[item.id] ? "rotate-180" : ""
                }`}
              />
            </button>
          </div>
        </div>
        <ul
          className={`pl-1.5 pr-1 ml-2 pb-1.5 transition-all duration-300 border-l border-zinc-500 ${
            showList[item.id] ? "block" : "hidden"
          }`}
        >
          <RasterItem item={item} itemType={itemType} addStyle={false} />
          {Object.keys(urlObject.styles).map((key) => {
            const itemId = item.id + "(magic)" + key;
            return (
              <RasterItem
                item={item}
                itemId={itemId}
                itemTitle={key}
                itemType={itemType}
                addStyle={false}
              />
            );
          })}
        </ul>
      </>
    );
  };

  if (filteredItems.length > 0) {
    return (
      <div className="w-full">
        <MapMenuList>
          <MapMenuListHeading text={title} />
          {selectionMode === "multiple" && (
            <MapMenuListItem key="multiple" onClick={toggleAll}>
              <FormCheck
                id={`all-${itemType}`}
                name={`all-${itemType}`}
                checked={allSelected}
                onChange={toggleAll}
                isCheckBox={false}
              />
              <div htmlFor={`all-${itemType}`}>All</div>
            </MapMenuListItem>
          )}
          {filteredItems.map((item) => (
            <React.Fragment key={item.id}>
              {item.url ? (
                renderRasters(item.url, itemType, item)
              ) : (
                <MapMenuListItem onClick={() => handleDivClick(item.id)}>
                  <FormCheck
                    id={`${itemType}-${item.id}`}
                    name={itemType}
                    value={item.title}
                    checked={mapLayout[itemIdentifier].indexOf(item.id) >= 0}
                    onChange={() => handleDivClick(item.id)}
                    isCheckBox={false}
                  />
                  {item.title}
                </MapMenuListItem>
              )}
            </React.Fragment>
          ))}
        </MapMenuList>
      </div>
    );
  } else return null;
};

export default SelectionComponent;
