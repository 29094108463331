import { useState, useEffect } from "react";
import { useFetch } from "./useFetch";

export const useEnvironment = (environment_id) => {
  return useFetch(
    environment_id !== undefined ? `/api/environments/${environment_id}` : null
  );
};

const useEnvironmentsData = () => {
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const { data: environments } = useFetch("/api/environments");

  const {
    loading: envLoading,
    error: envError,
    data: environmentDetails,
  } = useFetch(
    selectedEnvironment ? `/api/environments/${selectedEnvironment.id}` : null
  );

  useEffect(() => {
    if (environments && environments.length > 0 && !selectedEnvironment) {
      const sortedEnvironments = environments
        .filter((env) => env.status === "done")
        .sort((a, b) => b.id - a.id);
  
      if (sortedEnvironments.length > 0) {
        setSelectedEnvironment(sortedEnvironments[0]);
      }
    }
  }, [environments, selectedEnvironment, setSelectedEnvironment]);
  
  const handleEnvironmentSelect = (env) => {
    setSelectedEnvironment(env);
  };
  
  const sortedEnvironments = environments
    ? environments.filter((env) => env.status === "done").sort((a, b) => b.id - a.id)
    : [];
  
  const res = {
    environments: sortedEnvironments,
    selectedEnvironment,
    envLoading,
    envError,
    environmentDetails,
    handleEnvironmentSelect,
  };

  window.environment = res;

  return res;
};

export default useEnvironmentsData;
