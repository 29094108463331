import { FormLabel } from "../../common/forms/Form";
import FieldDescription from "./FieldDescription";
import { registerSchemaComponent } from "./fieldRegistration";
import { useId } from "react";

export const BaseTypeFormStep = ({ value, propKey, schemaFragment, inputType, handleChange, children, readOnly }) => {
  const id = useId();

  const isControlled = value !== undefined;

  // For boolean types, use the 'checked' prop instead of 'value'
  const isBoolean = schemaFragment?.type === "boolean";

  return (
    <label key={id} className="block grid grid-cols-6 gap-2 items-center mt-1">
      <div className="col-span-2 flex items-center">
        <FormLabel label={schemaFragment?.title || propKey} htmlFor={id} fontWeight="font-normal" />
      </div>
      <div className="col-span-4 flex items-center">
        <input
          type={inputType}
          className={`p-1.5 border rounded-md text-sm ${
            schemaFragment?.type === "number" || schemaFragment?.type === "integer"
              ? "w-20"
              : schemaFragment?.type === "string"
              ? "w-72"
              : ""
          }`}
          name={id}
          // Use 'checked' for boolean types, 'value' for others
          {...(isBoolean ? { checked: isControlled ? value : undefined } : { value: isControlled ? value : undefined })}
          onChange={handleChange}
          placeholder={`Enter ${schemaFragment?.title || propKey}`}
          disabled={readOnly}
        />
        {children}
        <FieldDescription schemaFragment={schemaFragment} />
      </div>
    </label>
  );
};

export const FileTypeFormStep = ({ setValue, ...props }) => {
  const handleChange = (e) => {
    setValue(e.target.files[0]);
  };

  return <BaseTypeFormStep {...props} setValue={setValue} inputType="file" handleChange={handleChange} />;
};

export const BooleanTypeFormStep = ({ setValue, ...props }) => {
  const handleChange = (e) => {
    setValue(e.target.checked);
  };

  return <BaseTypeFormStep {...props} setValue={setValue} inputType="checkbox" handleChange={handleChange} />;
};

export const StringTypeFormStep = ({ setValue, ...props }) => {
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return <BaseTypeFormStep {...props} setValue={setValue} inputType="text" handleChange={handleChange} />;
};

export const IntegerTypeFormStep = ({ setValue, value, schemaFragment, readOnly, ...props }) => {
  if (value === "") {
    setValue(0);
  }
  const handleChange = (e) => {
    let value = null;
    if (e.target.value !== "") {
      value = parseInt(e.target.value);
      if (schemaFragment.maximum !== undefined && value > schemaFragment.maximum) return;
      if (schemaFragment.minimum !== undefined && value < schemaFragment.minimum) return;
      if (schemaFragment.exclusiveMaximum !== undefined && value >= schemaFragment.exclusiveMaximum) return;
      if (schemaFragment.exclusiveMinimum !== undefined && value <= schemaFragment.exclusiveMinimum) return;
    }
    setValue(value);
  };

  return (
    <BaseTypeFormStep
      {...props}
      setValue={setValue}
      value={value}
      readOnly={readOnly}
      schemaFragment={schemaFragment}
      inputType="number"
      handleChange={handleChange}
    >
      {schemaFragment.minimum !== undefined && schemaFragment.maximum !== undefined && (
        <input
          type="range"
          value={value}
          onChange={handleChange}
          min={schemaFragment.minimum}
          max={schemaFragment.maximum}
          step="1"
          disabled={readOnly}
        />
      )}
    </BaseTypeFormStep>
  );
};

export const FloatTypeFormStep = ({ setValue, value, schemaFragment, readOnly, ...props }) => {
  const handleChange = (e) => {
    let value = null;
    if (e.target.value !== "") {
      value = parseFloat(e.target.value);
      if (schemaFragment.maximum !== undefined && value > schemaFragment.maximum) return;
      if (schemaFragment.minimum !== undefined && value < schemaFragment.minimum) return;
      if (schemaFragment.exclusiveMaximum !== undefined && value >= schemaFragment.exclusiveMaximum) return;
      if (schemaFragment.exclusiveMinimum !== undefined && value <= schemaFragment.exclusiveMinimum) return;
    }
    setValue(value);
  };

  return (
    <BaseTypeFormStep
      {...props}
      setValue={setValue}
      value={value}
      readOnly={readOnly}
      schemaFragment={schemaFragment}
      inputType="number"
      handleChange={handleChange}
    >
      {schemaFragment.minimum !== undefined && schemaFragment.maximum !== undefined && (
        <input
          type="range"
          value={value}
          onChange={handleChange}
          min={schemaFragment.minimum}
          max={schemaFragment.maximum}
          step={(schemaFragment.maximum - schemaFragment.minimum) / 100}
          disabled={readOnly}
        />
      )}
    </BaseTypeFormStep>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment?.type === "file";
}, FileTypeFormStep);

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment?.type === "boolean";
}, BooleanTypeFormStep);

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment?.type === "string";
}, StringTypeFormStep);

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment?.type === "integer";
}, IntegerTypeFormStep);

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment?.type === "number";
}, FloatTypeFormStep);
