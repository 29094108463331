import React, { useContext } from "react";
import MiscDataHeader from "./MiscDataHeader";
import { useAllAuxiliaryData } from "../../../hooks/api/useAuxiliaryData";
import { NodeDataContext } from "../../../contexts";
import { useEditDialog } from "../../../hooks/useEditDialog";
import RenderMiscData from "./RenderMiscData";
import { ArrowUpTrayIcon } from "../../../assets/icons";
import EmptyListPrompt from "../../common/EmptyListPropmt";
import EditDialog from "../EditDialog";

const MiscData = () => {
  const { activeNode } = useContext(NodeDataContext);
  const { data: allAuxData, isLoading, error } = useAllAuxiliaryData(activeNode[0]);
  const { isOpen, handleClosePopup, miscDataCreateClick } = useEditDialog();

  const groupedData = allAuxData?.reduce((acc, item) => {
    const title = item.datatype.title;

    // Initialize the array if it doesn't exist
    if (!acc[title]) {
      acc[title] = [];
    }

    // Push the item to the corresponding array
    acc[title].push(item);

    return acc;
  }, {});

  if (isLoading) {
    return <div>Fetching misc data</div>;
  }

  if (error) {
    return <div>There was an issue with fetching the misc data</div>;
  }

  return (
    <div className="beryl_misc-data">
      <MiscDataHeader miscDataCreateClick={miscDataCreateClick} />
      {allAuxData.length === 0 ? (
        <EmptyListPrompt icon={<ArrowUpTrayIcon className="w-4 h-4" />} text={"to upload data"} />
      ) : (
        <RenderMiscData data={groupedData} />
      )}
      <EditDialog isOpen={isOpen} closePopup={handleClosePopup} />
    </div>
  );
};

export default MiscData;
