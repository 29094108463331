import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { experimental_createPersister } from "@tanstack/query-persist-client-core";
import { get, set, del, createStore } from "idb-keyval";

function newIdbStorage(idbStore) {
  return {
    getItem: async (key) => {
      //const startTime = new Date();
      const res = await get(key, idbStore);
      //const endTime = new Date();
      //console.log("GET CACHE", key, endTime - startTime);
      return res;
    },
    setItem: async (key, value) => {
      //console.log("SET CACHE", key, value);
      return await set(key, value, idbStore);
    },
    removeItem: async (key) => await del(key, idbStore),
  };
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: Infinity, //1000 * 60 * 60, // 1 hour
      staleTime: Infinity,
      persister: experimental_createPersister({
        serialize: (value) => value,
        deserialize: (value) => value,
        storage: newIdbStorage(createStore("db_name", "store_name")),
        maxAge: 1000 * 60 * 60 * 24 * 7, // 7 days
      }),
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);
