const Heading = ({ size = "h1", children }) => {
  const Component = size;

  let style =
    "font-medium text-zinc-800 mb-2 flex items-center gap-2 break-words break-all";

  switch (size) {
    case "h1":
      style += " text-5xl";
      break;
    case "h2":
      style += " text-3xl";
      break;
    case "h3":
      style += " text-2xl";
      break;
    case "h4":
      style += " text-lg";
      break;
    case "h5":
      style += " text-md";
      break;
    case "h6":
      style += " text-base";
      break;
    default:
      break;
  }

  return <Component className={style}>{children}</Component>;
};

export default Heading;
