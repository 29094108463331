import React from "react";
import { iconColor } from "./color";

const PanSelectIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5083 0.21556L6.19526 3.5286C5.93491 3.78894 5.93491 4.21106 6.19526 4.4714C6.45561 4.73175 6.87772 4.73175 7.13807 4.4714L9.33257 2.2769L9.33289 9.33333H2.27614L4.4714 7.13807C4.73175 6.87772 4.73175 6.45561 4.4714 6.19526C4.21106 5.93491 3.78894 5.93491 3.5286 6.19526L0.195262 9.5286C-0.0650874 9.78895 -0.0650874 10.2111 0.195262 10.4714L3.5286 13.8047C3.78894 14.0651 4.21106 14.0651 4.4714 13.8047C4.73175 13.5444 4.73175 13.1223 4.4714 12.8619L2.27614 10.6667H9.33295L9.33326 17.7238L7.13807 15.5286C6.87772 15.2682 6.45561 15.2682 6.19526 15.5286C5.93491 15.7889 5.93491 16.2111 6.19526 16.4714L9.5286 19.8047C9.78895 20.0651 10.2111 20.0651 10.4714 19.8047L13.8047 16.4714C14.0651 16.2111 14.0651 15.7889 13.8047 15.5286C13.5444 15.2682 13.1223 15.2682 12.8619 15.5286L10.6666 17.7239L10.6663 10.6667H17.7239L15.5286 12.8619C15.2682 13.1223 15.2682 13.5444 15.5286 13.8047C15.7889 14.0651 16.2111 14.0651 16.4714 13.8047L19.8047 10.4714C20.0651 10.2111 20.0651 9.78895 19.8047 9.5286L16.4714 6.19526C16.2111 5.93491 15.7889 5.93491 15.5286 6.19526C15.2682 6.45561 15.2682 6.87772 15.5286 7.13807L17.7239 9.33333H10.6662L10.6659 2.27538L12.8619 4.4714C13.1223 4.73175 13.5444 4.73175 13.8047 4.4714C14.0651 4.21106 14.0651 3.78894 13.8047 3.5286L10.4714 0.195262C10.3411 0.06498 10.1703 -0.000107354 9.99958 1.32911e-07C9.99943 5.34379e-08 9.99928 1.37015e-08 9.99914 1.37015e-08C9.80494 8.67624e-06 9.63014 0.0830514 9.5083 0.21556Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default PanSelectIcon;
