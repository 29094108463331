import React from "react";
import { iconColor } from "./color";

const SavenUpdateIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 16V5C1 2.79086 2.79086 1 5 1H14.0388C14.6463 1 15.2209 1.27618 15.6005 1.75061L18.5617 5.45217C18.8454 5.8068 19 6.24742 19 6.70156V16C19 18.2091 17.2091 20 15 20H5C2.79086 20 1 18.2091 1 16Z"
        stroke={iconColor}
        strokeWidth="1.5"
      />
      <path
        d="M5 1V4.4C5 4.73137 5.26863 5 5.6 5H11.4C11.7314 5 12 4.73137 12 4.4V1"
        stroke={iconColor}
        strokeWidth="1.5"
      />
      <path
        d="M6 7.99999V10.5H6.291M6.291 10.5C6.62292 9.67906 7.21784 8.99145 7.98251 8.54492C8.74719 8.0984 9.6384 7.9182 10.5165 8.03256C11.3946 8.14693 12.2099 8.54939 12.8347 9.17688C13.4595 9.80437 13.8584 10.6214 13.969 11.5M6.291 10.5H8.5M14 16V13.5H13.7095M13.7095 13.5C13.3771 14.3204 12.782 15.0075 12.0174 15.4536C11.2528 15.8998 10.3619 16.0798 9.48405 15.9654C8.60623 15.8511 7.7911 15.4489 7.16627 14.8219C6.54144 14.1948 6.14218 13.3782 6.031 12.5M13.7095 13.5H11.5"
        stroke={iconColor}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SavenUpdateIcon;
