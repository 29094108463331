import React from "react";
import { FormLabel } from "./FormLabel";

export const FileInput = React.forwardRef(({ error, onChange }, ref) => {
  return (
    <div>
      <FormLabel label={"Choose file"} htmlFor={"example1"} />
      <input
        ref={ref}
        name="file"
        id="example1"
        type="file"
        onChange={onChange}
        className="block mt-2 w-full text-sm file:mr-4 file:rounded-md file:border file:border-zinc-500 file:bg-white file:py-1 file:px-2 file:text-zinc-600 focus:outline-none disabled:pointer-events-none disabled:opacity-60"
      />
      <div className="text-sm text-zinc-500 mt-1">
        {error && <p className="text-red-500">{error.message}</p>}
      </div>
    </div>
  );
});
