import { useEffect, useMemo } from "react";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON.js";
import Collection from "ol/Collection.js";
import { useAuxiliaryData } from "../api/useAuxiliaryData";
import TileLayer from "ol/layer/WebGLTile.js";
import XYZ from "ol/source/XYZ";

export const useOverlayLayersEffect = (map, mapLayout, projectId) => {
  const shapefiles = useAuxiliaryData(
    "shapefile",
    projectId,
    "application/zipped-shapefile"
  );

  const shapefileDict = useMemo(
    () =>
      shapefiles.data
        ? Object.fromEntries(
            shapefiles.data.map((shapefile) => [shapefile.id, shapefile])
          )
        : {},
    [shapefiles.data]
  );

  const rasters = useAuxiliaryData("raster", projectId, "image/geotiff");

  const rasterDict = useMemo(
    () =>
      rasters.data
        ? Object.fromEntries(rasters.data.map((raster) => [raster.id, raster]))
        : {},
    [rasters.data]
  );

  const layoutLayers = mapLayout.layers;
  const layoutRasters = mapLayout.rasters;
  const layoutRasterConfigs = mapLayout.rasters_configs;

  useEffect(() => {
    const shapefileUrls = layoutLayers
      .map((id) => shapefileDict[id]?.src)
      .filter((url) => url);

    const rasterList = layoutRasters.flatMap((id) => {
      let url;
      if (typeof id === "string") {
        const [rasterId, name] = id.split("(magic)");
        url = rasterDict[rasterId].url?.styles[name];
      } else {
        url = rasterDict[id].url.base;
      }
      return {
        url: url,
        id: id,
      };
    });

    map.current.overlayLayers.setLayers(
      new Collection(
        rasterList
          .map((raster) => {
            const layer = new TileLayer({
              source: new XYZ({
                url: raster.url,
              }),
            });
            layer.set("url", raster.url);
            layer.set("layerId", raster.id);
            layer.setOpacity(1);
            return layer;
          })
          .concat(
            shapefileUrls.map((url) => {
              return new VectorLayer({
                source: new VectorSource({
                  url: url + "?format=geojson",
                  format: new GeoJSON(),
                }),
              });
            })
          )
      )
    );
  }, [map, layoutLayers, layoutRasters, shapefileDict, rasterDict]);

  useEffect(() => {
    const allLayers = map.current.overlayLayers.getLayers();
    allLayers.forEach((layer) => {
      const layerId = layer.get("layerId");
      console.log("set opacity", layoutRasterConfigs[layerId]?.opacity);
      layer.setOpacity(
        layoutRasterConfigs[layerId]?.opacity !== undefined
          ? layoutRasterConfigs[layerId]?.opacity
          : 1
      );
    });
  }, [map, layoutRasterConfigs]);
};
