import React from "react";

const LoadingScreen = () => {
  return (
    <div className="bg-blur-xl flex items-center justify-center h-full w-full">
      <div className="flex items-center gap-2 w-fit">
        <div className="w-3 h-5 gradient-background-faded rounded-sm animate-pulse-1"></div>
        <div className="w-6 h-5 gradient-background-faded rounded-sm animate-pulse-2"></div>
        <div className="w-3 h-5 gradient-background-faded rounded-sm animate-pulse-3"></div>
        <div className="w-3 h-5 gradient-background-faded rounded-sm animate-pulse-4"></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
