import moment from "moment";

export const formatVersionTimestamp = (timestamp) => {
  const now = moment();
  const tsMoment = moment(timestamp);
  const startOfToday = moment().startOf("day");

  if (tsMoment.isSame(now, "day")) {
    // If the timestamp is from today
    let minutesAgo = now.diff(tsMoment, "minutes");
    if (minutesAgo < 60) {
      return `${minutesAgo} minutes ago`;
    } else {
      let hours = Math.floor(minutesAgo / 60);
      let minutes = minutesAgo % 60;
      return `${hours} hours ${minutes} minutes ago`;
    }
  } else if (tsMoment.isAfter(startOfToday.subtract(1, "days"))) {
    // For yesterday
    return `Yesterday at ${tsMoment.format("H:mm")}`;
  } else {
    // For dates older than yesterday
    return tsMoment.format("MMM D, YYYY H:mm");
  }
};
