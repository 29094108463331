import React from "react";

const ProcessingIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2L1.85858 1.14142C1.93668 1.06332 2.06332 1.06332 2.14142 1.14142L3 2"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M2 1V17C2 18.1046 2.89543 19 4 19H20"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M6 6L10.7471 6.85205C11.2929 6.95 11.8511 6.9565 12.399 6.87127L18 6"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M6 13.6L10.7471 14.452C11.2929 14.55 11.8511 14.5565 12.399 14.4712L18 13.6"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M6 9.79999L10.7471 10.652C11.2929 10.75 11.8511 10.7565 12.399 10.6713L18 9.79999"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeDasharray="2.5"
      />
      <path
        d="M19 18L19.8586 18.8586C19.9367 18.9367 19.9367 19.0633 19.8586 19.1414L19 20"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ProcessingIcon;
