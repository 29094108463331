import { SchemaComponent, registerSchemaComponent } from "./fieldRegistration";

export const SingleObjectStep = ({
  value,
  schemaFragment,
  setValue,
  propKey,
  ...props
}) => {
  const propName = Object.keys(schemaFragment.properties)[0];

  const setSubValue = (subValue) => {
    const newValue = { ...value };
    newValue[propName] = subValue;
    setValue(newValue);
  };

  const subSchema = schemaFragment.properties[propName];
  const subValue = value ? value[propName] : undefined;

  return (
    <SchemaComponent
      {...props}
      schemaFragment={subSchema}
      value={subValue}
      setValue={setSubValue}
      propKey={propName}
    />
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return (
    (schemaFragment?.type === "object" || schemaFragment?.type === undefined) &&
    schemaFragment?.properties &&
    Object.keys(schemaFragment?.properties).length === 1
  );
}, SingleObjectStep);
