import { MessagesContext } from "../../contexts";
import { Fragment, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "../../assets/icons";

const styles = {
  success: "border-l-4 border-green-500 bg-green-50",
  info: "border-l-4 border-blue-600 bg-blue-50",
  warning: "text-yellow-700 bg-yellow-100 border-l-4 border-yellow-700",
  error: "border-l-4 border-red-600 bg-red-50",
  notice: "border-l-4 border-orange-400 bg-orange-50",
};

const icons = {
  error: <ExclamationTriangleIcon className="w-6 h-6 text-red-600" />,
  notice: <ExclamationTriangleIcon className="w-6 h-6 text-orange-400" />,
  success: <CheckIcon className="w-6 h-6 text-green-500" />,
};

const getIcon = (styleKey) => icons[styleKey] || null;

export const MessageDialog = ({ msg, setOpen }) => {
  const { removeMessage } = useContext(MessagesContext);
  return (
    <Transition.Root show={!!msg} as={Fragment}>
      <Dialog as="div" className="message__backdrop relative" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="message__panel fixed inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 mt-10 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="m-6 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full">
                <div className="bg-white px-3 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-zinc-900"
                      >
                        {msg.title}
                      </Dialog.Title>
                      <div className="mt-2">{msg.message}</div>
                    </div>
                  </div>
                </div>
                <div className="bg-zinc-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      removeMessage(msg);
                      setOpen(null);
                    }}
                  >
                    Close & hide message
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const Messages = () => {
  const { messages, removeMessage } = useContext(MessagesContext);
  const [open, setOpen] = useState(null);

  if (messages.length === 0) {
    return null;
  }

  return (
    <div className="absolute right-4 bottom-7 flex flex-col gap-1.5">
      {(() => {
        return messages.map((msg, idx) => {
          const style = styles[msg.type];
          const icon = getIcon(msg.type);
          return (
            <div
              key={idx}
              className={`messages flex gap-2 justify-between bg-white 
                 rounded-md min-w-[320px] max-w-[450px] shadow shadow-md
                ${style} border`}
              role="alert"
              style={{ zIndex: 2000 }}
            >
              <div className="p-3 py-3 flex gap-3">
                {icon && <div className="mt-1"> {icon}</div>}
                <button
                  type="button"
                  onClick={() => setOpen(msg)}
                  className={`text-left flex flex-col gap-1 justify-center`}
                >
                  <h5 className="font-medium text-sm">{msg.title}</h5>
                  {msg.shortMessage && (
                    <p className="text-zinc-600 font-light text-xs">
                      {msg.shortMessage}
                    </p>
                  )}
                </button>
              </div>

              <button
                onClick={() => removeMessage(msg)}
                className="px-1.5 border-l border-zinc-200"
              >
                <XMarkIcon className="h-5 w-5 rounded text-zinc-500 hover:text-zinc-800" />
              </button>
            </div>
          );
        });
      })()}

      {!!open ? <MessageDialog msg={open} setOpen={setOpen} /> : null}
    </div>
  );
};

export default Messages;
