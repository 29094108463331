import React from "react";

const LayerIcon = (props) => {
  const outline = true;

  if (outline) {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 11.5L1.55003 12.7347C0.816657 13.068 0.816657 13.6138 1.55003 13.9472L7.71702 16.7597C8.4208 17.0801 9.5792 17.0801 10.283 16.7597L16.45 13.9472C17.1833 13.6138 17.1833 13.068 16.45 12.7347L13.1229 11.4433M16.4466 4.06877L10.223 1.23125C9.54836 0.922916 8.44414 0.922916 7.76952 1.23125L1.55003 4.06877C0.816657 4.40211 0.816657 4.94753 1.55003 5.28086L7.71702 8.09338C8.4208 8.41421 9.5792 8.41421 10.283 8.09338L16.45 5.28086C17.18 4.94753 17.18 4.40169 16.4466 4.06877Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.9993 7L1.5499 8.59799C0.816699 8.94202 0.816699 9.50536 1.5499 9.84938L7.71549 12.7517C8.41911 13.0828 9.57724 13.0828 10.2809 12.7517L16.4465 9.84938C17.1838 9.50536 17.1838 8.94202 16.4506 8.59799L12.9979 7"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <>
        <svg width="19" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 9.39481C10.2876 9.39481 9.57529 9.28686 9.02 9.07096L1.19308 6.02261C0.835048 5.8835 0 5.47654 0 4.70824C0 3.93995 0.835048 3.53389 1.19413 3.39297L9.08875 0.31843C10.1766 -0.106143 11.8181 -0.106143 12.9065 0.31843L20.8059 3.39297C21.165 3.53254 22 3.9395 22 4.70824C22 5.47699 21.165 5.8826 20.8059 6.02307L12.9789 9.07096C12.4247 9.28686 11.7124 9.39481 11 9.39481Z"
            fill={props.color || "#404040"}
          />
          <path
            d="M20.8027 8.07639L20.0047 7.7688L17.9543 8.57007L12.9832 10.5123C12.4279 10.7291 11.7139 10.837 11.0032 10.837C10.2924 10.837 9.57899 10.7291 9.02423 10.5123L4.04938 8.57007L1.99851 7.7688L1.19361 8.07774C0.835048 8.21731 0 8.62698 0 9.39482C0 10.1627 0.835048 10.5728 1.19308 10.7124L9.02 13.767C9.57212 13.9838 10.2855 14.0922 11 14.0922C11.7145 14.0922 12.4247 13.9838 12.98 13.7675L20.8 10.7137C21.1602 10.5741 22 10.1667 22 9.39482C22 8.62291 21.166 8.21731 20.8027 8.07639Z"
            fill={props.color || "#404040"}
          />
          <path
            d="M20.8027 12.7733L20.0047 12.4662L17.9543 13.267L12.9832 15.2069C12.4279 15.4228 11.7139 15.5312 11.0032 15.5312C10.2924 15.5312 9.57899 15.4233 9.02423 15.2069L4.04938 13.2647L1.99851 12.4662L1.19361 12.7751C0.835048 12.9147 0 13.3244 0 14.0922C0 14.8601 0.835048 15.2697 1.19308 15.4088L9.02 18.4617C9.57212 18.6776 10.2882 18.7896 11 18.7896C11.7118 18.7896 12.4215 18.6776 12.9768 18.4612L20.7995 15.4093C21.1602 15.2702 22 14.8628 22 14.0922C22 13.3217 21.166 12.9147 20.8027 12.7733Z"
            fill={props.color || "#404040"}
          />
        </svg>
      </>
    );
  }
};

export default LayerIcon;
