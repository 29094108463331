import React, { useContext, useState } from "react";
import { ArrowUpTrayIcon, DatasetIcon } from "../../../assets/icons";
import { handleToggleClick } from "./handlers";
import ProcessingList from "./ProcessingList";
import { fetchDatasets } from "../../../hooks/api/useDatasets";
import useInvalidateQueryOnStatus from "../../../hooks/useInvalidateQueryOnStatus";
import { LoadingPlaceholder } from "../LoadingPlaceholder";
import { NavItem, NavList } from ".";
import { darkenColor, hexToRgba } from "../../../utils";
import { useActiveProject } from "../../../hooks/useActiveProject";
import { MessagesContext, NodeDataContext } from "../../../contexts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { error2message } from "../../../hooks/api/useSafeQuery";
import EmptyListPrompt from "../EmptyListPropmt";

const DatasetList = ({ groupId, groupColor, activePath, onNodeClick, ActionButtons, openEditDialog }) => {
  const [openNodes, setOpenNodes] = useState([]);
  const [hoveredNode, setHoveredNode] = useState(null);
  const { projectId } = useActiveProject();
  const { activeNode, setActiveNode } = useContext(NodeDataContext);
  const { addMessage } = useContext(MessagesContext);
  const queryClient = useQueryClient();

  const {
    data: datasets,
    isLoading: isLoadingDatasets,
    isError,
  } = useQuery({
    queryKey: ["datasets", projectId, groupId],
    queryFn: () => fetchDatasets(projectId, groupId),
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: Infinity, // 10 minutes
    refetchOnWindowFocus: false,
    retry: false, // Disable retries on failure
    onError: (error) => {
      console.error("Error fetching datasets:", error);
      addMessage(error2message(error));
      queryClient.removeQueries(["datasets", projectId, groupId]); // Remove the failed query from the cache
    },
  });

  const sortedArray = datasets?.sort((a, b) => b.id - a.id);

  useInvalidateQueryOnStatus("datasets", "dataset");

  const handleNodeClick = async (id) => {
    onNodeClick([projectId, groupId, id]);
  };

  const handleActionButtonClick = async (id) => {
    onNodeClick([projectId, groupId, id], "create");
  };

  const handleOneClickProcInvClick = (id) => {
    setActiveNode([projectId, groupId, id]);
  };

  if (isLoadingDatasets || !datasets)
    return (
      <div className="p-2">
        <LoadingPlaceholder height="h-3" rows={1} />
      </div>
    );

  if (isError) {
    return <div className="p-2 text-zinc-600">Datasets unavailable</div>;
  }

  return (
    <NavList>
      {sortedArray.length === 0 && (
        <EmptyListPrompt
          icon={<ArrowUpTrayIcon className="w-4 h-4 text-zinc-600 hover:text-zinc-800" />}
          text={"to import data"}
        />
      )}
      {sortedArray?.map((dataset) => (
        <NavItem
          key={dataset.id}
          activePathNumber={2}
          onMouseEnter={() => setHoveredNode(dataset.id)} // set the hovered node ID
          onMouseLeave={() => setHoveredNode(null)} // clear it when the mouse leaves
          nodeItem={dataset}
          activePath={activePath}
          handleToggleClick={() => handleToggleClick(dataset.id, setOpenNodes)}
          handleNodeClick={() => {
            handleNodeClick(dataset.id);
            if (openNodes && !openNodes.includes(dataset.id)) {
              handleToggleClick(dataset.id, setOpenNodes);
            }
          }}
          handleActionButtonClick={() => handleActionButtonClick(dataset.id)}
          hoveredNode={hoveredNode}
          openNodes={openNodes}
          groupColor={groupColor}
          openEditDialog={openEditDialog}
          DoneStatusIcon={DatasetIcon}
          iconColorProp={darkenColor(hexToRgba(groupColor), 0)}
          ActionButtons={ActionButtons}
          handleOneClickProcInvClick={() => handleOneClickProcInvClick(dataset.id)}
          activeNode={activeNode}
        >
          {openNodes.includes(dataset.id) && (
            <ProcessingList
              projectId={projectId}
              groupId={groupId}
              groupColor={groupColor}
              datasetId={dataset.id}
              activePath={activePath}
              onNodeClick={onNodeClick}
              ActionButtons={ActionButtons}
              openEditDialog={openEditDialog}
            />
          )}
        </NavItem>
      ))}
    </NavList>
  );
};

export default DatasetList;
