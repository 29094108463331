export const Elevation = {
  title: function (context, args) {
    return;
  },
  fn: function (context, args) {
    let { selectedLineId, nodeBinaryByLine } = context;
    const binary = nodeBinaryByLine?.[args.dataset]?.[selectedLineId];
    if (!binary?.flightlines?.Alt) return [];
    const xdist = binary.flightlines.xdist;
    const topo = binary.flightlines.Topography;
    const elevation = binary.flightlines.Alt.map((y, idx) => topo[idx] + y);

    return [
      {
        name: "Elevation",
        x: xdist,
        y: elevation,
        legendgroup: "elevation",
        showlegend: false,
        line: {
          color: "rgb(127 127 255)",
          width: 1,
        },
        hoverinfo: context.showHover ? "all" : "none"
      },
    ];
  },
  xaxis: "xdist",
  yaxis: "elevation",
  schema: (context) => {
    const datasets = Object.keys(context.nodeBinaryByLine).filter((dataset) => {
      if (!context.nodeBinaryByLine[dataset]) return false;
      return (
        Object.keys(Object.values(context.nodeBinaryByLine[dataset])?.[0]?.flightlines).filter(
          (col) => col.indexOf("Alt") === 0
        ).length > 0
      );
    });
    return {
      type: "object",
      properties: {
        dataset: {
          type: "string",
          enum: datasets,
        },
      },
      additionalProperties: false,
    };
  },
};
