import React from "react";
import MiscDataListItem from "./MiscDataListItem";

const MiscDataList = ({ data }) => {
  return (
    <ul className="beryl_misc-data-list">
      {data?.map((item) => {
        return <MiscDataListItem key={item.id} item={item} />;
      })}
    </ul>
  );
};

export default MiscDataList;
