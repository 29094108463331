import React from "react";
import { iconColor } from "./color";

const DeactivatePointsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 15L19 15"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M14 1L0.999878 16"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <circle cx="8" cy="8" r="3" fill={iconColor} />
    </svg>
  );
};

export default DeactivatePointsIcon;
