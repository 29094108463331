import GroupList from "./NavTree/GroupList";
import { useNodeDetailsQuery } from "../../hooks/api/useNodeData";
import SimpleSelect from "./SimpleSelect";

const NodeSelect = ({
  projectId,
  activePath,
  setActivePath,
  nothingTitle = "Select inversion",
  buttonStyles = "relative flex items-center gap-2 justify-between text-sm font-normal w-full bg-zinc-100 hover:bg-zinc-200/50 transition ease-in-out 2s py-0.5 px-2 rounded-md",
  iconPosition = "after",
  width = "w-fit",
  IconComponent,
  header = null,
  footer = null,
  children,
}) => {
  const nodeDataQuery = useNodeDetailsQuery(activePath);
  const selectedItem = nodeDataQuery.data
    ? { title: nodeDataQuery.data.title }
    : null;

  return (
    <SimpleSelect
      selected={selectedItem}
      setSelected={setActivePath}
      nothingTitle={nothingTitle}
      width={width}
      dropdownWidth="w-72"
      buttonStyles={buttonStyles}
      IconComponent={IconComponent}
      iconPosition={iconPosition}
      iconClasses="text-zinc-700"
      >
        <div className="pr-2 pl-4 py-1">
          {header}
          {children || (
            <GroupList
             projectId={projectId}
             activePath={activePath}
             onNodeClick={setActivePath}
            />
          )}
          {footer}
        </div>      
    </SimpleSelect>
  );
};

export default NodeSelect;
