import { useSafeQuery } from "./useSafeQuery";
import client from "../../api/client";
import { fetchBinary } from "../../api/fetchBinary";

// Fetch and cache manual edit metadata

export const fetchManualEdits = async (projectId, manualEditId) => {
  if (manualEditId) {
    const { data } = await client.get(
      `/api/projects/${projectId}/manual-edit/${manualEditId}`
    );
    return data;
  } else {
    const { data } = await client.get(
      `/api/projects/${projectId}/manual-edit/`
    );
    return data;
  }
};

export const useManualEdits = (projectId, manualEditId) => {
  return useSafeQuery(
    manualEditId
      ? ["ManualEdit", projectId, manualEditId]
      : ["ManualEdit", projectId],
    () => fetchManualEdits(projectId, manualEditId)
  );
};

export const fetchManualEditBinary = async (projectId, manualEditId) => {
  if (projectId == null) return;
  const manualEditQuery = await fetchManualEdits(projectId, manualEditId);
  const src = manualEditQuery.src;
  if (!src) return;
  const binary = await fetchBinary(src);
  return binary;
};

export const useManualEditBinary = (projectId, manualEditId) => {
  return useSafeQuery(["ManualEdit", projectId, manualEditId, "binary"], () =>
    fetchManualEditBinary(projectId, manualEditId)
  );
};
