import React from "react";
import SupportLink from "./SupportLink";

const SidebarFooter = () => {
  return (
    <div className="beryl_sidebar-footer">
      <SupportLink />
    </div>
  );
};

export default SidebarFooter;
