import React, { useContext, useState } from "react";
import { registerSchemaComponent } from "../fieldRegistration";
import { FormLabel } from "../../../common/forms/Form";
import FieldDescription from "../FieldDescription";
import { GateInput } from "./GateInput";
import { NodeDataContext } from "../../../../contexts";
import RangeSlider from "./RangeSlider";

export const GateRangeFormStep = ({
  value,
  propKey,
  schemaFragment,
  setValue,
}) => {
  const { nodeBinaryByLine } = useContext(NodeDataContext);
  const [internalValue, setInternalValue] = useState({});

  const onStartGateClick = (start_gate) => {
    const newInternalValue = { ...internalValue };
    newInternalValue.start_gate = parseInt(start_gate.title);
    setInternalValue(newInternalValue);
    setValue(newInternalValue);
  };

  let selectedChannel = value && value.channel ? value.channel : null;

  const revertChannel = (channel) => {
    return `Gate_Ch${channel?.toString().padStart(2, "0")}`;
  };
  const fullChannel = revertChannel(selectedChannel);

  const getChannelList = (nodeBinaryByLine, channel) => {
    if (!nodeBinaryByLine) return null;

    const data = Object.values(nodeBinaryByLine.measured)?.[0].layer_data[
      channel
    ];

    if (!data) return null;

    let length;
    if (data instanceof Float64Array) {
      length = data.length;
    } else if (Array.isArray(data)) {
      length = data.length;
    } else if (typeof data === "object") {
      length = Object.keys(data).length;
    } else {
      length = 0;
    }

    return Array.from({ length }, (_, i) => ({ title: i, id: i }));
  };

  const gates = getChannelList(nodeBinaryByLine, fullChannel);

  const selectedStartGate = gates?.find(
    (item) => item.title === value?.start_gate
  );

  const [range, setRange] = useState([0, 27]);

  const handleRangeChange = (start, end) => {
    setRange([start, end]);
  };
  console.log(range);

  return (
    <div>
      <div className="flex items-center mb-3 mt-2">
        <h4 className="font-medium text-sm ">Culling window</h4>
        <FieldDescription schemaFragment={schemaFragment} />
      </div>

      <div className="block grid grid-cols-6 gap-2 items-center mt-1">
        <div className="col-span-2 flex items-center">
          <FormLabel
            label={"Channel 1"}
            htmlFor={"ch"}
            fontWeight="font-normal"
          />
        </div>
        <div className="col-span-4 flex items-center">
          {" "}
          <RangeSlider max={27} onRangeChange={handleRangeChange} />
        </div>
      </div>

      <div className="block grid grid-cols-6 gap-2 items-center mt-1">
        <div className="col-span-2 flex items-center">
          <FormLabel
            label={"Channel 2"}
            htmlFor={"ch"}
            fontWeight="font-normal"
          />
        </div>
        <div className="col-span-4 flex items-center">
          {" "}
          <RangeSlider max={27} onRangeChange={handleRangeChange} />
        </div>
      </div>
      <div className="hidden">
        <GateInput
          gates={gates}
          selectedGate={selectedStartGate}
          onSelectGate={onStartGateClick}
          schemaFragment={schemaFragment.properties.start_gate}
          label={"Start gate"}
        />
      </div>
    </div>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment && schemaFragment["x-reference"] === "gate-range";
}, GateRangeFormStep);
