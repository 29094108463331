import React, { useState } from "react";
import { EllipsisVerticalIcon } from "../../assets/icons";
import useUpdateProject from "../../hooks/useUpdateProject";

const ProjectCard = ({ title, projectId, onClick }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);

  const { handleFormSubmit } = useUpdateProject(() => setEditMode(false), projectId);

  const handleEditClick = (event) => {
    event.stopPropagation(); // Prevent onClick from firing on the card
    setEditMode(true);
  };

  const handleTitleChange = (event) => {
    setEditedTitle(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormSubmit({ title: editedTitle });
    setEditMode(false); // Exit edit mode
  };

  const handleFormClick = (event) => {
    event.stopPropagation();
  };

  const projectTitle = editMode ? (
    <form onSubmit={handleSubmit} onClick={handleFormClick}>
      <input
        type="text"
        value={editedTitle}
        onChange={handleTitleChange}
        autoFocus
        className="block w-full rounded-md placeholder:text-zinc-500 
        focus:ring-0 focus:ring-inset sm:leading-6 focus:outline-none 
        focus:border-zinc-600 text-sm bg-transparent py-1 px-2 
        border border border-zinc-300 rounded-md hover:border-zinc-600"
      />
      <button type="submit" className="hidden"></button>
    </form>
  ) : (
    <div title={title} className="beryl_project-card--title">
      {title}
    </div>
  );

  return (
    <div onClick={onClick} className={"beryl_project-card"}>
      <div title={title} className="beryl_project-card--square">
        {title?.slice(0, 2)}
      </div>

      {projectTitle}

      <div className="beryl_project-card--menubutton">
        <button onClick={(event) => handleEditClick(event)}>
          <EllipsisVerticalIcon className="h-6" />
        </button>
      </div>
    </div>
  );
};

export default ProjectCard;
