import { useSafeQuery } from "./useSafeQuery";
import { fetchBinary } from "../../api/fetchBinary";
import client from "../../api/client";

// Fetch and cache the inversions data for a specific processing

export const fetchInversions = async (projectId, groupId, datasetId, processingId) => {
  const { data } = await client.get(
    `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${processingId}/inversions`
  );
  return data;
};

export const fetchInversionDetails = async (projectId, groupId, datasetId, processingId, inversionId) => {
  const invId = inversionId.length ? `${inversionId[0]}/${inversionId[1]}` : inversionId;
  const { data } = await client.get(
    `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${processingId}/inversions/${invId}`
  );
  return data;
};

export const fetchInversionProgress = async (projectId, groupId, datasetId, processingId, inversionId) => {
  const invId = inversionId.length ? `${inversionId[0]}/${inversionId[1]}` : inversionId;
  const { data } = await client.get(
    `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${processingId}/inversions/${invId}/progress`
  );
  return data;
};

export const fetchInversionBinary = async (
  projectId,
  groupId,
  datasetId,
  processingId,
  inversionId,
  nodeData,
  selectedLineId
) => {
  if (!nodeData) return null;
  const invId = inversionId.length ? `${inversionId[0]}/${inversionId[1]}` : inversionId;
  let outputs = await Promise.all(
    nodeData.outputs.map(async (output) => {
      console.log("OUTPUT", output);
      return [
        output.name,
        await fetchBinary(
          `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${processingId}/inversions/${invId}/${output.name}/${selectedLineId}/binary`
        ),
      ];
    })
  );
  return Object.fromEntries(outputs);
};

export function useInversions(projectId, groupId, datasetId, processingId) {
  return useSafeQuery(["inversions", groupId, datasetId, processingId], () =>
    fetchInversions(projectId, groupId, datasetId, processingId)
  );
}
