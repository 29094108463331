import React, { useState } from "react";

const Tooltip = ({
  children,
  tooltipText,
  placement = "bottom",
  tooltipSpacing = "2",
  size = "sm",
}) => {
  const [isHovered, setIsHovered] = useState(false);

  let tooltipPosition = "";
  let tooltipSizeClasses = "";

  switch (placement) {
    case "top":
      tooltipPosition = `bottom-full mb-${tooltipSpacing} left-1/2 transform -translate-x-1/2`;

      break;
    case "bottom":
      tooltipPosition = `top-full mt-${tooltipSpacing} left-1/2 transform -translate-x-1/2`;

      break;
    case "left":
      tooltipPosition = `right-full mr-${tooltipSpacing}`;

      break;
    case "right":
      tooltipPosition = `left-full ml-${tooltipSpacing}`;

      break;
    default:
      break;
  }

  switch (size) {
    case "xs":
      tooltipSizeClasses = "px-1.5 py-1 text-xs"; // Smaller padding and font size
      break;
    case "sm":
      tooltipSizeClasses = "px-2 py-1.5 text-sm"; // Default to small if undefined
      break;
    default:
      tooltipSizeClasses = "px-2 py-1.5 text-sm"; // Default to small if undefined
      break;
  }

  return (
    <div
      className="tooltip relative flex gap-1 items-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {isHovered && (
        <div
          className={`tooltip-box ${tooltipPosition} 
            absolute z-10 flex items-center justify-center 
            bg-zinc-800 text-zinc-200 rounded-md ${tooltipSizeClasses} 
            font-normal whitespace-nowrap`}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
