import { useState, useEffect, useCallback, useMemo } from "react";
import { useQuery, useQueries, useQueryClient } from "@tanstack/react-query";
import { fetchDatasetBinary } from "./api/useDatasets";
import { fetchProcessingBinary } from "./api/useProcessings";
import { fetchInversionBinary } from "./api/useInversions";

export const useFetchFlightlinesData = ({ activeNode, nodeData, flightlines, nodeType }) => {
  const [projectId, groupId, datasetId, processingId, inversionId] = activeNode;

  const [loadingProgress, setLoadingProgress] = useState({
    fetched: 0,
    total: flightlines.length,
    progress: 0,
    remains: [...flightlines],
  });

  const queryClient = useQueryClient();

  const isDone = activeNode?.length > 2 && nodeData?.status === "done";
  const initialFlightlineId = flightlines[0] ?? null;

  const getQueryKey = useCallback(
    (flightlineId) => ["nodeBinary", ...activeNode, nodeData?.id, flightlineId, isDone],
    [activeNode, nodeData?.id, isDone]
  );

  const fetchFlightlineData = useCallback(
    async (flightlineId) => {
      const queryKey = getQueryKey(flightlineId);

      console.log(`Starting fetch for flightline ${flightlineId}`);

      let data = null;
      try {
        if (nodeType === "dataset") {
          data = await fetchDatasetBinary(projectId, groupId, datasetId, flightlineId, (progress) => {
            queryClient.setQueryData(queryKey, (old) => ({
              ...old,
              progress: progress,
            }));
          });
        } else if (nodeType === "processing") {
          data = await fetchProcessingBinary(
            queryClient,
            projectId,
            groupId,
            datasetId,
            processingId,
            nodeData,
            flightlineId,
            (progress) => {
              queryClient.setQueryData(queryKey, (old) => ({
                ...old,
                progress: progress,
              }));
            }
          );
        } else if (nodeType === "inversion") {
          data = await fetchInversionBinary(
            projectId,
            groupId,
            datasetId,
            processingId,
            inversionId,
            nodeData,
            flightlineId,
            (progress) => {
              queryClient.setQueryData(queryKey, (old) => ({
                ...old,
                progress: progress,
              }));
            }
          );
        }

        if (!data) {
          throw new Error(`No data fetched for flightline ${flightlineId}`);
        }

        console.log(`Data fetched for flightline ${flightlineId}`);
        // Explicitly update cache with valid data
        queryClient.setQueryData(queryKey, data, {
          structuralSharing: false, // Disable structural sharing
        });

        return data;
      } catch (error) {
        console.error(`Failed to fetch data for flightline ${flightlineId}`, error);
        throw error; // Ensure the error propagates so the query doesn't cache invalid data
      }
    },
    [projectId, groupId, datasetId, processingId, inversionId, nodeType, queryClient, getQueryKey, nodeData]
  );

  const initialFlightlineQuery = useQuery({
    queryKey: getQueryKey(initialFlightlineId),
    queryFn: () => fetchFlightlineData(initialFlightlineId),
    enabled: !!initialFlightlineId && isDone,
    staleTime: Infinity,
    gcTime: 8 * 60 * 60 * 1000, // 8 hours
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    structuralSharing: false, // Flightline data contains typed arrays, and is cached forever anyway :)
    onError: () => {
      queryClient.invalidateQueries(getQueryKey(initialFlightlineId)); // prevent cache to hold on to failed states
    },
  });

  const { isSuccess: isInitialDataFetched } = initialFlightlineQuery;

  const flightlineQueries = useQueries({
    queries: flightlines.slice(1).map((flightlineId) => ({
      queryKey: getQueryKey(flightlineId),
      queryFn: () => fetchFlightlineData(flightlineId),
      enabled: isInitialDataFetched && isDone,
      staleTime: Infinity,
      gcTime: 8 * 60 * 60 * 1000, // 8 hours
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      structuralSharing: false, // Flightline data contains typed arrays, and is cached forever anyway :)
      onError: () => {
        queryClient.invalidateQueries(getQueryKey(flightlineId)); // prevent cache to hold on to failed states
      },
    })),
  });

  useEffect(() => {
    if (isDone && loadingProgress.progress < 100) {
      const fetchedQueries = [initialFlightlineQuery, ...flightlineQueries].filter((query) => query.isSuccess);
      const totalFetched = fetchedQueries.length;
      const remainingFlightlines = flightlines.slice(totalFetched);

      const newProgress = (totalFetched / flightlines.length) * 100;

      if (newProgress !== loadingProgress.progress) {
        setLoadingProgress((prev) => ({
          ...prev,
          fetched: totalFetched,
          progress: newProgress,
          remains: remainingFlightlines,
        }));
      }
    }
  }, [flightlineQueries, initialFlightlineQuery, flightlines, isDone, loadingProgress.progress]);

  const flightlineItems = useMemo(
    () =>
      flightlines.map((flightlineId, index) => {
        const query = index === 0 ? initialFlightlineQuery : flightlineQueries[index - 1];
        return {
          id: flightlineId,
          title: flightlineId,
          unavailable: false,
          data: query?.data,
          isLoading: query?.isLoading,
        };
      }),
    [flightlines, initialFlightlineQuery, flightlineQueries]
  );

  return {
    flightlineItems,
    loadingProgress,
    isDone,
    initialFlightlineId,
    activeNode,
    nodeData,
    flightlines,
    nodeType,
  };
};
