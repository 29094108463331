import React from "react";
import { iconColor } from "./color";

const ZoomSelectionIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.46885 19.6965L3.12004 18.1261C3.38887 18.2376 3.68501 18.3 4 18.3H7V20H4C3.45763 20 2.94048 19.8921 2.46885 19.6965ZM13 20V18.3H16C16.315 18.3 16.6111 18.2376 16.88 18.1261L17.5312 19.6965C17.0595 19.8921 16.5424 20 16 20H13ZM20 7H18.3V4C18.3 3.68501 18.2376 3.38887 18.1261 3.12004L19.6965 2.46885C19.8921 2.94048 20 3.45763 20 4V7ZM7 0H4C3.45763 0 2.94048 0.107945 2.46885 0.303522L3.12004 1.87386C3.38887 1.76238 3.68501 1.7 4 1.7H7V0ZM0 13H1.7V16C1.7 16.315 1.76238 16.6111 1.87386 16.88L0.303522 17.5312C0.107945 17.0595 0 16.5424 0 16V13ZM0 7H1.7V4C1.7 3.68501 1.76238 3.38887 1.87386 3.12004L0.303522 2.46885C0.107945 2.94048 0 3.45763 0 4V7ZM13 0V1.7H16C16.315 1.7 16.6111 1.76238 16.88 1.87386L17.5312 0.303522C17.0595 0.107945 16.5424 0 16 0H13ZM20 13H18.3V16C18.3 16.315 18.2376 16.6111 18.1261 16.88L19.6965 17.5312C19.8921 17.0595 20 16.5424 20 16V13Z"
        fill={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.84058 5.56522C7.9719 5.56522 7.1388 5.9103 6.52455 6.52455C5.9103 7.1388 5.56522 7.9719 5.56522 8.84058C5.56522 9.27071 5.64994 9.69662 5.81454 10.094C5.97914 10.4914 6.2204 10.8525 6.52455 11.1566C6.82869 11.4608 7.18977 11.702 7.58715 11.8666C7.98454 12.0312 8.41045 12.1159 8.84058 12.1159C9.27071 12.1159 9.69662 12.0312 10.094 11.8666C10.4914 11.702 10.8525 11.4608 11.1566 11.1566C11.4608 10.8525 11.702 10.4914 11.8666 10.094C12.0312 9.69662 12.1159 9.27071 12.1159 8.84058C12.1159 7.9719 11.7709 7.1388 11.1566 6.52455C10.5424 5.9103 9.70926 5.56522 8.84058 5.56522ZM5.41777 5.41777C6.32556 4.50999 7.55678 4 8.84058 4C10.1244 4 11.3556 4.50999 12.2634 5.41777C13.1712 6.32556 13.6812 7.55678 13.6812 8.84058C13.6812 9.47625 13.556 10.1057 13.3127 10.693C13.17 11.0375 12.9883 11.3635 12.7717 11.665L15.7708 14.664C16.0764 14.9696 16.0764 15.4652 15.7708 15.7708C15.4652 16.0764 14.9696 16.0764 14.664 15.7708L11.665 12.7717C11.3635 12.9883 11.0375 13.17 10.693 13.3127C10.1057 13.556 9.47625 13.6812 8.84058 13.6812C8.20491 13.6812 7.57546 13.556 6.98817 13.3127C6.40088 13.0694 5.86726 12.7129 5.41777 12.2634C4.96828 11.8139 4.61173 11.2803 4.36847 10.693C4.12521 10.1057 4 9.47625 4 8.84058C4 7.55678 4.50999 6.32556 5.41777 5.41777Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default ZoomSelectionIcon;
