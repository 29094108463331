import React, { useContext, useEffect, useState } from "react";
import { useBaseMaps } from "../../../../../hooks/mapHooks/useBaseMaps";
import { MapLayoutContext } from "../../../../../contexts";
import { FormCheck } from "../../../../common/forms/Form";
import {
  MapMenuList,
  MapMenuListHeading,
  MapMenuListItem,
} from "./MapMenuListItems";

const BaseMapSelection = ({ switchBaseMap }) => {
  const { baseMaps, defaultBaseMap } = useBaseMaps();
  const [selectedBaseMap, setSelectedBasemap] = useState(null);
  const { mapLayout, setMapLayout } = useContext(MapLayoutContext);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (defaultBaseMap) {
      setSelectedBasemap(defaultBaseMap);
    }
  }, [defaultBaseMap]);

  const handleBaseMapChange = (selectedMap) => {
    setSelectedBasemap(selectedMap);
    switchBaseMap(selectedMap);
    mapLayout.basemap = selectedBaseMap.id;
    setMapLayout({ ...mapLayout });
  };

  const handleDivClick = (selectedMap) => {
    handleBaseMapChange(selectedMap);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const transitionStyle = {
    transition: "max-height 0.5s ease-out",
    overflow: "hidden",
    maxHeight: showAll ? "500px" : "100px",
  };

  return (
    <div className="flex flex-col pt-1 pb-1">
      <MapMenuListHeading text={"Basemaps"} />
      <MapMenuList style={transitionStyle}>
        {baseMaps?.slice(0, showAll ? baseMaps.length : 4).map(
          (
            map,
            index // Conditional rendering
          ) => (
            <MapMenuListItem key={map.name} onClick={() => handleDivClick(map)}>
              <FormCheck
                type="radio"
                id={`${map.id}-radio-${map.name}-${index}`}
                name="baseMap"
                value={map.name}
                checked={map.name === selectedBaseMap?.name}
                onChange={() => handleDivClick(map)}
              />
              {map.name}
            </MapMenuListItem>
          )
        )}
      </MapMenuList>
      <button
        onClick={toggleShowAll}
        className="mt-2 mb-1 rounded-md text-sm border border-zinc-400 text-zinc-300 hover:text-zinc-300/80 hover:border-zinc-400/80 transition ease-in-out duration-100"
      >
        {showAll ? "Show Less" : "Show All"}
      </button>
    </div>
  );
};

export default BaseMapSelection;
