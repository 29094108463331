import React, { useEffect, useState } from "react";
import { fetchTags } from "../../../../api/fetchTags";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { capitalizeFirstLetter } from "../../../../utils";
import useDeleteListItem from "../../../../hooks/useDeleteListItem";
import useUpdateAuxData from "../../../../hooks/useUpdateAuxData";
import useCreateTag from "../../../../hooks/useCreateTag";
import Spinner from "../../../common/ui/Spinner";
import Form, { FormInput } from "../../../common/forms/Form";
import { DisplayErrorMsg } from "../../../common/DisplayErrorMsg";
import { PopupDialog } from "../../../common/PopupDialog";
import { TagsField } from "./TagsField";

const schema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters long")
    .max(60, "Title is too long."),
  tags: yup.array().of(yup.object()).min(1, "Please select at least one tag").required("Tags field is required"),
});

const MiscDataEditForm = ({ item, isOpen, closeModal }) => {
  const projectId = item.project;
  const tags = item.tags;
  const auxDataId = item.id;
  const auxDataTitle = item.title;

  const [isLoading, setIsLoading] = useState(false);
  const [tagsExamples, setTagsExamples] = useState([]);
  const [selectedTags, setSelectedTags] = useState(tags);
  const [errorMsg, setErrorMsg] = useState(null);

  const { submitNewTags } = useCreateTag(tagsExamples, projectId, setErrorMsg);
  const { handleDelete } = useDeleteListItem();
  const { submitUpdateAuxiliaryData } = useUpdateAuxData(projectId, auxDataId, closeModal, setErrorMsg, setIsLoading);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: auxDataTitle,
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      // Step 1: Construct new data payload.
      const tagIds = data.tags.map((tag) => tag.id);
      const newData = {
        ...data,
        tags_ids: tagIds,
        title: capitalizeFirstLetter(data.title),
      };

      // Step 2: Submit the tags using modified data.
      await submitNewTags(newData);

      // Step 3: Submit the auxiliary data using modified data.
      await submitUpdateAuxiliaryData(newData);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occured:", error);
      setErrorMsg(`Sorry, an error occured: ${error.response.status} ${error.response.statusText}`);
    } finally {
      setErrorMsg(false);
      setIsLoading(false);
    }
  };

  const onError = (errors, e) => console.error("errors", errors, "event", e);

  useEffect(() => {
    const fetchData = async () => {
      const tags = await fetchTags(projectId);
      if (tags) {
        setTagsExamples(tags);
      }
    };

    fetchData();
  }, [projectId]);

  const handleCancelClick = () => {
    setIsLoading(false);
    closeModal();
    setErrorMsg(false);
  };

  return (
    <PopupDialog isOpen={isOpen} closeModal={closeModal} title="Edit file details" width="md">
      <Form
        onSubmit={handleSubmit(onSubmit, onError)}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        {errorMsg && <DisplayErrorMsg message={errorMsg} />}

        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <FormInput
              label={"Title"}
              htmlFor={"title"}
              value={field.value}
              placeholder="Write a title for the upload"
              error={errors.title}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="tags"
          defaultValue={selectedTags}
          render={({ field }) => (
            <TagsField
              label={"Tags"}
              examples={tagsExamples}
              error={errors.tags}
              handleTagsChange={(newTags) => {
                setSelectedTags(newTags);
                field.onChange(newTags);
              }}
              tags={selectedTags}
            />
          )}
        />

        <div className="button-group flex flex-row justify-between gap-2 mt-2">
          <button
            onClick={() => handleDelete(auxDataId, auxDataTitle, "auxdata")}
            className="bg-red-600 text-white text-sm px-4 py-2 rounded-md hover:bg-red-600/90 transition ease-in-out 2s flex items-center"
          >
            Delete
          </button>
          <div className="flex flex-row gap-2">
            <button
              onClick={handleCancelClick}
              type="button"
              className="bg-transparent text-zinc-800 text-sm px-3 py-2 rounded-md border border-zinc-200 bg-zinc-50 hover:bg-zinc-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-zinc-900 text-white text-sm px-4 py-2 rounded-md hover:bg-zinc-900/90 transition ease-in-out 2s flex items-center"
            >
              {isLoading && <Spinner borderColor="rgba(255, 255, 255, 0.5)" borderTopColor="white" />}
              Save
            </button>
          </div>
        </div>
      </Form>
    </PopupDialog>
  );
};

export default MiscDataEditForm;
