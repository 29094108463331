import { useContext, useEffect, useState } from "react";
import { useManualEdits } from "../../../../hooks/api/useManualEdits";
import SimpleSelect from "../../../common/SimpleSelect";
import { FormLabel } from "../../../common/forms/Form";
import { registerSchemaComponent } from "../fieldRegistration";
import { NodeDataContext } from "../../../../contexts";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import FieldDescription from "../FieldDescription";

export const ManualEditsFormStep = ({ value, schemaFragment, setValue }) => {
  const { activeNode } = useContext(NodeDataContext);

  const projectId = activeNode && activeNode.length > 0 ? activeNode[0] : null;

  const manualEditsQuery = useManualEdits(projectId);
  const manualEdits = manualEditsQuery.data;

  // prefill select if it comes from a previous processing
  const findMatchingEdit = () => {
    if (manualEdits && value) {
      if (value && value.title && value.id) {
        const matchingEdit = manualEdits.find(
          (edit) => edit.title === value.title && edit.id === value.id
        );
        if (matchingEdit) {
          return {
            url: matchingEdit.src,
            title: matchingEdit.title,
            id: matchingEdit.id,
          };
        }
      }
    }
    return null;
  };

  const [selectedEdit, setSelectedEdit] = useState(null);

  useEffect(() => {
    setSelectedEdit(findMatchingEdit());
  }, [manualEdits, value]); // eslint-disable-line

  const onSelectEdit = (item) => {
    const newData = { url: item.src, title: item.title, id: item.id };
    setSelectedEdit(newData);
    setValue(newData);
  };

  if (!projectId) {
    return null;
  }

  return (
    <div className="block grid grid-cols-6 gap-2 items-center mt-1">
      <div className="col-span-2 flex items-center">
        <FormLabel
          label={"Select manual edit"}
          htmlFor={"manual-edits"}
          fontWeight="font-normal"
        />
      </div>
      <div className="col-span-4 flex items-center">
        <SimpleSelect
          items={manualEdits}
          width="w-72"
          dropdownWidth="w-full"
          selected={selectedEdit}
          setSelected={onSelectEdit}
          buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
          IconComponent={ChevronUpDownIcon}
          iconPosition="after"
        />
        <FieldDescription schemaFragment={schemaFragment} />
      </div>
    </div>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return (
    schemaFragment?.anyOf && schemaFragment["x-reference"] === "manual-edit"
  );
}, ManualEditsFormStep);
