import React from "react";
import jsyaml from "js-yaml";
import { useEnvironment } from "../../../../../../../hooks/useEnvironmentsData";
import { useNavigate } from "react-router-dom";
import { useActiveProject } from "../../../../../../../hooks/useActiveProject";

export const PaneContent = ({
  activeMenuOption,
  nodeData,
  logData,
  isLoading,
  flightlines,
}) => {
  const { data: environment } = useEnvironment(nodeData?.environment);
  const { projectTitle } = useActiveProject();
  const navigate = useNavigate();

  const linkify = (item) => {
    if (typeof item === "object") {
      if (item.length === undefined) {
        return Object.fromEntries(
          Object.entries(item).map(([key, value]) => [key, linkify(value)])
        );
      } else {
        return item.map(linkify);
      }
    } else if (typeof item === "string") {
      if (item[0] === "/" || item.indexOf("://") !== -1) {
        return "<a href='" + item + "'>" + item + "</a>";
      } else {
        return item;
      }
    } else {
      return item;
    }
  };

  let config;
  if (nodeData?.config) {
    config = nodeData.config;
  } else if (nodeData?.steps) {
    config = nodeData.steps;
  } else if (nodeData?.class_name) {
    config = {};
    config[nodeData.class_name] = nodeData.arguments;
  }

  const settings = jsyaml.dump({
    Config: linkify(config),
    "Software version": {
      Title: environment?.title,
      ID: environment?.id,
      Libraries: environment?.specification,
    },
  });

  const navigateToInversionProgress = () => {
    navigate(
      `/project/${projectTitle}/studio/processing-inversion/inversion-progress`
    );
  };

  const lines = logData?.split("\n");

  if (isLoading) {
    return (
      <div className="node-info-pane-content p-5 overflow-scroll flex-grow">
        <div className="flex flex-col gap-2">
          <div className="animate-pulse rounded-md bg-zinc-200 h-3 w-full"></div>
          <div className="animate-pulse rounded-md bg-zinc-200 h-3 w-full"></div>
          <div className="animate-pulse rounded-md bg-zinc-200 h-3 w-1/2"></div>
        </div>
      </div>
    );
  }

  if (activeMenuOption === "Settings") {
    return (
      <div className="node-info-pane-content p-5 pt-4 overflow-scroll flex-grow bg-zinc-50">
        <pre
          className="text-sm text-zinc-600"
          dangerouslySetInnerHTML={{ __html: settings }}
        />
      </div>
    );
  }

  if (activeMenuOption === "Comments") {
    return (
      <div className="node-info-pane-content p-5 overflow-scroll flex-grow">
        <p>Comments will be shown here</p>
      </div>
    );
  }

  if (activeMenuOption === "Flightlines") {
    return (
      <div className="node-info-pane-content p-5 pt-4 overflow-scroll flex-grow">
        <ul className="flex flex-wrap gap-x-2 gap-y-1 text-zinc-600 text-sm">
          {flightlines?.map((line, index) => (
            <li key={line} className="w-16">
              {line}
              {""}
              {flightlines.length > 1 && index !== flightlines.length - 1
                ? ","
                : ""}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (activeMenuOption === "Log") {
    return (
      <div className="node-info-pane-content h-full overflow-y-scroll bg-zinc-50">
        <div className="flex relative h-full overflow-y-scroll p-3 px-4">
          {nodeData && nodeData.arguments && (
            <button
              onClick={navigateToInversionProgress}
              className="absolute right-6 top-3 text-sm text-zinc-700 border 
            border-zinc-300 hover:border-emerald-700 rounded p-1 px-1.5"
            >
              View progress plot
            </button>
          )}
          <ul className="line-numbers w-4 text-sm text-zinc-400 pb-5 pr-5">
            {lines.slice(0, -1).map((_, index) => (
              <li key={index} className="line-number">
                {index + 1}
              </li>
            ))}
          </ul>

          <pre
            className="text-sm text-zinc-600 h-fit mb-1 ml-1 overflow-x-scroll overflow-y-hidden"
            style={{ borderRadius: "0 5px 20px 0" }}
          >
            {logData}
          </pre>
        </div>
      </div>
    );
  }
};
