import React, { useCallback, useRef, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import client from "../../../../../../api/client";
import { DisplayErrorMsg } from "../../../../../common/DisplayErrorMsg";
import Form, { FormInput } from "../../../../../common/forms/Form";
import Spinner from "../../../../../common/ui/Spinner";

const SaveLayoutForm = ({ workspaceLayout, nodeType, setShowInput, activeNode }) => {
  const queryClient = useQueryClient();
  const layoutNameRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const updateWorkspaceMutation = useMutation({
    mutationFn: (data) => client.post("/api/layouts/", { ...data, project_id: activeNode[0] }),
    onSuccess: (newLayout) => {
      const newData = newLayout.data;

      // Update the cache with the new layout
      queryClient.setQueryData(["workspaceLayouts"], (oldData) => {
        return oldData ? [...oldData, newData] : [newData];
      });
      setIsLoading(false);
      setShowInput(false);
      setErrorMsg("");
    },
    onError: (error) => {
      setIsLoading(false);
      if (error.response?.status === 401) {
        setErrorMsg(error.response.data.message);
      } else {
        console.error("An error occurred while saving the layout.", error);
        setErrorMsg(`Failed to save layout: ${error.response?.status} ${error.response?.statusText}`);
      }
    },
  });

  const saveLayout = useCallback(
    (ev) => {
      ev.preventDefault();
      setIsLoading(true);
      const layout = {
        ...workspaceLayout,
        title: layoutNameRef.current.value,
        node_type: [nodeType],
        workspace_type: "Custom",
      };
      if (!layout.workspace_type) layout.workspace_type = "Custom";
      if (!layout.node_type) layout.node_type = [nodeType];
      delete layout.id;
      updateWorkspaceMutation.mutate(layout);
    },
    [workspaceLayout, nodeType, updateWorkspaceMutation]
  );

  return (
    <div className="overflow-hidden bg-white border-b border-zinc-400 transition ease-in-out">
      <div className="pb-3 flex flex-col gap-2">
        <div className="flow-root rounded-md transition duration-150 ease-in-out">
          <span className="font-medium text-zinc-700">Save current layout</span>
        </div>

        {errorMsg && <DisplayErrorMsg message={errorMsg} />}
        <Form onSubmit={saveLayout}>
          <div className="mb-2">
            <FormInput ref={layoutNameRef} name="Write a name" placeholder="Name" />
          </div>
          <button
            type="submit"
            className="w-full flex items-center gap-2 justify-center bg-zinc-600 text-white text-sm px-4 py-1.5 rounded-md hover:bg-zinc-700"
          >
            {isLoading ? (
              <span>
                <Spinner borderColor="rgba(255, 255, 255, 0.5)" borderTopColor="white" />
              </span>
            ) : (
              <span> Save</span>
            )}
          </button>
        </Form>
      </div>
    </div>
  );
};

export default SaveLayoutForm;
