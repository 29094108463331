import React from "react";
export * from "./FormLabel";
export * from "./FormInput";
export * from "./FileInput";
export * from "./FormCheck";

const Form = ({ onSubmit, children, ...restProps }) => {
  return (
    <form
      onSubmit={onSubmit}
      className={`flex flex-col gap-2.5`}
      {...restProps}
    >
      {children}
    </form>
  );
};

export default Form;
