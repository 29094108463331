import React from "react";
import { strokeColor } from "./layout/variables";

const InversionProgressIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4.5V9C2 13.9706 6.02944 18 11 18H15"
        stroke={strokeColor}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <circle
        cx="2.5"
        cy="2.5"
        r="1.7"
        stroke={strokeColor}
        strokeWidth="1.6"
      />
      <circle
        cx="17.5"
        cy="17.5"
        r="1.7"
        stroke={strokeColor}
        strokeWidth="1.6"
      />
    </svg>
  );
};

export default InversionProgressIcon;
