import React from "react";
import { useProjections } from "../../../../hooks/api/useProjections";
import SimpleSearchSelect from "../../../common/SimpleSearchSelect";
//import { FormLabel } from "../../../common/forms/Form";

const ProjectionSelect = ({ projection, setProjection }) => {
  const projectionsQuery = useProjections();

  console.log("proj Value", projection);

  const projections = [
    { id: "", title: "None" },
    ...Object.entries(projectionsQuery.data || {}).map(([id, title]) => {
      return { id: id, title: title };
    }),
  ];

  // Find the selected projection or allow null
  //const selected = projections.find((item) => item.id === String(projection)) || null;
  const selected = projections.filter((item) => item.id === JSON.stringify(projection, 10))[0];

  return (
    <div className="flex flex-col gap-0.5 pb-3 border-b border-zinc-300">
      <div className="flex items-center gap-1">
        <div className="flex text-sm text-zinc-600 mb-1">Optional: Set and override epsg code</div>
      </div>

      <SimpleSearchSelect
        items={projections}
        selected={selected}
        setSelected={(proj) => {
          // If 'None' (empty), clear the projection
          if (!proj || proj.id === "") {
            setProjection(null); // Clear the selection
          } else {
            setProjection(proj.id); // Set the selected projection's ID
          }
        }}
        inputStyles="projection-select relative text-sm w-full bg-transparent py-2 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
        inputWidth="w-full"
      />
    </div>
  );
};

export default ProjectionSelect;
