import { useState, useEffect } from "react";

export const useTitleSuggestions = (type, dataList) => {
  const [title, setTitle] = useState("");
  const [titleExamples, setTitleExamples] = useState([]);

  useEffect(() => {
    if (dataList?.length === 0) {
      const nextId = dataList.length + 1;
      const nextTitle = `${type}-1`;
      const newExamples = [
        { id: nextId, name: nextTitle },
        {
          id: nextId + 1,
          name: "or create title by typing",
          unavailable: true,
        },
      ];
      setTitle(newExamples[0]);
      setTitleExamples(newExamples);
    } else if (dataList?.length > 0) {
      const nextId = dataList.length + 1;
      const nextTitle = `${type}-${nextId}`;
      const newExamples = [
        { id: nextId, name: nextTitle },
        {
          id: nextId + 1,
          name: "or create title by typing",
          unavailable: true,
        },
      ];

      setTitle(newExamples[0]);
      setTitleExamples(newExamples);
    }
  }, [dataList, type]);

  return { title, setTitle, titleExamples, setTitleExamples };
};
