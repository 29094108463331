import React from "react";
import { Map } from "../workspace/modules/Map/";

const MapComponent = () => {
  return (
    <div className="w-full rounded-lg p-1 overflow-hidden">
      <div className="w-full relative" style={{ height: "calc(100vh - 2rem)" }}>
        <Map showMetaDataBar={false} showMapMenu={false} />
      </div>
    </div>
  );
};

export default MapComponent;
