import React from "react";

const InterpretationIcon = ({ color, className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8423 2.18423H5.46058C3.65111 2.18423 2.18423 3.65111 2.18423 5.46058V21.8423C2.18423 23.6518 3.65111 25.1187 5.46058 25.1187H21.8423C23.6518 25.1187 25.1187 23.6518 25.1187 21.8423V5.46058C25.1187 3.65111 23.6518 2.18423 21.8423 2.18423ZM5.46058 0C2.44479 0 0 2.44479 0 5.46058V21.8423C0 24.8581 2.44479 27.3029 5.46058 27.3029H21.8423C24.8581 27.3029 27.3029 24.8581 27.3029 21.8423V5.46058C27.3029 2.44479 24.8581 0 21.8423 0H5.46058Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9584 13.2832C13.1762 12.9337 14.4597 12.9073 15.69 13.2063L25.3334 15.5506C25.7981 15.6635 26.0862 16.1443 25.9768 16.6244C25.8675 17.1045 25.4022 17.4021 24.9375 17.2891L15.2941 14.9449C14.3468 14.7146 13.3587 14.7349 12.421 15.004L2.0959 17.9672C1.63595 18.0992 1.15952 17.821 1.03175 17.3458C0.903988 16.8706 1.17328 16.3784 1.63323 16.2464L11.9584 13.2832Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.966133 3.04608C1.20054 2.58335 1.72312 2.42258 2.13333 2.687L9.53454 7.45773C10.5601 8.11881 11.7419 8.40586 12.9178 8.27953L24.6599 7.01806C25.1302 6.96754 25.5478 7.35667 25.5926 7.88723C25.6374 8.41779 25.2924 8.88885 24.8221 8.93938L13.08 10.2008C11.5527 10.3649 10.0178 9.99209 8.68567 9.13344L1.28446 4.36272C0.874243 4.0983 0.731723 3.50882 0.966133 3.04608Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.64179 1.1514C10.0261 1.41222 10.1152 1.91965 9.84063 2.28479L6.55124 6.6597C6.2767 7.02484 5.74256 7.10941 5.35821 6.8486C4.97385 6.58778 4.88483 6.08035 5.15937 5.71521L8.44876 1.3403C8.7233 0.97516 9.25744 0.890587 9.64179 1.1514Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8661 0.996554C16.232 1.24747 16.321 1.74147 16.0649 2.09993L11.71 8.19422C11.4539 8.55268 10.9496 8.63985 10.5837 8.38893C10.2178 8.13801 10.1288 7.64402 10.3849 7.28556L14.7398 1.19127C14.9959 0.832811 15.5002 0.745635 15.8661 0.996554Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3554 1.00932C24.776 1.27424 24.869 1.78431 24.5631 2.14858L18.7678 9.0495C18.4618 9.41378 17.8729 9.49431 17.4522 9.22939C17.0316 8.96446 16.9386 8.45439 17.2445 8.09012L23.0398 1.1892C23.3458 0.824928 23.9347 0.744391 24.3554 1.00932Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.945 23.9327C12.4398 23.9327 12.8409 23.5316 12.8409 23.0368C12.8409 22.542 12.4398 22.1409 11.945 22.1409C11.4502 22.1409 11.0491 22.542 11.0491 23.0368C11.0491 23.5316 11.4502 23.9327 11.945 23.9327ZM11.945 25.5965C13.3587 25.5965 14.5047 24.4505 14.5047 23.0368C14.5047 21.6232 13.3587 20.4772 11.945 20.4772C10.5314 20.4772 9.38538 21.6232 9.38538 23.0368C9.38538 24.4505 10.5314 25.5965 11.945 25.5965Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 18.375C15.9832 18.375 16.375 17.9832 16.375 17.5C16.375 17.0168 15.9832 16.625 15.5 16.625C15.0168 16.625 14.625 17.0168 14.625 17.5C14.625 17.9832 15.0168 18.375 15.5 18.375ZM15.5 20C16.8807 20 18 18.8807 18 17.5C18 16.1193 16.8807 15 15.5 15C14.1193 15 13 16.1193 13 17.5C13 18.8807 14.1193 20 15.5 20Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 21.375C5.98325 21.375 6.375 20.9832 6.375 20.5C6.375 20.0168 5.98325 19.625 5.5 19.625C5.01675 19.625 4.625 20.0168 4.625 20.5C4.625 20.9832 5.01675 21.375 5.5 21.375ZM5.5 23C6.88071 23 8 21.8807 8 20.5C8 19.1193 6.88071 18 5.5 18C4.11929 18 3 19.1193 3 20.5C3 21.8807 4.11929 23 5.5 23Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0368 21.373C23.5316 21.373 23.9327 20.9719 23.9327 20.4771C23.9327 19.9823 23.5316 19.5813 23.0368 19.5813C22.542 19.5813 22.1409 19.9823 22.1409 20.4771C22.1409 20.9719 22.542 21.373 23.0368 21.373ZM23.0368 23.0368C24.4505 23.0368 25.5965 21.8908 25.5965 20.4771C25.5965 19.0635 24.4505 17.9175 23.0368 17.9175C21.6232 17.9175 20.4772 19.0635 20.4772 20.4771C20.4772 21.8908 21.6232 23.0368 23.0368 23.0368Z"
        fill={color}
      />
    </svg>
  );
};

export default InterpretationIcon;
