import React, { useEffect } from "react";
import { useActiveProject } from "../../hooks/useActiveProject";
import { useNavigate } from "react-router-dom";

const SidebarTitle = () => {
  const { projectTitle } = useActiveProject();
  const navigate = useNavigate();

  useEffect(() => {
    if (!projectTitle) {
      navigate("/");
    }
  }, [projectTitle, navigate]);
  return (
    <h2 aria-label={projectTitle} title={projectTitle} className="beryl_sidebar-title">
      {projectTitle}
    </h2>
  );
};

export default SidebarTitle;
