import { useState } from "react";

const useCustomAlert = (duration = 1600) => {
  const [alertVisible, setAlertVisible] = useState(false);

  const showAlert = () => {
    setAlertVisible(true);
    setTimeout(() => setAlertVisible(false), duration);
  };

  return [alertVisible, showAlert];
};

export default useCustomAlert;
