import React from "react";

const FieldDescription = ({ schemaFragment, fallbackDescription }) => {
  const description = schemaFragment?.description || fallbackDescription;

  if (!description) return null;

  const isComponentDescription = React.isValidElement(description);

  return (
    <div
      className={`ml-3 relative group rounded-full
        border border-zinc-600 text-zinc-600 text-xs
        hover:border-zinc-800 hover:text-zinc-800
        w-3.5 h-3.5 flex items-center justify-center`}
    >
      i
      <div
        className={`absolute bg-zinc-800 text-white shadow rounded-md p-2.5 px-3
          text-sm z-50 min-w-72 w-fit
          top-1 -right-2 transform -translate-y-1/2 translate-x-full opacity-0 invisible
          group-hover:opacity-100 group-hover:visible transition-opacity duration-200`}
      >
        {isComponentDescription ? description : <p>{description}</p>}
      </div>
    </div>
  );
};

export default FieldDescription;
