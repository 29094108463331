import React, { useState } from "react";
import { LayerIcon, PaperAirplaneIcon } from "../../../../../assets/icons";
import BaseMapSelection from "./BaseMapSelection";
import { ShapefilesSelection } from "./ShapefilesSelection";
import { RasterSelection } from "./RasterSelection";
import FlightlineSelection from "./FlightlineSelection";
import MapMenuSection from "./MapMenuSection";

const MapMenu = ({ switchBaseMap, activeNode, flightlines, selectedLineId, hiddenLineIds, setHiddenLineIds, map }) => {
  const [showLayers, setShowLayers] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const updateHiddenLineIds = (update) => {
    if (update.call !== undefined) {
      update = update(hiddenLineIds);
    }
    setHiddenLineIds(update);
  };

  return (
    <div className="beryl_ui_map_menu_wrapper">
      <div className="beryl_ui_map_menu_wrapper__inner">
        <MapMenuSection
          title={"Layers"}
          isActive={showLayers}
          toggleFunction={() => setShowLayers(!showLayers)}
          icon={<LayerIcon color="rgb(250 250 250)" />}
        >
          <ShapefilesSelection activeNode={activeNode} />
          <RasterSelection activeNode={activeNode} map={map} />
          <BaseMapSelection switchBaseMap={switchBaseMap} />
        </MapMenuSection>

        <MapMenuSection
          title={"Flightlines"}
          isActive={showSettings}
          toggleFunction={() => setShowSettings(!showSettings)}
          icon={<PaperAirplaneIcon className="w-5 h-5 text-white" />}
        >
          <FlightlineSelection
            flightlines={flightlines}
            selectedLineId={selectedLineId}
            hiddenFlightlines={hiddenLineIds}
            setHiddenFlightlines={updateHiddenLineIds}
          />
        </MapMenuSection>
      </div>
    </div>
  );
};

export default MapMenu;
