import React, { useContext } from "react";
import { PopupDialog } from "../common/PopupDialog";
import { SidebarContext } from "../../contexts/SidebarContext";
import EditGroupForm from "../Forms/EditGroupForm";
import EditTitleForm from "../Forms/EditTitleForm";
import MiscDataCreateForm from "./MiscData/Forms/MiscDataCreateForm";

const EditDialog = ({ isOpen, closePopup, isEditMode, setIsEmptyProject }) => {
  const { nodeItemClicked } = useContext(SidebarContext);

  const dialogTitle = (() => {
    if (nodeItemClicked?.data_type === "group") {
      return isEditMode ? "Edit group" : "Create group";
    } else if (nodeItemClicked?.data_type === "misc_data") {
      return "Upload misc data";
    } else {
      return "Rename";
    }
  })();

  const content = (() => {
    if (nodeItemClicked?.data_type === "group") {
      return (
        <EditGroupForm
          isEditMode={isEditMode}
          groupData={nodeItemClicked}
          closePopup={closePopup}
          setIsEmptyProject={setIsEmptyProject}
        />
      );
    } else if (nodeItemClicked?.data_type === "misc_data") {
      return <MiscDataCreateForm setShowForm={closePopup} />;
    } else {
      return <EditTitleForm data={nodeItemClicked} closePopup={closePopup} />;
    }
  })();

  return (
    <PopupDialog isOpen={isOpen} closeModal={closePopup} title={dialogTitle} width="md">
      {content}
    </PopupDialog>
  );
};

export default EditDialog;
