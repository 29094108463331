import { useContext } from "react";
import { useAuxiliaryData } from "../../../../hooks/api/useAuxiliaryData";
import SimpleSelect from "../../../common/SimpleSelect";
import { FormLabel } from "../../../common/forms/Form";
import { NodeDataContext } from "../../../../contexts";
import { registerSchemaComponent } from "../fieldRegistration";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import FieldDescription from "../FieldDescription";

export const AuxFileFormStep = ({
  value,
  propKey,
  schemaFragment,
  setValue,
}) => {
  const { activeNode } = useContext(NodeDataContext);
  const projectId = activeNode.length > 0 ? activeNode[0] : null;

  const files = useAuxiliaryData(
    propKey,
    projectId,
    schemaFragment["x-url-media-type"].replaceAll("/", "__")
  );

  // prefill select if it comes from a previous processing
  const findMatchingFile = () => {
    console.log("MATCHING VALUE", value, "SchemaFragment", schemaFragment);
    if (files && files.data && value) {
      const matchingFile = files.data.find((file) => file.src === value);
      return matchingFile || null;
    }
    return null;
  };

  const selectedItem = findMatchingFile();

  console.log(files.data);

  const onSelectEdit = (item) => {
    const src = item.src;
    setValue(src);
  };

  if (!projectId) {
    return null;
  }

  if (files.data && files.data.length !== 0) {
    return (
      <div className="block grid grid-cols-6 gap-2 items-center mt-1">
        <div className="col-span-2 flex items-center">
          <FormLabel
            label={`Select ${schemaFragment.title || propKey}`}
            htmlFor={"shapefile"}
            fontWeight="font-normal"
          />
        </div>
        <div className="col-span-4 flex items-center">
          <SimpleSelect
            items={files.data}
            width="w-72"
            dropdownWidth="w-full"
            selected={selectedItem}
            setSelected={onSelectEdit}
            nothingTitle="Nothing selected"
            buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
            IconComponent={ChevronUpDownIcon}
            iconPosition="after"
          />
          <FieldDescription schemaFragment={schemaFragment} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="block grid grid-cols-6 gap-2 items-center mt-1">
        <div className="col-span-2 flex items-center">
          <FormLabel
            label={`${schemaFragment.title || propKey}`}
            htmlFor={"file"}
            fontWeight="font-normal"
          />
        </div>
        <div className="col-span-4 flex items-center">
          <div className="text-sm text-zinc-500">No {propKey}s to select.</div>
        </div>
      </div>
    );
  }
};

registerSchemaComponent(({ schemaFragment }) => {
  return (
    schemaFragment &&
    (schemaFragment["x-url-media-type"] === "application/zipped-shapefile" ||
      schemaFragment["x-url-media-type"] === "image/geotiff")
  );
}, AuxFileFormStep);
