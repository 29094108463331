import { useQuery } from "@tanstack/react-query";
import client from "../../api/client";
import handleError from "../../api/handleError";

// Fetch and cache the project data

export const fetchProjects = async () => {
  try {
    const response = await client.get("/api/projects/");
    return response.data;
  } catch (err) {
    if (err.response && err.response.status === 404) {
      localStorage.removeItem("activeProject");
    }
    handleError(err, "Get Projects");
  }
};

export const fetchProjectDetails = async (projectId) => {
  try {
    const response = await client.get(`/api/projects/${projectId}`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.status === 404) {
      localStorage.removeItem("activeProject");
    }
    handleError(err, "Get Projects");
  }
};

export const useProjects = () => {
  return useQuery({
    queryKey: ["projects"],
    queryFn: () => fetchProjects(),
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 6, // 6 minutes
    refetchOnMount: "always",
    refetchOnWindowFocus: false,
  });
};

export const useProject = (projectId) => {
  return useQuery({
    queryKey: ["project", projectId],
    queryFn: () => fetchProjectDetails(projectId),
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 6, // 6 minutes
    refetchOnMount: "always",
    refetchOnWindowFocus: false,
    enabled: !!projectId,
  });
};
