import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../api/client";

const useCreateProject = (addMessage, handleProjectClick) => {
  const queryClient = useQueryClient();

  const createProjectMutation = useMutation({
    mutationFn: (data) =>
      client.post("/api/projects/", {
        title: data.title,
      }),
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries("projects");
      if (handleProjectClick) {
        handleProjectClick(data.data);
      }
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        console.log("error", error);
        // Assuming the ValidationError will return a 400 status code
        const errorMessage = {
          title: "Project limit reached",
          message: `We've set a limit on the number of projects for test users during our beta phase. If you have any questions contact our staff. Thank you for helping us improve our software!`,
        };
        addMessage({
          title: errorMessage.title,
          shortMessage: errorMessage.message,
          type: "notice",
          message: errorMessage.message,
        });
      } else {
        console.error("An unexpected error occurred:", error);
      }
    },
  });

  const handleFormSubmit = (data) => {
    createProjectMutation.mutate(data);
  };

  return {
    createProjectMutation,
    handleFormSubmit,
  };
};

export default useCreateProject;
