import React from "react";
//import { iconColor } from "./tools/color";

const ShrinkSidebarIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.8" y="0.8" width="15.4" height="15.4" rx="3.2" stroke="black" strokeWidth="1.6" />
      <path d="M5.95001 0.849976V16.15" stroke="black" strokeWidth="1.6" strokeLinecap="round" />
    </svg>
  );
};

export default ShrinkSidebarIcon;
