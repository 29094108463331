import React, { useContext, useState } from "react";
import jsyaml from "js-yaml";
import { NodeDataContext } from "../../../../../../contexts";
import { NodeInfoNav } from "./NodeInfoNav";
import { DetailsPane } from "./DetailsPane";
import { NodeInfoHeader } from "./NodeInfoHeader";
import { getDownloadUrlsForNode } from "./utils";
import { useNodeProgress } from "../../../../../../hooks/api/useNodeProgress";
import { ErrorHandler } from "../../../../../common/ErrorHandler";

const NodeInfo = () => {
  const [activeMenuOption, setActiveMenuOption] = useState("Flightlines");
  //const [sizes, setSizes] = useState([100, "40%", "auto"]);
  const { activeNode, nodeData, nodeBinaryByLine, nodeType } = useContext(NodeDataContext);
  const downloadUrls = getDownloadUrlsForNode(activeNode, nodeData);
  const nodeLogsQuery = useNodeProgress(activeNode, activeMenuOption);

  let binary = nodeBinaryByLine && Object.values(nodeBinaryByLine)?.[0];

  const flightlines = (binary && Object.keys(binary)) || [];
  const tag = nodeData?.tag;
  const isInversion = nodeData?.outputs ? nodeData.outputs.length === 0 : null;
  const isLoading = nodeLogsQuery.isLoading;
  const logData = jsyaml.dump(nodeLogsQuery.yamlifyLogs ? nodeLogsQuery.yamlifyLogs : null);

  return (
    <ErrorHandler>
      <div className="flex flex-col justify-start w-full">
        <NodeInfoNav downloadUrls={downloadUrls} isInversion={isInversion} />
        <div className="flex flex-col w-full h-full">
          <NodeInfoHeader nodeData={nodeData} nodeType={nodeType} tag={tag} />

          <DetailsPane
            setActiveMenuOption={setActiveMenuOption}
            nodeType={nodeType}
            activeMenuOption={activeMenuOption}
            logData={logData}
            isLoading={isLoading}
            nodeData={nodeData}
            flightlines={flightlines}
          />
        </div>
      </div>
    </ErrorHandler>
  );
};

export default NodeInfo;
