import React from "react";

const LocationIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.74998 8.5C9.74998 8.83152 9.61829 9.14947 9.38387 9.38389C9.14945 9.61831 8.83151 9.75 8.49998 9.75C8.16846 9.75 7.85052 9.61831 7.6161 9.38389C7.38168 9.14947 7.24998 8.83152 7.24998 8.5C7.24998 8.16848 7.38168 7.85054 7.6161 7.61612C7.85052 7.3817 8.16846 7.25 8.49998 7.25C8.83151 7.25 9.14945 7.3817 9.38387 7.61612C9.61829 7.85054 9.74998 8.16848 9.74998 8.5Z"
        fill={props.color || "#ffffff"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 8.25C8.4337 8.25 8.37011 8.27634 8.32322 8.32322C8.27634 8.37011 8.25 8.4337 8.25 8.5C8.25 8.5663 8.27634 8.62989 8.32322 8.67678C8.37011 8.72366 8.4337 8.75 8.5 8.75C8.5663 8.75 8.62989 8.72366 8.67678 8.67678C8.72366 8.62989 8.75 8.5663 8.75 8.5C8.75 8.4337 8.72366 8.37011 8.67678 8.32322C8.62989 8.27634 8.5663 8.25 8.5 8.25ZM6.90901 6.90901C7.33097 6.48705 7.90326 6.25 8.5 6.25C9.09674 6.25 9.66903 6.48705 10.091 6.90901C10.5129 7.33097 10.75 7.90326 10.75 8.5C10.75 9.09674 10.5129 9.66903 10.091 10.091C9.66903 10.5129 9.09674 10.75 8.5 10.75C7.90326 10.75 7.33097 10.5129 6.90901 10.091C6.48705 9.66903 6.25 9.09674 6.25 8.5C6.25 7.90326 6.48705 7.33097 6.90901 6.90901Z"
        fill={props.color || "#ffffff"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 4.5C7.43913 4.5 6.42172 4.92143 5.67157 5.67157C4.92143 6.42172 4.5 7.43913 4.5 8.5C4.5 9.56087 4.92143 10.5783 5.67157 11.3284C6.42172 12.0786 7.43913 12.5 8.5 12.5C9.56087 12.5 10.5783 12.0786 11.3284 11.3284C12.0786 10.5783 12.5 9.56087 12.5 8.5C12.5 7.43913 12.0786 6.42172 11.3284 5.67157C10.5783 4.92143 9.56087 4.5 8.5 4.5ZM4.25736 4.25736C5.38258 3.13214 6.9087 2.5 8.5 2.5C10.0913 2.5 11.6174 3.13214 12.7426 4.25736C13.8679 5.38258 14.5 6.9087 14.5 8.5C14.5 10.0913 13.8679 11.6174 12.7426 12.7426C11.6174 13.8679 10.0913 14.5 8.5 14.5C6.9087 14.5 5.38258 13.8679 4.25736 12.7426C3.13214 11.6174 2.5 10.0913 2.5 8.5C2.5 6.9087 3.13214 5.38258 4.25736 4.25736Z"
        fill={props.color || "#ffffff"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 8.5C12.5 7.94772 12.9477 7.5 13.5 7.5H16C16.5523 7.5 17 7.94772 17 8.5C17 9.05228 16.5523 9.5 16 9.5H13.5C12.9477 9.5 12.5 9.05228 12.5 8.5Z"
        fill={props.color || "#ffffff"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 12.5C9.05228 12.5 9.5 12.9477 9.5 13.5V16C9.5 16.5523 9.05228 17 8.5 17C7.94772 17 7.5 16.5523 7.5 16V13.5C7.5 12.9477 7.94772 12.5 8.5 12.5Z"
        fill={props.color || "#ffffff"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.5C0 7.94772 0.447715 7.5 1 7.5H3.5C4.05228 7.5 4.5 7.94772 4.5 8.5C4.5 9.05228 4.05228 9.5 3.5 9.5H1C0.447715 9.5 0 9.05228 0 8.5Z"
        fill={props.color || "#ffffff"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 0C9.05228 0 9.5 0.447715 9.5 1V3.5H7.5V1C7.5 0.447715 7.94772 0 8.5 0Z"
        fill={props.color || "#ffffff"}
      />
    </svg>
  );
};

export default LocationIcon;
