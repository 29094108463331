import React from "react";
import SelectionComponent from "./SelectionComponent";

export const RasterSelection = ({ activeNode, map }) => (
  <SelectionComponent
    title="Rasters"
    dataQuery="image/geotiff"
    contextHandler={activeNode[0]}
    filterCondition={(tag) => tag.name === "Broken"}
    itemIdentifier="rasters"
    itemName="raster"
    itemType="raster"
    map={map}
  />
);
