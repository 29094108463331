import React from "react";
import { darkenColor, hexToRgba } from "../../../utils";

const MiscDataTag = ({ item, mode = "full" }) => {
  const classes = mode === "full" ? "tag--full" : "tag";

  return (
    <div
      className={classes}
      style={{
        backgroundColor: hexToRgba(item.color, 0.4),
        color: darkenColor(hexToRgba(item.color), 30),
      }}
      title={item.name}
    >
      {mode === "full" ? item.name : item.name.substring(0, 3).toLowerCase()}
    </div>
  );
};

export default MiscDataTag;
