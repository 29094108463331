import React from "react";
import { borderRadius, strokeWidth } from "./variables";

const Layout2x2Filled = ({ strokeColor }) => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.840918"
        y="0.65"
        width="29.5091"
        height="29.5091"
        rx={borderRadius}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d="M22.1973 1.54041V29.2686"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M1.95166 14.9644L29.6798 14.9644"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M5.47266 5.28149L18.6765 5.28149"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M5.47266 10.5631H18.6765"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M5.47266 20.246H18.6765"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M5.47266 25.5275H18.6765"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <rect
        x="22.1973"
        y="0.880249"
        width="7.92233"
        height="29.0485"
        rx="2"
        fill={strokeColor}
        fillOpacity="0.2"
      />
    </svg>
  );
};

export default Layout2x2Filled;
