import React from "react";
import TooltipButton from "../components/common/TooltipButton";
import { ArrowRightEndOnRectangleIcon } from "../assets/icons";
import Projects from "../components/Projects/Projects";
import Banner from "../components/Banner/Banner";

const Home = () => {
  return (
    <div className="beryl_home-page">
      <div className="beryl_home-page__nav">
        <h1 className="h1-title">Projects</h1>

        <div className="beryl_home-page__nav--right">
          <a aria-label="docs" type="link" href="https://emerald-geomodelling.github.io/beryllium/" target="_">
            Open Source
          </a>
          <TooltipButton
            to="/accounts/logout/"
            tooltipText={"Sign out"}
            icon={<ArrowRightEndOnRectangleIcon className="h-5 w-5 text-black" />}
            componentType="externalLink"
            additionalClasses="text-white"
          />
        </div>
      </div>

      <div className="beryl_home-page--content">
        <Banner />
        <Projects />
      </div>
    </div>
  );
};

export default Home;
