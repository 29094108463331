export const colorPalette = [
  "rgb(255, 105, 180)", // Pink
  "rgb(255, 165, 0)", // Orange
  "rgb(60, 179, 113)", // Green
  "rgb(0, 191, 255)", // Blue
  "rgb(156, 85, 237)", // Purple
];

export const colorPaletteSecondary = [
  "rgb(255, 155, 230)", // Pink
  "rgb(255, 215, 50)", // Orange
  "rgb(110, 229, 163)", // Green
  "rgb(50, 241, 255)", // Blue
  "rgb(206, 135, 255)", // Purple
];

export const colorNotInUse = "rgb(192, 192, 192)";
export const colorNotInUseSecondary = "rgb(242, 242, 242)";

export const colorSignActive = [
  "rgb(255, 156, 156)", // Inactive, negative = Light Red
  "rgb(156, 156, 255)", // Inactive, positive = Light Blue
  "rgb(255, 0, 0)", // Active, negative = Pure Red
  "rgb(0, 0, 255)", // Active, positive = Pure Blue
];

export const colorSignActiveSecondary = [
  "rgb(255, 255, 156)", // Inactive, negative
  "rgb(156, 255, 255)", // Inactive, positive
  "rgb(255, 255, 0)", // Active, negative
  "rgb(0, 255, 255)", // Active, positive
];
