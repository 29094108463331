import { useState, createContext } from "react";

export const MapLayoutContext = createContext();

export const MapLayoutProvider = ({ children }) => {
  const [mapLayout, setMapLayout] = useState({
    layers: [],
    rasters: [],
    rasters_configs: {},
    basemap: null,
  });

  return (
    <MapLayoutContext.Provider value={{ mapLayout, setMapLayout }}>
      {children}
    </MapLayoutContext.Provider>
  );
};
