import { useQueryClient, useMutation } from "@tanstack/react-query";
import client from "../api/client";

const useCreateEnvironment = (setShowForm, setIsLoading, setErrorMsg) => {
  const queryClient = useQueryClient();

  const createEnvironmentMutation = useMutation({
    mutationFn: (data) =>
      client.post(`/api/environments/new`, {
        title: data.title,
        specification: data.specification,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries("environments");

      setIsLoading(false);
      setShowForm(false); // Close the form
    },
    onError: (error) => {
      console.error("Error saving environment.", error.message);
      setErrorMsg(`Error saving environment, ${error.message}`);
    },
  });

  const submitEnvironment = (data) => {
    createEnvironmentMutation.mutate(data);
  };

  return {
    createEnvironmentMutation,
    submitEnvironment,
  };
};

export default useCreateEnvironment;
