import { XMarkIcon } from "../../../../assets/icons";
import { darkenColor, hexToRgba } from "../../../../utils";

export const Tag = ({ tag, onRemove }) => (
  <div
    key={tag}
    className="inline-flex items-center rounded-full text-sm font-medium px-1.5 py-0.5 mt-2"
    style={{
      backgroundColor: hexToRgba(tag.color, 0.4),
      color: darkenColor(hexToRgba(tag.color), 30),
    }}
  >
    {tag.name}
    <XMarkIcon className="ml-1.5 h-4 w-4 cursor-pointer" onClick={() => onRemove(tag)} aria-hidden="true" />
  </div>
);
