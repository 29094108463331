import React from "react";
import EditableElementPlotWrapper from "../workspace/modules/Plotting/EditableElementPlotWrapper";
import { ErrorHandler } from "../common/ErrorHandler";
import { usePlotTools } from "../../contexts/PlotToolsContext";

const PlotComponent = () => {
  const { currentDragMode, setCurrentDragMode, subplotZooms, setSubplotZooms } = usePlotTools();

  return (
    <div className="beryl_plot">
      <ErrorHandler>
        <EditableElementPlotWrapper
          subplotZooms={subplotZooms}
          setSubplotZooms={setSubplotZooms}
          currentDragMode={currentDragMode}
          setCurrentDragMode={setCurrentDragMode}
        />
      </ErrorHandler>
    </div>
  );
};

export default PlotComponent;
