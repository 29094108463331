import React, { useEffect, useRef, useState } from "react";
import { EllipsisVerticalIcon, InformationCircleIcon } from "../../../assets/icons";
import MiscDataListItemMenu from "./MiscDataListItemMenu";
import MiscDataListItemInfo from "./MiscDataListItemInfo";
import MiscDataTag from "./MiscDataTag";
import MiscDataEditForm from "./Forms/MiscDataEditForm";
import StylesMark from "./StylesMark";

const MiscDataListItem = ({ item }) => {
  const [styleLoading, setStyleLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isOpen, setIsOpen] = useState(false);

  const popupRef = useRef(null);
  const tooltipRef = useRef(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  const showPopupMenu = () => {
    setShowMenu(true);
  };

  const handleTooltipClick = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
    setShowTooltip((prev) => !prev); // Toggle tooltip visibility
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowMenu(false);
    }
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const stylesMarkLoading = () => {
    if (styleLoading) {
      return <StylesMark isLoading={true} />;
    } else {
      return null;
    }
  };

  return (
    <li key={item.id} className="misc-data-list-item">
      <div className="misc-data-list-item--title" title={item.title}>
        {item.title}
      </div>
      {item.url && item.url.styles ? <StylesMark isLoading={false} /> : stylesMarkLoading()}
      <div className="misc-data-list-item-tags">
        {item.tags.map((tag) => {
          return <MiscDataTag key={tag.id} item={tag} mode={"short"} />;
        })}
      </div>

      <div className="misc-data-list-item--right">
        <button className="misc-data-menu-btn" onClick={handleTooltipClick}>
          <InformationCircleIcon className="w-5 h-5" />
        </button>
        {showTooltip && (
          <MiscDataListItemInfo
            ref={tooltipRef}
            style={{ top: mousePosition.y + 10, left: mousePosition.x + 10 }}
            item={item}
          />
        )}
        <button className="misc-data-menu-btn" onClick={showPopupMenu}>
          <EllipsisVerticalIcon className="w-5 h-5" />
        </button>
        {showMenu && (
          <MiscDataListItemMenu ref={popupRef} item={item} setStyleLoading={setStyleLoading} setIsOpen={setIsOpen} />
        )}
      </div>

      <MiscDataEditForm item={item} isOpen={isOpen} closeModal={closeModal} />
    </li>
  );
};

export default MiscDataListItem;
