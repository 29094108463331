import React, { useEffect, useState } from "react";

export const CustomAlert = ({ message, isVisible }) => {
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setIsFading(false);
    } else {
      setIsFading(true);
    }
  }, [isVisible]);

  const className = isFading ? "custom-alert fade-out" : "custom-alert";

  if (!isVisible && isFading) return null;

  return (
    <div
      className={`${className} flex justify-center items-center w-full h-full`}
    >
      <div className="bg-white/80 backdrop-blur-lg -mt-5 rounded-md p-1.5 px-3 text-base text-zinc-700 border border-zinc-200">
        {message}
      </div>
    </div>
  );
};
