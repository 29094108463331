import { XMarkIcon } from "@heroicons/react/20/solid";
import { ArrowUpTrayIcon, DocumentChartBarIcon } from "@heroicons/react/24/outline";
import React, { useRef, useState } from "react";
import { registerSchemaComponent } from "./fieldRegistration";
import { importFile } from "../../../api/importFile";
import { mangleFilename, patternsToExtensions } from "./SingleFileImportField";
import FieldDescription from "./FieldDescription";

export const FileImportField = ({ schemaFragment, value, setValue }) => {
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef(null);

  const fileRequirements = Object.entries(schemaFragment.properties).map(([key, subSchema]) => {
    // FIXME: What if a subschema has multiple types?!
    const validExtension = patternsToExtensions(subSchema)[0];
    return { extension: validExtension, quantity: 1 };
  });

  const FileDescriptions = () => {
    const fileDescriptions = Object.entries(schemaFragment.properties).map(([key, subSchema]) => {
      const description = subSchema.description;
      return { description, title: key };
    });

    return (
      <div className="file-descriptions flex flex-col space-y-2">
        {fileDescriptions.map(({ title, description }) => (
          <div key={title} className="file-description">
            <h3 className="font-medium">{title}</h3>
            <p className="text-sm">{description}</p>
          </div>
        ))}
      </div>
    );
  };

  const extensions = fileRequirements.map((requirement) => requirement.extension);
  const allowedFileTypes = extensions
    .map((ext) => ext.toLowerCase())
    .concat(extensions.map((ext) => ext.toUpperCase()));
  const allowedFileTypesStr = allowedFileTypes.join(", ");

  const validateFile = (file) => {
    const extension = file.name.split(".").pop();

    if (!allowedFileTypes.includes("." + extension.toLowerCase())) {
      setErrorMessage(`File type not allowed. Allowed types: ${allowedFileTypesStr}`);
      return false;
    }

    setErrorMessage(""); // if file is valid clear errormessage
    return true;
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files).filter(validateFile);
    const allFiles = mergeFiles(files, newFiles);
    updateFiles(allFiles);
  };

  const handleBrowse = (event) => {
    const newFiles = Array.from(event.target.files).filter(validateFile);
    const allFiles = mergeFiles(files, newFiles);
    updateFiles(allFiles);
  };

  const mergeFiles = (existingFiles, newFiles) => {
    const fileMap = new Map();
    existingFiles.forEach((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      fileMap.set(extension, file);
    });
    newFiles.forEach((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      fileMap.set(extension, file);
    });
    return Array.from(fileMap.values());
  };

  const updateFiles = (files) => {
    setFiles(files);
    const newValue = async () => {
      const urls = [];
      for (const file of files) {
        try {
          const request = await importFile(file, mangleFilename(file.name));
          urls.push(request.data.file);
        } catch (error) {
          console.error(`Error uploading file: ${file.name}`, error);
          setErrorMessage(`Sorry, there was an error uploading: ${file.name}`);
          urls.push(null);
        }
      }
      return Object.fromEntries(
        Object.entries(schemaFragment.properties).map(([key, subSchema]) => {
          const matchingFile = urls.filter((url) => url.match(subSchema.pattern))[0];
          return [key, matchingFile];
        })
      );
    };

    setValue(newValue);
  };

  const handleRemove = (e, indexToRemove) => {
    e.preventDefault();
    updateFiles(files.filter((_, index) => index !== indexToRemove));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="flex-grow flex flex-col gap-2">
      {errorMessage && <div className="text-red-500 text-sm">{errorMessage}</div>}

      <div
        className="w-full p-5 border-2 border-dotted border-zinc-300 bg-zinc-50
        text-zinc-500 rounded-md flex flex-col items-center justify-center text-sm 
        gap-3 hover:text-zinc-600 hover:border-emerald-700  hover:bg-emerald-700/5 transition ease-in-out 2s 
        cursor-pointer"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleClick}
      >
        <ArrowUpTrayIcon className="w-7 h-7" />
        <div className="flex gap-1.5 items-center">
          Drop or browse files{" "}
          <div className="flex gap-1.5 items-center text-sm">
            {fileRequirements.map((requirement) => (
              <div key={requirement.extension} className="rounded-md">
                {requirement.extension}
              </div>
            ))}
          </div>
        </div>

        <input
          type="file"
          multiple
          className="hidden"
          onChange={handleBrowse}
          ref={fileInputRef}
          accept={allowedFileTypesStr}
        />
      </div>

      <div className="col-span-6 flex text-xs text-zinc-600">
        {schemaFragment?.description}
        <FieldDescription fallbackDescription={<FileDescriptions />} />
      </div>

      <div className="font-medium my-1 text-sm flex gap-2 items-center justify-between">
        Ready to upload {files.length}
      </div>

      {files.map((file, index) => (
        <div key={index} className="bg-emerald-700/5 rounded-md p-2 text-sm text-zinc-800 flex gap-2 items-center">
          <DocumentChartBarIcon className="w-5 h-5 text-emerald-700" />
          {file.name}
          <span className="text-zinc-500">{(file.size / 1024).toFixed(2)} kb</span>
          <button onClick={(e) => handleRemove(e, index)} className="ml-auto text-zinc-500 hover:text-zinc-900">
            <XMarkIcon className="w-5 h-5" />
          </button>
          <input value={file.name} hidden readOnly />
        </div>
      ))}
    </div>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment && schemaFragment.type === "object" && schemaFragment["x-format"] === "multi-url";
}, FileImportField);
