import { useSafeQuery } from "./useSafeQuery";
import client from "../../api/client";

export const fetchEnvironments = async () => {
  const { data } = await client.get(`/api/environments`);
  return data;
};


export const useEnvironments = () => {
  return useSafeQuery(["environments"], () =>
    fetchEnvironments()
  );
};


export const fetchEnvironment = async ({ environmentId }) => {
  if (environmentId === undefined) return undefined;
  const { data } = await client.get(
    `/api/environments/${environmentId}`
  );
  return data;
};

export const fetchEnvironmentProgress = async ({ environmentId }) => {
  if (environmentId === undefined) return undefined;
  const { data } = await client.get(
    `/api/environments/${environmentId}/progress`
  );
  return data;
};

export const useEnvironment = (environmentId) => {
  return useSafeQuery(["environments", environmentId], () =>
    fetchEnvironment({ environmentId })
  );
};

export const useEnvironmentProgress = (environmentId) => {
  return useSafeQuery(["environments", environmentId, "progress"], () =>
    fetchEnvironmentProgress({ environmentId })
  );
};
