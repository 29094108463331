import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../api/client";
import { error2message } from "./api/useSafeQuery";

const useUpdateGroup = (onClose, activeNode, addMessage) => {
  const queryClient = useQueryClient();

  const projectId = activeNode[0];

  const updateGroupMutation = useMutation({
    mutationFn: (data) =>
      client.put(`/api/projects/${projectId}/groups/${activeNode[1]}`, {
        title: data.title,
        description: data.description,
        project: projectId,
        color: data.color,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries("groups");
      if (typeof onClose === "function") {
        onClose();
      }
    },
    onError: (error) => {
      onClose();
      if (error.response.status === 401) {
        const errorMessage = {
          title: error.response.statusText,
          message: error.response.data.message,
        };

        addMessage({
          title: errorMessage.title,
          shortMessage: errorMessage.message,
          type: "notice",
          message: errorMessage.message,
        });
      } else {
        addMessage(error2message(error));
        console.error("error", error);
      }
    },
  });

  const handleFormSubmit = (data) => {
    updateGroupMutation.mutate(data);
  };

  return {
    updateGroupMutation,
    handleFormSubmit,
  };
};

export default useUpdateGroup;
