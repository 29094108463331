import { colorNotInUse, colorSignActive, colorPalette } from "./colors";
import {
  markerSize,
  titleDatasetChannel,
  schemaDatasetChannelStyle,
  channel2col,
  channel2inusecol,
  channel2stdcol,
  channel2system,
  getGateTimes,
  getInUseColumn,
} from "./utils";

const genericGates = (unitDipole) => {
  return {
    title: titleDatasetChannel,
    xaxis: "xdist",
    yaxis: unitDipole ? "dbdt" : "dbdtmoment",
    fn: function (context, args, elements) {
      const { nodeBinaryByLine, selectedLineId, manualEdits } = context;
      const binary = nodeBinaryByLine?.[args.dataset || "measured"]?.[selectedLineId];
      if (!binary?.flightlines) return [];
      var traces = [];
      const channel = channel2col(args.channel);
      const inUseChannel = channel2inusecol(args.channel);
      const stdChannel = channel2stdcol(args.channel);
      const channelnr = parseInt(channel.replace(/[^0-9]*([0-9]*)[^0-9]*/, "$1"));
      const gatetimes = getGateTimes(binary, "Channel" + channelnr.toString());

      var xdist = binary.flightlines.xdist;
      for (let col in binary.layer_data[channel]) {
        const inUse = getInUseColumn(binary, manualEdits?.[selectedLineId], inUseChannel, col);

        const systemChannel = channel2system(args.channel);
        const moment = unitDipole ? 1 : binary.system[systemChannel].ApproxDipoleMoment;

        const ys = binary.layer_data[channel][col].map((y) => Math.abs(y) * binary.model_info.scalefactor * moment);

        const ypos = binary.layer_data[channel][col].map((a) => a >= 0);

        const ystd = binary.layer_data[stdChannel] !== undefined ? binary.layer_data[stdChannel][col] : binary.layer_data[channel][col].map((a) => NaN);

        const gatetime = gatetimes[col].toExponential(3);
        const name = `${args.channel} gate ${col} @ ${gatetime}s`;

        /*
          0: negative, not in use
          1: positive, not in use
          2: negative, in use
          3: positive, in use
        */

        const ysOriginal = binary.layer_data[channel][col].map((y) => y * binary.model_info.scalefactor * moment);

        const color_idxs = args.showNegative
          ? ypos.map((p, idx) => p + 2 * inUse[idx])
          : ypos.map((p, idx) => 2 * inUse[idx]);

        const lineWidth = 1;
        const style = args.style === "lines" ? "lines+markers" : args.style || "lines+markers";

        traces.push.apply(
          traces,
          [0, 1, 2, 3].map((color_idx) => {
            const yValues = ys.map((d, idx) =>
              (color_idxs[idx] === color_idx &&
                (color_idxs[idx - 1] >= color_idx || color_idxs[idx + 1] >= color_idx)) ||
              (color_idxs[idx] > color_idx && (color_idxs[idx - 1] === color_idx || color_idxs[idx + 1] === color_idx))
                ? d
                : NaN
            );

            const errorArray = args.showError
              ? ys.map((d, idx) =>
                  inUse[idx] === 1 && (inUse[idx - 1] === 1 || inUse[idx + 1] === 1) ? d * (1 + ystd[idx]) - d : NaN
                )
              : null;

            const errorArrayMinus = args.showError
              ? ys.map((d, idx) =>
                  inUse[idx] === 1 && (inUse[idx - 1] === 1 || inUse[idx + 1] === 1) ? d - d / (1 + ystd[idx]) : NaN
                )
              : null;

            const errorAmplitudes = ystd.map((error) => (error * 100).toFixed(2));

            const xUnit = elements.xaxis[this.xaxis].shortTitle;
            const yUnit = elements.yaxis[this.yaxis].shortTitle;

            elements.yaxis[this.yaxis].exponentformat = "power";
            elements.xaxis[this.xaxis].exponentformat = "power";
            elements.yaxis[this.yaxis].tickfont = {
              size: 10,
            };
            elements.xaxis[this.xaxis].tickfont = {
              size: 12,
            };
            //elements.yaxis[this.yaxis].range = [-10, 10];

            //1100m, 253±3% dBdt @ 5e-6s [Gate05] |||> Ch01

            //1100m, 53°

            const customData = Array.from(errorAmplitudes).map((amplitude, idx) => {
              return {
                error: amplitude,
                yValue: ysOriginal[idx],
              };
            });

            const hoverTemplate = `%{x:.1f} ${xUnit}, %{customdata.yValue:.5e} ± %{customdata.error}% ${yUnit} @ ${gatetime} s [Gate${String(
              col
            ).padStart(2, "0")}]<extra>Ch${String(channelnr).padStart(2, "0")} ${args.dataset}</extra>`;

            let trace = {
              type: "scattergl",
              mode: style,
              marker: {
                size: args.style === "lines" ? 0.000001 : markerSize,
              },
              legendgroup: args.channel,
              name: name,
              showlegend: false,
              x: xdist,
              y: yValues,
              gate: col,
              error_y:
                args.showError
                  ? {
                      type: "data",
                      symmetric: false,
                      array: errorArray,
                      arrayminus: errorArrayMinus,
                      width: 1,
                      thickness: 1,
                    }
                  : null,
              hoverinfo: context.showHover ? "all" : "none",
              hovertemplate: context.showHover ? hoverTemplate : "",
              customdata: customData,
            };

            return trace;
          })
        );
        const applyColors = (traces) => {
          let colorIdx2Counter = 0; // Counter for dynamic color assignment for color_idx 2
          return traces.map((trace, index) => {
            let selectedColor; // Color to be determined based on conditions

            // Directly use dynamic color for the general case
            let dynamicColor = colorPalette[colorIdx2Counter % colorPalette.length];

            if (args.showNegative) {
              // Define specific colors for when args.showNegative is true
              const conditionColor = colorSignActive;

              selectedColor = conditionColor[index % conditionColor.length];
            } else {
              // For color_idx 2 when args.showNegative is false, assign dynamically
              if (index % 4 === 2) {
                selectedColor = dynamicColor; // Use the dynamically selected color
                colorIdx2Counter++; // Increment counter for the next dynamic color
              } else {
                // Default/fallback color for other cases
                selectedColor = colorNotInUse; // zinc
              }
            }

            // Apply the selected color with a consistent lineWidth for all traces
            trace.line = {
              color: selectedColor,
              width: lineWidth,
            };

            return trace;
          });
        };
        applyColors(traces);
      }

      return traces;
    },

    schema: (context) => {
      const res = schemaDatasetChannelStyle(context);
      res.required.push("showError");
      res.properties.showError = {
        type: "boolean",
        default: false,
      };
      res.properties.showNegative = {
        type: "boolean",
        default: false,
      };
      return res;
    },
    onClick: (ev, context, args) => {
      if (ev.event.button !== 2) return;
      if (ev.event.altKey || ev.event.ctrlKey || ev.event.shiftKey) return;
      ev.event.preventDefault();

      this.apply_selections(context, { value: null }, ev.points, "set_in_use");
    },
    apply_selections: (context, args, points, action) => {
      const [[action_name, action_args]] = Object.entries(action);
      if (action_name === "set_in_use") {
        let { value } = action_args;

        const inUseChannel = channel2inusecol(args.channel);

        const { nodeBinaryByLine, manualEdits, setManualEdits, selectedLineId } = context;

        console.log("context", context);

        if (manualEdits[selectedLineId] === undefined)
          manualEdits[selectedLineId] = {
            model_info: { source: "Manual edit", diff_dummy: -1 },
            flightlines: { apply_idx: [] },
            layer_data: {},
          };

        const lineEdit = manualEdits[selectedLineId];
        if (lineEdit.layer_data[inUseChannel] === undefined) {
          lineEdit.layer_data[inUseChannel] = {};
        }
        const inUseTable = lineEdit.layer_data[inUseChannel];

        const binary = nodeBinaryByLine?.[args.dataset || "measured"]?.[selectedLineId];

        points.forEach((point) => {
          let editIdx = lineEdit.flightlines.apply_idx.indexOf(point.pointIndex);
          if (editIdx === -1) {
            editIdx = lineEdit.flightlines.apply_idx.length;
            Object.values(lineEdit.flightlines).map((col) => col.push(-1));
            Object.values(lineEdit.layer_data).map((table) => Object.values(table).map((col) => col.push(-1)));
            lineEdit.flightlines.apply_idx[editIdx] = point.pointIndex;
          }

          if (inUseTable[point.data.gate] === undefined) {
            inUseTable[point.data.gate] = [];
            for (var i = 0; i < lineEdit.flightlines.apply_idx.length; i++) {
              inUseTable[point.data.gate].push(-1);
            }
          }
          if (value === null) {
            if (isNaN(inUseTable[point.data.gate][editIdx])) {
              inUseTable[point.data.gate][editIdx] =
                !binary.layer_data[inUseChannel][point.data.gate][point.pointIndex];
            } else {
              inUseTable[point.data.gate][editIdx] = !inUseTable[point.data.gate][editIdx];
            }
          } else {
            inUseTable[point.data.gate][editIdx] = Number(value);
          }
        });

        setManualEdits({ ...manualEdits });
      }
    },
  };
};

export const gates = genericGates(true);
export const gatesDipoleMoment = genericGates(false);
