import React, { useEffect, useState } from "react";
import MiscDataList from "./MiscDataList";
import { ChevronDownIcon, ChevronRightIcon } from "../../../assets/icons";

const RenderMiscData = ({ data }) => {
  const [openGroups, setOpenGroups] = useState({}); // Object to track which groups are open

  useEffect(() => {
    const initialOpenGroups = Object.keys(data).reduce((acc, title) => {
      acc[title] = true;
      return acc;
    }, {});
    setOpenGroups(initialOpenGroups);
  }, [data]);

  const toggleGroup = (title) => {
    setOpenGroups((prev) => ({
      ...prev,
      [title]: !prev[title], // Toggle the visibility for the clicked title
    }));
  };

  return (
    <div>
      {Object.entries(data).map(([title, dataList]) => (
        <div key={title} className="group-container">
          <div
            className="group-title cursor-pointer flex justify-between items-center"
            style={{ backgroundColor: openGroups[title] && "#f0f0f0" }}
            onClick={() => toggleGroup(title)}
          >
            <span>{title}</span>
            {openGroups[title] ? <ChevronDownIcon className="w-5 h-5" /> : <ChevronRightIcon className="w-5 h-5" />}
          </div>
          {openGroups[title] && <MiscDataList data={dataList} />}
        </div>
      ))}
    </div>
  );
};

export default RenderMiscData;
