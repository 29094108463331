// NodeStatusContext.js
import React, { createContext, useState } from "react";

export const NodeStatusContext = createContext();

export function NodeStatusProvider({ children }) {
  const [statusUpdates, setStatusUpdates] = useState({});
  /*
  console.log("NODESTATUSPROVIDER");

  /*
  useEffect(() => {
    const wsProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
    const wsHost = "127.0.0.1:8000"; // Change this later
    const wsUrl = `${wsProtocol}//${wsHost}/ws/node-status/`;
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      console.log("NODESTATUSPROVIDER: Connection opened");
      ws.send(JSON.stringify({ message: "Client connected" }));

      setInterval(() => {
        ws.send(JSON.stringify({ type: "ping" }));
      }, 50000);
    };

    ws.onmessage = (event) => {
      const { type, dataType, dataId, message } = JSON.parse(event.data);

      if (type === "pong") {
        console.log("Pong sent");
      }

      if (message === "done" && dataId != null) {
        const numericDataId = parseInt(dataId, 10);
        console.error(
          "NODESTATUSPROVIDER: STATUS DONE for",
          dataType,
          "with ID",
          numericDataId
        );

        setStatusUpdates((prev) => ({
          ...prev,
          [dataType]: [...(prev[dataType] || []), numericDataId].filter(
            Boolean
          ), // Filter out falsy values and add the numeric ID
        }));
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    ws.onclose = () => {
      console.log("NODESTATUSPROVIDER: Connection closed");
    };

    return () => {
      ws.close();
    };
  }, []);

  */

  return (
    <NodeStatusContext.Provider value={{ statusUpdates, setStatusUpdates }}>
      {children}
    </NodeStatusContext.Provider>
  );
}
