export const fake0 = {
  title: (context, args) => "fake1",
  fn: function (context, args) {
    return [
      {
        type: "scattergl",
        x: [0, 1, 2],
        y: [0, 2, 1],
      },
    ];
  },
  xaxis: "fake",
  yaxis: "fake",
  schema: (context) => {
    return {
      type: "object",
      required: [],
      properties: {},
      additionalProperties: false,
    };
  },
};

export const fake1 = {
  title: (context, args) => "fake1",
  fn: function (context, args) {
    return [
      {
        type: "scattergl",
        name: "fake1",
        x: [0, 1, 2],
        y: [1, 0.5, 0.2],
        mode: "markers",
        marker: {
          color: [1, 2, 0],
          coloraxis: "test",
        },
      },
    ];
  },
  xaxis: "fake",
  yaxis: "fake",
  schema: (context) => {
    return {
      type: "object",
      required: ["fruit"],
      properties: {
        fruit: {
          type: "string",
          enum: ["apple", "banana", "melon"],
        },
      },
      additionalProperties: false,
    };
  },
};

export const fake2 = {
  title: (context, args) => "fake2",
  fn: function (context, args) {
    return [
      {
        type: "scattergl",
        name: "fake2",
        x: [0, 1, 2],
        y: [0.5, 0, 1],
        mode: "markers",
        marker: {
          color: [2, 3, 4],
          coloraxis: "test",
        },
      },
    ];
  },
  xaxis: "fake",
  yaxis: "fake",
  schema: (context) => {
    return {
      type: "object",
      required: ["fruit", "veggie"],
      properties: {
        fruit: {
          type: "string",
          enum: ["apple", "banana", "melon"],
        },
        veggie: {
          type: "string",
          enum: ["potatoe", "carrot"],
        },
      },
      additionalProperties: false,
    };
  },
};

export const fake3 = {
  title: (context, args) => "fake2",
  fn: function (context, args) {
    return [
      {
        type: "scattergl",
        name: "fake3",
        x: [0, 1, 2],
        y: [0.2, 2, 1.5],
      },
    ];
  },
  xaxis: "fake",
  yaxis: "fake",
  schema: (context) => {
    return {
      type: "object",
      required: ["fruit", "veggie", "land"],
      properties: {
        fruit: {
          type: "string",
          enum: ["apple", "banana", "melon"],
        },
        veggie: {
          type: "string",
          enum: ["potatoe", "carrot"],
        },
        land: {
          type: "string",
          enum: ["norway", "sweden", "denmark"],
        },
      },
      additionalProperties: false,
    };
  },
};

export const fake4 = {
  title: (context, args) => "fake2",
  fn: function (context, args) {
    return [
      {
        type: "scattergl",
        name: "fake4",
        x: [0, 1, 2],
        y: [1.2, 1.2, 1.7],
      },
    ];
  },
  xaxis: "fake",
  yaxis: "fake",
  schema: (context) => {
    return {
      type: "object",
      required: ["fruit", "veggie", "land", "color"],
      properties: {
        fruit: {
          type: "string",
          enum: ["apple", "banana", "melon"],
        },
        veggie: {
          type: "string",
          enum: ["potatoe", "carrot"],
        },
        land: {
          type: "string",
          enum: ["norway", "sweden", "denmark"],
        },
        color: {
          type: "string",
          enum: ["red", "green", "blue"],
        },
      },
      additionalProperties: false,
    };
  },
};

export default fake0;
