import React, { Fragment } from "react";
import { registerSchemaComponent } from "../fieldRegistration";
import { Combobox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { FormLabel } from "../../../common/forms/Form";

export const CustomSelectorStep = ({
  schemaFragment,
  value,
  propKey,
  setValue,
}) => {
  const predefinedItems = schemaFragment?.anyOf?.[0]?.enum || [];

  const handleTitleChange = (item) => {
    if (!value.includes(item)) {
      setValue([item]);
    }
  };

  return (
    <div className="block grid grid-cols-6 gap-2 items-center mt-1">
      <div className="col-span-2 flex items-center">
        <FormLabel label={propKey} htmlFor={propKey} fontWeight="font-normal" />
      </div>
      <div className="col-span-4 flex items-center">
        <Combobox
          value={value[0] || ""}
          onChange={(item) => {
            if (!value.includes(item)) {
              setValue([item]);
            }
          }}
        >
          <div className="relative">
            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left rounded-md">
              <Combobox.Input
                placeholder="Select or type custom + Enter"
                className="relative w-72 flex items-center gap-2 justify-between text-sm bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
                displayValue={(item) => item}
                onChange={(event) => handleTitleChange(event.target.value)}
                onKeyDown={(event) => {
                  if (
                    event.key === "Enter" &&
                    event.target.value.trim() !== ""
                  ) {
                    event.preventDefault();
                    if (!value.includes(event.target.value.trim())) {
                      setValue([event.target.value.trim()]);
                    }
                  }
                }}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-4 w-4 text-zinc-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => {}}
            >
              <Combobox.Options className="absolute mt-1 max-h-60 z-30 w-72 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {predefinedItems.map((item, index) => (
                  <Combobox.Option
                    key={index}
                    value={item}
                    className={({ active }) =>
                      ` flex items-center gap-2 relative cursor-default select-none py-2 p-4 hover:bg-zinc-100 text-zinc-900 ${
                        active ? "text-zinc-800 bg-zinc-100" : "text-zinc-900"
                      }`
                    }
                  >
                    {item}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
};
registerSchemaComponent(({ schemaFragment }) => {
  return (
    schemaFragment &&
    schemaFragment?.anyOf &&
    schemaFragment?.anyOf?.length === 2 &&
    schemaFragment?.anyOf?.[0]?.enum &&
    schemaFragment?.anyOf?.[1]?.type === "string"
  );
}, CustomSelectorStep);
