export const getGateTimes = function (binary, channel) {
  if (!binary || !binary.system || !binary.system[channel]) {
    throw new Error("Invalid binary or channel");
  }

  var no_gates = binary.system[channel].NoGates;
  var remove_gates_from = binary.system[channel].RemoveGatesFrom || 0;
  var gex = binary.system;
  return gex.General.GateTime[0].slice(remove_gates_from, remove_gates_from + no_gates).map(function (i) {
    return i + gex[channel].GateTimeShift + (gex[channel].MeaTimeDelay || 0.0);
  });
};

export const appendTypedArrays = function (arrays) {
  const res = new arrays[0].constructor(arrays.map((a) => a.length).reduce((a, b) => a + b));
  var pos = 0;
  for (var idx = 0; idx < arrays.length; idx++) {
    res.set(arrays[idx], pos);
    pos += arrays[idx].length;
  }
  return res;
};

export const makeLineFilt = (binary, selectedLineId) => {
  if (
    selectedLineId != null &&
    binary?.flightlines.Line.length > 0 &&
    typeof binary?.flightlines.Line[0] === "bigint"
  ) {
    selectedLineId = BigInt(selectedLineId);
  }
  return (data) => {
    return data.filter(function (el, idx) {
      return binary?.flightlines.Line[idx] === selectedLineId;
    });
  };
};

export const markerSize = 3;

export const col2channel = (name) => {
  return name.replace(/Gate_Ch(.*)/, "[$1]");
};

export const channel2col = (name) => {
  return name.replace(/\[(.*)\]/, "Gate_Ch$1");
};

export const channel2inusecol = (name) => {
  return name.replace(/\[(.*)\]/, "InUse_Ch$1");
};
export const channel2stdcol = (name) => {
  return name.replace(/\[(.*)\]/, "STD_Ch$1");
};

export const channel2system = (name) => {
  return name.replace(/\[0*(.*)\]/, "Channel$1");
};

export const titleDatasetChannel = function (context, args) {
  return args.dataset + col2channel(args.channel);
};

const _schemaDatasetChannelStyle = (context, level) => {
  const { nodeBinaryByLine } = context;
  if (!nodeBinaryByLine) return {};

  const lines = Object.keys(Object.values(nodeBinaryByLine)[0]);
  const datasets = Object.keys(nodeBinaryByLine || {}).filter(
    (dataset) => !!nodeBinaryByLine[dataset]?.[lines[0]].layer_data.Gate_Ch01
  );
  const required = [];
  const properties = {};
  if (level > 0) {
    required.push("dataset");
    properties.dataset = {
      type: "string",
      enum: datasets,
    };
    if (level > 1) {
      required.push("channel");

      let channels = [];
      if (datasets.length > 0) {
        channels = Object.keys(nodeBinaryByLine[datasets[0]]?.[lines[0]].layer_data)
          .filter((col) => col.indexOf("Gate_Ch") === 0)
          .map(col2channel);
      }

      properties.channel = {
        type: "string",
        enum: channels,
      };
      if (level > 2) {
        required.push("style");
        properties.style = {
          type: "string",
          enum: ["lines", "markers", "lines+markers"],
        };
      }
    }
  }

  return {
    type: "object",
    required: required,
    properties: properties,
    additionalProperties: false,
  };
};

export const schemaDatasetChannelStyle = (context) => {
  return _schemaDatasetChannelStyle(context, 3);
};

export const schemaDatasetChannel = (context) => {
  return _schemaDatasetChannelStyle(context, 2);
};

export const schemaDataset = (context) => {
  return _schemaDatasetChannelStyle(context, 1);
};

export const getInUseColumn = (binary, editBinary, inUseChannel, col) => {
  let inUse;
  if (binary.layer_data[inUseChannel] === undefined) {
    inUse = Int8Array.from(binary.flightlines.xdist.map((d) => 1));
  } else {
    inUse = Int8Array.from(Array.from(binary.layer_data[inUseChannel][col]).map(Number));
  }

  const manualEditCol = editBinary?.layer_data?.[inUseChannel]?.[col];
  if (manualEditCol) {
    editBinary.flightlines.apply_idx.forEach((apply_idx, editIdx) => {
      if (manualEditCol[editIdx] !== -1) {
        inUse[apply_idx] = manualEditCol[editIdx];
      }
    });
  }
  return inUse;
};
