import React from "react";
import Tooltip from "./Tooltip";
import { Link } from "react-router-dom";

const TooltipButton = ({
  onClick,
  to,
  tooltipText,
  icon,
  tooltipPlacement = "bottom",
  componentType = "button",
  tooltipSpacing,
  target,
  onMouseEnter,
  onMouseLeave,
  additionalClasses = "",
}) => {
  let Component;
  let componentProps;

  if (componentType === "link") {
    Component = Link;
    componentProps = { to, onClick, onMouseEnter, onMouseLeave };
  } else if (componentType === "externalLink") {
    Component = "a";
    componentProps = {
      href: to,
      target,
      rel: target === "_blank" ? "noopener noreferrer" : undefined,
    };
  } else {
    Component = "button";
    componentProps = { onClick, onMouseEnter, onMouseLeave };
  }

  return (
    <Tooltip
      placement={tooltipPlacement}
      tooltipText={tooltipText}
      tooltipSpacing={tooltipSpacing}
    >
      <Component
        className={`tooltip-button ${additionalClasses}`}
        {...componentProps}
      >
        {icon}
      </Component>
    </Tooltip>
  );
};

export default TooltipButton;
