import { useQueryClient } from "@tanstack/react-query";
import client from "../api/client";

const useUpdateProject = (onClose, projectId) => {
  const queryClient = useQueryClient();

  const handleFormSubmit = async (data) => {
    try {
      await client.put(`/api/projects/${projectId}`, {
        title: data.title,
      });
      queryClient.invalidateQueries("projects");
      if (typeof onClose === "function") {
        onClose();
      }
    } catch (err) {
      console.error("Error updating project:", err);
    }
  };

  return {
    handleFormSubmit,
  };
};

export default useUpdateProject;
