import { ErrorBoundary } from "react-error-boundary";
import { MessagesContext } from "../../contexts/MessagesContext";
import { useContext, useEffect } from "react";

export const FallbackRender = ({ error, resetErrorBoundary }) => {
  const { addMessage } = useContext(MessagesContext);

  useEffect(() => {
    let title = error;
    if (typeof title != "string") {
      title = JSON.stringify(title);
    }
    addMessage({
      title: title,
      retry: resetErrorBoundary,
    });
  }, [error]); // eslint-disable-line

  return "ERROR";
};

export const ErrorHandler = ({ children }) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <FallbackRender error={error} resetErrorBoundary={resetErrorBoundary} />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorHandler;
