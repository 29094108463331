import React from "react";

const StylesMark = ({ isLoading }) => {
  return (
    <div
      className={`styles-mark flex ${isLoading ? "loading" : ""}`}
      title="This raster has applied styles"
      aria-label="Styles applied"
    >
      <div className="h-3 w-3 rounded-full bg-green-500/60 "></div>
      <div className="h-3 w-3 rounded-full bg-blue-500/60 -ml-1.5"></div>
      <div className="h-3 w-3 rounded-full bg-rose-500/60 -ml-1.5"></div>
    </div>
  );
};

export default StylesMark;
