import { useSafeQuery } from "./useSafeQuery";
import { fetchTemplates } from "../../api/fetchTemplates";

const filterAndSortData = (data) => {
  const filteredData = data.filter(
    (item) => (item.status !== "failed"
               && item.show_in_ui !== "False"));
  filteredData.sort((a, b) => {
    if (b.is_global_template !== a.is_global_template) {
      return b.is_global_template - a.is_global_template;
    } else {
      return new Date(b.date) - new Date(a.date);
    }
  });
  return filteredData;
};

export const useProcessingTemplates = (projectId) => {
  return useSafeQuery(["processing-templates", projectId], async () => {
    return filterAndSortData(
      await fetchTemplates({ projectId: projectId, type: "processing" }));
  });
};

export const useInversionTemplates = (projectId) => {
  return useSafeQuery(["inversion-templates", projectId], async () => {
    return filterAndSortData(
      await fetchTemplates({ projectId: projectId, type: "inversion" }));
  });
};
