import React, { createContext, useState } from "react";

export const NodeInfoContext = createContext();

export const NodeInfoProvider = ({ children }) => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

  return (
    <NodeInfoContext.Provider value={{ isSidePanelOpen, setIsSidePanelOpen }}>
      {children}
    </NodeInfoContext.Provider>
  );
};
