import React, { useCallback, useContext, useRef, useState } from "react";

import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { MessagesContext, NodeDataContext } from "../../../../../../contexts";
import TooltipButton from "../../../../../common/TooltipButton";
import { useUpdateInversion } from "../../../../../../hooks/useUpdateInversion";
import useUpdateProcessing from "../../../../../../hooks/useUpdateProcessing";
import { useNavigate } from "react-router-dom";
import { useActiveProject } from "../../../../../../hooks/useActiveProject";
import ToolbarTooltipPanel from "../ToolbarTooltipPanel";
import Form, { FormInput } from "../../../../../common/forms/Form";
import { diffSummaryText } from "../../../../../../api/fetchBinary";
import Spinner from "../../../../../common/ui/Spinner";
import { useQueryClient } from "@tanstack/react-query";
import { error2message } from "../../../../../../hooks/api/useSafeQuery";
import { SavenUpdateIcon } from "../../../../../../assets/icons";

export const UpdateProcessingVersion = () => {
  return (
    <ToolbarTooltipPanel
      content={<ApplyManualEditsForm />}
      tooltipText="Apply manual edits"
      tooltipSpacing={"3"}
      icon={<SavenUpdateIcon />}
      width={"250px"}
    />
  );
};

export const UpdateInversionVersion = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { projectTitle } = useActiveProject();

  const { updateInversion } = useUpdateInversion();

  const handleClick = useCallback(() => {
    updateInversion({
      onSuccess: (data) => {
        navigate(`/${projectTitle}/workspace`);
        queryClient.invalidateQueries("nodeData");
      },
    });
  }, [updateInversion, navigate, projectTitle, queryClient]);

  return (
    <TooltipButton
      onClick={handleClick}
      tooltipText="Rerun with newest processing"
      tooltipSpacing={"3"}
      icon={<ArrowPathIcon className="h-5 w-5" />}
    />
  );
};

const ApplyManualEditsForm = () => {
  const queryClient = useQueryClient();
  const editTitleRef = useRef();
  const { nodeBinaryByLine, manualEdits } = useContext(NodeDataContext);
  const navigate = useNavigate();
  const { projectTitle } = useActiveProject();
  const [isLoading, setIsLoading] = useState(false);
  const { addMessage } = useContext(MessagesContext);

  const onProcessingSuccess = (data) => {
    setIsLoading(false);
    navigate(`/${projectTitle}/workspace`);
    queryClient.invalidateQueries("nodeData");
  };

  let title = "";
  if (nodeBinaryByLine?.processed && manualEdits) {
    title = Object.entries(manualEdits)
      .map(([line, manualEdit]) => diffSummaryText(nodeBinaryByLine.processed[line], manualEdit))
      .join(", ");
  }

  const update = useUpdateProcessing({ onProcessingSuccess });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await update(editTitleRef.current.value);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 401) {
        setIsLoading(false);

        const errorMessage = {
          title: error.response.statusText,
          message: error.response.data.message,
        };

        addMessage({
          title: errorMessage.title,
          shortMessage: errorMessage.message,
          type: "notice",
          message: errorMessage.message,
        });
      } else {
        addMessage(error2message(error));
        setIsLoading(false);
        console.error("error", error);
      }
    }
  };

  return (
    <div className="p-2">
      {title === "" ? (
        <div className="text-zinc-600">No edits done</div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <FormInput ref={editTitleRef} name="Name" placeholder="Describe edits for reference" defaultValue={title} />

          <button
            type="submit"
            className="w-full flex items-center gap-2 justify-center bg-zinc-600 text-white text-sm px-4 py-1.5 rounded-md hover:bg-zinc-700"
          >
            {isLoading ? (
              <div className="flex items-center p-0.5">
                <Spinner borderColor="rgba(255, 255, 255, 0.5)" borderTopColor="white" />
              </div>
            ) : (
              <div className="flex items-center gap-1">
                Apply <ArrowPathIcon className="h-4 w-4" />
              </div>
            )}
          </button>
        </Form>
      )}
    </div>
  );
};
