import React, { useRef } from "react";

const RangeSlider = ({ max, start, end, setStart, setEnd }) => {
  const sliderRef = useRef(null);

  console.log("MAX", max);

  const handleMouseDown = (thumb, e) => {
    e.stopPropagation();
    e.preventDefault();

    const slider = sliderRef.current;
    const sliderRect = slider.getBoundingClientRect();

    const onMouseMove = (event) => {
      event.stopPropagation();
      event.preventDefault();

      const newValue = Math.min(
        max,
        Math.max(
          0,
          ((event.clientX - sliderRect.left) / sliderRect.width) * max
        )
      );

      if (thumb === "start" && newValue < end) {
        setStart(Math.round(newValue));
      } else if (thumb === "end" && newValue > start) {
        setEnd(Math.round(newValue));
      }
    };

    const onMouseUp = (event) => {
      event.stopPropagation();
      event.preventDefault();

      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  return (
    <div className="w-full max-w-96 py-8 pl-1 pr-1">
      <div className="flex gap-3 items-center w-full">
        <div className="text-zinc-500 text-xs hidden">{"0 "}</div>
        <div
          className="relative w-full h-1 bg-zinc-300 rounded-full"
          ref={sliderRef}
        >
          <div
            className="absolute h-1 bg-emerald-900/60 rounded-full"
            style={{
              left: `${(start / max) * 100}%`,
              width: `${((end - start) / max) * 100}%`,
            }}
          ></div>
          <div
            className="absolute w-5 h-5 rounded-full bg-emerald-900 border-4 border-emerald-900 cursor-pointer -mt-2 group transition ease-in .2s"
            style={{
              left: `${(start / max) * 100}%`,
              transform: "translateX(-50%)",
            }}
            onMouseDown={(e) => handleMouseDown("start", e)}
          >
            <div className="absolute -mt-7 -ml-1.5 w-6 rounded-md text-center text-sm bg-zinc-200 text-zinc-800 no-arrows">
              {start}
            </div>
          </div>
          <div
            className="absolute w-5 h-5 bg-emerald-900 border-4 border-emerald-900 rounded-full cursor-pointer -mt-2 group transition ease-in .2s"
            style={{
              left: `${(end / max) * 100}%`,
              transform: "translateX(-50%)",
            }}
            onMouseDown={(e) => handleMouseDown("end", e)}
          >
            <div className="absolute -mt-7 -ml-1.5 w-6 rounded-md text-center text-sm  bg-zinc-200 text-zinc-800 no-arrows">
              {end}
            </div>
          </div>
        </div>
        <div className="text-zinc-500 text-xs hidden">{max}</div>
      </div>
    </div>
  );
};

export default RangeSlider;

/* 
  const [range, setRange] = useState([0, 27]);

  const handleRangeChange = (start, end) => {
    setRange([start, end]);
  };

  <RangeSlider max={100} onRangeChange={handleRangeChange} />
  
  */
