import React, { useState } from "react";
import { NiceJsonForm } from "../components/workspace/NiceJsonForm";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

const jsonParse = (value) => {
  const replaceUndefined = (value) => {
    if (typeof value !== "object") return value;
    if (value === null) return value;
    if (value?.constructor?.prototype?.map !== undefined) {
      return value.map(replaceUndefined);
    }
    if (value?.__jsonclass__?.[0] === "undefined") return undefined;
    return Object.fromEntries(
      Object.entries(value).map(([name, subValue]) => [
        name,
        replaceUndefined(subValue),
      ])
    );
  };

  return replaceUndefined(
    JSON.parse(value.replace("undefined", '{"__jsonclass__": ["undefined"]}'))
  );
};

const NiceJsonEditorPage = () => {
  const [steps, setSteps] = useState(undefined);
  const [schema, setSchema] = useState(null);
  const [schemaInput, setSchemaInput] = useState("");
  const [stepsJson, setStepsJson] = useState("undefined");

  const handleSchemaChange = (event) => {
    setSchemaInput(event.target.value);
  };

  const handleJsonChange = (event) => {
    setStepsJson(event.target.value);
  };

  const setValueFromJson = () => {
    setSteps(jsonParse(stepsJson));
  };

  const setFormValue = (value) => {
    console.log("new value", value);
    setSteps(value);
    setStepsJson(JSON.stringify(value, undefined, 2));
  };

  const handleSchemaSubmit = (event) => {
    event.preventDefault();
    console.log("schema submit", schemaInput);
    if (!schemaInput.trim()) {
      setSchema(null);
      return;
    }
    try {
      const parsedSchema = JSON.parse(schemaInput);
      setSchema(parsedSchema);
    } catch (error) {
      alert("Invalid JSON schema");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.shiftKey === false) {
      event.preventDefault();
      handleSchemaSubmit(event);
    }
  };

  const clearSchema = () => {
    console.log("Clear schema");
    setSchemaInput("");
    setStepsJson("");
  };

  return (
    <div className="nice-json-editor-page h-screen">
      <div className="flex flex-col h-full">
        <div className="h-fit p-5 bg-zinc-800">
          <form
            onSubmit={handleSchemaSubmit}
            onKeyPress={handleKeyPress}
            className="h-full"
          >
            <div className="flex gap-2">
              <div className="w-2/3 relative">
                <textarea
                  value={schemaInput}
                  onChange={handleSchemaChange}
                  placeholder="Enter JSON schema here"
                  className="border border-zinc-700 rounded p-4 px-5 
               w-full h-4/5 min-h-28 
              bg-zinc-900 text-zinc-200 text-sm font-light
              focus:outline-none
              focus:ring-none"
                />
                <div className="absolute bottom-4 right-4 p-0 flex gap-2 items-center">
                  <button
                    onClick={clearSchema}
                    className="p-2 py-1.5 transition ease-in-out .2s
              bg-transparent border border-zinc-300 hover:opacity-80
              text-zinc-300 rounded-md text-sm"
                  >
                    Clear
                  </button>
                  <button
                    type="submit"
                    className=" p-2 transition ease-in-out .2s
              bg-emerald-700 hover:bg-emerald-700/90 
              text-white rounded-md text-sm"
                  >
                    <ArrowPathIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>
              <div className="w-1/3 relative">
                <textarea
                  value={stepsJson}
                  onChange={handleJsonChange}
                  placeholder="Output"
                  className="border border-zinc-700 rounded p-4 px-5 
             w-full h-4/5 min-h-28 
              bg-zinc-900 text-zinc-200 text-sm font-light
              focus:outline-none
              focus:ring-none"
                />
                <div className="absolute bottom-4 right-4 p-0 flex gap-2 items-center">
                  <button
                    onClick={setValueFromJson}
                    className=" p-2 transition ease-in-out .2s
              bg-emerald-700 hover:bg-emerald-700/90 
              text-white rounded-md text-sm"
                  >
                    <ArrowPathIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="flex-grow p-10 bg-white">
          {schema ? (
            <NiceJsonForm
              schema={schema}
              value={steps}
              setValue={setFormValue}
            />
          ) : (
            <div className="text-gray-500 p-5 flex items-center justify-center">
              Please enter a JSON schema to get started.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NiceJsonEditorPage;
