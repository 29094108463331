import { useEffect, useState } from "react";

export const useBaseMapSwitcher = (defaultBaseMapSrc) => {
  const [baseMapURL, setBaseMapURL] = useState(null);

  const switchBaseMap = (selectedMap) => {
    setBaseMapURL(selectedMap.src);
  };

  useEffect(() => {
    if (defaultBaseMapSrc) {
      setBaseMapURL(defaultBaseMapSrc);
    }
  }, [defaultBaseMapSrc]);

  return { baseMapURL, switchBaseMap };
};
