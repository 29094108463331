import React, { useState } from "react";
import { QuestionMarkCircleIcon } from "../../assets/icons";

const SupportLink = () => {
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  const toggleSupportWidget = () => {
    if (window.FreshworksWidget) {
      if (!isWidgetOpen) {
        window.FreshworksWidget("show", "launcher"); // Show the launcher
        window.FreshworksWidget("open"); // Open widget
      } else {
        window.FreshworksWidget("hide", "launcher"); // Hide the launcher
        window.FreshworksWidget("close"); // Close widget
      }
      setIsWidgetOpen(!isWidgetOpen);
    }
  };

  return (
    <button
      type="button"
      onClick={() => {
        toggleSupportWidget();
      }}
      className="beryl_support-widget-button"
    >
      <QuestionMarkCircleIcon className="w-5 h-5" />
      Support
    </button>
  );
};

export default SupportLink;
