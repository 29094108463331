import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNodeProgress } from "../hooks/api/useNodeProgress";
import Spinner from "../components/common/ui/Spinner";
import { ArrowsPointingOutIcon, ClipboardIcon, ExclamationTriangleIcon, ArrowsPointingInIcon } from "../assets/icons";
import ProgressPlot from "../components/Plot/ProgressPlot";
import TooltipButton from "../components/common/TooltipButton";
import VersionSelect from "../components/Plot/VersionSelect";

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    () => {
      console.log("Copied to clipboard successfully!");
    },
    (err) => {
      console.error("Could not copy text: ", err);
    }
  );
};

const ProgressPage = ({ activeNode, nodeType, nodeData, status }) => {
  const [expanded, setExpanded] = useState(false);
  const { yamlifyLogs } = useNodeProgress(activeNode);
  const lines = yamlifyLogs.split("\n");

  const logContainerRef = useRef(null);

  const messagesArray = useMemo(() => {
    const regex = /msg:\s*(.+)/g;
    const array = [];
    let matches;

    while ((matches = regex.exec(yamlifyLogs)) !== null) {
      array.push(matches[1]);
    }

    return array;
  }, [yamlifyLogs]);

  const [lastMessage, setLastMessage] = useState("Starting");

  useEffect(() => {
    if (messagesArray.length > 0) {
      setLastMessage(messagesArray[messagesArray.length - 1]);
    }
  }, [messagesArray]);

  // Scroll
  useEffect(() => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, [yamlifyLogs]);

  return (
    <div className="empty-project-page relative">
      {(nodeType === "inversion" || nodeType === "processing") && (
        <div className="absolute top-1 left-1">
          <VersionSelect />
        </div>
      )}

      {nodeType === "inversion" ? (
        <div className="h-92 w-full relative top-0">
          <ProgressPlot nodeData={nodeData} activeNode={activeNode} />
        </div>
      ) : (
        <div className="empty-project-content">
          <h2 key={lastMessage} className="fade-in">
            {lastMessage}
          </h2>
        </div>
      )}

      <div
        className={`absolute bg-zinc-900 rounded-lg bottom-0 right-1 p-1 px-2 overflow-hidden ${
          expanded ? "w-full h-full" : " h-48 w-2/5"
        }`}
      >
        <div className="flex justify-between text-white text-sm items-center mb-0.5 p-1 ">
          <div>Log</div>

          {status === "failed" ? (
            <div className="flex items-center gap-1">
              <div className="text-red-500 flex items-center gap-1">
                <ExclamationTriangleIcon className="h-4 w-4 text-red-500" />
                Progress failed
              </div>
              <TooltipButton
                icon={<ClipboardIcon className="h-4 w-4" />}
                tooltipText="Copy traceback"
                tooltipSpacing={"2"}
                tooltipPlacement="left"
                additionalClasses="text-zinc-200 hover:text-zinc-300"
                onClick={() => copyToClipboard(yamlifyLogs)}
              />
            </div>
          ) : (
            <Spinner />
          )}
        </div>

        <div ref={logContainerRef} className={`logs-container flex gap-0.5 overflow-scroll`}>
          <div className="line-numbers w-4 p-2 text-xs text-zinc-500 pb-5">
            {lines.map((_, index) => (
              <div key={index} className="line-number">
                {index + 1}
              </div>
            ))}
          </div>
          <pre className="logs-container--content text-xs text-zinc-400 p-2 pb-5 pr-3">{yamlifyLogs}</pre>
        </div>
      </div>
      <div className="absolute bottom-0 right-1">
        <button onClick={() => setExpanded(!expanded)} className="p-2 rounded-lg hover:bg-zinc-800">
          {expanded ? (
            <ArrowsPointingInIcon className="w-5 h-5 text-white" />
          ) : (
            <ArrowsPointingOutIcon className="w-5 h-5 text-white" />
          )}
        </button>
      </div>
    </div>
  );
};

export default ProgressPage;
