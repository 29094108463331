import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { useSharedState } from "../hooks";
import { useNodeDetailsQuery } from "../hooks/api/useNodeData";
import { InteractiveStateContext } from "./InteractiveStateContext";
import { useFetchFlightlinesData } from "../hooks/useFetchFlightlineData";

export const NodeDataContext = createContext(null);

export const NodeDataProvider = ({ children, currentPath }) => {
  const [activeNode, setActiveNodeReal] = useSharedState("activeNode", []);
  const [previousActiveNode, selectPreviousActiveNode] = useState(null);
  const [workspaceLayout, setWorkspaceLayout] = useState(null);
  const [manualEdits, setManualEdits] = useState({});
  const {
    setSelectedLineId,
    setHoveredLineId,
    setSelectedSoundingId,
    setHoveredSoundingId,
    setSelectedSoundingColor,
    setSelections,
  } = useContext(InteractiveStateContext);

  const setActiveNode = (node) => {
    //console.log("setActiveNode", node);
    setSelectedLineId(null);
    setHoveredLineId(null);
    setSelectedSoundingId(null);
    setHoveredSoundingId(null);
    setSelections(null);
    setManualEdits([]);
    setSelectedSoundingColor(null);
    setActiveNodeReal(node);
  };

  // Determines the type of the node based on its length
  const determineNodeType = () => {
    const types = ["project", "group", "dataset", "processing", "inversion"];
    return types[activeNode.length - 1] || null;
  };

  const nodeType = determineNodeType();

  // Query to fetch node data
  const nodeDataQuery = useNodeDetailsQuery(activeNode);
  const nodeData = nodeDataQuery.data;

  const flightlines = useMemo(() => {
    const summary = nodeData && nodeData.outputs ? nodeData.outputs.find((output) => output)?.summary || {} : {};
    return summary
      ? Object.keys(summary).filter((key) => key !== "all") // Filter out the entry with the key "all"
      : [];
  }, [nodeData]);

  const { flightlineItems, loadingProgress, initialFlightlineId } = useFetchFlightlinesData({
    activeNode,
    nodeData,
    flightlines,
    nodeType,
  });

  // Query to fetch binary data
  const nodeBinaryByLine = useMemo(() => {
    if (!flightlineItems) return null;
    const loadedFlightlineItems = flightlineItems.filter((item) => item.data && item.data.progress === undefined);
    if (loadedFlightlineItems.length === 0) return null;
    const res = {};
    loadedFlightlineItems.forEach((item) => {
      Object.entries(item.data).forEach(([datasetName, binary]) => {
        if (res[datasetName] === undefined) {
          res[datasetName] = {};
        }
        res[datasetName][item.id] = binary;
      });
    });
    return res;
  }, [flightlineItems]);

  useEffect(() => {
    setManualEdits({});
    selectPreviousActiveNode(activeNode);
  }, [activeNode, previousActiveNode !== activeNode]); // eslint-disable-line

  const dataContext = {
    activeNode,
    setActiveNode,
    nodeData,
    nodeType,

    nodeBinaryByLine,
    manualEdits,
    setManualEdits,
    flightlineItems,

    workspaceLayout,
    setWorkspaceLayout,
    flightlines,
    loadingProgress,
    initialFlightlineId,
  };

  // Make debugging data issues easier (so you don't have to set a
  // breakpoint just to look at the binary)
  window.dataContext = dataContext;

  // Return the context provider with all necessary values
  return <NodeDataContext.Provider value={dataContext}>{children}</NodeDataContext.Provider>;
};
