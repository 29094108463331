import { useEffect } from "react";
import useSharedState from "../hooks/useSharedState";

const PaymentPage = () => {
  const [lastTransaction, setLastTransaction] = useSharedState("lastTransaction", null); // eslint-disable-line
  
  useEffect(() => {
    if (window.payment.status === "done" && window.payment.ipn) {
      setLastTransaction(window.payment.ipn);
      window.close();
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [setLastTransaction]);

  if (window.payment.status === "done") {
    if (!window.payment.ipn) {
      return (
        <div>
          <h1>Please wait while we verify your payment...</h1>
          <div>Your PayPal payer ID is: {window.payment.PayerID}</div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Payment complete</h1>
          <div>IPN: {window.payment.ipn}</div>
        </div>
      );
    }
  } else {
    return (
      <div>
        <h1>Transaction canceled</h1>
        <div>It is now safe to close this popup.</div>
      </div>
    );
  }
}

export default PaymentPage;
