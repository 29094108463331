import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { error2message } from "../hooks/api/useSafeQuery";
import { MessagesContext } from "../contexts/MessagesContext";
import client from "../api/client";

const useDeleteListItem = () => {
  const queryClient = useQueryClient();
  const { addMessage } = useContext(MessagesContext);

  const deleteListItem = async (itemType, itemId) => {
    const storedInfo = localStorage.getItem("activeProject");
    const { projectId } = JSON.parse(storedInfo);
    try {
      const response = await client.post(`/api/${itemType}/${itemId}/toggle_show_in_ui`, { project_id: projectId });
      console.log("Visibility toggled successfully", response.data);
      if (itemType === "auxdata") {
        queryClient.invalidateQueries("allAuxiliaryData");
      } else {
        queryClient.invalidateQueries(itemType); // Invalidate queries based on item type
      }
    } catch (error) {
      if (error.response.status === 401) {
        const errorMessage = {
          title: error.response.statusText,
          message: error.response.data.message,
        };

        addMessage({
          title: errorMessage.title,
          shortMessage: errorMessage.message,
          type: "notice",
          message: errorMessage.message,
        });
      } else {
        addMessage(error2message(error));
        console.error("error", error);
      }
    }
  };

  const handleDelete = async (nodeId, nodeTitle, nodeType) => {
    return new Promise((resolve, reject) => {
      const isConfirmed = window.confirm(`Are you sure you want to delete ${nodeTitle}?`);

      if (isConfirmed) {
        deleteListItem(nodeType, nodeId)
          .then(() => resolve())
          .catch((error) => reject(error));
      } else {
        console.log("Delete action cancelled");
        resolve(); // Resolve even if the action is cancelled
      }
    });
  };

  return { useDeleteListItem, handleDelete };
};

export default useDeleteListItem;
