import { useQueryClient, useMutation } from "@tanstack/react-query";
import client from "../api/client";

const useUpdateAuxData = (
  projectId,
  auxDataId,
  closeModal,
  setIsLoading,
  setErrorMsg
) => {
  const queryClient = useQueryClient();

  const updateAuxDataMutation = useMutation({
    mutationFn: (data) =>
      client.patch(`/api/projects/${projectId}/data/${auxDataId}`, {
        title: data.title,
        tags_ids: data.tags_ids,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["allAuxiliaryData", projectId]);

      setIsLoading(false);
      closeModal();
    },
    onError: (error) => {
      console.error("Error updating aux data.", error.message);
      setErrorMsg(`Error updating data, ${error.message}`);
    },
  });

  const submitUpdateAuxiliaryData = (data) => {
    updateAuxDataMutation.mutate(data);
  };

  return {
    updateAuxDataMutation,
    submitUpdateAuxiliaryData,
  };
};

export default useUpdateAuxData;
