import React, { useContext, useState } from "react";
import { MessagesContext, NodeDataContext } from "../../contexts";
import { PopupDialog } from "../common/PopupDialog";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../common/forms/Form";
import { error2message } from "../../hooks/api/useSafeQuery";
import useCreateInvite from "../..//hooks/useCreateInvite";
import * as yup from "yup";

const validateEmail = yup.object().shape({
  email: yup.string().required("You must provide an email.").email("You must provide a valid email address."),
});

const InviteUserForm = ({ isOpen, handleCloseModal }) => {
  const { activeNode } = useContext(NodeDataContext);
  const { addMessage } = useContext(MessagesContext);
  const [email, setEmail] = useState("");

  const onSuccess = () => {
    handleCloseModal();
    addMessage({
      title: "Invitation sent",
      shortMessage: `You have sent an invitation to ${email}`,
      type: "success",
      message: "Invitation sent",
    });
  };

  const onError = (error) => {
    handleCloseModal();
    addMessage(error2message(error));
  };

  const { handleFormSubmit } = useCreateInvite(onSuccess, onError, activeNode);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validateEmail),
  });

  const onSubmit = () => {
    handleFormSubmit({ email });
  };

  return (
    <PopupDialog
      isOpen={isOpen}
      closeModal={handleCloseModal}
      title="Invite member"
      width="md"
      description={"Be aware that the member will be able to view and edit data."}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      >
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <FormInput
              placeholder="example@gmail.com"
              label="Email"
              size="sm"
              error={errors.email}
              {...field}
              onChange={(e) => {
                field.onChange(e);
                setEmail(e.target.value);
              }}
            />
          )}
        />

        <div className="button-group flex flex-row justify-end gap-2">
          <button
            onClick={handleCloseModal}
            type="button"
            className="bg-transparent text-zinc-600 text-sm px-3 py-2 rounded-md border border-zinc-200 bg-zinc-50 hover:bg-zinc-100"
          >
            Cancel
          </button>
          <button type="submit" className="bg-zinc-800 text-white px-5 py-2 rounded-md">
            Send invite
          </button>
        </div>
      </form>
    </PopupDialog>
  );
};

export default InviteUserForm;
