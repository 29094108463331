import React, { forwardRef } from "react";
import useDeleteListItem from "../../../hooks/useDeleteListItem";
import { ArrowDownTrayIcon, PencilIcon, PlusIcon, TrashIcon } from "../../../assets/icons";
import { useAddStyleClick } from "../../../hooks/mapHooks/useAddRasterStyle";

const MiscDataListItemMenu = forwardRef(({ item, setStyleLoading, setIsOpen }, ref) => {
  const { handleDelete } = useDeleteListItem();
  const onAddStyleClick = useAddStyleClick("allAuxiliaryData");

  console.log("item", item);

  return (
    <div ref={ref} className="misc-data-list-item__menu">
      <div className="misc-data-list-item__menu--buttons">
        {item.url && (
          <button
            onClick={() => {
              onAddStyleClick(item.project, item.id);
              setStyleLoading(true);
            }}
            className={`px-2.5 py-2 hover:bg-zinc-100 rounded-md w-full text-left text-xs flex items-center gap-2 min-w-28`}
          >
            <PlusIcon aria-hidden="true" className="w-4 h-4" />
            Add style
          </button>
        )}

        <button
          onClick={() => setIsOpen(true)}
          className={`px-2.5 py-2 hover:bg-zinc-100 rounded-md w-full text-left text-xs flex items-center gap-2 min-w-28`}
        >
          <PencilIcon aria-hidden="true" className="w-4 h-4" />
          Edit
        </button>
        <a
          download
          href={item.src}
          className={`px-2.5 py-2 hover:bg-zinc-100 rounded-md w-full text-left text-xs flex items-center gap-2 min-w-28`}
        >
          <ArrowDownTrayIcon aria-hidden="true" className="w-4 h-4" />
          Download
        </a>
        <button
          onClick={() => handleDelete(item.id, item.title, "auxdata")}
          className={`px-2.5 py-2 hover:bg-zinc-100 rounded-md w-full text-left text-xs flex items-center gap-2 min-w-28`}
        >
          <TrashIcon aria-hidden="true" className="w-4 h-4" />
          Delete
        </button>
      </div>
    </div>
  );
});

export default MiscDataListItemMenu;
