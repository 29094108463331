import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  PlusIcon,
  TrashIcon,
  Layout1x1,
  Layout1x2,
  Layout2x2,
  Layout3x2,
  Layout5x2,
  Layout4x2,
} from "../../../../../../assets/icons";
import { useWorkspaceLayout, useWorkspaceLayouts } from "../../../../../../hooks/api/useWorkspaceLayouts";
import { NodeDataContext } from "../../../../../../contexts";
import SaveLayoutForm from "./SaveLayoutForm";
import useDeleteLayout from "../../../../../../hooks/useDeleteLayout";
import { twEmerald600 } from "../../../../../../assets/icons/layout/variables";
import Heading from "../../../../../common/ui/Heading";
import Tooltip from "../../../../../common/Tooltip";

const LayoutBadge = ({ title, isActive }) => {
  const badgeDetails = {
    Default: {
      text: "Blank",
      bgColor: "bg-stone-200",
      borderColor: "border-stone-300",
      textColor: "text-stone-600",
    },
    Prefilled: {
      text: "Prefilled",
      bgColor: "bg-yellow-100",
      borderColor: "border-yellow-300",
      textColor: "text-yellow-600",
    },
  };

  const { text, bgColor, borderColor, textColor } = badgeDetails[title] || {};

  if (!text) return null; // No badge to display for this title

  // Classes that control visibility
  const visibilityClass = isActive ? "hidden" : "hidden group-hover:flex";

  return (
    <div
      className={`${visibilityClass} absolute top-1 -left-2 text-xs px-1 rounded ${bgColor} ${borderColor} ${textColor} border`}
    >
      {text}
    </div>
  );
};

const LayoutEditor = () => {
  const { workspaceLayout, setWorkspaceLayout, nodeType, activeNode } = useContext(NodeDataContext);
  const [activeLayout, setActiveLayout] = useState(null);
  const [showInput, setShowInput] = useState(false);

  const { data: layouts = [] } = useWorkspaceLayouts();
  const { data: selectedLayout } = useWorkspaceLayout(activeLayout);

  useEffect(() => {
    if (activeLayout && selectedLayout) {
      const newLayout = {
        ...workspaceLayout,
        [nodeType]: {
          ...selectedLayout,
          id: activeLayout,
        },
      };
      setWorkspaceLayout(newLayout);
      setActiveLayout(null);
    }
  }, [selectedLayout, nodeType, workspaceLayout, setWorkspaceLayout, activeLayout]);

  const iconComponents = useMemo(
    () => ({
      Layout1x1,
      Layout1x2,
      Layout2x2,
      Layout3x2,
      Layout4x2,
      Layout5x2,
    }),
    []
  ); // only create once

  const DefaultIcon = useMemo(() => () => <div>Icon</div>, []);

  const renderLayoutIcon = useCallback(
    (iconName, strokeColor) => {
      const IconComponent = iconComponents[iconName] || DefaultIcon;
      return <IconComponent strokeColor={strokeColor} />;
    },
    [iconComponents, DefaultIcon]
  );

  const memoizedLayouts = useMemo(() => {
    const reversedLayoutsArray = [...layouts].reverse();
    const prefilledLayouts = reversedLayoutsArray.filter((layout) => layout.workspace_type?.includes("Prefilled"));
    const blankLayouts = reversedLayoutsArray.filter((layout) => layout.workspace_type?.includes("Blank"));
    const defaultAndPrefilledLayouts = [...prefilledLayouts, ...blankLayouts];

    const defaultLayouts = defaultAndPrefilledLayouts.filter((layout) => layout.node_type?.includes(nodeType));

    const myLayouts = reversedLayoutsArray.filter((layout) => layout.workspace_type?.includes("Custom"));

    return { defaultLayouts, myLayouts };
  }, [layouts, nodeType]);

  const { defaultLayouts, myLayouts } = memoizedLayouts;

  const renderLayoutSection = (sectionTitle, layouts) => {
    if (layouts.length === 0) {
      return (
        <div className="flex flex-col gap-2">
          <Heading size="h6">{sectionTitle}</Heading>
          <div className="text-zinc-500">Click on + to save new layout</div>
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-1.5">
        <Heading size="h4">{sectionTitle}</Heading>
        <ul className="flex gap-1 flex-wrap w-72" role="listbox" aria-label={`Select a layout for ${sectionTitle}`}>
          {layouts?.map((layout) => {
            const strokeColor = activeLayout === workspaceLayout[nodeType]?.id ? twEmerald600 : "#a1a1aa";
            return (
              <li
                id={`layoutOption-${layout.id}`} // It's better to use layout.id for uniqueness
                key={layout.id}
                title={`${layout.title} ${layout.dimensions}`}
                style={{
                  flex: "1 0 100%",
                  maxWidth: "100%",
                  minWidth: "100%",
                  width: "100%",
                }}
                className={`group relative flex flex-1 gap-3 items-center p-2 py-2 pr-2.5 rounded-md border-2 border-transparent hover:border-zinc-400 transition ease-in-out 2s ${
                  workspaceLayout[nodeType]?.id === layout.id ? "border-zinc-800 text-zinc-900" : "text-zinc-700"
                }`}
                role="option"
                aria-selected={workspaceLayout[nodeType]?.id === layout.id ? "true" : "false"}
                tabIndex="0"
                onClick={() => setActiveLayout(layout.id)}
              >
                <LayoutBadge title={layout.title} isActive={workspaceLayout[nodeType]?.id === layout.id} />

                <div className="w-7 h-5 flex items-center">{renderLayoutIcon(layout.icon_name, strokeColor)}</div>
                <div className="flex gap-2 items-center justify-between w-full">
                  <div className="text-sm text-center text-nowrap text-ellipsis truncate clip">{layout.title}</div>
                  <div className="text-zinc-500 text-xs">{layout.dimensions}</div>
                </div>
                {layout.id !== activeLayout && layout.workspace_type === "Custom" && <DeleteButton layout={layout} />}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-2 p-4 px-5 text-sm w-full overflow-hidden">
      <div className="flex flex-col gap-3 relative">
        <div className="flex flex-col gap-2 justify-end absolute w-full">
          <button
            onClick={() => setShowInput((prevShowInput) => !prevShowInput)}
            className={`flex items-center rounded-md w-fit ml-auto text-white ${
              showInput ? "bg-zinc-400 opacity-90 p-0.5" : "bg-emerald-800 hover:bg-emerald-800/90 p-1.5"
            }`}
          >
            <Tooltip tooltipText={`${showInput ? "Close" : "New layout"}`} placement="left">
              <PlusIcon className={`w-5 h-5 ${showInput ? "rotate-45" : ""}`} />
            </Tooltip>
          </button>
        </div>

        {showInput && (
          <SaveLayoutForm
            workspaceLayout={workspaceLayout[nodeType]}
            nodeType={nodeType}
            setShowInput={setShowInput}
            activeNode={activeNode}
          />
        )}

        <div className="flex flex-col flex-1 gap-2" style={{ minWidth: "30%", maxWidth: "100%" }}>
          {renderLayoutSection("My layouts", myLayouts)}
        </div>
        <div className="flex flex-col gap-2" style={{ minWidth: "50%", maxWidth: "100%" }}>
          {renderLayoutSection("Default layouts", defaultLayouts)}
        </div>
      </div>
    </div>
  );
};

export default LayoutEditor;

const DeleteButton = ({ layout }) => {
  const { handleDeleteLayout } = useDeleteLayout(layout.id);

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    handleDeleteLayout(layout.id, layout.title);
  };
  return (
    <button
      onClick={handleDeleteClick}
      aria-label="Delete"
      className="hidden group-hover:flex absolute bg-zinc-600 hover:bg-red-500/90 text-white hover:scale-105 rounded-full w-6 h-6 right-0.5 top-0.5 flex justify-center items-center transition ease-in-out 2s z-50"
    >
      <TrashIcon className="w-4 h-4" />
    </button>
  );
};
