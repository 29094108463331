import React, { useRef, useState } from "react";
import { registerSchemaComponent } from "./fieldRegistration";
import { importFile } from "../../../api/importFile";
import { FormLabel } from "../../common/forms/Form";

export const mangleFilename = (name) => {
  const names = name.replace(" ", "-").split(".");
  names[names.length-1] = names[names.length-1].toLowerCase();
  return names.join(".")
}

export const patternsToExtensions = (schemaFragment) => {
  return schemaFragment.pattern
    .replace("\\.", ".")
    .slice(0, -1) // Remove trailing '$'
    .split("|") // Split by '|'
    .map((ext) => ext[0] !== '.' ? `.${ext}` : ext); // Add a dot to each extension if not present
}

export const SingleFileImportField = ({
  schemaFragment,
  propKey,
  value,
  setValue,
}) => {
  // value is a string that contains of a url /api/file/blabla.xyz
  // setValue takes a parameter that looks like value

  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef(null);

  const extensions = patternsToExtensions(schemaFragment);
  const allowedFileTypes = extensions.map((ext) => ext.toLowerCase())
        .concat(extensions.map((ext) => ext.toUpperCase()));
  const allowedFileTypesStr = allowedFileTypes.join(", ");

  const fileChange = (file) => {
    const extension = file.name.split(".").pop();

    if (!allowedFileTypes.includes("." + extension.toLowerCase())) {
      setErrorMessage(
        `File type not allowed. Allowed types: ${allowedFileTypesStr}`
      );
      fileInputRef.current.value = ""; // Clear the input value
      return;
    }

    // Clear the error message if the file is valid
    setErrorMessage("");

    const newValue = async () => {
      const request = await importFile(file, mangleFilename(file.name));
      return request.data.file;
    };
    setValue(newValue);
  };

  // alternatively value can look like async () => { return url with same format }
  // alternatively setValue can take the async value as a parameter as well

  return (
    <div className="block grid grid-cols-6 gap-2 items-center mt-1">
      <div className="col-span-2 flex items-center">
        <FormLabel
          label={schemaFragment?.title || propKey}
          htmlFor={schemaFragment?.title || propKey}
          fontWeight="font-normal"
        />
      </div>
      <div className="col-span-4 flex items-center text-sm">
        <input
          ref={fileInputRef}
          type="file"
          accept={allowedFileTypesStr}
          onChange={(e) => fileChange(e.target.files[0])}
        />
        {errorMessage && (
          <div className="col-span-6 text-red-500 text-sm">{errorMessage}</div>
        )}
      </div>
    </div>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment?.type === "string" && schemaFragment?.format === "url";
}, SingleFileImportField);
