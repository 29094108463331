import React, { useContext } from "react";
import moment from "moment";
import {
  ChevronRightIcon,
  DatasetIcon,
  ProcessingIcon,
} from "../../../../../../../assets/icons";
import { formatVersionTimestamp } from "../../../../../../../utils";
import { NodeDataContext } from "../../../../../../../contexts";

export const Description = () => {
  const { nodeData, activeNode, setActiveNode, nodeType } =
    useContext(NodeDataContext);

  const formattedDate = moment(
    nodeData?.inversion
      ? nodeData?.inversion.created_at
      : nodeData?.processing
      ? nodeData?.processing.created_at
      : nodeData?.created_at
  ).format("MMM D, YYYY");

  const lastUpdate = formatVersionTimestamp(nodeData?.created_at);

  let items = [
    {
      title: "Origin",
      description: (
        <OriginPath
          nodeData={nodeData}
          activeNode={activeNode}
          setActiveNode={setActiveNode}
          nodeType={nodeType}
        />
      ),
    },
    { title: "Created", description: formattedDate },
  ];

  if (nodeType === "processing" || nodeType === "inversion") {
    items.splice(1, 0, { title: "Last updated", description: lastUpdate });
  }

  return (
    <div className="sidepanel__main--description-list">
      <div className="w-full my-2">
        <div className="grid grid-cols-5 gap-3">
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <div className="col-span-1 text-left font-medium">
                {item.title}
              </div>
              <div className="col-span-4 text-left">{item.description}</div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

const OriginPath = ({ nodeData, activeNode, setActiveNode, nodeType }) => {
  const gotoGroup = () => {
    setActiveNode(activeNode.slice(0, 2));
  };

  const gotoImport = () => {
    setActiveNode(activeNode.slice(0, 3));
  };

  const gotoProcessingVersion = () => {
    setActiveNode(
      activeNode
        .slice(0, 3)
        .concat([[nodeData.processing.id, nodeData.processing_version.id]])
    );
  };

  const seperator = (
    <ChevronRightIcon className="w-4 h-4 text-zinc-500 overflow-visible" />
  );
  const linkStyles = "flex gap-1.5 items-center hover:text-zinc-600";
  const itemStyles = "truncate text-clip text-ellipsis overflow-hidden";

  return (
    <div className="flex items-center gap-2 overflow-x-hidden w-full">
      <button
        onClick={gotoGroup}
        className={`${linkStyles}`}
        title={nodeData?.group?.title}
      >
        <div
          className="w-3.5 h-3.5 rounded"
          style={{ backgroundColor: nodeData?.group?.color }}
        />
        <div>{nodeData?.group?.title}</div>
      </button>
      {nodeType === "processing" && (
        <>
          {seperator}
          <button onClick={gotoImport} className={`${linkStyles}`}>
            <DatasetIcon color={nodeData?.group?.color} />
            <div className={itemStyles}>{nodeData?.import?.title}</div>
          </button>
        </>
      )}
      {nodeType === "inversion" && (
        <>
          {seperator}

          <button
            onClick={gotoImport}
            className={`${linkStyles} relative group`}
          >
            <DatasetIcon color={nodeData?.group?.color} />
            <div className={itemStyles}>...</div>
            <div className="bg-zinc-800 text-zinc-200 shadow rounded-md p-1.5 px-2 fixed mt-14 -ml-2 text-xs opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-200">
              {nodeData?.import?.title}
            </div>
          </button>

          {seperator}
          <button
            onClick={gotoProcessingVersion}
            className={`${linkStyles} truncate relative group`}
          >
            <div className="text-nowrap">
              <ProcessingIcon color={nodeData?.group?.color} />
            </div>
            <div
              className={
                "truncate whitespace-nowrap overflow-hidden text-ellipsis"
              }
            >
              {nodeData?.processing.title}{" "}
            </div>
            <div className="whitespace-nowrap text-nowrap bg-zinc-50 border border-zinc-300 text-xs p-0.5 px-1 rounded-md">
              {formatVersionTimestamp(nodeData?.processing_version.created_at)}
            </div>
            <div className="bg-zinc-800 text-zinc-200 shadow rounded-md p-1.5 px-2 fixed mt-14 -ml-2 text-xs opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-200">
              {nodeData?.processing.title +
                " " +
                formatVersionTimestamp(nodeData?.processing_version.created_at)}
            </div>
          </button>
        </>
      )}
    </div>
  );
};
