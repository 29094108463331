import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { useSidebar } from "../../contexts/SidebarContext";

const WorkspaceLayout = () => {
  const { isCollapsed, sidebarWidth } = useSidebar();

  return (
    <div className="beryl_workspace-layout">
      <Sidebar />
      <div className="beryl_workspace--main-content" style={{ width: `calc(100% - ${sidebarWidth}px)` }}>
        <Outlet context={{ isCollapsed }} />
      </div>
    </div>
  );
};

export default WorkspaceLayout;
