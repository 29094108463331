import React, { useContext } from "react";
import { NodeDataContext, WorkspaceContext } from "../../../../../contexts";
import { useInteractiveStates } from "../../../../../contexts/InteractiveStateContext";
import { resetZoom } from "emerald-plotly-react";
import {
  BoxSelectionIcon,
  PanSelectIcon,
  ArrowsPointingOutIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  MagnifyingGlassIcon,
  LassoIcon,
  ChatBubbleBottomCenterIcon,
  ChatBubbleBottomCenterTextIcon,
} from "../../../../../assets/icons";
import TooltipButton from "../../../../common/TooltipButton";
import ManualEditButtons from "./ManualEditSelect/ManualEditButtons";

import { UpdateProcessingVersion } from "./UpdateVersion";

const moveToStartOfXAxis = ({ setSubplotZooms, subplotZooms }) => {
  const updatedZooms = { ...subplotZooms };

  for (let axis in updatedZooms) {
    if (axis.startsWith("xaxis") && updatedZooms[axis].range) {
      const zoomSpan = updatedZooms[axis].range[1] - updatedZooms[axis].range[0];
      updatedZooms[axis].range = [0, zoomSpan];
    }
  }
  setSubplotZooms(updatedZooms);
};

const moveToEndOfXAxis = ({ setSubplotZooms, subplotZooms, nodeBinaryByLine, selectedLineId }) => {
  const updatedZooms = { ...subplotZooms };
  const line = Object.values(nodeBinaryByLine)?.[0][selectedLineId].flightlines.xdist;

  const lineXdistMax = line[line?.length - 1];
  const maxX = lineXdistMax; /* retrieve the maximum x value here */

  for (let axis in updatedZooms) {
    if (axis.startsWith("xaxis") && updatedZooms[axis].range) {
      const zoomSpan = updatedZooms[axis].range[1] - updatedZooms[axis].range[0];
      updatedZooms[axis].range = [maxX - zoomSpan, maxX];
    }
  }

  setSubplotZooms(updatedZooms);
};

const CustomizedModebar = ({
  subplotZooms,
  setSubplotZooms,
  setCurrentDragMode,
  setIsManualEditSelect,
  isManualEditSelect,
}) => {
  const { isToolbarExpanded } = useContext(WorkspaceContext);
  const { nodeBinaryByLine, activeNode } = useContext(NodeDataContext);
  const { selectedLineId, showHover, setShowHover } = useInteractiveStates();

  if (!isToolbarExpanded) return null;

  const showExpandedMenu = activeNode?.length === 4;
  const showInvMenu = activeNode?.length === 5;

  const handleMoveToStart = () => {
    moveToStartOfXAxis({
      subplotZooms,
      setSubplotZooms,
    });
  };

  const handleMoveToEnd = () => {
    moveToEndOfXAxis({
      subplotZooms,
      setSubplotZooms,
      nodeBinaryByLine,
      selectedLineId,
    });
  };

  const handleResetZoom = () => {
    resetZoom({ setSubplotZooms });
  };

  const activateZoomSelect = () => {
    setCurrentDragMode("zoom");
    setIsManualEditSelect(false);
  };

  const activateBoxSelect = () => {
    setCurrentDragMode("select");
    setIsManualEditSelect(true);
  };

  const activateLassoSelect = () => {
    setCurrentDragMode("lasso");
    setIsManualEditSelect(true);
  };

  const activatePanSelect = () => {
    setCurrentDragMode("pan");
    setIsManualEditSelect(false);
  };

  return (
    <div className="plot-modebar flex">
      {(showExpandedMenu || showInvMenu) && (
        <div className="flex items-center border-l border-r border-zinc-300 ml-1 mr-1 pl-1 pr-1">
          <TooltipButton
            onClick={activateBoxSelect}
            tooltipText={showInvMenu ? "Box select" : "Cull with box"}
            tooltipSpacing={"3"}
            icon={<BoxSelectionIcon />}
          />
          {showExpandedMenu && (
            <>
              <TooltipButton
                onClick={activateLassoSelect}
                tooltipText="Cull with lasso"
                tooltipSpacing={"3"}
                icon={<LassoIcon />}
              />
              <ManualEditButtons isManualEditSelect={isManualEditSelect} />
              <UpdateProcessingVersion />
            </>
          )}
        </div>
      )}

      <TooltipButton
        onClick={() => setShowHover(!showHover)}
        tooltipText="Show hover"
        tooltipSpacing={"3"}
        icon={
          showHover ? (
            <ChatBubbleBottomCenterTextIcon className="w-5 h-5" />
          ) : (
            <ChatBubbleBottomCenterIcon className="w-5 h-5" />
          )
        }
      />

      <TooltipButton onClick={activatePanSelect} tooltipText="Pan" tooltipSpacing={"3"} icon={<PanSelectIcon />} />

      <TooltipButton
        onClick={activateZoomSelect}
        tooltipText="Zoom"
        tooltipSpacing={"3"}
        icon={<MagnifyingGlassIcon className="w-5 h-5" />}
      />

      <TooltipButton
        onClick={handleResetZoom}
        tooltipText="Reset zoom"
        tooltipSpacing={"3"}
        icon={<ArrowsPointingOutIcon className="w-5 h-5" />}
      />

      <TooltipButton
        onClick={handleMoveToStart}
        tooltipText="Go to start of x-axis"
        tooltipSpacing={"3"}
        icon={<ChevronDoubleLeftIcon className="h-5 w-5" />}
      />

      <TooltipButton
        onClick={handleMoveToEnd}
        tooltipText="Go to end of x-axis"
        tooltipSpacing={"3"}
        icon={<ChevronDoubleRightIcon className="h-5 w-5" />}
      />
    </div>
  );
};

export default CustomizedModebar;
