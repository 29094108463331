import { useMemo, useContext } from "react";
import { NodeDataContext } from "../contexts";
import { InteractiveStateContext } from "../contexts/InteractiveStateContext";

export const useSelectedSoundings = () => {
  const { nodeBinaryByLine } = useContext(NodeDataContext);
  const { selectedLineId, selections } = useContext(InteractiveStateContext);

  return useMemo(() => {
    if (!selections
        || !selections.points
        || !nodeBinaryByLine
        || !Object.values(nodeBinaryByLine).length)
      return [];
    const xdist = Object.values(nodeBinaryByLine)[0][selectedLineId].flightlines.xdist;
    return selections.points
      .filter((p) => p.data.xaxis_unit === "xdist")
      .map((p) => xdist.findIndex((a) => a >= p.x));
  }, [nodeBinaryByLine, selectedLineId, selections]);
};
