const handleError = (error, requestName) => {
  if (error.response) {
    // Error response received from the server
    console.error(`Error in ${requestName}:`, error.response.status);
    console.error("Error Response Data:", error.response.data);
  } else if (error.request) {
    // No response received
    console.error(`Error in ${requestName}: No response received`);
    console.error("Request Details:", error.request);
  } else {
    // Other types of errors
    console.error(`Error in ${requestName}:`, error.message);
  }
};

export default handleError;
