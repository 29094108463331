import React, { useEffect, useState } from "react";
import { SELECTED_STYLE } from "../../../../../utils/flightlineStyles";
import { FormCheck } from "../../../../common/forms/Form";
import { MapMenuList, MapMenuListItem } from "./MapMenuListItems";

const FlightlineSelection = ({
  flightlines,
  selectedLineId,
  hiddenFlightlines,
  setHiddenFlightlines,
}) => {
  const [allChecked, setAllChecked] = useState(hiddenFlightlines.length === 0);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = () => setIsDragging(true);
  const handleMouseUp = () => setIsDragging(false);

  useEffect(() => {
    setAllChecked(hiddenFlightlines.length === 0);
  }, [hiddenFlightlines]);

  const toggleAllFlightlines = () => {
    if (allChecked) {
      // If currently checked, hide all flightlines
      setHiddenFlightlines(flightlines);
    } else {
      // If currently unchecked, show all flightlines
      setHiddenFlightlines([]);
    }
    setAllChecked(!allChecked);
  };

  const toggleFlightlineVisibility = (lineId) => {
    setHiddenFlightlines((prevHidden) => {
      if (prevHidden.includes(lineId)) {
        // If the flightline is currently hidden, remove it from the hidden list to make it visible
        return prevHidden.filter((id) => id !== lineId);
      } else {
        // If the flightline is currently visible, add it to the hidden list to hide it
        return [...prevHidden, lineId];
      }
    });
  };

  const handleDivClick = (lineId) => {
    toggleFlightlineVisibility(lineId);
  };

  const handleMouseEnter = (lineId) => {
    if (isDragging) {
      toggleFlightlineVisibility(lineId);
    }
  };

  return (
    <MapMenuList onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
      <MapMenuListItem onClick={toggleAllFlightlines}>
        <FormCheck
          id={`all-lines`}
          name="shapefile"
          checked={allChecked}
          onChange={toggleAllFlightlines}
          isCheckBox={false}
        />

        <div
          htmlFor={`all-lines`}
          className="flex gap-2 items-center justify-between w-full"
        >
          All
          {allChecked ? (
            <span className="rounded-full h-1 w-6 ml-auto mr-2 bg-zinc-600"></span>
          ) : (
            <span className="rounded-full h-1 w-6 ml-auto mr-2 bg-zinc-400"></span>
          )}
        </div>
      </MapMenuListItem>
      {flightlines.map((line) => (
        <MapMenuListItem
          key={line}
          onMouseEnter={() => handleMouseEnter(line)}
          onClick={() => handleDivClick(line)}
        >
          <FormCheck
            id={`line-${line}`}
            name="shapefile"
            value={line}
            checked={!hiddenFlightlines.includes(line)}
            onChange={() => toggleFlightlineVisibility(line)}
            isCheckBox={false}
          />

          {line}
          <span
            className={`rounded-full h-1 w-6 ml-auto mr-2`}
            style={{
              backgroundColor: !hiddenFlightlines.includes(line)
                ? line === selectedLineId
                  ? SELECTED_STYLE.color
                  : "rgb(0, 0, 170)" // FIXME: Where did line.values_.style.color come from?
                : "rgb(161 161 170)",
            }}
          ></span>
        </MapMenuListItem>
      ))}
    </MapMenuList>
  );
};

export default FlightlineSelection;
