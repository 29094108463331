import client from "./client";

export const importFile = (file, filename) => {
  const formData = new FormData();
  formData.append("file", file, filename || file.name);

  return client.post(`/api/file/new`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
