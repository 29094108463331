export const LoadingPlaceholder = ({ height = "h-6", rows = 5 }) => (
  <div className="flex flex-col gap-2">
    {Array(rows)
      .fill(0)
      .map((_, idx) => (
        <div
          key={idx}
          className={`animate-pulse rounded-md bg-zinc-200 ${height} ${
            idx === 4 ? "w-1/2" : "w-full"
          }`}
        ></div>
      ))}
  </div>
);
