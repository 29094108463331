import React, { useState, useRef } from "react";
import { useClickOutside } from "../../../../../hooks";
import TooltipButton from "../../../../common/TooltipButton";

const ToolbarTooltipPanel = ({
  content,
  icon,
  tooltipText,
  width,
  height,
  overflow = false,
}) => {
  const [showPanel, setShowPanel] = useState(false);
  const panelRef = useRef(null);

  const handleMouseEnter = () => {
    setShowPanel(true);
  };

  useClickOutside(panelRef, () => {
    setShowPanel(false);
  });

  return (
    <div className="relative">
      <TooltipButton
        onClick={handleMouseEnter}
        icon={icon}
        tooltipText={tooltipText}
        tooltipSpacing={"3"}
      />
      {showPanel && (
        <div
          ref={panelRef}
          className={`fade-in fixed p-3 -ml-14 ${overflow && "right-0 -mr-1"}`}
          style={{
            zIndex: 1000,
            width: width,
            height: height,
            maxHeight: "80vh",
            maxWidth: "1050px",
          }}
        >
          <div
            className={`bg-white overflow-y-scroll
            outline outline-1 outline-zinc-100 text-zinc-800 rounded-md shadow-lg relative`}
            style={{
              maxHeight: "80vh",
            }}
          >
            {content}
          </div>
        </div>
      )}
    </div>
  );
};

export default ToolbarTooltipPanel;
