import React from "react";
import Table, {
  TableRow,
  TableCell,
  TableHeaderCell,
} from "../../../common/Table";

const Transaction = ({ item }) => {
  return (
    <TableRow>
      <TableHeaderCell>{item.details}</TableHeaderCell>

      <TableCell>
        {item.created_at.replace("T", " ").replace("Z", "")}
      </TableCell>

      <TableCell>{item.amount > 0 ? item.amount : ""}</TableCell>

      <TableCell>{item.amount <= 0 ? -item.amount : ""}</TableCell>
    </TableRow>
  );
};

const TransactionList = ({ data }) => {
  const columns = [
    { title: "Description" },
    { title: "Timestamp" },
    { title: "Top up" },
    { title: "Charge" },
  ];

  const renderRow = (item, rowIndex) => (
    <Transaction key={item.id} item={item} />
  );

  return (
    <Table
      columns={columns}
      data={data}
      renderRow={renderRow}
      emptyMessage="No transactions yet"
    />
  );
};

export default TransactionList;
