import { useContext, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { NodeStatusContext } from "../contexts/NodeStatusContext";

const useInvalidateQueryOnStatus = (queryKey, dataType) => {
  const queryClient = useQueryClient();
  const { statusUpdates } = useContext(NodeStatusContext);

  useEffect(() => {
    if (statusUpdates[dataType]?.length) {
      queryClient.invalidateQueries(queryKey);
    }
  }, [statusUpdates, dataType, queryKey, queryClient]);
};

export default useInvalidateQueryOnStatus;
