import React from "react";
import Tooltip from "../common/Tooltip";
import { ActionInversionIcon, ActionProcessingIcon, ArrowUpTrayIcon, MagicWandIcon } from "../../assets/icons";
import { useDialog } from "../../contexts/DialogContext";
import { useActiveProject } from "../../hooks/useActiveProject";
import AutoProcessForm from "../Forms/AutoProcessForm";

const AutoProcessActionButton = ({ nodeTitle, handleOneClickProcInvClick, activeNode }) => {
  const { openDialog, closeDialog } = useDialog();
  const { projectId } = useActiveProject();

  return (
    <Tooltip tooltipText={"Auto process"} placement={"left"}>
      <button
        className="proc-and-inv"
        onClick={() => {
          handleOneClickProcInvClick();
          openDialog(
            `Process and invert: ${nodeTitle}`,
            <AutoProcessForm closeDialog={closeDialog} projectId={projectId} activeNode={activeNode} />
          );
        }}
      >
        <MagicWandIcon />
      </button>
    </Tooltip>
  );
};

const ActionButtons = ({
  handleOneClickProcInvClick,
  handleActionButtonClick,
  activePath,
  activePathNumber,
  activeNode,
  nodeItem,
  hoveredNode,
}) => {
  const showProcessingAndInversionBtn = activePathNumber === 2;

  let actionButtonTooltipText;
  let IconComponent;

  if (activePathNumber === 1) {
    actionButtonTooltipText = "Import EM data";
    IconComponent = ArrowUpTrayIcon;
  } else if (activePathNumber === 2) {
    actionButtonTooltipText = "Process";
    IconComponent = ActionProcessingIcon;
  } else if (activePathNumber === 3) {
    actionButtonTooltipText = "Invert";
    IconComponent = ActionInversionIcon;
  }

  return (
    <div className="flex gap-2">
      {(activePath[activePathNumber] === nodeItem.id || hoveredNode === nodeItem.id) && (
        <Tooltip tooltipText={actionButtonTooltipText} placement={"left"}>
          <button className="action-btn" onClick={handleActionButtonClick}>
            {IconComponent && <IconComponent className="w-4 h-4 text-zinc-600 hover:text-zinc-800" />}
          </button>
        </Tooltip>
      )}
      {showProcessingAndInversionBtn &&
        (activePath[activePathNumber] === nodeItem.id || hoveredNode === nodeItem.id) && (
          <AutoProcessActionButton
            nodeTitle={nodeItem.title}
            handleOneClickProcInvClick={handleOneClickProcInvClick}
            activeNode={activeNode}
          />
        )}
    </div>
  );
};

export default ActionButtons;
