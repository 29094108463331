import React, { useRef, useEffect } from "react";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";

const BaseMap = ({ center = [0, 0], zoom = 2, onClick, onMapInit, onHover, baseMapURL }) => {
  const map = useRef();
  const baseLayer = useRef();
  const mapElement = useRef();

  useEffect(() => {
    if (!mapElement.current) return;

    baseLayer.current = new TileLayer({});

    map.current = new Map({
      target: mapElement.current,
      layers: [baseLayer.current],
      view: new View({
        projection: "EPSG:3857",
        center: center,
        zoom: zoom,
      }),
      controls: [],
    });

    if (onMapInit && map.current) {
      onMapInit(map.current);
    }

    if (onHover) {
      map.current.on("pointermove", onHover);
    }
    if (onClick) {
      map.current.on("singleclick", onClick);
    }

    return () => {
      if (!map.current) return;
      map.current.setTarget(undefined);
      map.current = null;
    };
  }, [mapElement]); // eslint-disable-line

  useEffect(() => {
    if (baseLayer.current && map.current) {
      const currentURLs = baseLayer.current.getSource()?.getUrls();
      if (!Array.isArray(currentURLs) || currentURLs[0] !== baseMapURL) {
        const source = new XYZ({ url: baseMapURL });

        baseLayer.current.setSource(source);
      }
    }
  }, [baseMapURL, map]);

  return (
    <div className="map w-full h-full">
      <div
        ref={mapElement}
        className="map-container"
        style={{ position: "absolute", width: "100%", height: "100%" }}
      ></div>
    </div>
  );
};

export default BaseMap;
