export const MapMenuList = ({ children, style, ...props }) => (
  <div className="w-full">
    <ul style={style} {...props} className="flex flex-col pt-1 pb-1 text-sm">
      {children}
    </ul>
  </div>
);

export const MapMenuListItem = ({ onClick, children, ...props }) => (
  <li
    onClick={onClick}
    {...props}
    className="flex gap-2 text-md items-center text-zinc-300 hover:text-white hover:bg-zinc-500/50 transition ease-in-out duration-150 rounded p-0.5 px-1.5 cursor-pointer break-keep whitespace-nowrap"
  >
    {children}
  </li>
);

export const MapMenuListHeading = ({ text }) => (
  <li className="mb-1 px-1 text-zinc-300 font-medium px-0.5 list-none">
    <h5>{text}</h5>
  </li>
);
