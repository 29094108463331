import React, { useContext, useEffect, useRef } from "react";
import { NodeDataContext } from "../../../../contexts";
import { InteractiveStateContext } from "../../../../contexts/InteractiveStateContext";
import { elements } from "./PlotElements";

import { usePlotContext } from "./PlotContext";

import { BasePlot } from "emerald-plotly-react";
import { useWorkspaceLayout, useWorkspaceLayouts } from "../../../../hooks/api/useWorkspaceLayouts";
import LoadingScreen from "../../../common/ui/LoadingScreen";

const EditableElementPlotWrapper = ({ subplotZooms, setSubplotZooms, currentDragMode }) => {
  const { nodeBinaryByLine, workspaceLayout, setWorkspaceLayout, nodeType } =
    useContext(NodeDataContext);

  const { selectedLineId, selections, setSelections } = useContext(InteractiveStateContext);
  const plotContext = usePlotContext();
  const plotRef = useRef(null);

  const { data: layouts } = useWorkspaceLayouts();

  const datasetLayoutId =
    workspaceLayout && workspaceLayout["dataset"]
      ? workspaceLayout["dataset"].id
      : layouts?.find(
          (layout) =>
            layout.title === "Dataset plot" && layout.dimensions === "3x2" && layout.node_type.includes("dataset")
        )?.id || null;

  const processingLayoutId =
    workspaceLayout && workspaceLayout["processing"]
      ? workspaceLayout["processing"].id
      : layouts?.find(
          (layout) =>
            layout.title === "Dual channel data plot" &&
            layout.dimensions === "2x2" &&
            layout.node_type.includes("processing")
        )?.id;

  const inversionLayoutId =
    workspaceLayout && workspaceLayout["inversion"]
      ? workspaceLayout["inversion"].id
      : layouts?.find((layout) => layout.title === "Inversion plot")?.id;

  const { data: datasetLayout } = useWorkspaceLayout(datasetLayoutId);
  const { data: inversionLayout } = useWorkspaceLayout(inversionLayoutId);
  const { data: processingLayout } = useWorkspaceLayout(processingLayoutId);

  useEffect(() => {
    if (datasetLayout && inversionLayout && processingLayout) {
      setWorkspaceLayout({
        dataset: datasetLayout,
        processing: processingLayout,
        inversion: inversionLayout,
      });
    }
  }, [datasetLayout, inversionLayout, processingLayout, setWorkspaceLayout]);

  useEffect(() => {
    setSelections(null); // Clear the selections when lineId changes
  }, [selectedLineId, setSelections]);

  let plot =
    workspaceLayout && workspaceLayout[nodeType] && workspaceLayout[nodeType].specification
      ? JSON.parse(JSON.stringify(workspaceLayout[nodeType].specification))
      : null;

  const setPlot = (plot) => {
    const layout = JSON.parse(JSON.stringify(workspaceLayout));
    if (!layout[nodeType]) {
      layout[nodeType] = {};
    }

    layout[nodeType].specification = plot;
    setWorkspaceLayout(layout);
  };

  const plotData = nodeBinaryByLine && selectedLineId ? plot : null;

  return (
    <div ref={plotRef} className="beryl_plot-inner">
      <BasePlot
        context={plotContext}
        plot={plotData}
        setPlot={setPlot}
        elements={elements}
        subplotZooms={subplotZooms}
        setSubplotZooms={setSubplotZooms}
        currentDragMode={currentDragMode}
        selections={selections}
        setSelections={setSelections}
        useDefaultModebar={false}
      >
        <LoadingScreen />
      </BasePlot>
    </div>
  );
};

export default EditableElementPlotWrapper;
