import { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../api/client";
import { MessagesContext } from "../contexts/MessagesContext";
import { error2message } from "./api/useSafeQuery";

const useCreateInversion = (
  projectId,
  groupId,
  datasetId,
  processingId,
  selectedEnvironment,
  title,
  isTemplate,
  parentInversionId,
  setActiveNode,
  onSuccessCallback,
  isLoading,
  setIsLoading,
  inversion_id, // optional, to make a new version of an inversion
  processing_version_id // optional, to use a specific version of the processing
) => {
  const queryClient = useQueryClient();
  const { addMessage } = useContext(MessagesContext);

  const createInversionMutation = useMutation({
    mutationFn: ({ className, args }) => {
      const endpoint = inversion_id !== undefined ? `${inversion_id}/new` : "new";
      return client.post(
        `/api/projects/${projectId}/groups/${groupId}/imports/${datasetId}/processings/${processingId}/inversions/${endpoint}`,
        {
          environment: selectedEnvironment.id,
          title: title,
          class_name: className,
          processing_version_id: processing_version_id || "latest",
          arguments: args,
          parent: parentInversionId,
          is_template: isTemplate,
        }
      );
    },
    // onSuccess, onError, etc. callbacks can be defined here for handling the mutation result
    onSuccess: (data) => {
      setIsLoading(false);
      queryClient.invalidateQueries("datasets");
      queryClient.invalidateQueries("processings");
      queryClient.invalidateQueries("inversions");
      setActiveNode([projectId, groupId, datasetId, processingId, [data.data.inversion.id, data.data.id]]);
      onSuccessCallback(data);
    },
    onError: (error) => {
      if (error.response?.status === 402) {
        console.log(error.response);
        const balance = error.response.data.balance;
        setIsLoading(false);

        const errorMessage = {
          title: "Not enough money!",
          message: `You do not have enough money to run an inversion. Your balance is: ${balance} nok.`,
        };

        addMessage({
          title: errorMessage.title,
          shortMessage: errorMessage.message,
          type: "notice",
          message: errorMessage.message,
        });
      } else if (error.response?.status === 401) {
        setIsLoading(false);

        const errorMessage = {
          title: error.response.statusText,
          message: error.response.data.message,
        };

        addMessage({
          title: errorMessage.title,
          shortMessage: errorMessage.message,
          type: "notice",
          message: errorMessage.message,
        });
      } else {
        addMessage(error2message(error));
        setIsLoading(false);
        console.error("error", error);
      }
    },
  });

  const handleFormSubmit = (formSteps) => {
    setIsLoading(true);
    console.log(formSteps);
    const className = Object.keys(formSteps)[0];
    const args = formSteps[className];
    createInversionMutation.mutate({ className, args });
  };

  return {
    createInversionMutation,
    handleFormSubmit,
    isLoading,
  };
};

export default useCreateInversion;
