import { Step } from "./Step";
import { registerSchemaComponent } from "../fieldRegistration";

export const renderFormSteps = ({ properties, value, setValue, ...props }) => {
  return Object.entries(properties).map(([key, prop]) => {
    let subValue = value[key];

    const handleSetValue = (subValue) => {
      const newValue = { ...value };
      newValue[key] = subValue;
      setValue(newValue);
    };

    return (
      <Step
        {...props}
        key={key}
        schemaFragment={prop}
        stepTitle={key}
        index={key}
        value={subValue}
        setValue={handleSetValue}
        propKey={key}
      />
    );
  });
};

export const TopLevelObjectStep = ({
  value,
  schemaFragment,
  setValue,
  propKey,
  formStyleToplevel,
  ...props
}) => {
  return (
    <div className={`object_step`}>
      {renderFormSteps({
        ...props,
        properties: schemaFragment.properties,
        value: value || {}, // Ensure nested data exists
        setValue: setValue,
        formStyleToplevel: false,
      })}
    </div>
  );
};

registerSchemaComponent(({ schemaFragment, formStyle, formStyleToplevel }) => {
  return (
    (schemaFragment?.type === "object" || schemaFragment?.type === undefined) &&
    schemaFragment?.properties &&
    formStyleToplevel &&
    Object.keys(schemaFragment?.properties).length !== 1
  );
}, TopLevelObjectStep);
