import React, { useContext } from "react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  TrashIcon,
} from "../../../assets/icons";
import ContextMenu from "../ContextMenu";
import Spinner from "../ui/Spinner";
import { SidebarContext } from "../../../contexts/SidebarContext";
import useDeleteListItem from "../../../hooks/useDeleteListItem";

const NavTree = ({ children }) => {
  return <div className="flex flex-col gap-1">{children}</div>;
};

export default NavTree;

export const NavList = ({ nestedClass = true, children }) => {
  const nestedListClass = `pl-3 ml-3.5 mt-1 mb-1 border-l border-zinc-400`;
  const finalClassName = nestedClass ? nestedListClass : `-ml-2`;
  return <ul className={finalClassName}>{children}</ul>;
};

export const NavItem = ({
  itemKey,
  activePathNumber,
  onMouseEnter,
  onMouseLeave,
  activePath,
  nodeItem,
  handleToggleClick,
  handleNodeClick,
  handleActionButtonClick,
  hoveredNode,
  openNodes,
  DoneStatusIcon,
  groupColor,
  openEditDialog,
  setIsEditMode,
  setGroupData,
  iconColorProp,
  children,
  ActionButtons,
  handleOneClickProcInvClick,
  activeNode,
}) => {
  const { setNodeItemClicked } = useContext(SidebarContext);
  const { handleDelete } = useDeleteListItem();

  // activePath[activePathNumber] is a list if we've selected a particular version of that node
  const selectedNodeId = activePath[activePathNumber]?.length
    ? activePath[activePathNumber][0]
    : activePath[activePathNumber];

  const renderStatusIcon = () => {
    if (!nodeItem.status) {
      return <div className="w-3.5 h-3.5 rounded" style={{ backgroundColor: nodeItem.color }} />;
    }
    if (!nodeItem.status || nodeItem.status === "running") {
      return <Spinner borderColor={groupColor} />;
    } else if (nodeItem.status === "done") {
      return DoneStatusIcon ? (
        <DoneStatusIcon className="w-4 h-4" style={{ color: groupColor }} color={iconColorProp} />
      ) : null;
    } else if (nodeItem.status === "failed") {
      return <ExclamationTriangleIcon className="w-4 h-4 text-red-600" />;
    }
    return null;
  };

  const handleGroupEdit = (nodeItem) => {
    setNodeItemClicked(nodeItem);
    //setGroupData(nodeItem);
    //setIsEditMode(true);
    openEditDialog(true);
  };

  return (
    <li key={itemKey} className={`mb-1`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <ContextMenu
        menuItems={[
          ...(nodeItem.data_type === "group"
            ? [
                {
                  label: `Edit`,
                  onClick: () => {
                    handleGroupEdit(nodeItem);
                  },
                  icon: PencilIcon,
                },
              ]
            : [
                {
                  label: `Rename`,
                  onClick: () => {
                    handleGroupEdit(nodeItem);
                  },
                  icon: PencilIcon,
                },
              ]),
          {
            label: `Delete`,
            onClick: () => {
              handleDelete(nodeItem.id, nodeItem.title, nodeItem.data_type);
            },
            icon: TrashIcon,
          },
        ]}
        description={
          nodeItem.data_type === "group" ? (
            <div className="border-t border-zinc-200 p-1.5 mt-1">
              <h4 className="text-zinc-600 text-xs font-medium mb-1">Description</h4>
              <p className="text-zinc-600  text-xs" dangerouslySetInnerHTML={{ __html: nodeItem.description }} />
            </div>
          ) : null
        }
      >
        <div
          className={`nav-node flex items-center justify-between gap-2 py-1.5 pr-3 pl-1.5 h-9 w-full rounded-md
         hover:bg-emerald-900/10 ${selectedNodeId === nodeItem.id ? "bg-emerald-900/10" : ""}`}
        >
          <div className="flex items-center gap-1.5 whitespace-nowrap overflow-hidden truncate">
            {openNodes && (
              <button className="toggle-btn" onClick={handleToggleClick}>
                {openNodes.includes(nodeItem.id) ? (
                  <ChevronDownIcon className="w-4 h-4 text-zinc-800" />
                ) : (
                  <ChevronRightIcon className="w-4 h-4 text-zinc-500 hover:text-zinc-800" />
                )}
              </button>
            )}

            <button
              className="node-btn flex gap-2 items-center w-fit overflow-hidden truncate"
              onClick={handleNodeClick}
            >
              <div>{renderStatusIcon()}</div>

              <div style={{ fontSize: "0.92rem" }} title={nodeItem.title}>
                {nodeItem.title}
              </div>
            </button>
          </div>

          {ActionButtons === undefined ? null : (
            <ActionButtons
              handleOneClickProcInvClick={handleOneClickProcInvClick}
              handleActionButtonClick={handleActionButtonClick}
              activePath={activePath}
              activePathNumber={activePathNumber}
              activeNode={activeNode}
              nodeItem={nodeItem}
              hoveredNode={hoveredNode}
            />
          )}
        </div>
      </ContextMenu>
      {children}
    </li>
  );
};
