import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../api/client";

const useCreateInvite = (onSuccess, onError, activeNode) => {
  const queryClient = useQueryClient();

  const projectId = activeNode[0];

  const createInviteMutation = useMutation({
    mutationFn: (data) =>
      client.post(`/api/projects/${projectId}/invite/`, {
        email: data.email,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries("project");
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    },
    onError: (error) => {
      if (typeof onError === "function") {
        onError(error);
      }
    },
  });

  const handleFormSubmit = (data) => {
    createInviteMutation.mutate(data);
  };

  return {
    createInviteMutation,
    handleFormSubmit,
  };
};

export default useCreateInvite;
