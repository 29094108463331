import { useState, useContext } from "react";
import { SidebarContext } from "../contexts/SidebarContext";

export const useEditDialog = () => {
  const { setNodeItemClicked } = useContext(SidebarContext);
  const [isEditMode, setIsEditMode] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenPopup = () => setIsOpen(true);

  const handleClosePopup = () => {
    setIsOpen(false);
    setIsEditMode(true);
  };

  const groupCreateClick = () => {
    setNodeItemClicked({ data_type: "group" });
    setIsEditMode(false);
    handleOpenPopup(true);
  };

  const miscDataCreateClick = () => {
    setNodeItemClicked({ data_type: "misc_data" });
    setIsEditMode(false);
    handleOpenPopup(true);
  };

  return {
    isEditMode,
    isOpen,
    handleOpenPopup,
    handleClosePopup,
    groupCreateClick,
    setIsEditMode,
    setIsOpen,
    miscDataCreateClick,
  };
};
