export const PaneMenu = ({
  nodeType,
  setActiveMenuOption,
  activeMenuOption,
  flightlines,
}) => {
  const buttonStyles =
    " text-sm text-zinc-500 hover:text-zinc-600 p-1 px-2 rounded transition ease-in-out 2s";

  const activeStyles = "text-zinc-700 bg-zinc-200 shadow-sm";
  const handleButtonClick = (menuOption) => {
    setActiveMenuOption(menuOption);
  };

  return (
    <div className="py-2 px-4 border-t border-b border-zinc-200 flex gap-1.5">
      <button
        onClick={() => handleButtonClick("Flightlines")}
        className={`${buttonStyles} ${
          activeMenuOption === "Flightlines" && activeStyles
        }`}
      >
        Flightlines ({flightlines?.length})
      </button>
      <button
        onClick={() => handleButtonClick("Settings")}
        className={`${buttonStyles} ${
          activeMenuOption === "Settings" && activeStyles
        }`}
      >
        Settings
      </button>
      <button
        onClick={() => handleButtonClick("Comments")}
        className={`${buttonStyles} hidden ${
          activeMenuOption === "Comments" && activeStyles
        }`}
      >
        Comments (0)
      </button>
      {(nodeType === "dataset" ||
        nodeType === "processing" ||
        nodeType === "inversion") && (
        <button
          onClick={() => handleButtonClick("Log")}
          className={`${buttonStyles} ${
            activeMenuOption === "Log" && activeStyles
          }`}
        >
          Logs
        </button>
      )}
    </div>
  );
};
