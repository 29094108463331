import { useEffect, useState } from "react";
import { registerSchemaComponent } from "../fieldRegistration";
import { FormLabel } from "../../../common/forms/Form";
import FieldDescription from "../FieldDescription";

export const ArrayOfEnumStep = ({ schemaFragment, value, propKey, setValue }) => {
  const [lastCheckedIndex, setLastCheckedIndex] = useState(null);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  value = value || [];
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setIsShiftPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleCheckboxChange = (event, newItem, index) => {
    let newValue = [...value];

    if (isShiftPressed && lastCheckedIndex !== null) {
      const start = Math.min(index, lastCheckedIndex);
      const end = Math.max(index, lastCheckedIndex);
      const range = value.slice(start, end + 1);
      
      if (event.target.checked) {
        newValue = [
          ...new Set([...newValue, ...range]),
        ];
      } else {
        newValue = newValue.filter(
          (item) => !range.includes(newItem)
        );
      }
    } else {
      newValue = event.target.checked
        ? [...newValue, newItem]
        : newValue.filter((item) => item !== newItem);
    }

    setValue(newValue);
    setLastCheckedIndex(index);
  };

  return (
    <div className="block grid grid-cols-6 gap-2 mt-1 items-start">
      <div className="col-span-6 flex flex-col gap-2 justify-between h-full">
        <div className="flex items-center">
          <FormLabel
            label={"Select " + (schemaFragment?.title || propKey)}
            htmlFor={"flightline"}
            fontWeight="font-medium"
          />
          <FieldDescription schemaFragment={schemaFragment} />
        </div>
      </div>
      <div className="col-span-6 flex flex-col gap-3">
        <ul className="border border-zinc-300 rounded-md p-1.5 px-1 text-sm w-full max-h-[300px] flex flex-col gap-0.5 overflow-scroll">
          {schemaFragment.items.enum.map((item, index) => {
            return (
              <li
                key={item}
                className={`text-zinc-700 rounded-md p-1 px-2 ${
                  value.includes(item) &&
                  "bg-emerald-900/10"
                }`}
              >
                <label className="flex items-center justfiy-between gap-2">
                  <input
                    type="checkbox"
                    value={item}
                    onChange={(event) =>
                      handleCheckboxChange(event, item, index)
                    }
                    checked={value.includes(item)}
                  />
                  {item}
                </label>
              </li>
            );
          })}
        </ul>
        <div className="w-full flex justify-between items-center flex-wrap">
          <div className="text-xs leading-5 text-zinc-600 mb-2">
            Items selected: {value.length}
          </div>
          <div className="text-xs leading-5 text-zinc-600 mb-2">
            Hold{" "}
            <span className="px-1 py-0.5 rounded border border-zinc-300">
              Shift ⇧
            </span>{" "}
            and click to select multiple items.
          </div>
        </div>
      </div>
    </div>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return (
    schemaFragment &&
    schemaFragment.items &&
    schemaFragment.items.enum !== undefined
  );
}, ArrayOfEnumStep);
