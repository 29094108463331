import { appendTypedArrays } from "./utils";

export const resistivity = {
  title: function (context, args) {
    return;
  },
  fn: function (context, args) {
    const { nodeBinaryByLine, selectedLineId } = context;
    let binaries = null;
    if (nodeBinaryByLine?.[args.dataset] === undefined) {
      binaries = nodeBinaryByLine?.[args.dataset + "_model"];
    } else {
      binaries = nodeBinaryByLine?.[args.dataset];
    }
    let binary = binaries?.[selectedLineId];
    if (!binary) return [];
    const xdist = binary.flightlines.xdist;
    const wl = xdist.map((d, idx) => d - (xdist[idx - 1] ?? d));
    const wh = xdist.map((d, idx) => ((xdist[idx + 1] ?? d) - d) / 2);
    const w = wl.map((d, idx) => d + wh[idx]);
    const x = xdist.map((d, idx) => d - wl[idx]);
    const topo = binary.flightlines.Topography;

    const resistivity = appendTypedArrays(Object.values(binary.layer_data.resistivity));
    const dep_top = appendTypedArrays(Object.values(binary.layer_data.dep_top));
    const dep_bot = appendTypedArrays(Object.values(binary.layer_data.dep_bot));
    const topos = appendTypedArrays(Object.values(binary.layer_data.resistivity).map(() => topo));
    const z = dep_bot.map((bot, idx) => topos[idx] - bot);
    const h = dep_bot.map((bot, idx) => bot - dep_top[idx]);
    const xs = appendTypedArrays(Object.values(binary.layer_data.resistivity).map(() => x));
    const ws = appendTypedArrays(Object.values(binary.layer_data.resistivity).map(() => w));

    let res = resistivity;
    let coloraxis = "ohmm";
    if (args.logspace) {
      coloraxis = "log10_ohmm";
      res = resistivity.map(Math.log10);
    }
    return [
      {
        type: "bar",
        name: "res",
        x: xs,
        y: h,
        base: z,
        width: ws,
        showlegend: false,
        legendgroup: "res",
        marker: {
          color: res,
          coloraxis: coloraxis,
        },
        hoverinfo: context.showHover ? "all" : "none"
      },
    ];
  },
  xaxis: "xdist",
  yaxis: "elevation",
  schema: (context) => {
    const datasets = Object.keys(context.nodeBinaryByLine)
      .filter((dataset) => !!Object.values(context.nodeBinaryByLine[dataset])?.[0].layer_data.resistivity)
      .map((dataset) => dataset.replace("_model", ""));

    if (!datasets.length) {
      return false;
    }

    return {
      type: "object",
      required: ["dataset"],
      properties: {
        dataset: {
          type: "string",
          enum: datasets,
        },
        logspace: {
          type: "boolean",
        },
      },
      additionalProperties: false,
    };
  },
};
