const VerticalLine = {
  title: function (context, args) {
    return;
  },
  shapes: function (context, args) {
    const { nodeBinaryByLine, selectedLineId, selectedSoundingId } = context;
    if (!nodeBinaryByLine || !selectedSoundingId) return [];
    let binary = Object.values(nodeBinaryByLine)?.[0]?.[selectedLineId];
    if (!binary || !binary.flightlines) return [];
    const xdist = binary.flightlines.xdist[selectedSoundingId];

    if (xdist === undefined) return [];

    return [
      // White line (outline)
      {
        type: "line",
        x0: xdist,
        y0: 0,
        x1: xdist,
        y1: 1,
        xref: "x",
        yref: "paper",
        line: {
          color: "white",
          width: 3,
        },
      },
      // Black line (center)
      {
        type: "line",
        x0: xdist,
        y0: 0,
        x1: xdist,
        y1: 1,
        xref: "x",
        yref: "paper",
        line: {
          color: "black",
          width: 0.8,
        },
      },
    ];
  },
  xaxis: "xdist",
  yaxis: "dbdt",
  schema: (context) => ({ type: "object", additionalProperties: false }),
};

export default VerticalLine;
