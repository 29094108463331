import client from "../../api/client";
import { useQuery } from "@tanstack/react-query";

export const fetchWorkspaceLayouts = async (layoutId) => {
  if (layoutId) {
    const { data } = await client.get(`/api/layouts/${layoutId}`);
    return data;
  } else {
    const { data } = await client.get(`/api/layouts`);
    return data;
  }
};

export const useWorkspaceLayouts = () => {
  return useQuery({
    queryKey: ["workspaceLayouts"],
    queryFn: () => fetchWorkspaceLayouts(),
    staleTime: Infinity,
    cacheTime: 8 * 60 * 60 * 1000, // 8 hours or more
  });
};

export const useWorkspaceLayout = (id) => {
  return useQuery({
    queryKey: ["workspaceLayout", id],
    queryFn: () => fetchWorkspaceLayouts(id),
    enabled: !!id,
  });
};
