import fetchBaseMaps from "../../api/fetchBaseMaps";
import { useQuery } from "@tanstack/react-query";

const fetchBaseMapsData = async () => {
  const baseMaps = await fetchBaseMaps();
  return {
    baseMaps,
    defaultBaseMap: baseMaps[0],
  };
};

export const useBaseMaps = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["baseMaps"],
    queryFn: fetchBaseMapsData,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    cacheTime: 1000 * 60 * 60 * 24 * 7, // 7 days
  });

  return {
    baseMaps: data?.baseMaps ?? [],
    defaultBaseMap: data?.defaultBaseMap ?? null,
    isLoading,
    error,
  };
};
