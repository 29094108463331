import React, { useCallback, useContext, useEffect } from "react";
import { registerSchemaComponent } from "../fieldRegistration";
import { ChannelInput } from "./ChannelInput";
import { NodeDataContext } from "../../../../contexts";
import RangeSlider from "./RangeSlider";
import { FormLabel } from "../../../common/forms/Form";
import FieldDescription from "../FieldDescription";

export const ChannelGateRangeField = ({
  value,
  propKey,
  schemaFragment,
  setValue,
}) => {
  value = value || {};
  const { nodeBinaryByLine } = useContext(NodeDataContext);

  const getChannelList = useCallback((nodeBinaryByLine, channel) => {
    if (!nodeBinaryByLine) return null;

    const data = Object.values(nodeBinaryByLine.measured)?.[0].layer_data[
      channel
    ];

    if (!data) return null;

    let length;
    if (data instanceof Float64Array) {
      length = data.length;
    } else if (Array.isArray(data)) {
      length = data.length;
    } else if (typeof data === "object") {
      length = Object.keys(data).length;
    } else {
      length = 0;
    }

    return Array.from({ length }, (_, i) => ({ title: i, id: i }));
  }, []);

  const initialChannel =
    value?.channel || Object.keys(nodeBinaryByLine.measured)?.[0];
  const fullInitialChannel = `Gate_Ch${initialChannel
    ?.toString()
    .padStart(2, "0")}`;

  const gates = getChannelList(nodeBinaryByLine, fullInitialChannel);
  const maxRange = gates ? gates.length - 1 : 1;
  const start = value.start_gate || 0;
  const end = value.end_gate || (gates ? gates.length - 1 : 1);

  const setStart = (start) => {
    setValue({ ...value, start_gate: start });
  };

  const setEnd = (end) => {
    setValue({ ...value, end_gate: end });
  };

  useEffect(() => {
    if (value.end_gate === undefined) {
      setValue({ ...value, end_gate: end });
    }
    if (value.start_gate === undefined) {
      setValue({ ...value, start_gate: start });
    }
  }, [value, start, end, setValue]);

  const onSelectChannel = useCallback(
    (channel) => {
      const newInternalValue = { ...value, channel: channel.id };
      setValue(newInternalValue);

      const newFullChannel = `Gate_Ch${channel.id.toString().padStart(2, "0")}`;
      const newGates = getChannelList(nodeBinaryByLine, newFullChannel);

      if (newGates && newGates.length > 0) {
        const newMaxRange = newGates.length - 1;
        const updatedInternalValue = {
          ...newInternalValue,
          start_gate: 0,
          end_gate: newMaxRange,
        };
        setValue(updatedInternalValue);
      } else {
        const updatedInternalValue = {
          ...newInternalValue,
          start_gate: 0,
          end_gate: 1,
        };
        setValue(updatedInternalValue);
      }
    },
    [value, nodeBinaryByLine, setValue, getChannelList]
  );

  const handleRangeChange = (newStart, newEnd) => {
    setStart(newStart);
    setEnd(newEnd);
    const newInternalValue = {
      ...value,
      start_gate: newStart,
      end_gate: newEnd,
    };
    setValue(newInternalValue);
  };

  return (
    <div>
      <div className="mt-1 mb-1">
        <ChannelInput
          value={value}
          onSelectChannel={onSelectChannel}
          propKey={propKey}
          schemaFragment={schemaFragment.properties.channel}
          label={"Channel"}
        />
      </div>
      <div className="block grid grid-cols-6 gap-2 items-center mt-1">
        <div className="col-span-2 flex items-center">
          <FormLabel
            label={"Gate range"}
            htmlFor={"channel"}
            fontWeight="font-normal"
          />
        </div>
        <div className="col-span-4 flex items-center w-full">
          <RangeSlider
            max={maxRange}
            start={start}
            end={end}
            setStart={(newStart) => handleRangeChange(newStart, end)}
            setEnd={(newEnd) => handleRangeChange(start, newEnd)}
          />
          <FieldDescription
            schemaFragment={schemaFragment.properties.start_gate}
          />
        </div>
      </div>
    </div>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return (
    schemaFragment && schemaFragment["x-reference"] === "channel-gate-range"
  );
}, ChannelGateRangeField);
