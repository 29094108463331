import React, { useContext, useEffect, useRef, useState } from "react";
import { NodeDataContext } from "../../contexts";
import { useInteractiveStates } from "../../contexts/InteractiveStateContext";
import { useProjections } from "../../hooks/api/useProjections";
import { ClipboardIcon } from "../../assets/icons";
import { copyToClipboard } from "../../utils/copyToClipboard";
import moment from "moment";

const PlotMetaDatabar = () => {
  const { nodeBinaryByLine } = useContext(NodeDataContext);
  const { selectedLineId, selectedSoundingId, hoveredLineId, hoveredSoundingId } = useInteractiveStates();
  const projectionsQuery = useProjections();
  const containerRef = useRef();
  const utmCoordinatesRef = useRef(null);
  const latlonCoordinatesRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const handleCopy = (ref) => {
    const text = ref.current.innerText;
    copyToClipboard(text);
  };

  const soundingId = hoveredSoundingId != null ? hoveredSoundingId : selectedSoundingId;
  const lineId = hoveredLineId != null ? hoveredLineId : selectedLineId;

  let xdistNum = "-";
  let xdistNumFormatted = "-";
  let xdist = "-";
  let UTMX = "-";
  let UTMY = "-";
  let latitude = "-";
  let longitude = "-";
  let epsg = "-";
  let projection = "";
  let date = "";

  if (nodeBinaryByLine) {
    const binary = Object.values(nodeBinaryByLine)?.[0]?.[lineId];

    if (binary) {
      xdistNum = binary.flightlines.xdist[soundingId];
      xdistNumFormatted = Math.floor(xdistNum);
      xdist = xdistNumFormatted;
      UTMX = binary.flightlines.UTMX[soundingId];
      UTMX = UTMX !== undefined ? Number(UTMX).toFixed(1) : "N/A";
      UTMY = binary.flightlines.UTMY[soundingId];
      UTMY = UTMY !== undefined ? Number(UTMY).toFixed(1) : "N/A";
      latitude = binary.flightlines.lat[soundingId];
      latitude = latitude !== undefined ? Number(latitude).toFixed(6) : "N/A";
      longitude = binary.flightlines.lon[soundingId];
      longitude = longitude !== undefined ? Number(longitude).toFixed(6) : "N/A";
      epsg = binary.model_info.projection;
      if (binary.flightlines.DateTime) {
        const extractDate = new Date((binary.flightlines?.DateTime[soundingId] - 25567.0) * 86400000);
        // Convert the local date-time to a moment object in UTC
        const utcDateTime = moment.utc(extractDate);
        const formattedDate = utcDateTime.format("MMM D, YYYY HH:mm:ss");
        date = formattedDate;
      }
    }

    if (projectionsQuery.data) {
      projection = projectionsQuery.data[epsg];
    }
  }

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth);
      }
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return (
    <div className="beryl_metadata-bar" ref={containerRef}>
      <div className="beryl_metadata-bar--group">
        {containerWidth > 1300 && <div>{projection} |</div>}
        <div>EPSG: {epsg} | UTMX, UTMY</div>
        <div
          ref={utmCoordinatesRef}
          className={`bg-gray-100/80 p-1 px-2 w-fit flex items-center justify-center gap-1.5 rounded-md flex-nowrap`}
          style={{ width: 170 }}
        >
          {UTMX + ", " + UTMY}
        </div>
        <button
          onClick={() => handleCopy(utmCoordinatesRef)}
          className={"p-1 rounded hover:text-zinc-300/70 hover:bg-zinc-500/50"}
        >
          <ClipboardIcon className={`w-3.5 h-3.5 text-black`} />
        </button>
        <div>WGS84 | LAT, LON</div>
        <div
          ref={latlonCoordinatesRef}
          className={`bg-gray-100/80 p-1 px-2 flex items-center justify-center gap-0.5 rounded-md flex-nowrap break-keep`}
          style={{ width: 180 }}
        >
          {latitude + ", " + longitude}
        </div>
        <button
          onClick={() => handleCopy(latlonCoordinatesRef)}
          className={"p-1 rounded hover:text-zinc-300/70 hover:bg-zinc-500/50"}
        >
          <ClipboardIcon className={`w-3.5 h-3.5 text-black`} />
        </button>
      </div>

      <div className="beryl_metadata-bar--group">
        {date} | LINE {lineId} @ {xdist}m
      </div>
    </div>
  );
};

export default PlotMetaDatabar;
