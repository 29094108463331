import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import PlotComponent from "../components/Plot/PlotComponent";
import PlotToolbar from "../components/Plot/PlotToolbar";
import PlotMetaDatabar from "../components/Plot/PlotMetaDatabar";
import { NodeDataContext } from "../contexts";
import EmptyProjectPage from "./EmptyProjectPage";
import ProgressPage from "./ProgressPage";
import MapComponent from "../components/Map/MapComponent";

const PlotPage = () => {
  const queryClient = useQueryClient();
  const { isCollapsed } = useOutletContext();
  const { activeNode, nodeData, nodeType } = useContext(NodeDataContext);

  const [isEmptyGroups, setIsEmptyGroups] = useState(false);
  const [isEmptyProject, setIsEmptyProject] = useState(false);

  const groups = queryClient.getQueryData([`groups`, activeNode[0]]);
  const statusRunning = nodeData?.status === "running";
  const statusFailed = nodeData?.status === "failed";
  const groupId = groups ? groups[0]?.id : null;

  console.log("nodeData", nodeData);

  useEffect(() => {
    if (groups && groups.length > 0) {
      setIsEmptyGroups(groups.every((group) => group.dataset_count === 0));
      setIsEmptyProject(false);
    } else {
      setIsEmptyGroups(false);
      setIsEmptyProject(true);
    }
  }, [groups]);

  useEffect(() => {
    const hasGroups = groups && groups.length > 0;
    const isEmpty = activeNode?.length === 1 && nodeData?.summary === null && !hasGroups;
    setIsEmptyProject(isEmpty);
  }, [activeNode, nodeData, groups]);

  // If the status is "running", set up an interval to invalidate a specific query
  useEffect(() => {
    let interval;
    if (statusRunning) {
      let time = 5000;
      if (nodeType === "inversion") {
        time = 60000;
      }
      interval = setInterval(() => {
        queryClient.invalidateQueries("nodeData");
        if (nodeType === "inversion") {
          queryClient.invalidateQueries("inversions");
        } else if (nodeType === "processing") {
          queryClient.invalidateQueries("processings");
        }
      }, time);
    }
    return () => {
      clearInterval(interval);
    };
  }, [statusRunning, queryClient, nodeType]);

  if (isEmptyProject || isEmptyGroups) {
    return (
      <EmptyProjectPage
        isEmptyProject={isEmptyProject}
        setIsEmptyProject={setIsEmptyProject}
        isEmptyGroups={isEmptyGroups}
        groupId={groupId}
      />
    );
  }

  if (statusRunning || statusFailed) {
    return <ProgressPage activeNode={activeNode} nodeType={nodeType} nodeData={nodeData} status={nodeData?.status} />;
  }

  if (activeNode.length === 1 && !isEmptyProject && !isEmptyGroups) return <MapComponent />;

  return (
    <div className={`beryl_plot-page ${isCollapsed ? "sidebar-collapsed" : ""}`}>
      <div className="beryl_plot-toolbar-container">
        <PlotToolbar />
      </div>
      <div className={`beryl_plot-main-content ${isCollapsed ? "sidebar-collapsed" : ""}`}>
        <PlotComponent />
        <PlotMetaDatabar />
      </div>
    </div>
  );
};

export default PlotPage;
