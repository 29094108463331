import { useState } from "react";
import SimpleSelect from "../../common/SimpleSelect";
import { SchemaComponent, registerSchemaComponent } from "./fieldRegistration";
import { ChevronUpDownIcon } from "../../../assets/icons";
import { FormLabel } from "../../common/forms/Form";

export const AnyOfFormStep = ({ value, schemaFragment, setValue, ...props }) => {
  const subSchemas = schemaFragment.anyOf.map((subSchema, idx) => {
    return {
      title: subSchema.title || (subSchema.type + (subSchema.enum !== undefined ? " (enum)" : "")),
      id: idx,
      subSchema: subSchema,
    };
  });

  const [selectedSubSchema, setSelectedSubSchema] = useState(subSchemas[0]);

  const updateSelectedSubSchema = (subSchema) => {
    setValue(undefined);
    setSelectedSubSchema(subSchema);
  };

  return (
    <div className="selectorForm flex flex-col gap-1">
      <FormLabel
        label={`Select ${schemaFragment?.description}`}
        htmlFor={schemaFragment}
        fontWeight="font-medium"
        size="base"
      />
      <SimpleSelect
        items={subSchemas}
        selected={selectedSubSchema}
        setSelected={updateSelectedSubSchema}
        width="w-72"
        dropdownWidth="w-full"
        nothingTitle="Nothing selected"
        buttonStyles="relative flex items-center gap-2 justify-between text-sm w-full bg-transparent py-1.5 px-2 border border-1 border-zinc-300 rounded-md hover:border-zinc-500"
        IconComponent={ChevronUpDownIcon}
        iconPosition="after"
      />
      <SchemaComponent
        {...props}
        schemaFragment={selectedSubSchema?.subSchema}
        value={value}
        setValue={setValue}
        formStyle={"selector-form"}
        formStyleToplevel={true}
      />
    </div>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return schemaFragment?.anyOf !== undefined;
}, AnyOfFormStep);
