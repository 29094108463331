import { useQuery } from "@tanstack/react-query";
import client from "../../api/client";

export const fetchProjections = async () => {
  const { data } = await client.get(`/api/projections/byepsg`);
  return data;
};

export function useProjections() {
  const queryFn = async () => {
    const data = await fetchProjections();
    if (data === null) {
      throw new Error("Data is null, retrying...");
    }
    return data;
  };

  return useQuery({
    queryKey: ["projections"],
    queryFn,
    retry: true, // Retry the query if it fails (throws an error)
    staleTime: 1000 * 60 * 5, // Cache valid data for 5 minutes (adjust as needed)
    cacheTime: 1000 * 60 * 10, // Cache time for the query
  });
}
