import React from "react";

const ProcessingAndInverisonIcon = ({ className, color }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 2L1.85858 1.14142C1.93668 1.06332 2.06332 1.06332 2.14142 1.14142L3 2"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M2 1V17C2 18.1046 2.89543 19 4 19H20"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M19 18L19.8586 18.8586C19.9367 18.9367 19.9367 19.0633 19.8586 19.1414L19 20"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <circle
        cx="16.25"
        cy="4.25"
        r="1.45"
        fill={color}
        stroke={color}
        strokeWidth="1.6"
      />
      <circle
        cx="10.25"
        cy="10.25"
        r="1.45"
        fill={color}
        stroke={color}
        strokeWidth="1.6"
      />
    </svg>
  );
};

export default ProcessingAndInverisonIcon;
