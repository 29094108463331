import axios from "axios";

function getCookie(name) {
  let value = "; " + document.cookie;
  let parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

const csrfToken = getCookie("csrftoken");

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // url stored in .env
  headers: {
    "Content-Type": "application/json",
    "X-CSRFToken": csrfToken,
  },
  withCredentials: true,
  validateStatus: function (status) {
    if (status === 403) {
      window.location =
        "/accounts/login/?next=" + escape(window.location.pathname);
    }
    return status >= 200 && status < 300; // default
  },
});

export default client;
