import React, { useContext } from "react";
import GroupList from "../common/NavTree/GroupList";
import { useActiveProject } from "../../hooks/useActiveProject";
import { useNavigate } from "react-router-dom";
import { NodeDataContext } from "../../contexts";
import { PlusIcon } from "../../assets/icons";
import { useEditDialog } from "../../hooks/useEditDialog";
import ActionButtons from "./ActionButtons";
import EditDialog from "./EditDialog";

const TreeNavigation = () => {
  const navigate = useNavigate();
  const { activeNode, setActiveNode } = useContext(NodeDataContext);
  const { isEditMode, isOpen, handleClosePopup, groupCreateClick, handleOpenPopup, setIsEditMode } = useEditDialog();
  const { projectTitle } = useActiveProject();

  const handleNodeClick = (path, action) => {
    console.log("node is clicked", action);
    setActiveNode(path);
    if (path.length === 2 && action) {
      navigate(`/${projectTitle}/workspace/import`);
    } else if (path.length === 3 && action) {
      navigate(`/${projectTitle}/workspace/process`);
    } else if (path.length === 4 && action) {
      navigate(`/${projectTitle}/workspace/invert`);
    } else {
      navigate(`/${projectTitle}/workspace`);
    }
  };

  return (
    <div className="beryl_tree-navigation">
      <GroupList
        activePath={activeNode}
        openEditDialog={handleOpenPopup}
        onNodeClick={handleNodeClick}
        setIsEditMode={setIsEditMode}
        ActionButtons={ActionButtons}
      />
      <button className="create-group-btn" onClick={groupCreateClick}>
        <PlusIcon className="w-5 h-5" /> Create group
      </button>
      <EditDialog isOpen={isOpen} isEditMode={isEditMode} closePopup={handleClosePopup} />
    </div>
  );
};

export default TreeNavigation;
