import { CheckIcon } from "@heroicons/react/20/solid";
import React from "react";

export const FormCheck = ({
  type = "checkbox",
  id,
  name,
  value,
  onChange,
  checked,
  labelClassName,
  isCheckBox = true,
  ...props
}) => {
  const baseClass = `relative flex jusitfy-center items-center cursor-pointer before:block before:w-[16px] before:h-[16px] before:border  ${
    isCheckBox ? "before:rounded-full" : "before:rounded"
  }`;
  const checkedClass = checked
    ? "before:bg-zinc-900  before:border-zinc-400"
    : "before:bg-transparent before:border-zinc-400";

  return (
    <>
      <input
        type={type}
        id={id}
        name={name}
        className="sr-only"
        value={value}
        onChange={onChange}
        checked={checked}
        {...props}
      />
      <label
        htmlFor={id}
        className={`${baseClass} ${labelClassName || ""} ${checkedClass}`}
      >
        {checked && type === "checkbox" && (
          <CheckIcon className="absolute m-auto ml-0.5 w-[12px] h-[12px] text-white pointer-events-none" />
        )}
      </label>
    </>
  );
};
