import React from "react";
import { iconColor } from "./color";

const MapIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6821 9.12653L14.2954 11.6514C14.191 11.7619 14.0671 11.8496 13.9306 11.9094C13.7942 11.9692 13.648 12 13.5003 12C13.3526 12 13.2064 11.9692 13.0699 11.9094C12.9335 11.8496 12.8096 11.7619 12.7052 11.6514L10.3179 9.12653C9.68863 8.46075 9.26007 7.61252 9.08645 6.68909C8.91284 5.76566 9.00197 4.8085 9.34257 3.93865C9.68317 3.06881 10.2599 2.32534 11 1.80227C11.74 1.27919 12.61 1 13.5 1C14.39 1 15.26 1.27919 16 1.80227C16.7401 2.32534 17.3168 3.06881 17.6574 3.93865C17.998 4.8085 18.0872 5.76566 17.9135 6.68909C17.7399 7.61252 17.3114 8.46075 16.6821 9.12653Z"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 5.5C15 5.89782 14.842 6.27936 14.5607 6.56066C14.2794 6.84196 13.8978 7 13.5 7C13.1022 7 12.7206 6.84196 12.4393 6.56066C12.158 6.27936 12 5.89782 12 5.5C12 5.10218 12.158 4.72064 12.4393 4.43934C12.7206 4.15804 13.1022 4 13.5 4C13.8978 4 14.2794 4.15804 14.5607 4.43934C14.842 4.72064 15 5.10218 15 5.5Z"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.5 7.5V22.5" stroke={iconColor} strokeWidth="1.6" />
      <path
        d="M14.3 12.5C14.3 12.0582 13.9418 11.7 13.5 11.7C13.0582 11.7 12.7 12.0582 12.7 12.5H14.3ZM12.7 12.5V18H14.3V12.5H12.7Z"
        fill={iconColor}
      />
      <path
        d="M18.3558 6.46154L19.5744 7.31674C19.8412 7.50394 20 7.80939 20 8.13529V20.9836C20 21.8106 19.0532 22.2802 18.3948 21.7797L14.0122 18.4479C13.6622 18.1818 13.1794 18.1755 12.8226 18.4325L7.13946 22.5256C6.75561 22.8021 6.23055 22.7717 5.88108 22.4529L1.32604 18.2974C1.11834 18.108 1 17.8398 1 17.5587V5.14945C1 4.29919 1.99377 3.83686 2.64414 4.38454L5.90732 7.13248C6.24717 7.41867 6.73509 7.44631 7.10509 7.20033L8.21635 6.46154"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MapIcon;
