import { SchemaComponent } from "./fieldRegistration";

export const renderFormInputs = ({ properties, propertyOrder, value, setValue, ...props }) => {
  const proplist = propertyOrder || Object.keys(properties);
  return proplist.map((key) => {
    const prop = properties[key];
    let subValue = value[key];

    const handleSetValue = (subValue) => {
      const newValue = { ...value };
      newValue[key] = subValue;
      setValue(newValue);
    };

    return (
      <SchemaComponent
        {...props}
        key={key}
        schemaFragment={prop}
        value={subValue}
        propKey={key}
        setValue={handleSetValue}
      />
    );
  });
};
