import { useContext } from "react";
import { NodeDataContext } from "../contexts";
import useCreateInversion from "./useCreateInversion";

export const useUpdateInversion = () => {
  const { nodeData, activeNode, setActiveNode } = useContext(NodeDataContext);
  const isTemplate = false;

  const createInversionMutation = useCreateInversion(
    activeNode ? activeNode[0] : null,
    activeNode ? activeNode[1] : null,
    activeNode ? activeNode[2] : null,
    activeNode ? activeNode[3] : null,
    { id: nodeData?.environment || "" },
    { name: nodeData?.title || "" },
    isTemplate,
    undefined,
    setActiveNode,
    nodeData?.inversion?.id,
    nodeData?.processing_version?.id
  );

  const updateInversion = ({ onSuccess }) => {
    if (nodeData && activeNode && activeNode.length === 5) {
      createInversionMutation.mutate({
        className: nodeData.class_name,
        args: nodeData.arguments,
        onSuccess,
      });
    }
  };

  return { updateInversion };
};

export default useUpdateInversion;
