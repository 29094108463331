import React from "react";
import { ArrowDownTrayIcon } from "../../../assets/icons";
import MiscDataTag from "./MiscDataTag";

const MiscDataListItemInfo = ({ style, item }) => {
  function extractFilenameFromPath(path) {
    const lastSegment = path.split("/").pop();
    const filename = lastSegment.split("-").pop();
    return filename.replace(/%/g, " ");
  }

  return (
    <div className="misc-data-list-item-info" style={style}>
      <div className="misc-data-list-item__menu--info mb-2">
        <div className="py-1 pt-2 px-3 flex flex-col gap-1 text-xs">
          <div className="font-medium">Filename</div>
          <div className="text-zinc-600">{extractFilenameFromPath(item.src)}</div>
        </div>
        <div className="py-1 px-3 flex flex-col gap-1  text-xs">
          <div className="font-medium">Filetype</div>
          <div className="text-zinc-600">{item.datatype.mime}</div>
        </div>
        <div className="py-1 px-3 flex flex-col gap-1  text-xs">
          <div className="font-medium">Datatype</div>
          <div className="text-zinc-600">{item.datatype.title}</div>
        </div>

        <div className="py-1.5 px-3 flex flex-col gap-1 border-t border-zinc-200 mt-1">
          <div className="font-medium flex gap-1.5 items-center pt-1 w-full text-xs">
            <div className="-mt-1">Tags</div>
          </div>
          <div className="flex gap-1 items-center flex-wrap">
            {item.tags?.map((tag) => {
              return <MiscDataTag item={tag} />;
            })}
          </div>
        </div>

        {item.url && (
          <div className="py-1.5 px-3 flex flex-col gap-1 border-t border-zinc-200 mt-2">
            <div className="font-medium flex gap-1.5 items-center pt-1 w-full -mt-1 text-xs">
              <div className="-mt-1">Styles</div>
              <a
                download
                href={`/rasterserver/download/project/${item.project}/raster/${item.id}`}
                className={`ml-auto text-xs flex gap-1 relative group hover:bg-emerald-900/10 rounded p-1`}
              >
                <ArrowDownTrayIcon aria-hidden="true" className="w-3.5 h-3.5" />
                <div className=" bg-zinc-800 text-zinc-200 shadow rounded-md p-1.5 px-2 absolute mt-5 -ml-20 text-xs opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-200">
                  Download zip
                </div>
              </a>
            </div>
            {item.url && item.url.styles ? (
              <ul className="flex flex-col gap-0.5 p-0.5 max-h-[100px] rounded-md border border-zinc-100 bg-zinc-50 px-2 overflow-scroll">
                {Object.keys(item.url.styles).map((key) => (
                  <li key={key} className="text-zinc-600 flex items-center gap-2 text-xs">
                    <div aria-label={key} title={key} className="text-nowrap text-ellipsis truncate clip">
                      {key}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-zinc-600 text-xs">No styles applied yet</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MiscDataListItemInfo;
