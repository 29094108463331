import React, { createContext, useContext, useState, useCallback } from "react";
import { PopupDialog } from "../components/common/PopupDialog";

const DialogContext = createContext();

export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    children: null,
    width: "md", // default width
  });

  const openDialog = useCallback((title, content, width = "md") => {
    setDialogContent({ title, children: content, width });
    setIsOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      <PopupDialog
        isOpen={isOpen}
        closeModal={closeDialog}
        title={dialogContent.title}
        children={dialogContent.children}
        width={dialogContent.width}
      />
    </DialogContext.Provider>
  );
};
