import React from "react";

const TW_ZINC_700 = "#3f3f46";

const Spinner = ({
  spinnerSize = "sm",
  borderColor = TW_ZINC_700,
  borderTopColor = "white",
}) => {
  let spinnerClass;

  if (spinnerSize === "sm") {
    spinnerClass = "spinner";
  } else if (spinnerSize === "lg") {
    spinnerClass = "spinner-lg";
  }

  return (
    <div
      className={`spinner ${spinnerClass} animate-spin rounded-full mr-1.5`}
      style={{
        borderColor: borderColor,
        borderTopColor: borderTopColor,
      }}
    ></div>
  );
};

export default Spinner;
