import React, { useContext, useState } from "react";
import Heading from "../../../common/ui/Heading";
import { ArrowUpTrayIcon } from "../../../../assets/icons";
import { NodeDataContext } from "../../../../contexts";
import { useTransactions } from "../../../../hooks/api/useTransactions";
import { LoadingPlaceholder } from "../../../common/LoadingPlaceholder";
import TransactionList from "./TransactionList";
import { useProject } from "../../../../hooks/api/useProjects";
import { FormInput } from "../../../common/forms/Form";

const Billing = () => {
  const { activeNode } = useContext(NodeDataContext);
  const [amount, setAmount] = useState(10);
  const projectId = activeNode[0];

  const projectData = useProject(projectId);

  const { data: transactions, isLoading, error } = useTransactions(projectId);

  return (
    <div className="w-full h-full">
      <div className="p-4 px-8 flex flex-col gap-3 w-full relative h-full bg-white">
        <div className="flex justify-between items-center">
          <Heading size="h4">Transaction history</Heading>

          <div className="flex justify-right items-right">
            <div className="mx-2 py-2 pl-3 pr-10 text-zinc-900">
              Current balance: {projectData.data?.balance}NOK
            </div>

            <FormInput
              type="text"
              value={amount}
              onChange={(event) => setAmount(event.target.value)}
              className="block w-36  rounded-md border-0 mx-2 py-2 pl-3 pr-20  text-zinc-900 ring-1  ring-inset ring-zinc-300  placeholder:text-zinc-400  focus:ring-2 focus:ring-inset  focus:ring-zinc-600 sm:text-sm  sm:leading-6 focus:outline-none"
            />

            <button
              onClick={() => {
                window.open(
                  "/payment/init?project=" + projectId + "&amount=" + amount,
                  "_blank",
                  { noopener: false }
                );
              }}
              className="bg-zinc-600 text-white text-sm mx-2 pl-3 pr-4 py-2 rounded-md hover:bg-zinc-700 flex items-center gap-1.5"
            >
              <ArrowUpTrayIcon className="w-4 h-4" /> Top up
            </button>
          </div>
        </div>
        <div className="section">
          {isLoading ? (
            <LoadingPlaceholder />
          ) : transactions ? (
            <TransactionList data={transactions} />
          ) : (
            error && <div>Error loading data</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Billing;
