import React, { useState } from "react";
import { DragArea, Draggable } from "./Draggable";
import { ChevronDoubleDownIcon, PlusIcon } from "@heroicons/react/20/solid";
//import { renderFormInputs } from "./renderFormInputs";
import { registerSchemaComponent } from "../fieldRegistration";
import { Step } from "./Step";

const ProcessingParameterButton = ({ onClick, label, count, isActive }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="relative group">
      <button
        type="button"
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`text-sm py-1 px-2 pr-1 relative group rounded-md whitespace-nowrap w-full flex gap-2 justify-between items-center ${
          isActive
            ? "bg-emerald-900/5"
            : "text-zinc-700 hover:text-zinc-900 hover:bg-emerald-900/5"
        }`}
        aria-label={label}
      >
        <span className="flex-1 min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-left flex items-center">
          {label}
        </span>

        {(isHovered || count) && (
          <div
            className={`flex-shrink-0 text-xs rounded-md text-zinc-800 w-5 h-5 flex items-center justify-center 
             ${isHovered ? "bg-emerald-800" : "bg-emerald-900/10"} 
            `}
          >
            {isHovered ? <PlusIcon className="w-4 h-4 text-white" /> : count}
          </div>
        )}
      </button>
    </div>
  );
};

export const SequenceFormStep = ({
  value,
  schemaFragment,
  setValue,
  ...props
}) => {
  const schema = schemaFragment;

  const setValueInner = (value) => {
    setValue(
      value.map((step) => {
        const propertyGroupName = Object.keys(step.properties)[0];
        return {
          [propertyGroupName]: step.data,
        };
      })
    );
  };

  const valueInner = value
    ? value.map((step, index) => {
        const propertyGroupName = Object.keys(step)[0];
        const nestedData = step[propertyGroupName] || {};
        const formattedTitle = propertyGroupName.split(".").pop();

        const schemaItem = schema.items.anyOf.find((item) =>
          Object.keys(item.properties).includes(propertyGroupName)
        );

        const nestedSchema = schemaItem
          ? schemaItem.properties[propertyGroupName]
          : "";

        const properties = schemaItem
          ? { [propertyGroupName]: nestedSchema }
          : {};

        return {
          id: `${formattedTitle}-${index}`, // Ensure a consistent, unique ID
          title: formattedTitle,
          properties: properties,
          data: nestedData,
          schema: nestedSchema,
        };
      })
    : [];

  const stepCounts = {};
  valueInner.map((step) => {
    const name = step.title;
    if (stepCounts[name] === undefined) {
      stepCounts[name] = 1;
    } else {
      stepCounts[name] = stepCounts[name] + 1;
    }
    return null;
  });

  const steps = schema?.items.anyOf.map((item, index) => {
    return {
      id: index,
      title: item.title,
      properties: item.properties,
    };
  });

  const handleAddStep = (stepTemplate) => {
    const initData = {};
    const propertyGroupName = Object.keys(stepTemplate.properties)[0];
    const properties =
      stepTemplate.properties[propertyGroupName].properties || {};

    Object.keys(properties).forEach((key) => {
      initData[key] =
        properties[key].default !== undefined
          ? properties[key].default
          : properties[key].type === "number"
          ? 0
          : properties[key].type === "boolean"
          ? false
          : "";
    });

    const newStep = {
      ...stepTemplate,
      id: Date.now() + Math.random(),
      data: initData,
    };

    setValueInner([...valueInner, newStep]);
  };

  const handleRemoveStep = (id) => {
    const stepToRemove = valueInner.find((step) => step.id === id);
    if (!stepToRemove) return;

    setValueInner(valueInner.filter((step) => step.id !== id));
  };

  const newProps = { ...props };
  newProps.formStyle = "sequence-form";

  return (
    <div className="space-y-4 w-full flex gap-4 justify-end">
      <div className="w-full">
        {valueInner.length === 0 ? (
          <div className="w-full rounded-md bg-zinc-100 h-20 mt-4 text-zinc-500 flex justify-center items-center">
            No form steps added
          </div>
        ) : (
          <DragArea formSteps={valueInner} setFormSteps={setValueInner}>
            {valueInner?.map((step, index) => {
              const setStepValue = (subValue) => {
                const newValue = valueInner.map((oldStep, oldIndex) => {
                  if (oldIndex !== index) {
                    return oldStep;
                  } else {
                    const newStep = { ...step };
                    newStep.data = subValue;
                    return newStep;
                  }
                });
                setValueInner(newValue);
              };

              return (
                <Draggable key={step.id}>
                  <Step
                    {...newProps}
                    schemaFragment={step.schema}
                    stepTitle={step.title}
                    index={index}
                    onRemove={() => handleRemoveStep(step.id, step.title)}
                    value={step.data}
                    setValue={setStepValue}
                  />
                </Draggable>
              );
            })}
          </DragArea>
        )}
      </div>
      <div id="add-step-buttons" className="w-[300px]">
        <div className="w-[300px]">
          <div className="flex flex-col gap-1 p-2.5 pl-3 border border-zinc-200 rounded-md p-1 h-fit mb-2">
            <div className="flex gap-3 justify-between items-center font-medium w-full">
              Add steps <ChevronDoubleDownIcon className="w-4 h-4" />
            </div>
            <div>
              {steps?.map((step) => (
                <ProcessingParameterButton
                  key={step.title}
                  onClick={() => handleAddStep(step)}
                  label={step.title}
                  count={stepCounts[step.title]}
                  isActive={stepCounts[step.title] > 0}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

registerSchemaComponent(({ schemaFragment }) => {
  return (
    schemaFragment?.type === "array" &&
    schemaFragment?.items?.anyOf !== undefined &&
    schemaFragment?.items?.anyOf[0].type === "object"
  );
}, SequenceFormStep);
