export const splitBinaryByLine = (binary) => {
  if (!(binary?.flightlines?.Line.length > 0)) {
    return {};
  }
  const lines = binary?.flightlines?.Line.filter(
    (value, idx, arr) => arr.indexOf(value) === idx
  );

  return Object.fromEntries(
    Array.from(lines).map((line) => {
      const filt = (el, idx) => binary?.flightlines?.Line[idx] === line;

      const filtCols = (table) =>
        Object.fromEntries(
          Object.entries(table).map(([col, values]) => [
            col,
            values?.filter ? values.filter(filt) : null,
          ])
        );

      return [
        line,
        {
          ...binary,
          flightlines: {
            ...filtCols(binary.flightlines),
            apply_idx: binary.flightlines?.Line.map((el, idx) =>
              BigInt(idx)
            ).filter(filt),
          },
          layer_data: Object.fromEntries(
            Object.entries(binary.layer_data).map(([layer_name, layer]) => [
              layer_name,
              filtCols(layer),
            ])
          ),
        },
      ];
    })
  );
};

const concatBinaryArrays = (...arrs) => {
  const anti = arrs.filter((a) => !a);
  if (anti.length > 0) return anti[0];
  if (arrs.length === 0) return null;

  const len = arrs.reduce((a, b) => a + b.length, 0);

  var c = new arrs[0].constructor(len);
  var pos = 0;
  var idx;
  for (idx = 0; idx < arrs.length; idx++) {
    c.set(arrs[idx], pos);
    pos += arrs[idx].length;
  }
  return c;
};

const concatTables = (...tables) =>
  Object.fromEntries(
    Object.entries(tables[0]).map(([col, values]) => [
      col,
      concatBinaryArrays(...tables.map((table) => table[col])),
    ])
  );

export const mergeBinaries = (...binaries) => {
  return {
    ...binaries[0],
    flightlines: concatTables(...binaries.map((binary) => binary.flightlines)),
    layer_data: Object.fromEntries(
      Object.entries(binaries[0].layer_data).map(([layer_name, layer]) => [
        layer_name,
        concatTables(
          ...binaries.map((binary) => binary.layer_data[layer_name])
        ),
      ])
    ),
  };
};

const tableToTypedArrays = (orig, tbl) => {
  const convertCol = (constr, col) => {
    if (constr.name.indexOf("Big") !== -1) {
      col = col.map(BigInt);
    }
    return new constr(col);
  };

  return Object.fromEntries(
    Object.entries(tbl).map(([colname, col]) => [
      colname,
      convertCol(orig[colname].constructor, col),
    ])
  );
};

const manualEditToTypedArrays = (orig, manualEdit) => {
  return {
    ...manualEdit,
    flightlines: tableToTypedArrays(orig.flightlines, manualEdit.flightlines),
    layer_data: Object.fromEntries(
      Object.entries(manualEdit.layer_data).map(([colname, col]) => {
        return [colname, tableToTypedArrays(orig.layer_data[colname], col)];
      })
    ),
  };
};

const globalizeApplyIdx = (orig, manualEdit) => {
  return manualEdit.flightlines.apply_idx.map(
    (diff_apply_idx) => orig.flightlines.apply_idx[diff_apply_idx]
  );
};

const manualEditGlobalizeApplyIdx = (orig, manualEdit) => {
  return {
    ...manualEdit,
    flightlines: {
      ...manualEdit.flightlines,
      apply_idx: globalizeApplyIdx(orig, manualEdit),
    },
  };
};

export const mergeManualEdits = (nodeBinaryByLine, manualEdits) => {
  return mergeBinaries(
    ...Object.entries(manualEdits).map(([line, manualEdit]) => {
      return manualEditGlobalizeApplyIdx(
        nodeBinaryByLine[line],
        manualEditToTypedArrays(nodeBinaryByLine[line], manualEdit)
      );
    })
  );
};
