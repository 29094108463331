import React, { useContext, useState } from "react";
import { fetchGroups } from "../../../hooks/api/useGroups";
import { handleToggleClick } from "./handlers";
import DatasetList from "./DatasetList";
import { LoadingPlaceholder } from "../LoadingPlaceholder";
import { NavItem, NavList } from ".";
import { useActiveProject } from "../../../hooks/useActiveProject";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { MessagesContext } from "../../../contexts";
import { error2message } from "../../../hooks/api/useSafeQuery";

const GroupList = ({ activePath, onNodeClick, openEditDialog, setIsEditMode, setGroupData, ActionButtons }) => {
  const [openNodes, setOpenNodes] = useState([]);
  const [hoveredNode, setHoveredNode] = useState(null);
  const { projectId } = useActiveProject();
  const { addMessage } = useContext(MessagesContext);
  const queryClient = useQueryClient();

  const {
    data: groups,
    isLoading: isLoadingGroups,
    isError,
  } = useQuery({
    queryKey: ["groups", projectId],
    queryFn: () => fetchGroups(projectId),
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: Infinity, // 10 minutes
    refetchOnWindowFocus: false,
    retry: false, // Disable retries on failure
    onError: (error) => {
      console.error("Error fetching groups:", error);
      addMessage(error2message(error));
      queryClient.removeQueries(["groups", projectId]); // Remove the failed query from the cache
    },
  });
  const sortedArray = groups?.sort((a, b) => b.id - a.id);

  const handleActionButtonClick = (group) => {
    onNodeClick([projectId, group.id], "create");
  };

  if (isLoadingGroups)
    return (
      <div className="p-2">
        <LoadingPlaceholder height="h-3" rows={3} />
      </div>
    );

  if (isError) {
    return <div className="p-2 text-zinc-600">Groups unavailable</div>;
  }

  if (sortedArray?.length === 0) {
    return null;
  }

  return (
    <NavList nestedClass={false}>
      {sortedArray?.map((group) => (
        <NavItem
          key={group.id}
          activePathNumber={1}
          onMouseEnter={() => setHoveredNode(group.id)} // set the hovered node id
          onMouseLeave={() => setHoveredNode(null)} // clear it when the mouse leaves
          nodeItem={group}
          activePath={activePath}
          handleToggleClick={() => handleToggleClick(group.id, setOpenNodes)}
          handleNodeClick={() => handleToggleClick(group.id, setOpenNodes)}
          handleActionButtonClick={() => handleActionButtonClick(group)}
          hoveredNode={hoveredNode}
          openNodes={openNodes}
          openEditDialog={openEditDialog}
          setIsEditMode={setIsEditMode}
          setGroupData={setGroupData}
          ActionButtons={ActionButtons}
        >
          {openNodes.includes(group.id) && (
            <DatasetList
              groupId={group.id}
              groupColor={group.color}
              activePath={activePath}
              onNodeClick={onNodeClick}
              ActionButtons={ActionButtons}
              openEditDialog={openEditDialog}
            />
          )}
        </NavItem>
      ))}
    </NavList>
  );
};

export default GroupList;
