import React, { useState } from "react";
import { useEnvironment, useEnvironmentProgress } from "../../hooks/api/useEnvironments";
import jsyaml from "js-yaml";
import FlyoutContainer from "../common/FlyoutContainer";

const EnvironmentLogs = ({ environmentId }) => {
  const logsQuery = useEnvironmentProgress(environmentId);
  const yamlifyLogs = jsyaml.dump(logsQuery.data?.logs || null);
  const lines = yamlifyLogs?.split("\n");

  return (
    <div className="flex relative h-full overflow-y-scroll p-3 px-4">
      <ul className="line-numbers w-4 text-sm text-zinc-400 pb-5 pr-5">
        {lines.slice(0, -1).map((_, index) => (
          <li key={index} className="line-number">
            {index + 1}
          </li>
        ))}
      </ul>

      <pre
        className="text-sm text-zinc-600 h-fit mb-1 ml-1 overflow-x-scroll overflow-y-hidden"
        style={{ borderRadius: "0 5px 20px 0" }}
      >
        {yamlifyLogs}
      </pre>
    </div>
  );
};

const EnvironmentDetails = ({ setEnvironmentId, environmentId }) => {
  const [displayType, setDisplayType] = useState("specification");

  let { data: environment } = useEnvironment(environmentId);
  if (environment !== undefined) {
    environment = { ...environment };
    environment.specification = jsyaml.dump(environment.specification);
    environment.introspection = jsyaml.dump(environment.introspection);
  }

  return (
    <FlyoutContainer
      heading={environment?.title}
      toggleVisibility={() => {
        setEnvironmentId(undefined);
      }}
      position="right"
    >
      <>
        <div className="h-full overflow-hidden">
          <div className="py-2 px-5 border-t border-b border-zinc-200 flex gap-4">
            <button
              onClick={() => setDisplayType("specification")}
              className="font-medium text-sm hover:text-zinc-600 focus:text-zinc-600 false"
            >
              Specification
            </button>
            <button
              onClick={() => setDisplayType("introspection")}
              className="font-medium text-sm hover:text-zinc-600 focus:text-zinc-600 false"
            >
              Introspection
            </button>
            <button
              onClick={() => setDisplayType("logs")}
              className="font-medium text-sm hover:text-zinc-600 focus:text-zinc-600 false"
            >
              Logs
            </button>
          </div>

          <div className="overflow-scroll h-full flex-grow">
            {(() => {
              if (displayType === "specification") {
                return <pre>{environment?.specification}</pre>;
              } else if (displayType === "introspection") {
                return <pre>{environment?.introspection}</pre>;
              } else if (displayType === "logs") {
                return <EnvironmentLogs environmentId={environmentId} />;
              }
            })()}
          </div>
        </div>
        <div className="button-group flex flex-row justify-between mt-2"></div>
      </>
    </FlyoutContainer>
  );
};

export default EnvironmentDetails;
