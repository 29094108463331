import { useEffect, useState } from "react";

function useSharedState(name, defaultValue) {
  if (defaultValue === undefined) defaultValue = null;
  const [value, setValue] = useState(defaultValue);
  defaultValue = JSON.stringify(defaultValue);

  useEffect(() => {
    const onChange = (e) => {
      if (e.key === name) {
        setValue(JSON.parse(e.newValue || defaultValue));
      }
    };

    setValue(JSON.parse(localStorage.getItem(name) || defaultValue));
    window.addEventListener("storage", onChange);
    return () => {
      window.removeEventListener("storage", onChange);
    };
  }, [name, defaultValue]);

  const setValueUser = (v) => {
    if (v === undefined) v = null;
    setValue(v);
    localStorage.setItem(name, JSON.stringify(v));
  };

  return [value, setValueUser];
}

export default useSharedState;
